import {StrictFormControl} from '@koddington/ga-common';
import {WlTaskTrackerPartnerStateTypes} from '../../autogen/TaskTrackerPartner';
import {PartnersPromoSelectorViewModel} from '../components/partner-registries-crud/models/partners-promo-selector-view-model';
import {PartnersBtagSelectorViewModel} from '../components/partner-registries-crud/models/partners-btag-selector-view-model';

export class WlPartnerRegistriesViewModel {
  public id: StrictFormControl<number> = new StrictFormControl<number>(null);
  public name: StrictFormControl<string> = new StrictFormControl<string>(null);
  public state: StrictFormControl<WlTaskTrackerPartnerStateTypes> = new StrictFormControl<WlTaskTrackerPartnerStateTypes>(WlTaskTrackerPartnerStateTypes.Active);
  public isCanGiveReward: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
  public externalId: StrictFormControl<string> = new StrictFormControl<string>(null);
  public virtualHostTitle: StrictFormControl<string> = new StrictFormControl<string>(null);
  public regUrl: StrictFormControl<string> = new StrictFormControl<string>(null);
  public agreementRequired: StrictFormControl<boolean> = new StrictFormControl<boolean>(true);
  public agreementTextIntro: StrictFormControl<string> = new StrictFormControl<string>(null);
  public agreementTextDetails: StrictFormControl<string> = new StrictFormControl<string>(null);
  public mediaPromoSettings: StrictFormControl<PartnersPromoSelectorViewModel[]> = new StrictFormControl<PartnersPromoSelectorViewModel[]>([]);
  public mediaBtagSettings: StrictFormControl<PartnersBtagSelectorViewModel[]> = new StrictFormControl<PartnersBtagSelectorViewModel[]>([]);
}
