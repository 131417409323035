import { StrictFormControl } from '@koddington/ga-common';
import { Dayjs } from 'dayjs';
import { DailyTasksPlayersCardsSelectorViewModel } from './daily-tasks-players-cards-selector-view-model';
import {
    WlCrmTaskGroupSearchModel,
    WlDailyTaskStates,
    WlDtFreeBetLootBoxListModel,
} from '../../../../autogen/DailyTasks';
import { PickemOutcomeViewModel } from './pickem-outcome-view-model';
import { WlDailyTasksLimitsConsts } from '../../../../../consts/wl-daily-tasks-limits-consts';


export class DailyTasksDaysCrudViewModel {
    public id: StrictFormControl<number> = new StrictFormControl<number>(null);
    public name: StrictFormControl<string> = new StrictFormControl<string>(null);
    public startDate: StrictFormControl<Dayjs> = new StrictFormControl<Dayjs>(null);
    public endDate: StrictFormControl<Dayjs> = new StrictFormControl<Dayjs>(null);
    public crmTaskGroup: StrictFormControl<WlCrmTaskGroupSearchModel> = new StrictFormControl<WlCrmTaskGroupSearchModel>(null);
    public lootBox: StrictFormControl<WlDtFreeBetLootBoxListModel> = new StrictFormControl<WlDtFreeBetLootBoxListModel>(null);
    public description: StrictFormControl<string> = new StrictFormControl<string>(null);
    public isOffPlayDay: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
    public isUnconditionalDay: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
    public isActive: StrictFormControl<boolean> = new StrictFormControl<boolean>(null);
    public playerCards: StrictFormControl<DailyTasksPlayersCardsSelectorViewModel[]> = new StrictFormControl<DailyTasksPlayersCardsSelectorViewModel[]>([]);
    public state: StrictFormControl<WlDailyTaskStates> = new StrictFormControl<WlDailyTaskStates>(null);

    public isPickemMode: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
    public usersForecastDeadline: StrictFormControl<Dayjs> = new StrictFormControl<Dayjs>(null);
    public pickemId: StrictFormControl<number> = new StrictFormControl<number>(null);
    public pickemText: StrictFormControl<string> = new StrictFormControl<string>(null);
    public forecastTypeImageUrl: StrictFormControl<string> = new StrictFormControl<string>(null);
    public forecastTypeText: StrictFormControl<string> = new StrictFormControl<string>(null);
    public outcomes: StrictFormControl<PickemOutcomeViewModel[]> = new StrictFormControl<PickemOutcomeViewModel[]>(
        [
            ...Array(WlDailyTasksLimitsConsts.PickemCardsCount).keys()
        ].map((order) => new PickemOutcomeViewModel(order + 1))
    );
}
