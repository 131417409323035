import {StrictFormControl} from '@koddington/ga-common';
import {
    WlBattlePassFreeBetLootBoxCrudViewModel
} from '../../../../../battle-pass/components/wl-battle-pass-free-bet-loot-box-crud/view-models/wl-battle-pass-free-bet-loot-box-crud-view-model';
import {WlBattlePassFreeBetLootBoxAutocompleteStrategy} from '../../../../../battle-pass/strategies/wl-battle-pass-free-bet-loot-box-autocomplete.strategy';
import {WlFreeBetLootBoxListModel} from '../../../../../autogen/BattlePass';
import {WlBattlePassFreeBetLootBoxesScrollServiceAdapter} from '../../../../../battle-pass/services/wrapper-service/wl-battle-pass-free-bet-loot-boxes.scroll-service-adapter';
import {EventEmitter} from '@angular/core';

export class DailyTasksLootBoxGradeViewModel {
    public gradeStart: StrictFormControl<number> = new StrictFormControl<number>(0);
    public lootBox: StrictFormControl<WlBattlePassFreeBetLootBoxCrudViewModel> = new StrictFormControl(new WlBattlePassFreeBetLootBoxCrudViewModel());

    // freebet lootboxes load components
    public lootBoxStrategy: WlBattlePassFreeBetLootBoxAutocompleteStrategy;
    public selectedLootBoxModel: StrictFormControl<WlFreeBetLootBoxListModel> = new StrictFormControl<WlFreeBetLootBoxListModel>(null);
    public lootBoxUpdateEmitter: EventEmitter<void> = new EventEmitter<void>;

    constructor(protected readonly _apiClient: WlBattlePassFreeBetLootBoxesScrollServiceAdapter) {
        this.lootBoxStrategy = new WlBattlePassFreeBetLootBoxAutocompleteStrategy(_apiClient);
    }
}
