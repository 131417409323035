//export class WlAuthGuardService {}

import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { WlAuthGuardWrapperService } from './auth-guard-wrapper-service';
import { WlLogoutGuardWrapperService } from './logout-guard-wrapper-service';

export const WlAuthGuardService: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> => {
  return inject(WlAuthGuardWrapperService).canActivate(route, state);
};

export const WlLogoutGuardService: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  return inject(WlLogoutGuardWrapperService).canActivate(route, state);
};
