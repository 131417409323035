import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlockUIModule } from 'ng-block-ui';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { GaCommonModule } from '@koddington/ga-common';
import { RootComponent } from './components/root/root.component';
import { LayoutsModule } from '../layouts/layouts.module';
import { TrackerModule } from '../trackers/app-tracker.module';
import { UnauthorizedErrorInterceptor } from './components/authorize/unauthorized-error-interceptor';
import { WlDefaultPage } from './components/default/default-page.component';
import { RootRoutes } from './root-module.routes';
import { SharedModule } from '../shared/shared.module';
import { WlLoginComponent } from './components/authorize/login/app-login-component';
import { WlLogoutComponent } from './components/authorize/logout/app-logout-component';
import { WlBattlePassModule } from '../battle-pass/wl-battle-pass.module';
import { RateRaceModule } from '../rate-race-area51/rate-race-area51.module';

@NgModule({
  declarations: [
    WlDefaultPage,
    WlLoginComponent,
    WlLogoutComponent,
    RootComponent,
  ],
  imports: [
    CommonModule,
    BlockUIModule.forRoot(),
    RouterModule.forChild(RootRoutes),
    LayoutsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatIconModule,
    FormsModule,
    SharedModule,
    GaCommonModule,
    TrackerModule,
    WlBattlePassModule,
    RateRaceModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedErrorInterceptor,
      multi: true,
    },
  ],
})
export class WlRootModule {}
