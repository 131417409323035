import {DropdownItem, EnumExtensions, IDropdownStrategy} from '@koddington/ga-common';
import {Observable, of} from 'rxjs';
import {WlTaskTrackerRewardTypes, WlTaskTrackerRewardTypesExtensions} from '../../autogen/TaskTrackerRewards';

export class WlTaskTrackerRewardTypesStrategy implements IDropdownStrategy<WlTaskTrackerRewardTypes> {
  getEntities(): Observable<Array<WlTaskTrackerRewardTypes>> {
    const entities = EnumExtensions.toArray(WlTaskTrackerRewardTypes);
    return of(entities);
  }

  map(model: WlTaskTrackerRewardTypes): DropdownItem<WlTaskTrackerRewardTypes> {
    const item = new DropdownItem<WlTaskTrackerRewardTypes>();
    item.entity = model;
    item.title = WlTaskTrackerRewardTypesExtensions.format(model);
    item.id = Number(model);

    return item;
  }
}
