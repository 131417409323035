import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-wl-page-header',
    templateUrl: './wl-page-header.component.html',
    styleUrls: ['./wl-page-header.component.scss'],
})
export class WlPageHeaderComponent implements OnInit {
    @Input()
    header = '';

    @Input()
    ignoreTitleSet: boolean;

    constructor(private titleService: Title) {}
    ngOnInit() {
        if (!this.ignoreTitleSet)
            this.titleService.setTitle(this.header);
    }
}
