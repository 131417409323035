import { StrictFormControl } from '@koddington/ga-common';


export class PickemOutcomeViewModel {
    public id: StrictFormControl<number> = new StrictFormControl<number>(null);
    public name: StrictFormControl<string> = new StrictFormControl<string>(null);
    public description: StrictFormControl<string> = new StrictFormControl<string>(null);
    public imageUrl: StrictFormControl<string> = new StrictFormControl<string>(null);
    public order: StrictFormControl<number> = new StrictFormControl<number>(null);

    constructor(orderSetup: number) {
        this.order.strictValue = orderSetup;
    }
}
