import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from '@koddington/ga-common';

@Pipe({
    name: 'wlBoolean',
})
export class WlBooleanPipe implements PipeTransform {
    public transform(value?: boolean): string {
        if (isNullOrUndefined(value)) {
            return '-';
        }

        return value === true ? 'Да' : 'Нет';
    }
}
