import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter, finalize } from 'rxjs/operators';
import { WlRateRaceLootboxCrudValidator } from './validator/wl-rate-race-lootbox-crud-validator';
import { WlRateRaceLootBoxViewModel } from '../../models/wl-rate-race-loot-box-view-model';
import { WlRateRaceRewardViewModel } from '../../models/wl-rate-race-reward-view-model';
import { GaMessagingService, isNullOrUndefined, StrictError } from '@koddington/ga-common';
import { BattlePassRateRaceService, WlRateRaceLootBoxCreateForm, WlRateRaceLootBoxModel, WlRateRaceLootBoxUpdateForm, WlRateRaceRewardUpdateForm } from '../../../autogen/BattlePass';
import { builderModelToStrict, builderStrictToModel, gaMap } from '../../../shared/common/operation/builder-operation';

@Component({
    selector: 'app-wl-rate-race-lootbox-crud',
    templateUrl: './wl-rate-race-lootbox-crud.component.html',
    styleUrls: ['./wl-rate-race-lootbox-crud.component.scss']
})
export class WlRateRaceLootboxCrudComponent implements OnInit, OnDestroy {
    public loading: boolean;
    public viewModel: WlRateRaceLootBoxViewModel = new WlRateRaceLootBoxViewModel();
    public readonly userManipulationsSource = new Subject<void>();
    public errors: StrictError[] = [];
    private readonly validator = new WlRateRaceLootboxCrudValidator();

    constructor(private readonly _messaging: GaMessagingService,
                private readonly _router: Router,
                private readonly _route: ActivatedRoute,
                private readonly _titleService: Title,
                private readonly _service: BattlePassRateRaceService) {
    }

    public ngOnInit(): void {
        this.loading = true;

        this.viewModel.id.strictValue = isNullOrUndefined(this._route.snapshot.params['id']) ? null : this._route.snapshot.params['id'];
        this.load();
        this._titleService.setTitle('Создание / Редактирование лутбокса');
        this.subscriptionToValidate();
    }

    public ngOnDestroy(): void {
    }

    public onSave(byPaging: boolean = false): void {
        this.errors = this.validator.validate(this.viewModel);
        if (this.errors.length === 0)
            this.save(byPaging);
    }

    private save(byPaging: boolean): void {
        this.loading = true;

        if (this.viewModel.id.hasStrictValue) {
            this.update(byPaging);
        } else {
            this.create();
        }
    }

    private load(): void {
        if (this.viewModel.id.hasStrictValue) {
            this._service.getLootBoxById(this.viewModel.id.strictValue)
                .pipe(
                    filter((value) => !this._messaging.tryShowError(value)),
                    untilDestroyed(this),
                    finalize(() => this.loading = false)
                ).subscribe((result) => {
                this.loadModel(result.result);
            });
            return;
        }

        this.userManipulationsSource.next();
        this.loading = false;
    }

    private loadModel(model: WlRateRaceLootBoxModel): void {
        builderModelToStrict(this.viewModel, model, [{
            sourceMapProperty: 'content',
            targetMapProperty: 'content',
            mapObject: gaMap(model.content, (value) => {
                const mapped = new WlRateRaceRewardViewModel();
                builderModelToStrict(mapped, value, null, null);
                mapped.name.strictValue = value.reward.name;
                return mapped;
            }),
        }], null, null);

        this.userManipulationsSource.next();
    }

    private create(): void {
        const form = builderStrictToModel(WlRateRaceLootBoxCreateForm, this.viewModel, null, null, null);
        this._service
            .addLootBox(form)
            .pipe(
                filter((value) => !this._messaging.tryShowError(value)),
                untilDestroyed(this),
                finalize(() => this.loading = false)
            ).subscribe(() => {
            this._router.navigate(['/menu/raterace/lootBox/list']);
        });
    }

    private update(byPaging: boolean): void {
        const form = this.createUpdateForm();
        this._service
            .updateLootBox(form)
            .pipe(
                filter((value) => !this._messaging.tryShowError(value)),
                untilDestroyed(this),
                finalize(() => this.loading = false)
            ).subscribe(() => {
            if (!byPaging)
                this._router.navigate(['/menu/raterace/lootBox/list']);
        });
    }

    private createUpdateForm(): WlRateRaceLootBoxUpdateForm {
        return builderStrictToModel(
            WlRateRaceLootBoxUpdateForm,
            this.viewModel,
            [
                {
                    sourceMapProperty: 'editContent',
                    targetMapProperty: 'content',
                    mapObject: gaMap(this.viewModel.editContent.strictValue, (value) => {
                        const mapped = new WlRateRaceRewardUpdateForm();
                        mapped.order = value.order.strictValue;
                        mapped.exchangeAmount = value.exchangeAmount.strictValue;
                        mapped.rewardId = value.reward.strictValue.id;
                        return mapped;
                    }),
                },
            ], null, null
        );
    }

    private subscriptionToValidate(): void {
        this.userManipulationsSource.pipe(untilDestroyed(this)).subscribe(() => {
            this.errors = this.validator.validate(this.viewModel);
        });
        this.userManipulationsSource.next();
    }

    get purpose(): string {
        if (this.viewModel.id.hasStrictValue) {
            return `Редактирование лутбокса № ${this.viewModel.id.strictValue}`;
        }
        return 'Создание лутбокса';
    }

    get saveButtonText(): string {
        if (this.viewModel.id.hasStrictValue) {
            return 'Обновить';
        }
        return 'Сохранить';
    }

    get isEdit(): boolean {
        return this.viewModel.id.hasStrictValue;
    }

    get isValid(): boolean {
        return this.errors.length === 0;
    }
}
