import { IAutocompleteItem } from '../../strict-autocomplete/contracts/app-autocomplete-item';
import { Injectable, OnDestroy } from '@angular/core';
import { ScrollStrategy } from 'src/app/common/abstracts/abstract-scroll-strategy';
import { ThrottlingSearchForm, ThrottlingSearchModel, ThrottlingsService } from 'src/app/modules/autogen/BattlePass';

@Injectable()
export class ThrottlingAutocompleteStrategy extends ScrollStrategy<ThrottlingSearchModel, ThrottlingSearchForm> implements OnDestroy {
    constructor(protected readonly _apiClient: ThrottlingsService) {
        super(_apiClient);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    convert(model: ThrottlingSearchModel): IAutocompleteItem<ThrottlingSearchModel> {
        return this.convert_(() => {
            return {
                entity: model,
                title: model.name,
                key: model.id?.toString(),
            };
        });
    }

    public clear(): void {
        super._sourceClearAutocomplete$.next();
    }
}
