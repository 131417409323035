import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WlMainMenuRouterService {
  currentRoute: string[] = [];
  private highlightMenuItem = '';

  private menuItemGroups = new Map<string, string>([
    ['regestry', 'tracker'],
    ['book-reward-dictionary', 'dictionary'],
  ]);

  private _updateMenuSubject = new Subject<string>();
  public menuUpdate: Observable<string> = this._updateMenuSubject;

  constructor(
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute
  ) {}

  public refreshRoute(name: string = null) {
    const routingData = [];
    let current = this._activatedRoute;
    while (!!current) {
      routingData.push(current.data);
      current = current.firstChild;
    }

    combineLatest(routingData).subscribe((res) => {
      this.refreshRouteName(res, name);
      this.refreshActiveMenu(res);
    });
  }

  public setEntityName(name: string) {
    this.refreshRoute(name);
  }

  get currentGroup() {
    return this.menuItemGroups.get(this.highlightMenuItem);
  }

  get currentMenuItem() {
    return this.highlightMenuItem;
  }

  private refreshRouteName(res, name) {
    this.currentRoute = [];
    res
      .filter((elem) => {
        return !!elem['routeName'];
      })
      .map((elem) => this.currentRoute.push(elem['routeName']));

    if (!!name && this.currentRoute.length >= 1) {
      const lastIndex = this.currentRoute.length - 1;
      this.currentRoute[lastIndex] = this.currentRoute[lastIndex] + ' ' + name;
    }
  }

  private refreshActiveMenu(res) {
    const highlightMenuItems = res.filter(
      (elem) => !!elem['highlightMenuItem']
    );
    if (highlightMenuItems.length > 0) {
      const newItem = highlightMenuItems[0]['highlightMenuItem'].toString();
      if (this.highlightMenuItem !== newItem) {
        this.highlightMenuItem = newItem;
        const group = this.menuItemGroups.get(newItem);
        this._updateMenuSubject.next(group);
      }
      return;
    }

    this.highlightMenuItem = null;
    this._updateMenuSubject.next(null);
  }
}
