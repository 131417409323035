import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { GaMessagingService, StrictFormControl } from '@koddington/ga-common';
import { combineLatest, Observable, Subject } from 'rxjs';
import { DailyTasksPromotionsService } from '../../../../autogen/DailyTasks';

@Component({
    selector: 'app-wl-daily-task-file-picker',
    templateUrl: './wl-daily-task-file-picker.component.html',
    styleUrls: ['./wl-daily-task-file-picker.component.scss'],
})
export class WlDailyTaskFilePickerComponent {
    @Input()
    title: string;
    @Input()
    fileNameControl: StrictFormControl<string> = new StrictFormControl<string>(null);
    @Input()
    pathControl: StrictFormControl<String> = new StrictFormControl<String>(null);
    @Input()
    fileLoaded: StrictFormControl<boolean> = new StrictFormControl<boolean>(null);
    @Input()
    onChangeSubj: Subject<void>;
    @ViewChild('filepicker')
    filePicker: ElementRef;

    private readonly _dataTransfer = new DataTransfer();
    private fileName = '';

    constructor(private readonly _filesService: DailyTasksPromotionsService, private readonly _messaging: GaMessagingService) {}

    public onFileChange(event: any): void {
        this.fileLoaded.strictValue = true;
        if (!this.validateFile(event)) {
            return;
        }

        const file = event.target.files[0];
        this.fileName = file.name;
        this._filesService
            .uploadFile(file)
            .pipe(take(1))
            .subscribe((res) => {
                if (!this._messaging.tryShowError(res)) {
                    this.pathControl.strictValue = res.result;
                    this.fileNameControl.strictValue = file.name;
                    this._messaging.showMessage('Файл успешно загружен');
                } else {
                    this.pathControl.strictValue = null;
                    this.fileNameControl.strictValue = null;
                }

                this.onChangeSubj.next();
            });

        this.filePicker.nativeElement.value = null;
    }

    private validateFile(event: any): boolean {
        const files = event.target.files;

        if (files.length === 0) {
            return false;
        }

        if (files[0].size === 0) {
            this._messaging.showMessage('Выбран пустой файл');
            event.target.files = this._dataTransfer.files;

            return false;
        }

        return true;
    }

    public getFileName(): string {
        return !!this.fileName ? this.fileName : 'Выберите список пользователей';
    }

    public clear() {
        this.pathControl.strictValue = null;
        this.fileNameControl.strictValue = null;
        this.fileLoaded.strictValue = false;
        this.fileName = null;

        this.onChangeSubj.next();
    }

    public isFileLoaded(): boolean {
        return !!this.fileLoaded.strictValue;
    }

    public get shouldDisplayErrors(): Observable<boolean> {
        return combineLatest(this.pathControl.shouldDisplayErrors, this.fileLoaded.shouldDisplayErrors, this.fileNameControl.shouldDisplayErrors).pipe(
            map((res) => res[0] || res[1])
        );
    }
}
