<div class="wl-loot-box-container">
    <app-wl-page-header *ngIf="!asSubComponent" header="{{pageHeaderText}}"></app-wl-page-header>
    <app-wl-loading *ngIf="loading"></app-wl-loading>
    <div *ngIf="!asSubComponent" ngclass="wl-task-button-footer">
        <button class="wl-button-primary wl-loot-box-btn"
                [disabled]="!canSave"
                (click)="save()"
        >
            {{saveButtonText}}
        </button>
        <button class="wl-button-primary wl-loot-box-btn"
                *ngIf="isEdit"
                [disabled]="!canSave"
                (click)="copy()"
        >
            Копировать
        </button>
    </div>
    <div class="wl-loot-box-row">
        <app-wl-strict-input-text title="Название"
                               (valueChange)="userManipulationsSource.next()"
                               [control]="viewModel.name"
        ></app-wl-strict-input-text>
    </div>
    <div class="wl-loot-box-row">
        <app-wl-strict-input-number title="Сумма вероятностей"
                                 [control]="viewModel.probabilitiesSum"
        ></app-wl-strict-input-number>
    </div>
    <div class="wl-loot-box-row">
        <span>Содержимое</span>
        <button class="wl-button-primary wl-loot-box-btn"
                (click)="addEmptyContent()"
        >
            Добавить
        </button>
    </div>
</div>
<div class="wl-loot-box-container-table-block">
    <ga-table *ngIf="hasLootBoxContents"
                  [data]="tableData"
                  [hideArrows]="true"
    ></ga-table>
    <app-wl-paging [pagedResult]="viewModel.pagingResult"
                   (pageChangedEvent)="contentPageChanged($event)"
    ></app-wl-paging>
</div>

<ng-template #freeBetAmount let-rawModel="model">
        <app-wl-strict-input-number placeholder="Сумма фрибета"
                                 (valueChange)="userManipulationsSource.next()"
                                 [control]="asContentModel(rawModel).amount"
        ></app-wl-strict-input-number>
</ng-template>
<ng-template #freeBetProbability let-rawModel="model">
    <app-wl-strict-input-number placeholder="Вероятность фрибета"
                             (valueChange)="userManipulationsSource.next()"
                             [control]="asContentModel(rawModel).probability"
                             [step]="0.01"
    ></app-wl-strict-input-number>
</ng-template>
<ng-template #scarcity let-rawModel="model">
    <app-wl-strict-input-text placeholder="Редкость (Scarcity)"
                           (valueChange)="userManipulationsSource.next()"
                           [control]="asContentModel(rawModel).scarcity"
    ></app-wl-strict-input-text>
</ng-template>
<ng-template #scarValue let-rawModel="model">
    <app-wl-strict-input-number placeholder="Редкость (Scar Value)"
                             (valueChange)="userManipulationsSource.next()"
                             [control]="asContentModel(rawModel).scarValue"
    ></app-wl-strict-input-number>
</ng-template>
<ng-template #imageUrl let-rawModel="model">
    <app-wl-strict-input-text placeholder="Ссылка на изображение"
                           (valueChange)="userManipulationsSource.next()"
                           [control]="asContentModel(rawModel).imageUrl"
    ></app-wl-strict-input-text>
</ng-template>
<ng-template #actionButtons let-rawModel="model">
    <ng-template [ngIf]="asContentModel(rawModel)" let-model="ngIf">
        <button *ngIf="canDeleteLootBoxContents"
                class="wl-button-primary"
                (click)="deleteContent(model)"
        >
            Удалить
        </button>
    </ng-template>
</ng-template>
