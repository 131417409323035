<div class="wl-container">
  <app-loading *ngIf="loading"></app-loading>
  <app-wl-strict-input-text
    class="wl-row"
    [control]="viewModel.name"
    title="Название"
    placeholder="Введите название партнера"
    (valueChange)="userManipulationsSource.next()"
  >
  </app-wl-strict-input-text>
  <app-wl-strict-input-text
    class="wl-row"
    [control]="viewModel.externalId"
    title="Внешний Id"
    placeholder="Введите внешний Id"
    (valueChange)="userManipulationsSource.next()"
  >
  </app-wl-strict-input-text>
  <app-wl-strict-input-text
    class="wl-row"
    [control]="viewModel.virtualHostTitle"
    title="Имя виртуального хоста"
    placeholder="Введите название"
    (valueChange)="userManipulationsSource.next()"
  >
  </app-wl-strict-input-text>
  <app-wl-strict-input-text
    class="wl-row"
    [control]="viewModel.regUrl"
    title="regUrl"
    placeholder="Введите URL для регистрации"
    (valueChange)="userManipulationsSource.next()"
  >
  </app-wl-strict-input-text>
  <app-wl-strict-input-boolean label="Необходимо соглашение"
                               [control]="viewModel.agreementRequired">
  </app-wl-strict-input-boolean>
  <app-wl-strict-input-text
    class="wl-row"
    [control]="viewModel.agreementTextIntro"
    title="agreementTextIntro"
    placeholder="Введите интро для текста соглашения"
    (valueChange)="userManipulationsSource.next()"
  >
  </app-wl-strict-input-text>
  <app-wl-strict-input-text
    class="wl-row"
    [control]="viewModel.agreementTextDetails"
    title="agreementTextDetails"
    placeholder="Введите детали для текста соглашения"
    (valueChange)="userManipulationsSource.next()"
  >
  </app-wl-strict-input-text>
  <app-strict-scroll-autocomplete-new class="wl-row"
                                      label="Promo"
                                      placeholder="Вводите названия Promo поочерёдно"
                                      [isSearch]="false"
                                      [control]="mediaPromoAutocomplete"
                                      (valueChange)="userManipulationsSource.next()"
                                      [showItemTitleAfterSelect]="false"
                                      [strategy]="mediaPromoStrategy">
  </app-strict-scroll-autocomplete-new>

  <div *ngFor="let promoSettingsSelector of viewModel.mediaPromoSettings.strictValue; let i = index">
    № {{i+1}}
    <div class="wl-media-container">
      <div class="wl-media-info-row">
        <span class="wl-media-info-field-label">Id Promo: </span>
        <span class="wl-media-info-field-value">
            {{promoSettingsSelector?.promoId?.strictValue}}
        </span>
      </div>
      <div class="wl-media-info-row">
        <span class="wl-media-info-field-label">Название Promo: </span>
        <span class="wl-media-info-field-value">
            {{promoSettingsSelector?.promoName?.strictValue}}
        </span>
      </div>
      <div class="wl-media-info-row">
        <span class="wl-media-info-field-label">Id канала: </span>
        <span *ngIf="promoSettingsSelector?.channelId?.strictValue > 0" class="wl-media-info-field-value">
            {{promoSettingsSelector?.channelId?.strictValue}}
        </span>
      </div>
      <div class="wl-media-info-row">
        <span class="wl-media-info-field-label">Название канала: </span>
        <span class="wl-media-info-field-value">
            {{promoSettingsSelector?.channelName?.strictValue}}
        </span>
      </div>
      <div class="wl-media-info-row">
        <span class="wl-media-info-field-label">Id источника: </span>
        <span *ngIf="promoSettingsSelector?.sourceId?.strictValue > 0" class="wl-media-info-field-value">
            {{promoSettingsSelector?.sourceId?.strictValue}}
        </span>
      </div>
      <div class="wl-media-info-row">
        <span class="wl-media-info-field-label">Название источника: </span>
        <span class="wl-media-info-field-value">
            {{promoSettingsSelector?.sourceName?.strictValue}}
        </span>
      </div>
      <app-strict-input-boolean [control]="promoSettingsSelector.isPromoEnabled"
                                label="Передавать Promo?">
      </app-strict-input-boolean>

      <app-strict-input-boolean [control]="promoSettingsSelector.isChannelEnabled"
                                label="Передавать канал?">
      </app-strict-input-boolean>

      <app-strict-input-boolean [control]="promoSettingsSelector.isSourceEnabled"
                                label="Передавать источник?">
      </app-strict-input-boolean>
    </div>

    <button class="wl-btn-form-inactive wl-margin-top-4 wl-margin-bottom-4" type="button" (click)="deleteItem(i,true)">Убрать настройку </button>

  </div>

  <app-strict-scroll-autocomplete-new class="wl-row"
                                      label="Btag"
                                      placeholder="Вводите названия Btag поочерёдно"
                                      [isSearch]="false"
                                      [control]="mediaBtagAutocomplete"
                                      (valueChange)="userManipulationsSource.next()"
                                      [showItemTitleAfterSelect]="false"
                                      [strategy]="mediaBtagStrategy">
  </app-strict-scroll-autocomplete-new>

  <div class="wl-players-cards-selector" *ngFor="let btagSettingsSelector of viewModel.mediaBtagSettings.strictValue; let i = index">
    № {{i+1}}
    <div class="wl-media-container">
      <div class="wl-media-info-row">
        <span class="wl-media-info-field-label">Id Btag: </span>
        <span class="wl-media-info-field-value">
            {{btagSettingsSelector?.btagId?.strictValue}}
        </span>
      </div>
      <div class="wl-media-info-row">
        <span class="wl-media-info-field-label">Название Btag: </span>
        <span class="wl-media-info-field-value">
            {{btagSettingsSelector?.btagName?.strictValue}}
        </span>
      </div>
      <div class="wl-media-info-row">
        <span class="wl-media-info-field-label">Id канала: </span>
        <span *ngIf="btagSettingsSelector.channelId?.strictValue > 0" class="wl-media-info-field-value">
            {{btagSettingsSelector?.channelId?.strictValue}}
        </span>
      </div>
      <div class="wl-media-info-row">
        <span class="wl-media-info-field-label">Название канала: </span>
        <span class="wl-media-info-field-value">
            {{btagSettingsSelector?.channelName?.strictValue}}
        </span>
      </div>
      <div class="wl-media-info-row">
        <span class="wl-media-info-field-label">Id источника: </span>
        <span *ngIf="btagSettingsSelector.sourceId?.strictValue > 0" class="wl-media-info-field-value">
            {{btagSettingsSelector?.sourceId?.strictValue}}
        </span>
      </div>
      <div class="wl-media-info-row">
        <span class="wl-media-info-field-label">Название источника: </span>
        <span class="wl-media-info-field-value">
            {{btagSettingsSelector?.sourceName?.strictValue}}
        </span>
      </div>
      <app-strict-input-boolean [control]="btagSettingsSelector.isBtagEnabled"
                                label="Передавать Btag?">
      </app-strict-input-boolean>

      <app-strict-input-boolean [control]="btagSettingsSelector.isChannelEnabled"
                                label="Передавать канал?">
      </app-strict-input-boolean>

      <app-strict-input-boolean [control]="btagSettingsSelector.isSourceEnabled"
                                label="Передавать источник?">
      </app-strict-input-boolean>
    </div>

    <button class="wl-btn-form-inactive wl-margin-top-4 wl-margin-bottom-4" type="button" (click)="deleteItem(i,false)">Убрать настройку</button>

  </div>

  <div class="wl-partner-button-footer">
    <button class="wl-button-primary" [disabled]="!isValid" (click)="save()">
      Сохранить
    </button>
  </div>
</div>
