import { Component, OnDestroy, OnInit } from '@angular/core';
import { GaPagedResult } from '@koddington/ga-common/lib/results/results';
import {
  GaPagingForm,
  isNullOrUndefined,
} from '@koddington/ga-common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import {
  RewardsService,
  WlTaskTrackerRewardListModel,
  WlTaskTrackerRewardTypesExtensions,
} from '../../../autogen/TaskTrackerRewards';
import { PartnerViewModel } from './view-model/partner-view-model';
import { map, switchMap } from 'rxjs/operators';
import { getNumberByKey } from 'src/app/modules/shared/common/operation/common-operation';
import { builderStrictToModel } from 'src/app/modules/shared/common/operation/builder-operation';
import { GaTableData } from '@koddington/ga-common';

@Component({
  selector: 'app-partner-rewards-list',
  templateUrl: './partner-rewards-list.component.html'
})
export class PartnerRewardsListComponent implements OnInit, OnDestroy {
  public loading: boolean;
  public partners: GaPagedResult<WlTaskTrackerRewardListModel>;
  public tableData: GaTableData<WlTaskTrackerRewardListModel>;

  private searchViewModel: PartnerViewModel = new PartnerViewModel();
  private readonly count = 30;

  constructor(
    private readonly _service: RewardsService,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.subscribeLoad();
  }

  ngOnDestroy() {}

  private subscribeLoad(): void {
    this._route.queryParams
      .pipe(
        map((params) => this.initViewModel(params)),
        map(() => this.createGaPagingForm()),
        switchMap((form: GaPagingForm) => this._service.list(form)),
        untilDestroyed(this)
      )
      .subscribe((result) => {
        this.partners = result;
        this.mapToTable();
        this.loading = false;
      });
  }

  private initViewModel(params: Params): void {
    this.loading = true;
    if (!isNullOrUndefined(params?.count)) {
      this.searchViewModel.count.strictValue = getNumberByKey(params, 'count');
    }
    if (!isNullOrUndefined(params?.offset)) {
      this.searchViewModel.offset.strictValue = getNumberByKey(
        params,
        'offset'
      );
    }
  }
  private createGaPagingForm(): GaPagingForm {
    return builderStrictToModel(GaPagingForm, this.searchViewModel);
  }

  private search(page: GaPagingForm = null): void {
    const params: Params = {
      offset: page?.offset ?? this.searchViewModel.offset.strictValue,
      count: this.searchViewModel.count.strictValue,
    };
    this._router.navigate([], { relativeTo: this._route, queryParams: params });
  }

  public pageChanged(page: GaPagingForm): void {
    this.search(page);
  }

  public add(): void {
    this._router.navigate(['menu/dictionary/reward/edit']);
  }

  private mapToTable(): void {
    const data = new GaTableData<WlTaskTrackerRewardListModel>()
        .addRouterLinkColumn(
          elem => elem.id.toString(),
            elem => ['edit', elem.id],
          {title: 'Id', widthSize: 100}
        )
        .addSimpleColumn(elem => elem.name, { title: 'Название', widthSize: 500 })
        .addSimpleColumn(elem => WlTaskTrackerRewardTypesExtensions.format(elem.type), { title: 'Тип', widthSize: 300 })
        .addSimpleColumn(elem => elem.amount.toString(), { title: 'Вознаграждение', widthSize: 300 });

    data.data = this.partners.results;
    this.tableData = data;
  }
}
