import {
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    PartnersService,
    TaskTrackerPartnerUpdateForm,
    WlTaskTrackerPartnerListModel,
    WlTaskTrackerPartnerStateTypes,
    WlTaskTrackerPartnerStateTypesExtensions,
} from '../../../autogen/TaskTrackerPartner';
import { Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { booleanToString } from '../../../shared/extensions/convert-extensions';
import {
    GaConfirmationService,
    GaMessagingService,
    GaPagingForm,
    GaTableCellTemplate,
    GaTableData,
} from '@koddington/ga-common';
import { GaPagedResult } from '@koddington/ga-common/lib/results/results';
import { filter, finalize, take } from 'rxjs/operators';
import { RouterNavigationExtensions } from 'src/app/modules/shared/extensions/navigation-extensions';

@Component({
    selector: 'app-partner-registries-list',
    templateUrl: './partner-registries-list.component.html',
})
export class PartnerRegistriesListComponent implements OnInit, OnDestroy {
    @ViewChild('reward') reward: TemplateRef<any>;
    @ViewChild('hasActivatePartner') hasActivatePartner: TemplateRef<any>;

    public loading: boolean;
    partners: GaPagedResult<WlTaskTrackerPartnerListModel>;
    tableData: GaTableData<WlTaskTrackerPartnerListModel>;
    private readonly count = 30;

    constructor(
        private readonly _service: PartnersService,
        private readonly _router: Router,
        private readonly _messaging: GaMessagingService,
        private readonly _confirmation: GaConfirmationService
    ) {
    }

    ngOnInit() {
        this.load();
    }

    ngOnDestroy() {
    }

    public load(offset: number = 0): void {
        this.loading = true;
        const form = new GaPagingForm();
        form.count = this.count;
        form.offset = offset;

        this._service
            .list(form)
            .pipe(untilDestroyed(this))
            .subscribe((res) => {
                this.partners = res;
                this.mapToTable();
                this.loading = false;
            });
    }

    public pageChanged(page: GaPagingForm): void {
        this.load(page.offset);
    }

    public add(): void {
        this._router.navigate(['menu', 'dictionary', 'partner', 'edit']);
    }

    public activatePartner(partner: WlTaskTrackerPartnerListModel): void {
        const form = this.buildFormForUpdate(partner);
        form.state = WlTaskTrackerPartnerStateTypes.Active;
        this.update(form);
    }

    public deactivatePartner(partner: WlTaskTrackerPartnerListModel): void {
        const form = this.buildFormForUpdate(partner);
        form.state = WlTaskTrackerPartnerStateTypes.NotActive;
        this.update(form);
    }

    public acceptReward(partner: WlTaskTrackerPartnerListModel): void {
        if (!partner) throw new Error('partner has empty data');
        const form = this.buildFormForUpdate(partner);
        form.isCanGiveReward = true;
        this.update(form);
    }

    public declineReward(partner: WlTaskTrackerPartnerListModel): void {
        const form = this.buildFormForUpdate(partner);
        form.isCanGiveReward = false;
        this.update(form);
    }

    public getHasState(elem: WlTaskTrackerPartnerListModel): boolean {
        return elem.state === WlTaskTrackerPartnerStateTypes.Active;
    }

    public getHasReward(elem: WlTaskTrackerPartnerListModel): boolean {
        return elem.isCanGiveReward;
    }

    private mapToTable(): void {
        const data = new GaTableData<WlTaskTrackerPartnerListModel>()
            .addRouterLinkColumn(
                (elem) => elem.id.toString(),
                (elem) => ['edit', elem.id],
                {title: 'Id', widthSize: 100}
            )
            .addSimpleColumn((elem) => elem.name, {title: 'Название', widthSize: 350})
            .addSimpleColumn(
                (elem) => booleanToString(elem.isCanGiveReward),
                {title: 'Может выдать награды', widthSize: 200}
            )
            .addSimpleColumn(
                (elem) => WlTaskTrackerPartnerStateTypesExtensions.format(elem.state),
                {
                    title: 'Статус',
                    widthSize: 100
                },
            )
            .addSimpleColumn((elem) => elem.externalId, {title: 'Внешний Id', widthSize: 200})
            .addSimpleColumn((elem) => elem.virtualHostTitle, {title: 'Имя вирт.хоста', widthSize: 140})
            .addTemplateColumn(
                new GaTableCellTemplate(this.reward, (elem) => {
                    return {
                        partner: elem,
                    };
                }), {
                    title: 'Награды',
                    widthSize: 200
                }
            )
            .addTemplateColumn(
                new GaTableCellTemplate(this.hasActivatePartner, (elem) => {
                    return {
                        partner: elem,
                    };
                }), {
                    title: 'Активировать статус',
                    widthSize: 300
                }
            );
        data.data = this.partners.results;
        this.tableData = data;
    }

    private update(form: TaskTrackerPartnerUpdateForm): void {
        this.loading = true;
        this._confirmation
            .openDialog(
                'Вы уверены что хотите сохранить изменения состояния',
                'Подтвердить',
                'Отмена'
            )
            .pipe(
                take(1),
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe((isConfirm) => {
                if (isConfirm) {
                    this._service
                        .switcherForRewardsAndState(form)
                        .pipe(
                            take(1),
                            filter((value) => !this._messaging.tryShowError(value))
                        )
                        .subscribe(_ => {
                            this.refresh();
                            return;
                        });
                    return;
                }
                this._messaging.showMessage('Отмена');
                return;
            });
    }

    private refresh(): void {
        RouterNavigationExtensions.navigateAndRefresh(this._router, [
            'menu',
            'dictionary',
            'partner',
        ]);
    }

    private buildFormForUpdate(
        partner: WlTaskTrackerPartnerListModel
    ): TaskTrackerPartnerUpdateForm {
        return new TaskTrackerPartnerUpdateForm({
            id: partner.id,
            state: partner.state,
            name: partner.name,
            isCanGiveReward: partner.isCanGiveReward,
            externalId: partner.externalId,
            virtualHostTitle: partner.virtualHostTitle,
        });
    }
}
