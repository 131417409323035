import { Component } from '@angular/core';
import { GaConfig } from '@koddington/ga-common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor() {
    if (window.location.pathname === '/') {
      // see https://github.com/angular/angular/issues/10726
      window.location.replace('/default-page');
    }

    GaConfig.host = environment.host;
    GaConfig.production = environment.production;
    GaConfig.protocol = window.location.protocol;
    GaConfig.headers = { WlApp: 'angular' };
  }

  title = 'battle-pass-admin';
}
