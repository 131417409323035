import { WlCrmTaskLiteViewModel } from '../../../models/wl-crm-task-lite-view-model';
import { WlGameTaskLiteViewModel } from '../../../models/wl-game-task-lite-view-model';
import {Dayjs} from 'dayjs';
import { AbstractModelValidator, isNullOrUndefined } from '@koddington/ga-common';

export abstract class WlBattlePassLevelTasksExtensionValidator extends AbstractModelValidator<WlCrmTaskLiteViewModel | WlGameTaskLiteViewModel> {
    protected abstract _gameTask: WlGameTaskLiteViewModel[] | null | undefined;
    protected abstract _crmTask: WlCrmTaskLiteViewModel[] | null | undefined;
    protected abstract _startDate: Dayjs | null | undefined;
    constructor() {
        super();
    }

    get isTasksEmpty(): boolean {
        if (isNullOrUndefined(this._crmTask) && isNullOrUndefined(this._gameTask))
            return true;

        if (this._crmTask.some(u => u.task.hasStrictValue || u.order.hasStrictValue))
            return false;

        if (this._gameTask.some(u => u.task.hasStrictValue || u.order.hasStrictValue))
            return false;

        return true;
    }

    get isStartDateExistAndTasksEmpty(): boolean
    {
        if (!isNullOrUndefined(this._startDate))
        {
            if (this.isTasksEmpty)
                return true;
        }

        return false;
    }
}
