import { AbstractModelValidator } from '@koddington/ga-common';
import {WlCrmGameTaskViewModel} from '../../../models/wl-crm-game-task-view-model';

export class WlBattlePassGameTaskValidator extends AbstractModelValidator<WlCrmGameTaskViewModel> {

    constructor() {
        super();

        this.ruleForControl(u => u.name).requiredAndNotZero('Введите наименование игрового задания');
        this.ruleForControl(u => u.description).requiredAndNotZero('Введите описание задания');
        this.ruleForControl(u => u.imageUrl).requiredAndNotZero('Введите ссылку на картинку');
        this.ruleForControl(u => u.taskTemplate).required('Выберите игровой шаблон');
        this.ruleForControl(u => u.target)
            .required('Укажите целевой параметр')
            .min(1, 'Целевой параметр должен быть больше 0');
        this.ruleForControlIf(u => u.template, u => u.throttling.hasStrictValue)
            .required('Шаблон рассылки должен быть заполнен');
    }
}
