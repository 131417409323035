import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { StrictFormControl } from '@koddington/ga-common';
import { BattlePassService, WlNotificationTemplate } from 'src/app/modules/autogen/BattlePass';
import { PushTemplateScrollStrategy } from './strategy/push-template-scroll-strategy';

@Component({
    selector: 'app-strict-push-template-autocomplete',
    templateUrl: './strict-push-template-autocomplete.component.html',
    styleUrls: ['./strict-push-template-autocomplete.component.scss'],
    providers: [PushTemplateScrollStrategy],
})
export class StrictPushTemplateAutocompleteComponent implements OnInit, OnDestroy {
    @Input()
    public isSearch = false;
    @Input()
    textlabel = '';
    @Input()
    public control = new StrictFormControl<WlNotificationTemplate>(null);
    @Input()
    public isShowAll = new StrictFormControl<boolean>(false);
    @Output()
    public userSelect = new EventEmitter<void>();
    @Output()
    public searchMethod = new EventEmitter<void>();
    @Output()
    public switchCheckBox$ = new EventEmitter<void>();

    constructor(private readonly _apiClient: BattlePassService, private readonly _pushScrollTemplateStrategy: PushTemplateScrollStrategy) {}

    public ngOnDestroy(): void {}

    public ngOnInit() {}

    public emitSearch(): void {
        this.searchMethod.emit();
    }

    public get getShowAll(): StrictFormControl<boolean> {
        return this.isShowAll;
    }
    public switchCheckBox(): void {
        this.switchCheckBox$.emit();
    }

    get pushScrollTemplateStrategy(): PushTemplateScrollStrategy {
        return this._pushScrollTemplateStrategy;
    }
}
