import {hasControlAndValue, isNullOrUndefined, StrictError, StrictFormControl} from '@koddington/ga-common';

export namespace StrictValidationRules {
    const GUID_REGEXP = /^[a-f\d]{8}-([a-f\d]{4}-){3}[a-f\d]{12}$/i;

    export function isInteger(val: number, error: string = 'integerStrictError', message: string = 'Введено не целое число'): StrictError | null {
        if (val % 1 !== 0) {
            return new StrictError(error, message);
        }
        return null;
    }

    export function isGuid(val: string, error: string = 'guidStrictError', message: string = 'Введен не Guid'): StrictError | null {
        if (GUID_REGEXP.test(val)) {
            return null;
        }

        return new StrictError(error, message);
    }

    export function controlIsCorrectAndEqual<T>(control: StrictFormControl<T>, value: any): boolean {
        if (!hasControlAndValue(control)) {
            return false;
        }

        if (isNullOrUndefined(value)){
            return false;
        }

        if (control.value !== value) {
            return false;
        }

        return true;
    }
}
