import {DropdownItem, IDropdownStrategy} from '@koddington/ga-common';
import {FiltredOutBetsTargets, FiltredOutBetsTargetsExtensions} from '../../../../../autogen/BattlePass';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FiltredOutBetsTargetsStrategy implements IDropdownStrategy<FiltredOutBetsTargets> {
  getEntities(): Observable<Array<FiltredOutBetsTargets>> {
    const entities = [FiltredOutBetsTargets.All, FiltredOutBetsTargets.BattlePass, FiltredOutBetsTargets.DailyTasks];
    return of(entities);
  }

  map: (model: FiltredOutBetsTargets) => DropdownItem<FiltredOutBetsTargets> = model => {
    const item = new DropdownItem<FiltredOutBetsTargets>();
    item.entity = model;
    item.title = FiltredOutBetsTargetsExtensions.format(model);
    item.id = Number(model);

    return item;
  }
}
