import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { formatDateTime, GaConfirmationService, GaMessagingService, GaTableCellTemplate, GaTableData, isNullOrUndefined } from '@koddington/ga-common';
import { filter, first, map, take } from 'rxjs/operators';
import {
    DailyTaskUserInfoService, WlDailyTasksUserProfile, WlDailyTasksUserProfileDay, WlDailyTasksUserPromoTask,
    WlDailyTasksUserReward, WlDailyTasksUserTask, WlDtForceCompleteForm, WlPromoTypes, WlUserWithPromoForm
} from 'src/app/modules/autogen/DailyTasks';
import { builderModelToStrict, builderStrictToModel } from '../../../shared/common/operation/builder-operation';
import { DailyTaskProfileSearchModel } from './models/daily-task-profile-search-model';
import { ActivatedRoute, Params } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { SearchNavigationService } from '../../../shared/services/search-navigation.service';
import dayjs from 'dayjs';
import { AuthService } from '../../../autogen/Shared';

@Component({
    selector: 'app-daily-task-user-info',
    templateUrl: './daily-task-user-info.component.html',
    styleUrls: ['./daily-task-user-info.component.scss'],
})
export class DailyTaskUserInfoComponent implements OnInit, OnDestroy {
    @ViewChild('showReward') showReward: TemplateRef<any>;
    @ViewChild('showTask') showTask: TemplateRef<any>;
    @ViewChild('showPickemInfo') showPickemInfo: TemplateRef<any>;
    protected viewModel: DailyTaskProfileSearchModel = new DailyTaskProfileSearchModel();
    protected loading: boolean = false;
    public tableData: GaTableData<WlDailyTasksUserProfileDay>;
    public result: WlDailyTasksUserProfile;
    public isDeveloper: boolean;

    constructor(private readonly messaging: GaMessagingService,
                private readonly service: DailyTaskUserInfoService,
                private readonly authService: AuthService,
                private readonly navigation: SearchNavigationService,
                private readonly activeRoute: ActivatedRoute,
                private readonly confirmation: GaConfirmationService) {
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
        this.activeRoute.queryParams
            .pipe(
                map((value) => this.initModel(value)),
                filter(() => !this.isSearchForbidden),
                untilDestroyed(this)
            )
            .subscribe(() => this.load());
    }

    public typifyReward(rawReward: any): WlDailyTasksUserReward {
        return rawReward as WlDailyTasksUserReward;
    }

    public typifyTask(rawTask: any): WlDailyTasksUserTask {
        return rawTask as WlDailyTasksUserTask;
    }

    public mapFreebetAmount(reward: WlDailyTasksUserReward): string {
        return !isNullOrUndefined(reward.freebetAmount) ? reward.freebetAmount.toString() : 'Не выдан';
    }

    public mapProgress(task: WlDailyTasksUserTask): string {
        return `${task.progress}/${task.target}`;
    }

    public mapPromoProgress(task: WlDailyTasksUserPromoTask): string {
        if (isNullOrUndefined(task)) return '-/-'
        return `${task?.progress}/${task?.target}`;
    }

    public hasRewardError(reward: WlDailyTasksUserReward): boolean {
        return !isNullOrUndefined(reward.errorCode);
    }

    public resetCache(): void {
        this.service
            .resetCache(this.getForm())
            .pipe(take(1),
                filter((res) => !this.messaging.tryShowError(res))
            )
            .subscribe(() => this.messaging.showMessage('Кэш сброшен'));
    }

    public forceComplete(day: WlDailyTasksUserProfileDay): void {
        this.confirmation
            .openDialog('Завершить задание для пользователя?')
            .pipe(
                first(),
                untilDestroyed(this),
                filter((res) => !!res)
            )
            .subscribe(() => this.forceCompleteInternal(day));
    }

    protected search(): void {
        const params: Params = {
            userId: this.viewModel.userId.strictValue,
            promoId: this.viewModel.promoId.strictValue,
        };
        this.navigation.search(this.activeRoute, params);
    }

    private forceCompleteInternal(day: WlDailyTasksUserProfileDay) {
        const form = new WlDtForceCompleteForm();
        form.userId = this.result.userId;
        form.dayId = day.dayId;

        this.service
            .addDtForceCompleteRequest(form)
            .pipe(
                first(),
                untilDestroyed(this),
                filter((res) => !this.messaging.tryShowError(res))
            )
            .subscribe(() => {
                this.messaging.showMessage('Запрос на завершение задания  Daily Task успешно добавлен ( Id дня - ' + day.dayId + ')');
            });
    }

    private load(): void {
        this.service
            .get(this.getForm())
            .pipe(take(1),
                filter((res) => !this.messaging.tryShowError(res))
            )
            .subscribe((res) => {
                this.result = res?.result;
                builderModelToStrict(this.viewModel, res?.result?.days);
                this.mapToTable();
            });

        this.authService
            .amIDeveloper()
            .pipe(take(1),
                filter((res) => !this.messaging.tryShowError(res))
            )
            .subscribe((res) => {
                this.isDeveloper = res.result;
                this.loading = false;
            });

    }

    private initModel(params: Params): void {
        this.loading = true;
        this.viewModel.userId.strictValue = !isNullOrUndefined(params['userId']) ? Number(params['userId']) : null;
        this.viewModel.promoId.strictValue = !isNullOrUndefined(params['promoId']) ? Number(params['promoId']) : null;
    }

    private getForm(): WlUserWithPromoForm {
        return builderStrictToModel(WlUserWithPromoForm, this.viewModel);
    }

    private mapToTable(): void {
        this.tableData = new GaTableData<WlDailyTasksUserProfileDay>()
            .addRouterLinkColumn(
                (elem) => elem.dayId,
                (elem) => ['/menu/dailyTasks/days/update', elem.dayId],
                {title: 'Id дня', widthSize: 80}
            )
            .addSimpleColumn((elem) => formatDateTime(elem?.startDate), {title: 'Дата старта дня', widthSize: 130})
            .addSimpleColumn((elem) => formatDateTime(elem?.endDate), {title: 'Дата окончания дня', widthSize: 150})
            .addSimpleColumn((elem) => formatDateTime(elem?.taskCompletionPushSentAt), {title: 'Дата уведомления окончания дня', widthSize: 150})
            .addSimpleColumn((elem) => elem?.isOffPlayDay ? 'Неигровой' : 'Игровой', {title: 'Тип дня', widthSize: 120})
            .addActionColumn(_ => 'Зачесть', elem => this.forceComplete(elem), {
                title: 'Зачесть день',
                widthSize: 120,
                elemHtmlClass: _ => 'wl-a-button-cell',
                hideContent: elem => !this.canForceComplete(elem)
            })
            .addSimpleColumn((elem) => elem?.drop, {title: 'Дроп за предыдущий день', widthSize: 200})
            .addTemplateColumn(
                new GaTableCellTemplate(this.showTask, (elem) => {
                    return {
                        task: elem.task
                    };
                }),
                {title: 'Задание', widthSize: 400}
            )
            .addTemplateColumn(
                new GaTableCellTemplate(this.showReward, (elem) => {
                    return {
                        reward: elem.reward
                    };
                }),
                {title: 'Награда', widthSize: 350}
            )
            .addTemplateColumn(
                new GaTableCellTemplate(this.showPickemInfo, (elem) => {
                    return {
                        promoDay: elem.promoDay,
                        pickem: elem.pickem
                    };
                }),
                {title: 'Pickem', widthSize: 350}
            )
            .setData(this.result.days);
    }

    private canForceComplete(day: WlDailyTasksUserProfileDay) {
        return !day?.task?.completedAt && dayjs(day.endDate) > dayjs() && !day.isOffPlayDay;
    }

    get isSearchForbidden(): boolean {
        return isNullOrUndefined(this.viewModel.userId.strictValue) || isNullOrUndefined(this.viewModel.promoId.strictValue);
    }

    get isPickemType(): boolean {
        return this.result.promoInfo.type === WlPromoTypes.Pickem;
    }
}
