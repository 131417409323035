import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WlResult } from 'src/app/models/common/results/wl-result';
import { BattlePassService, WlGameTaskSearchForm, WlGameTaskSearchModel } from 'src/app/modules/autogen/BattlePass';

@Injectable({
    providedIn: 'root',
})
export class WlBattlePassGameTaskWrapperService {
    constructor(private readonly _apiClient: BattlePassService) {}

    public search(body: WlGameTaskSearchForm): Observable<WlResult<WlGameTaskSearchModel[]>> {
        return this._apiClient.searchGameTask(body);
    }
}
