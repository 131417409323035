import { isNullOrUndefined, StrictFormControl } from '@koddington/ga-common';
import { WlBattlePassGameTaskWrapperService } from '../services/wrapper-service/wl-battle-pass-wrapper-game-service';
import { WlGameTaskSearchModel } from '../../autogen/BattlePass';
import { WlBattlePassGameTaskScrollStrategy } from '../strategies/wl-battle-pass-game-task-scroll-strategy';

export class WlGameTaskLiteViewModel {
    constructor(protected readonly _api: WlBattlePassGameTaskWrapperService) {}
    public order: StrictFormControl<number> = new StrictFormControl<number>(null);
    public task: StrictFormControl<WlGameTaskSearchModel> = new StrictFormControl<WlGameTaskSearchModel>(null);
    public isWildCard: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
    public strategy: WlBattlePassGameTaskScrollStrategy = new WlBattlePassGameTaskScrollStrategy(this._api);
    public canChange = true;

    get isEmpty() {
        return isNullOrUndefined(this.order.strictValue) || isNullOrUndefined(this.task.strictValue);
    }
}
