<div class="wl-container">
    <app-wl-page-header header="{{purpose}}"></app-wl-page-header>
    <app-wl-loading *ngIf="loading"></app-wl-loading>
    <div class="wl-task-row">
        <app-wl-strict-input-text [control]="viewModel.name"
                               title="Название"
                               placeholder="Введите название задания"
                               (valueChange)="userManipulationsSource.next()"
                               [className]="'wl-battle-pass-task-name'">
        </app-wl-strict-input-text>
    </div>
    <div class="wl-task-row">
        <app-wl-strict-input-text [control]="viewModel.description"
                               title="Описание"
                               placeholder="Введите описание задания"
                               (valueChange)="userManipulationsSource.next()"
                               [className]="'wl-battle-pass-task-name'">
        </app-wl-strict-input-text>
    </div>
    <div class="wl-task-row">
        <app-wl-strict-input-text [control]="viewModel.bullets"
                               title="Буллиты"
                               placeholder="Дополнительные условия выполнения задания"
                               (valueChange)="userManipulationsSource.next()"
                               [className]="'wl-battle-pass-task-name'">
        </app-wl-strict-input-text>
    </div>
    <div class="wl-task-row">
        <app-wl-strict-input-text [control]="viewModel.humanizedTargetDescription"
                               title="Название целевого параметра"
                               placeholder="Введите название целевого параметра для отображения пользователям"
                               (valueChange)="userManipulationsSource.next()"
                               [className]="'wl-battle-pass-task-name'">
        </app-wl-strict-input-text>
    </div>
    <div class="wl-task-row">
        <app-strict-scroll-autocomplete-new
                placeholder="Выберите шаблон задания"
                [label]="'Шаблон'"
                [isSearch]="false"
                [control]="viewModel.taskTemplate"
                (valueChange)="userManipulationsSource.next()"
                [strategy]="templateStrategy">
        </app-strict-scroll-autocomplete-new>
    </div>
    <div class="wl-task-row">
        <app-wl-strict-input-number class="one-col" title="Целевой показатель"
                                 (valueChange)="userManipulationsSource.next()"
                                 [control]="viewModel.target">
        </app-wl-strict-input-number>
    </div>
    <div class="wl-task-row">
        <app-wl-strict-input-text title="Deeplink"
                               (valueChange)="userManipulationsSource.next()"
                               [control]="viewModel.deeplink">
        </app-wl-strict-input-text>
    </div>
    <div class="wl-task-row">
        <app-wl-strict-input-text title="ImageUrl"
                               (valueChange)="userManipulationsSource.next()"
                               [control]="viewModel.imageUrl">
        </app-wl-strict-input-text>
    </div>
    <div class="wl-task-row">
        <app-wl-strict-input-text title="ImageUrl Web"
                               (valueChange)="userManipulationsSource.next()"
                               [control]="viewModel.imageWebUrl">
        </app-wl-strict-input-text>
    </div>
    <div class="wl-task-row">
        <app-wl-strict-input-boolean label="Пройдено автоматически"
                                  (userSelect)="userManipulationsSource.next()"
                                  [control]="viewModel.forceComplete">
        </app-wl-strict-input-boolean>
    </div>
    <div class="wl-task-row">
        <app-strict-push-template-autocomplete
                class="wl-app-search-autocomplete-wrapper"
                textlabel="Push шаблон рассылки о выполнении задания"
                [control]="viewModel.template"
                (userSelect)="userManipulationsSource.next()"
                [isSearch]="true"
        ></app-strict-push-template-autocomplete>
    </div>
    <div class="wl-task-row">
        <app-strict-throttling-autocomplete
                class="wl-app-wrapper"
                textLabel="Троттлинг для рассылки"
                (userSelect)="userManipulationsSource.next()"
                [control]="viewModel.throttling">
        </app-strict-throttling-autocomplete>
    </div>
    <div class="wl-task-button-footer">
        <button class="wl-button-primary wl-task-button" [disabled]="!isValid" (click)="save()">{{saveButtonText}} задание</button>
        <button class="wl-button-primary wl-task-button" [disabled]="!canBeCloned" (click)="clone()">Копировать</button>
    </div>
</div>
