import { WlTaskComparisonConditionsTypes, WlTaskComparisonConditionsTypesExtensions } from '../../autogen/BattlePass';
import { DropdownItem, EnumExtensions, IDropdownStrategy } from '@koddington/ga-common';
import { Observable, of } from 'rxjs';

export class WlTaskComparisonConditionsTypeStrategy implements IDropdownStrategy<WlTaskComparisonConditionsTypes> {
    private _filterType: WlTaskComparisonConditionsTypes[];

    public setFilterType(filterType: WlTaskComparisonConditionsTypes[]): void {
        this._filterType = filterType;
}

    getEntities(): Observable<Array<WlTaskComparisonConditionsTypes>> {
        const entities = !!this._filterType ? this._filterType : EnumExtensions.toArray(WlTaskComparisonConditionsTypes);
        return of(entities);
    }

    map: (model: WlTaskComparisonConditionsTypes) => DropdownItem<WlTaskComparisonConditionsTypes> = model => {
        const item = new DropdownItem<WlTaskComparisonConditionsTypes>();
        item.entity = model;
        item.title = WlTaskComparisonConditionsTypesExtensions.format(model);
        item.id = Number(model);

        return item;
    }
}
