import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { WlAuthStateService } from '../services/auth-state-service';

@Injectable({
  providedIn: 'root',
})
export class WlLogoutGuardWrapperService {
  constructor(
    private readonly _authRequestService: WlAuthStateService,
    private readonly _router: Router
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    this._authRequestService.logout();
    this._router.navigate(['default-page']);
    return true;
  }
}
