<div class="wl-container">
    <app-wl-page-header header="Лутбоксы Daily Tasks"></app-wl-page-header>
    <div class="wl-search-row">
        <app-wl-strict-input-text
                class="wl-app-input-search-wrapper wl-float-left-wrapper"
                placeholder="Введите название"
                [isSearch]="true"
                [control]="searchViewModel.term"
                (searchMethod)="pageFiltered()"
        ></app-wl-strict-input-text>
        <button class="wl-search-btn"
                [routerLink]="['/menu/dailyTasks/loot-boxes/create']"
        >
            Добавить лутбокс
        </button>
    </div>
    <ga-table *ngIf="!!tableData"
              [data]="tableData"
              [breakWordOnly]="true"
    ></ga-table>
    <app-wl-paging class="wl-paging-wrapper"
                   *ngIf="pagedResult"
                   [pagedResult]="pagedResult"
                   (pageChangedEvent)="pageChanged($event)"
    ></app-wl-paging>
</div>
