<div class="wl-task-item-selector-body">
    <div class="wl-item-row" *ngIf="isBetType || isWinType || isRedeemType">
        <app-wl-dropdown
            [strategy]="betTypeStrategy"
            [options]="{ placeholder: 'Выберите тип ставки', title: 'Тип ставки ' }"
            (userSelect)="change.next()"
            [control]="taskItem.betType"
        >
        </app-wl-dropdown>
    </div>
    <div class="wl-item-row" *ngIf="isByCountType && !isCollectInExpress && !isDepositsType">
        <div class="wl-item-container">
            <app-wl-strict-input-number class="wl-number-component"
                                     title="Количество ставок"
                                     placeholder=""
                                     min="1"
                                     [control]="taskItem.betCount">
            </app-wl-strict-input-number>
        </div>
    </div>
    <div class="wl-item-row" *ngIf="isShowSeveralBetsSumRange">
        <app-wl-battle-pass-range-condition class="battle-pass-range-condition"
                                            [title]="'Общая сумма ставок'"
                                            [allowedConditionalType]="moreOrEqualStrongConditionalType"
                                            (change)="change.emit()"
                                            minValue="1"
                                            [viewModel]="taskItem.severalBetsSumRange">
        </app-wl-battle-pass-range-condition>
    </div>
    <div class="wl-item-row" *ngIf="isWinType && isBySumType && !isCollectInExpress">
        <app-wl-battle-pass-range-condition class="battle-pass-range-condition"
                                            [title]="'Общая сумма выигрыша'"
                                            [allowedConditionalType]="moreOrEqualStrongConditionalType"
                                            (change)="change.emit()"
                                            minValue="1"
                                            [viewModel]="taskItem.totalWinAmountRange">
        </app-wl-battle-pass-range-condition>
    </div>
    <div class="wl-item-row" *ngIf="isBetExpressType && !isCollectInExpress">
        <app-wl-battle-pass-range-condition class="battle-pass-range-condition"
                                            [title]="'Событий в экспрессе'"
                                            (change)="change.emit()"
                                            minValue="1"
                                            [viewModel]="taskItem.eventCountExpressRange">
        </app-wl-battle-pass-range-condition>
    </div>
    <div class="wl-item-row" *ngIf="isBetExpressOrAnyBetType">
        <app-wl-battle-pass-range-condition class="battle-pass-range-condition"
                                            [title]="'Коэффициент линии'"
                                            (change)="change.emit()"
                                            [minValue]="1.01"
                                            [step]="0.01"
                                            [viewModel]="taskItem.lineRateRange">
        </app-wl-battle-pass-range-condition>
    </div>
    <div class="wl-item-row" *ngIf="(isBetType || isWinType || isRedeemType) && !isCollectInExpress">
        <app-wl-battle-pass-range-condition class="battle-pass-range-condition"
                                            [title]="'Коэффициент ставки'"
                                            [minValue]="1.01"
                                            [step]="0.01"
                                            (change)="change.emit()"
                                            [viewModel]="taskItem.betRateRange">
        </app-wl-battle-pass-range-condition>
    </div>
    <div class="wl-item-row" *ngIf="isByCountType && !isCollectInExpress && !isDepositsType">
        <app-wl-battle-pass-range-condition class="battle-pass-range-condition"
                                            [title]="'Сумма ставки'"
                                            (change)="change.emit()"
                                            minValue="1"
                                            [viewModel]="taskItem.betSumRange">
        </app-wl-battle-pass-range-condition>
    </div>
    <div class="wl-item-row" *ngIf="isBetType || isWinType || isRedeemType">
        <app-wl-dropdown [strategy]="sportTypeStrategy"
                         [options]="{placeholder: 'Выберите вид спорта',title:'Вид спорта '}"
                         [defaultValue]="setInitSportType()"
                         (userSelect)="change.next()"
                         [control]="taskItem.sportType">
        </app-wl-dropdown>
    </div>
    <div class="wl-item-row" *ngIf="isBetType || isWinType || isRedeemType">
        <app-wl-dropdown
            [strategy]="betPeriodStrategy"
            [options]="{ placeholder: 'Выберите период ставки', title: 'Период ставки ' }"
            (userSelect)="change.next()"
            [control]="taskItem.betPeriod"
        >
        </app-wl-dropdown>
    </div>
    <div class="wl-item-row" *ngIf="isBetType || isWinType || isRedeemType">
        <app-wl-dropdown [control]="taskItem.isExcludedSportType"
                         (userSelect)="change.next()"
                         [options]="{ placeholder: 'Не указано', title: 'Виды спорта' }"
                         [strategy]="filterStrategy"
        ></app-wl-dropdown>
        <app-wl-strict-multi-select-dropdown
                                          dropDownPlaceHolder="Выберите виды спорта"
                                          (userSelect)="change.next()"
                                          [strategy]="sportTypeStrategy"
                                          [maxElementsCount]="maxGameItems"
                                          [multiSelectControl]="taskItem.sportTypes"
                                          [shouldShowErrorList]="true"
        ></app-wl-strict-multi-select-dropdown>
    </div>
    <div class="wl-item-row" *ngIf="isBetType || isWinType || isRedeemType">
        <app-wl-strict-multi-select-dropdown
            title="Список платформ ставок"
            dropDownPlaceHolder="Выберите платформу"
            (userSelect)="change.next()"
            [strategy]="betPlatformStrategy"
            [multiSelectControl]="taskItem.betPlatforms"
        >
        </app-wl-strict-multi-select-dropdown>
    </div>
    <div class="wl-item-row" *ngIf="isBetType || isWinType || isRedeemType">
        <app-wl-strict-input-number title="Чемпионат"
                                 placeholder=""
                                 min="1"
                                 [control]="taskItem.championshipId">
        </app-wl-strict-input-number>
    </div>
    <div class="wl-item-row" *ngIf="isBetType || isWinType || isRedeemType">
        <app-wl-dropdown [control]="taskItem.isExcludedGames"
                         (userSelect)="change.next()"
                         [options]="{ placeholder: 'Не указано', title: 'Дисциплина' }"
                         [strategy]="filterStrategy"
        ></app-wl-dropdown>
        <app-wl-strict-multi-select-dropdown dropDownPlaceHolder="Выберите дисциплину"
                                          (userSelect)="change.next()"
                                          [strategy]="gamesStrategy"
                                          [maxElementsCount]="maxGameItems"
                                          [multiSelectControl]="taskItem.games"
                                          [shouldShowErrorList]="true"
        ></app-wl-strict-multi-select-dropdown>
    </div>
    <div class="wl-item-row" *ngIf="isWinType && isByCountType && !isCollectInExpress">
        <app-wl-battle-pass-range-condition class="battle-pass-range-condition"
                                            [title]="'Сумма выигрыша ставки'"
                                            (change)="change.emit()"
                                            minValue="1"
                                            [viewModel]="taskItem.betWinAmountRange">
        </app-wl-battle-pass-range-condition>
    </div>
    <div class="wl-item-row" *ngIf="isBetType || isWinType || isRedeemType">
        <div class="market-header-block">
            <span class="wl-field-title">Маркеты:</span>
            <button class="wl-btn-form-active" type="button" (click)="addMarket()">Добавить маркет</button>
        </div>
        <div class="market-item" *ngFor="let market of taskItem.activeMarkets.strictValue; let i = index">
            <div class="market-item-name">
                <span class="wl-field-text">{{market.market.strictValue.id}} - {{market.market.strictValue.name}}</span>
                <a class="wl-test-exports-list-link a-button" [routerLink]="" (click)="editMarket(i)">Редактировать</a>
                <a class="wl-test-exports-list-link a-button" [routerLink]="" (click)="deleteMarket(i)">Удалить</a>
            </div>
            <div class="market-item-fts">
                <span *ngIf="market.ftsParameterA.strictValue" class="wl-field-text">[a]:{{market.ftsParameterA.strictValue}}</span>
                <span *ngIf="market.ftsParameterB.strictValue" class="wl-field-text">[b]:{{market.ftsParameterB.strictValue}}</span>
                <span *ngIf="market.ftsParameterC.strictValue" class="wl-field-text">[c]:{{market.ftsParameterC.strictValue}}</span>
            </div>
        </div>
    </div>
    <div class="wl-item-row" *ngIf="isDepositsType && isByCountType">
        <app-wl-strict-input-number title="Количество депозитов"
                                 placeholder=""
                                 min="1"
                                 [control]="taskItem.depositsCount">
        </app-wl-strict-input-number>
    </div>
    <div class="wl-item-row" *ngIf="isDepositsType && isByCountType">
        <app-wl-battle-pass-range-condition class="battle-pass-range-condition"
                                            [title]="'Сумма депозита'"
                                            (change)="change.emit()"
                                            minValue="1"
                                            [viewModel]="taskItem.depositsSumRange">
        </app-wl-battle-pass-range-condition>
    </div>
    <div class="wl-item-row" *ngIf="isDepositsType && isBySumType">
        <app-wl-battle-pass-range-condition class="battle-pass-range-condition"
                                            [title]="'Общая сумма депозитов'"
                                            (change)="change.emit()"
                                            minValue="1"
                                            [allowedConditionalType]="moreOrEqualStrongConditionalType"
                                            [viewModel]="taskItem.totalDepositsAmountRange">
        </app-wl-battle-pass-range-condition>
    </div>
    <hr>
    <div class="wl-button-row">
        <button class="wl-btn-form-active" type="button" [disabled]="isAddTaskDisabled" (click)="addTask()">Добавить задание</button>
        <div *ngIf="isDeleteButtonActive">
            <button class="wl-btn-form-inactive wl-button-position" type="button" (click)="delete()">Удалить</button>
        </div>
    </div>
    <hr />
    <div *ngIf="isTaskExist" class="wl-task-item-selector-body">Id подзадания: {{ taskItem.id.strictValue }}</div>
</div>
