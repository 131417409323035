import { WlBattlePassGameTaskTemplateViewModel } from '../models/wl-battle-pass-game-task-template-view-model';
import { AbstractModelValidator } from '@koddington/ga-common';

export class WlBattlePassGameTaskTemplateValidator extends AbstractModelValidator<WlBattlePassGameTaskTemplateViewModel> {

    constructor() {
        super();

        this.ruleForControl(u => u.name)
            .requiredAndNotZero('Введите название шаблона');

        this.ruleForControl(u => u.description)
            .requiredAndNotZero('Введите описание задания');

        this.ruleForControl(u => u.externalPartnerId)
            .requiredAndNotZero('Введите партнерский ID задания');

        this.ruleForControl(u => u.icon)
            .requiredAndNotZero('Введите партнерский ID задания');
    }
}
