import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { isNullOrUndefined } from '@koddington/ga-common';
import { WlRadioButtonViewModel } from './view-models/wl-radio-button-view-model';

@Component({
    selector: 'app-wl-radio-buttons',
    templateUrl: './wl-radio-buttons.component.html',
    styleUrls: ['./wl-radio-buttons.component.scss'],
})
export class WlRadioButtonsComponent implements OnInit, OnDestroy {
    @Input()
    public control: WlRadioButtonViewModel[];
    @Output()
    public userSelect = new EventEmitter<void>();

    constructor() {
    }

    public ngOnDestroy(): void {
    }

    public ngOnInit(): void {
        if (isNullOrUndefined(this.control)) {
            throw new Error("Control not implemented.");
        }
        if (this.control.length <= 0) {
            throw new Error("Control is empty.");
        }

        this.setControls(this.control, 0);
    }

    public onSelect(index: number): void {
        this.setControls(this.control, index);
        this.userSelect.emit();
    }

    public isChecked(index: number): boolean {
        return this.control[index].radioButton?.strictValue;
    }

    private setControls(values: WlRadioButtonViewModel[], index: number): void {
        values.forEach(v => v.radioButton.strictValue = false);
        values[index].radioButton.strictValue = true;
    }
}
