import { WlTaskComparisonConditionsTypes } from '../../autogen/BattlePass';
import { WlRangeConditionViewModel } from '../../battle-pass/models/wl-range-condition-view-model';

export function isMoreThanValue(range: WlRangeConditionViewModel, value: number): boolean {
    if (range == null) {
        return true;
    }

    if (!range.condition.hasStrictValue || !range.value.hasStrictValue) {
        return false;
    }

    switch (range.condition.strictValue) {
        case WlTaskComparisonConditionsTypes.Equal:
            return range.value.strictValue > value;
        case WlTaskComparisonConditionsTypes.Less:
            return range.value.strictValue > value + 1;
        case WlTaskComparisonConditionsTypes.More:
            return range.value.strictValue >= value;
        case WlTaskComparisonConditionsTypes.LessEqual:
            return range.value.strictValue >= value + 1;
        case WlTaskComparisonConditionsTypes.MoreEqual:
            return range.value.strictValue > value;
    }
}
