import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ThrottlingSearchModel, ThrottlingsService } from 'src/app/modules/autogen/BattlePass';
import { StrictFormControl } from '@koddington/ga-common';
import { ThrottlingAutocompleteStrategy } from './strategy/throttling-autocomplete-strategy';

@Component({
    selector: 'app-strict-throttling-autocomplete',
    templateUrl: './strict-throttling-autocomplete.component.html',
    styleUrls: ['./strict-throttling-autocomplete.component.scss'],
    providers: [ThrottlingAutocompleteStrategy],
})
export class StrictThrottlingAutocompleteComponent implements OnInit, OnDestroy {
    @Input()
    public textLabel = '';
    @Input()
    public control = new StrictFormControl<ThrottlingSearchModel>(null);
    @Output()
    public userSelect = new EventEmitter<void>();
    @Output()
    public searchMethod = new EventEmitter<void>();

    constructor(private readonly _throttlingApiClient: ThrottlingsService, private readonly _throttlingStrategy: ThrottlingAutocompleteStrategy) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    get throttlingStrategy(): ThrottlingAutocompleteStrategy {
        return this._throttlingStrategy;
    }
}
