<app-wl-page-header header="Профиль пользователя"></app-wl-page-header>
<div class="wl-search-row">
    <app-wl-strict-input-number
            class="wl-app-input-search-wrapper wl-margin-right-8"
            isSearch="true"
            [control]="viewModel.userId"
            [placeholder]="'Id пользователя'"
            [min]="0"
            (searchMethod)="search()"
    ></app-wl-strict-input-number>
    <app-wl-strict-input-number
            class="wl-app-input-search-wrapper wl-margin-right-8"
            isSearch="true"
            [control]="viewModel.promoId"
            [placeholder]="'Id акции'"
            [min]="0"
            (searchMethod)="search()"
    ></app-wl-strict-input-number>
    <button class="wl-search-btn wl-margin-right-8" (click)="search()" [disabled]="isSearchForbidden">Показать</button>
    <button *ngIf="isDeveloper" class="wl-search-btn" (click)="resetCache()" [disabled]="isSearchForbidden">Сбросить кэш</button>
</div>

<div class="wl-userinfo-container" *ngIf="result ">
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Id пользователя: </span>
        <span class="wl-userinfo-field-value">
            {{ result.userId }}
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Id акции: </span>
        <span class="wl-userinfo-field-value">
            <a [routerLink]="['/menu/dailyTasks/promotions/update', result.promoInfo.id]">{{ result.promoInfo.id }}</a>
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Название акции: </span>
        <span class="wl-userinfo-field-value">
            {{ result.promoInfo.name}}
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Тип акции: </span>
        <span class="wl-userinfo-field-value">
            {{ result.promoInfo.type | wlPromoTypesTransform }}
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Дата старта акции: </span>
        <span class="wl-userinfo-field-value">
            {{ result.promoInfo.startDate | wlDate }}
        </span>
    </div>

    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Дата окончания акции: </span>
        <span class="wl-userinfo-field-value">
            {{ result.promoInfo.endDate | wlDate }}
        </span>
    </div>

    <div class="wl-user-info-row" *ngIf="!isPickemType"> <!--    maybe dont need hide this-->
        <span class="wl-userinfo-field-label">Id фрибета: </span>
        <span class="wl-userinfo-field-value">
            {{ result.promoInfo.freeBetId }}
        </span>
    </div>
    <div class="wl-user-info-row" *ngIf="isPickemType"> <!--    maybe dont need hide this-->
        <span class="wl-userinfo-field-label">Очки прогноза: </span>
        <span class="wl-userinfo-field-value">
            {{ result.points }}
        </span>
    </div>
    <div class="wl-user-info-row" *ngIf="isPickemType"> <!--    maybe dont need hide this-->
        <ul>
            <li class="point-history-item" *ngFor="let item of result.pointsHistory;">
                <span class="wl-userinfo-field-label">Дата: </span> {{ item.timestamp | wlDateTime }}
                <span class="wl-userinfo-field-label ml-2">Новый баланс: </span> {{ item.newPointsAmount }}
                <span class="wl-userinfo-field-label ml-2">Комментарий: </span> {{ item.comment }}
            </li>
        </ul>
    </div>
    <div *ngIf="isPickemType"> <!--    maybe dont need hide this-->
        <span class="wl-userinfo-field-label">Id задания акции: <a [routerLink]="['/menu/battlePass/task/edit', result.promoTask?.taskId]">{{ result.promoTask?.taskId }}</a></span>
        <div class="wl-user-info-row">
            <span class="wl-userinfo-field-label">Дата выполнения задания: </span>
            <span class="wl-userinfo-field-value">
            {{ result.promoTask?.completedAt | wlDateTime }}
        </span>
        </div>
        <div class="wl-user-info-row">
            <span class="wl-userinfo-field-label">Прогресс: </span>
            <span class="wl-userinfo-field-value">{{ mapPromoProgress(result.promoTask) }}</span>
        </div>
        <div *ngFor="let subtask of result.promoTask?.subtasks; let i = index">
            <div class="wl-user-info-row">
                <span class="wl-userinfo-field-label">Id подзадания: </span>
                <span class="wl-userinfo-field-value">{{ subtask.subtaskId }}</span>
            </div>
            <div class="wl-user-info-row word-break">
                <span class="wl-userinfo-field-label">Id ставок:</span>
                <div *ngFor="let bet of subtask.betsIds; let l  = last">
                    <span class="wl-bet-used-for-progress"> {{ bet }}</span>
                    <span *ngIf="!l">, </span>
                </div>
            </div>
        </div>
    </div>
</div>
<br>

<ga-table *ngIf="!!tableData && result" [data]="tableData" [breakWordOnly]="true" [hideArrows]="true"></ga-table>

<ng-template #showReward let-rawReward="reward">
    <ng-template [ngIf]="typifyReward(rawReward)" let-reward="ngIf">
        <div class="wl-task-info-text">
            <div>Уведомление о выполнении задания отправлено: {{ reward.rewardNotificationSent | wlBoolean }}</div>
            <div>Состояние награды: {{ reward.rewardState | wlDailyTaskAccrueRewardStateTypesTransform }}</div>
            <div>Id грейда лутбокса: {{ reward.lootBoxGradeId }}</div>
            <div>Минимальная сумма грейда: {{ reward.lootBoxGradeStart }}</div>
            <div>Лутбокс выдан: {{ reward.issueLootBoxAt | wlDateTime}}</div>
            <div>Лутбокс открыт: {{ reward.openedLootBoxAt | wlDateTime }}</div>
            <div>Фрибет выдан: {{ reward.issueFreeBetAt | wlDateTime }}</div>
            <div>Номинал фрибета: {{ mapFreebetAmount(reward) }}</div>
            <div *ngIf="hasRewardError(reward)">Ошибка: {{ reward.errorCode | wlDailyTaskFreeBetRequestErrorCodeTransform }}</div>
        </div>
    </ng-template>
    <div class="wl-task-info-text" *ngIf="!rawReward">
        <div>Лутбокс не начислен</div>
    </div>
</ng-template>

<ng-template #showTask let-rawTask="task">
    <ng-template [ngIf]="typifyTask(rawTask)" let-task="ngIf">
        <div class="wl-task-info-text">
            <div>Id задания: <a [routerLink]="['/menu/battlePass/task/edit', task.taskId]">{{ task.taskId }}</a></div>
            <div>Дата выполнения задания: {{task.completedAt | wlDateTime}}</div>
            <div>Прогресс: {{ mapProgress(task) }}</div>
            <div *ngFor="let subtask of task.subtasks; let i = index">
                <div>Id подзадания: {{ subtask.subtaskId }}</div>
                <div>Id ставок:</div>
                <div *ngIf="!task.isForceCompleted">
                    <span *ngFor="let bet of subtask.entities">
                        <span class="wl-bet-used-for-progress" *ngIf="bet.usedForProgress"> {{ bet.entityId }} </span>
                        <span *ngIf="!bet.usedForProgress"> {{ bet.entityId }} </span>
                        <br/>
                    </span>
                </div>
                <div class="wl-bet-used-for-progress" *ngIf="task.isForceCompleted">
                    Задание зачтено из профиля
                </div>
            </div>
        </div>
    </ng-template>
    <div class="wl-task-info-text" *ngIf="!rawTask">
        <div>Игрок не выполнял задание</div>
    </div>
</ng-template>

<ng-template #showPickemInfo let-rawPromoDay="promoDay" let-rawPickem="pickem">
    <div class="wl-task-info-text">
        <div>Прогноз выиграл?: {{ rawPromoDay?.forecastIsWin | wlBoolean }}</div>
        <div>Дата прогноза: {{rawPromoDay?.forecastMadeAt | wlDateTime}}</div>
        <div>Дата расчёта: {{rawPromoDay?.outcomeCalculatedAt | wlDateTime}}</div>
        <div>Выбранная карточка: ({{rawPromoDay?.selectedOutcome?.id}}) {{rawPromoDay?.selectedOutcome?.name}}</div>
        <div>Выигравшая карточка: ({{rawPickem?.winOutcome?.id}}) {{ rawPickem?.winOutcome?.name}}</div>
    </div>
    <div class="wl-task-info-text" *ngIf="!rawPickem">
        <div>Игрок не выполнял прогноз</div>
    </div>
</ng-template>
