import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GaPagedResult, GaPagingForm } from '@koddington/ga-common';
import { take } from 'rxjs/operators';
import { BattlePassGameLootBoxesService, WlGameLootBoxListModel, WlGameLootBoxSearchForm } from 'src/app/modules/autogen/BattlePass';
import { GaTableData } from '@koddington/ga-common';

@Component({
    selector: 'app-wl-battle-pass-game-loot-box-list',
    templateUrl: './wl-battle-pass-game-loot-box-list.component.html',
})
export class WlBattlePassGameLootBoxListComponent implements OnInit, OnDestroy {
    lootBoxItems: GaPagedResult<WlGameLootBoxListModel>;
    loading: boolean;

    tableData: GaTableData<WlGameLootBoxListModel>;

    constructor(private readonly _battlePassLootBoxesService: BattlePassGameLootBoxesService, private readonly _router: Router) {}

    ngOnInit(): void {
        this.load(0);
    }

    ngOnDestroy(): void {}

    public add(): void {
        this._router.navigate(['/menu/battlePass/lootBox/edit']);
    }

    public pageChanged(page: GaPagingForm): void {
        this.load(page.offset);
    }

    private load(offset: number = 0): void {
        this.loading = true;
        const filter = new WlGameLootBoxSearchForm();
        filter.count = 30;
        filter.offset = offset;

        this._battlePassLootBoxesService
            .lootBoxList(filter)
            .pipe(take(1))
            .subscribe((res) => {
                this.lootBoxItems = res;
                this.mapToTable();
                this.loading = false;
            });
    }

    private mapToTable(): void {
        const data = new GaTableData<WlGameLootBoxListModel>();

        this.tableData = data
            .addRouterLinkColumn(
                (elem) => elem.id,
                (elem) => ['edit', elem.id],
                { title: 'Код', widthSize: 100 }
            )
            .addSimpleColumn((elem) => elem.name, { title: 'Название', widthSize: 600 })
            .addSimpleColumn((elem) => elem.description, { title: 'Описание', widthSize: 690 })
            .setData(this.lootBoxItems.results);
    }
}
