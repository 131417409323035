import {StrictFormControl} from '@koddington/ga-common';

export class PartnersPromoSelectorViewModel {

  public promoId: StrictFormControl<number> = new StrictFormControl<number>(null);
  public promoName: StrictFormControl<string> = new StrictFormControl<string>(null);
  public sourceId: StrictFormControl<number> = new StrictFormControl<number>(null);
  public channelId: StrictFormControl<number> = new StrictFormControl<number>(null);
  public sourceName: StrictFormControl<string> = new StrictFormControl<string>(null);
  public channelName: StrictFormControl<string> = new StrictFormControl<string>(null);
  public isPromoEnabled: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
  public isSourceEnabled: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
  public isChannelEnabled: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
}
