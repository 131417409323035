import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {WlLevelViewModel} from '../../models/wl-level-view-model';
import { Observable, Subject } from 'rxjs';
import {WlBattlePassLevelValidator} from './validator/wl-battle-pass-level-validator';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {WlCrmTaskLiteViewModel} from '../../models/wl-crm-task-lite-view-model';
import {WlGameTaskLiteViewModel} from '../../models/wl-game-task-lite-view-model';
import {WlBattlePassGameTaskWrapperService} from '../../services/wrapper-service/wl-battle-pass-wrapper-game-service';
import {WlBattlePassWrapperService} from '../../services/wrapper-service/wl-battle-pass-wrapper-service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {WlSeasonViewModel} from '../../models/wl-season-view-model';
import { take } from 'rxjs/operators';
import { WlLevelGameLootBoxViewModel } from '../../models/wl-level-game-loot-box-view-model';
import { WlLevelFreeBetLootBoxViewModel } from '../../models/wl-level-free-bet-loot-box-view-model';
import { WlBattlePassGameLootBoxesScrollServiceAdapter } from '../../services/wrapper-service/wl-battle-pass-game-loot-boxes.scroll-service-adapter';
import { WlBattlePassCompletedTaskTypesStrategy } from '../../strategies/wl-battle-pass-completed-task-types-strategy';
import { WlBattlePassFreeBetLootBoxesScrollServiceAdapter } from '../../services/wrapper-service/wl-battle-pass-free-bet-loot-boxes.scroll-service-adapter';
import { GaConfirmationService, isNullOrUndefined, StrictError } from '@koddington/ga-common';
import { WlCrmTaskGroupLiteModel, WlGameTaskLiteModel, WlLevelFreeBetLootBoxModel, WlLevelGameLootBoxModel, WlSeasonLevelModel } from '../../../autogen/BattlePass';
import { builderModelToStrict, builderStrictToModel, gaMap } from 'src/app/modules/shared/common/operation/builder-operation';

@Component({
    selector: 'app-wl-battle-pass-level-edit-edit',
    templateUrl: './wl-battle-pass-level-edit.component.html',
    styleUrls: ['./wl-battle-pass-level-edit.component.scss'],
})
export class WlBattlePassLevelEditComponent implements OnInit, OnDestroy {
    public viewModel: WlLevelViewModel = new WlLevelViewModel();
    public readonly userManipulationsSource = new Subject<void>();
    public errors: StrictError[] = [];

    private level: WlSeasonLevelModel;
    private season: WlSeasonViewModel;
    private readonly validator = new WlBattlePassLevelValidator();

    private readonly DEFAULT_MAX_TASK_ORDER: number = 8;
    private readonly GAMBLER_MAX_TASK_ORDER: number = 1;

    constructor(
        public readonly taskTypesStrategy: WlBattlePassCompletedTaskTypesStrategy,
        private readonly _gameLootBoxesApiWrapper: WlBattlePassGameLootBoxesScrollServiceAdapter,
        private readonly _freeBetLootBoxesApiWrapper: WlBattlePassFreeBetLootBoxesScrollServiceAdapter,
        private readonly _battlePassCRMServiceWrapper: WlBattlePassWrapperService,
        private readonly _battlePassGameServiceWrapper: WlBattlePassGameTaskWrapperService,
        private readonly _dialog: GaConfirmationService,
        private readonly _dialogRef: MatDialogRef<WlBattlePassLevelEditComponent, WlSeasonLevelModel>,
        @Inject(MAT_DIALOG_DATA) public data: { season: WlSeasonViewModel, index: number | null }
    ) {
    }

    ngOnInit(): void {
        this.init(false);
        this.subscriptionToValidate();
        this.setReadonlyItems();
    }

    public ngOnDestroy(): void {
    }

    public addCrmTask(): void {
        this.viewModel.crmTaskGroups.strictValue.push(new WlCrmTaskLiteViewModel(this._battlePassCRMServiceWrapper, this.viewModel.isGamblerLevel?.strictValue));
        this.userManipulationsSource.next();
    }

    public addGameTask(): void {
        this.viewModel.gameTasks.strictValue.push(new WlGameTaskLiteViewModel(this._battlePassGameServiceWrapper));
        this.userManipulationsSource.next();
    }

    public addGameLootBox(): void {
        this.viewModel.gameLootBoxes.strictValue.push(new WlLevelGameLootBoxViewModel(this._gameLootBoxesApiWrapper));
        this.userManipulationsSource.next();
    }

    public addFreeBetLootBox(): void {
        this.viewModel.freeBetLootBoxes.strictValue.push(new WlLevelFreeBetLootBoxViewModel(this._freeBetLootBoxesApiWrapper));
        this.userManipulationsSource.next();
    }

    public save(): void {
        const level = builderStrictToModel(
            WlSeasonLevelModel,
            this.viewModel,
            [
                {
                    sourceMapProperty: 'gameTasks',
                    targetMapProperty: 'gameTasks',
                    mapObject: gaMap(this.viewModel.gameTasks.strictValue.filter(u => !u.isEmpty), (value) => {
                        const mapped = new WlGameTaskLiteModel();
                        mapped.order = value.order.strictValue;
                        mapped.task = value.task.strictValue;
                        if (!isNullOrUndefined(mapped.task)) {
                            mapped.task.isWildCard = value.isWildCard.strictValue;
                        }
                        return mapped;
                    }),
                },
                {
                    sourceMapProperty: 'crmTaskGroups',
                    targetMapProperty: 'crmTaskGroups',
                    mapObject: gaMap(this.viewModel.crmTaskGroups.strictValue.filter(u => !u.isEmpty), (value) => {
                        const mapped = new WlCrmTaskGroupLiteModel();
                        mapped.order = value.order.strictValue;
                        mapped.taskGroup = value.task.strictValue;
                        if (!isNullOrUndefined(mapped.taskGroup)) {
                            mapped.taskGroup.isWildCard = value.isWildCard.strictValue;
                        }
                        return mapped;
                    }),
                },
                {
                    sourceMapProperty: 'gameLootBoxes',
                    targetMapProperty: 'gameLootBoxes',
                    mapObject: gaMap(this.viewModel.gameLootBoxes.strictValue.filter(u => !!u.lootBox.strictValue?.id), (value) => {
                        const mapped = new WlLevelGameLootBoxModel();
                        mapped.id = value.lootBox.strictValue.id;
                        mapped.name = value.lootBox.strictValue.name;
                        mapped.completeTaskType = value.completedTaskType.strictValue;
                        mapped.gameType = value.lootBox.strictValue.gameType;

                        return mapped;
                    }),
                },
                {
                    sourceMapProperty: 'freeBetLootBoxes',
                    targetMapProperty: 'freeBetLootBoxes',
                    mapObject: gaMap(this.viewModel.freeBetLootBoxes.strictValue.filter(u => !!u.lootBox.strictValue?.id), (value) => {
                        const mapped = new WlLevelFreeBetLootBoxModel();
                        mapped.id = value.lootBox.strictValue.id;
                        mapped.name = value.lootBox.strictValue.name;
                        mapped.completeTaskType = value.completedTaskType.strictValue;

                        return mapped;
                    }),
                },
            ],
            [
                {sourceKey: 'pushTemplate', targetKey: 'template', newValue: null},
            ],
            null
        );

        this._dialogRef.close(level);
    }

    public onGamblerModeChange(): void {
        const enteredTasksCount =
            this.viewModel.crmTaskGroups.strictValue.filter(u => !isNullOrUndefined(u.task.strictValue)).length +
            this.viewModel.gameTasks.strictValue.filter(u => !isNullOrUndefined(u.task.strictValue)).length;

        if (enteredTasksCount === 0) {
            this.processLevelModeChange();
            return;
        }

        this._dialog.openDialog('В конструктор уровня введены задания, при переключении режима они будут сброшены. Продолжить?', 'Да', 'Нет')
            .pipe(take(1))
            .subscribe(res => {
                if (res) {
                    this.processLevelModeChange();
                    return;
                }

                this.viewModel.isGamblerLevel.strictValue = !this.isGamblerLevel;
            });
    }

    public close(): void {
        this._dialogRef.close();
    }

    public deleteCrmTask(crm: WlCrmTaskLiteViewModel): void {
        this.viewModel.crmTaskGroups.strictValue = this.viewModel.crmTaskGroups.strictValue.filter(u => u !== crm);
        this.userManipulationsSource.next();
    }

    public deleteGameTask(game: WlGameTaskLiteViewModel): void {
        this.viewModel.gameTasks.strictValue = this.viewModel.gameTasks.strictValue.filter(u => u !== game);
        this.userManipulationsSource.next();
    }

    public deleteGameLootBox(gameLootBox: WlLevelGameLootBoxViewModel): void {
        this.viewModel.gameLootBoxes.strictValue = this.viewModel.gameLootBoxes.strictValue.filter(u => u !== gameLootBox);
        this.userManipulationsSource.next();
    }

    public deleteFreeBetLootBox(gameLootBox: WlLevelFreeBetLootBoxViewModel): void {
        this.viewModel.freeBetLootBoxes.strictValue = this.viewModel.freeBetLootBoxes.strictValue.filter(u => u !== gameLootBox);
        this.userManipulationsSource.next();
    }

    protected setLevelDateFromSeasonStart(): void {
        this.viewModel.startDate.strictValue = this.season.startDate.strictValue;
        this.userManipulationsSource.next();
    }

    private subscriptionToValidate(): void {
        this.userManipulationsSource.pipe(untilDestroyed(this)).subscribe(() => {
            this.errors = this.validator.validateCustom(this.season.startDate.strictValue, this.season.endDate.strictValue, this.viewModel);
        });
        this.userManipulationsSource.next();
    }

    private init(shouldReset: boolean): void {
        this.season = this.data.season;
        if (!isNullOrUndefined(this.data.index)) {
            this.level = this.data.season.levels.strictValue[this.data.index];
        }

        if (isNullOrUndefined(this.level) || shouldReset) {
            this.removeTasks();
        } else {
            this.loadViewModel();
        }

        this._dialogRef.disableClose = true;
    }

    private setReadonlyItems(): void {
        if (!this.isActiveLevel) {
            return;
        }

        this.viewModel.isGamblerLevel.disable();
        this.viewModel.order.disable();
        this.viewModel.name.disable();
        this.viewModel.template.disable();
        this.viewModel.startDate.disable();
        this.viewModel.crmTaskGroups.strictValue.forEach((crm) => {
            crm.task.disable();
            crm.isWildCard.disable();
            crm.canChange = false;
        });
        this.viewModel.gameTasks.strictValue.forEach((game) => {
            game.task.disable();
            game.isWildCard.disable();
            game.canChange = false;
        });
        this.viewModel.gameLootBoxes.disable();
        this.viewModel.gameLootBoxes.strictValue.forEach((game) => {
            game.lootBox.disable();
            game.completedTaskType.disable();
            game.canChange = false;
        });
        this.viewModel.freeBetLootBoxes.disable();
        this.viewModel.freeBetLootBoxes.strictValue.forEach((game) => {
            game.lootBox.disable();
            game.completedTaskType.disable();
            game.canChange = false;
        });
    }

    private removeTasks(): void {
        this.viewModel.crmTaskGroups.strictValue = [];
        this.viewModel.gameTasks.strictValue = [];
    }

    private loadViewModel(): void {
        builderModelToStrict(
                this.viewModel,
                this.level,
                [
                    {
                        sourceMapProperty: 'crmTaskGroups',
                        targetMapProperty: 'crmTaskGroups',
                        mapObject: gaMap(this.level.crmTaskGroups, (val) => {
                            const mapped = new WlCrmTaskLiteViewModel(this._battlePassCRMServiceWrapper, this.level.isGamblerLevel);
                            mapped.order.strictValue = val.order;
                            mapped.task.strictValue = val.taskGroup;
                            mapped.isWildCard.strictValue = val.taskGroup?.isWildCard;

                            return mapped;
                        }).sort((first, second) => first.order.strictValue - second.order.strictValue),
                    },
                    {
                        sourceMapProperty: 'gameTasks',
                        targetMapProperty: 'gameTasks',
                        mapObject: gaMap(this.level.gameTasks, (val) => {
                            const mapped = new WlGameTaskLiteViewModel(this._battlePassGameServiceWrapper);
                            mapped.order.strictValue = val.order;
                            mapped.task.strictValue = val.task;
                            mapped.isWildCard.strictValue = val.task?.isWildCard;

                            return mapped;
                        }).sort((first, second) => first.order.strictValue - second.order.strictValue),
                    },
                    {
                        sourceMapProperty: 'gameLootBoxes',
                        targetMapProperty: 'gameLootBoxes',
                        mapObject: gaMap(this.level.gameLootBoxes, (val) => {
                            const mapped = new WlLevelGameLootBoxViewModel(this._gameLootBoxesApiWrapper);
                            mapped.lootBox.strictValue = val;
                            mapped.completedTaskType.strictValue = val.completeTaskType;

                            return mapped;
                        }),
                    },
                    {
                        sourceMapProperty: 'freeBetLootBoxes',
                        targetMapProperty: 'freeBetLootBoxes',
                        mapObject: gaMap(this.level.freeBetLootBoxes, (val) => {
                            const mapped = new WlLevelFreeBetLootBoxViewModel(this._freeBetLootBoxesApiWrapper);
                            mapped.lootBox.strictValue = val;
                            mapped.completedTaskType.strictValue = val.completeTaskType;

                            return mapped;
                        }),
                    },
                ],
                [
                    {sourceKey: 'startDate', targetKey: 'startDate', newValue: !isNullOrUndefined(this.level.startDate) ? this.level.startDate : null}
                ],
                null
        );
    }

    private processLevelModeChange(): void {
        this.viewModel.crmTaskGroups.strictValue.splice(0);
        this.viewModel.gameTasks.strictValue.splice(0);

        this.init(true);
        this.userManipulationsSource.next();
    }


    public get crmTasks(): WlCrmTaskLiteViewModel[] {
        return this.viewModel.crmTaskGroups.strictValue;
    }

    public get gameLootBoxes(): WlLevelGameLootBoxViewModel[] {
        return this.viewModel.gameLootBoxes.strictValue;
    }

    public get freeBetLootBoxes(): WlLevelFreeBetLootBoxViewModel[] {
        return this.viewModel.freeBetLootBoxes.strictValue;
    }

    get isValid(): boolean {
        return this.errors.length === 0;
    }

    get maxOrder(): number {
        return this.viewModel.isGamblerLevel.strictValue
            ? this.GAMBLER_MAX_TASK_ORDER
            : this.DEFAULT_MAX_TASK_ORDER;
    }

    get canAddTask(): boolean {
        return this.viewModel.overallTasksCount(false) < this.maxOrder;
    }

    get isGamblerLevel() {
        return this.viewModel?.isGamblerLevel.strictValue;
    }

    get gameLootBoxErrors(): Observable<StrictError[]> {
        return this.viewModel.gameLootBoxes.strictErrors;
    }

    get freeBetLootBoxErrors(): Observable<StrictError[]> {
        return this.viewModel.freeBetLootBoxes.strictErrors;
    }

    get isActiveLevel(): boolean {
        return this.viewModel.isActive.strictValue;
    }

    get hasSeasonStartDate(): boolean {
        return this.season.startDate.hasStrictValue;
    }
}
