import { WlCrmTaskLiteViewModel } from '../../../models/wl-crm-task-lite-view-model';
import { WlGameTaskLiteViewModel } from '../../../models/wl-game-task-lite-view-model';
import {
    WlBattlePassLevelTasksExtensionValidator
} from './wl-battle-pass-level-tasks-extension-validator';
import {Dayjs} from 'dayjs';
import { isNullOrUndefined, StrictError } from '@koddington/ga-common';

export class WlBattlePassLevelGameTaskValidator extends WlBattlePassLevelTasksExtensionValidator {
    protected _gameTask: WlGameTaskLiteViewModel[] | null | undefined;
    protected _crmTask: WlCrmTaskLiteViewModel[] | null | undefined;
    protected _startDate: Dayjs | null | undefined;

    constructor() {
        super();

        this.ruleForControlIf(u => u.task, () => !this.isTasksEmpty || this.isStartDateExistAndTasksEmpty)
            .required('Игровые задания должны быть заполнены');

        this.ruleForControlIf(u => u.order, () => !this.isTasksEmpty || this.isStartDateExistAndTasksEmpty)
            .required('Последовательность должна быть заполнена');

        this.ruleForControl((u) => u.order).customRule((order) => {
            if (!isNullOrUndefined(this._crmTask) && !isNullOrUndefined(order)) {
                const result = this._crmTask.filter((crmOrder) => crmOrder.order.strictValue === order);

                return result.length > 0 ? new StrictError('order', `Порядковый номер "${result[0].order.strictValue}" уже существует`) : null;
            }
            return null;
        });
        this.ruleForControl((u) => u.order).customRule((order) => {
            if (!isNullOrUndefined(this._gameTask) && !isNullOrUndefined(order)) {
                const orders: number[] = this._gameTask.filter((u) => u.order.hasStrictValue).map((v) => v.order.strictValue);
                const result: number[] = orders.filter((item, index) => orders.indexOf(item) !== index);

                return result.length > 0 ? new StrictError('order', `Порядковый номер "${result[0]}" уже существует`) : null;
            }
            return null;
        });
    }

    public validateCustom(gameTaskArray: WlGameTaskLiteViewModel[] | null | undefined, crmTaskArray: WlCrmTaskLiteViewModel[] | null | undefined
        , startDate: Dayjs | null | undefined): StrictError[] {
        this._crmTask = crmTaskArray;
        this._gameTask = gameTaskArray;
        this._startDate = startDate;
        return super.validateArray(gameTaskArray);
    }
}
