import { StrictFormControl } from '@koddington/ga-common';
import { WlDtPromotionDay, WlPromoTypes } from '../../../../autogen/DailyTasks';
import { DailyTaskDaysScrollServiceAdapter } from '../../../services/wrapper-services/daily-task-days.scroll-service-adapter';
import { DailyTaskDaysAutocompleteStrategy } from '../../../strategies/daily-task-days-autocomplete-strategy';
import { WlPickemOutcomeSelectorViewModel } from './wl-pickem-outcome-selector.view-model';

export class WlDailyTasksDaysSelectorViewModel {
    constructor(public readonly _api: DailyTaskDaysScrollServiceAdapter,
                public readonly _promoTypeRule: () => WlPromoTypes) {
    }

    public day: StrictFormControl<WlDtPromotionDay> = new StrictFormControl<WlDtPromotionDay>(null);
    public pickemSelectorInfo: WlPickemOutcomeSelectorViewModel = null;

    public strategy: DailyTaskDaysAutocompleteStrategy = new DailyTaskDaysAutocompleteStrategy(this._api, this._promoTypeRule);
}
