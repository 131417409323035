import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WlSeasonsSearchViewModel } from '../../models/wl-seasons-search-view-model';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { formatDateTime, GaPagedResult, GaPagingForm, isNullOrUndefined } from '@koddington/ga-common';
import { BattlePassService, WlBattlePassSeasonFinishReasonsExtensions, WlBattlePassStatesExtensions, WlSeasonListModel, WlSeasonSearchForm } from '../../../autogen/BattlePass';
import { GaSearchManagementStateService } from '../../../shared/extensions/search-state-managment';
import { GaTableData } from '@koddington/ga-common';

@Component({
    selector: 'app-wl-battle-pass-season-list',
    templateUrl: './wl-battle-pass-season-list.component.html',
    providers: [GaSearchManagementStateService],
})
export class WlBattlePassSeasonListComponent implements OnInit, OnDestroy {
    public result: GaPagedResult<WlSeasonListModel>;
    public tableData: GaTableData<WlSeasonListModel>;
    public viewSearchModel: WlSeasonsSearchViewModel = new WlSeasonsSearchViewModel();

    constructor(
        private readonly _httpService: BattlePassService,
        private readonly _router: Router,
        private readonly _searchManagementState: GaSearchManagementStateService<GaPagedResult<WlSeasonListModel>>
    ) {}

    public ngOnInit() {
        this._searchManagementState.init((form) => this._httpService.listSeason(form as WlSeasonSearchForm), this.viewSearchModel);
        this._searchManagementState.searchState.pipe(untilDestroyed(this)).subscribe((result) => {
            this.result = result;
            this._searchManagementState.loadingStatus = false;
            this.mapToTable();
        });
    }

    public ngOnDestroy(): void {}

    public pageChanged(page: GaPagingForm): void {
        this._searchManagementState.nextSearch(page);
    }

    public pageFiltered(page: GaPagingForm): void {
        this._searchManagementState.nextSearch(page, true);
    }

    public add(): void {
        this._router.navigate(['/menu/battlePass/season/edit']);
    }

    private mapToTable(): void {
        const data = new GaTableData<WlSeasonListModel>();

        this.tableData = data
            .addRouterLinkColumn(
                (elem) => elem.id,
                (elem) => ['edit', elem.id],
                { title: 'Код', widthSize: 70 }
            )
            .addSimpleColumn((elem) => elem.name, { title: 'Название', widthSize: 200 })
            .addSimpleColumn((elem) => this.getStatus(elem), { title: 'Статус', widthSize: 160 })
            .addSimpleColumn((elem) => elem.levelsCount, { title: 'Уровней', widthSize: 100 })
            .addSimpleColumn((elem) => elem.rateRaceId ?? '-', { title: 'RateRace', widthSize: 100 })
            .addSimpleColumn((elem) => formatDateTime(elem.startDate), { title: 'Дата начала', widthSize: 130 })
            .addSimpleColumn((elem) => formatDateTime(elem.endDate), { title: 'Дата окончания', widthSize: 130 })
            .addSimpleColumn((elem) => elem.entityActors.creatorAccountLogin, { title: 'Создано', widthSize: 100 })
            .addSimpleColumn((elem) => elem.entityActors.editorAccountLogin, { title: 'Изменено', widthSize: 100 })
            .addSimpleColumn((elem) => formatDateTime(elem.entityActors.createdDate), { title: 'Дата создания', widthSize: 130 })
            .addSimpleColumn((elem) => formatDateTime(elem.entityActors.modifiedDate), { title: 'Дата изменения', widthSize: 130 })
            .setData(this.result.results);
    }

    private getStatus(season: WlSeasonListModel) {
        if (isNullOrUndefined(season.finishReason)) {
            return WlBattlePassStatesExtensions.format(season.state);
        }

        return `${WlBattlePassStatesExtensions.format(season.state)} (${WlBattlePassSeasonFinishReasonsExtensions.format(season.finishReason)})`;
    }

    get loading(): boolean {
        return this._searchManagementState.loadingStatus;
    }
}
