<div class="wl-season-container">
    <app-wl-page-header header="{{ purpose }}"></app-wl-page-header>
    <app-wl-loading *ngIf="loading"></app-wl-loading>
    <div class="wl-search-row wl-search-row-height">
        <button class="wl-button-primary wl-season-column-margin" [disabled]="!canSave" (click)="save()">
            {{ saveButtonText }}
        </button>
        <div class="wl-creators wl-creators-float-right" *ngIf="isUpdate">
            Создал: {{ viewModel.actorLogin.strictValue }}
        </div>
        <div class="wl-creators" *ngIf="isUpdate">Изменил: {{ viewModel.editorLogin.strictValue }}</div>
    </div>
    <div class="wl-season-column">
        <div class="wl-season-row-main">
            <app-wl-strict-input-text
                [control]="viewModel.name"
                title="Название"
                placeholder="Введите название сезона"
                (valueChange)="userManipulationsSource.next()"
                [className]="'wl-battle-pass-task-name'"
            >
            </app-wl-strict-input-text>
        </div>
        <div class="wl-season-row-main-second">
            <app-wl-strict-input-text
                [control]="viewModel.description"
                title="Описание"
                placeholder="Введите описание"
                (valueChange)="userManipulationsSource.next()"
                [className]="'wl-battle-pass-task-name'"
            >
            </app-wl-strict-input-text>
        </div>
    </div>
    <div class="wl-season-column">
        <div class="wl-season-row-main">
            <app-wl-datetime-single-picker
                [date]="viewModel.startDate"
                title="Дата начала"
                (userChosen)="userManipulationsSource.next()"
            ></app-wl-datetime-single-picker>
        </div>
        <div class="wl-season-row-main-second">
            <app-wl-strict-input-text
                [control]="viewModel.imageUrl"
                title="Изображение"
                placeholder="Введите ссылку на изображение"
                (valueChange)="userManipulationsSource.next()"
                [className]="'wl-battle-pass-task-name'"
            >
            </app-wl-strict-input-text>
        </div>
    </div>
    <div class="wl-season-column">
        <div class="wl-season-row-main">
            <app-wl-datetime-single-picker
                [date]="viewModel.endDate"
                title="Дата технического расчёта"
                (userChosen)="userManipulationsSource.next()"
            ></app-wl-datetime-single-picker>
        </div>
        <div class="wl-season-row-main-second">
            <app-wl-datetime-single-picker
                [date]="viewModel.payoutDate"
                title="Дата фактического расчёта"
                (userChosen)="userManipulationsSource.next()"
            ></app-wl-datetime-single-picker>
        </div>
    </div>
    <div class="wl-season-column">
        <div class="wl-season-row-main">
            <app-wl-strict-input-number
                [control]="viewModel.levelsCount"
                [title]="'Уровней ' + countLevel + ' из'"
                placeholder="Введите количество уровней"
                (valueChange)="userManipulationsSource.next()"
                [className]="'wl-battle-pass-task-name'"
            >
            </app-wl-strict-input-number>
        </div>
        <div class="wl-season-row-main-second">
            <app-strict-scroll-autocomplete-new placeholder="Турнир RateRace"
                                                label="Турнир RateRace"
                                                [strategy]="rateRaceStrategy"
                                                [control]="viewModel.rateRace"
            ></app-strict-scroll-autocomplete-new>
        </div>
    </div>
    <div class="wl-season-row">
        <button
            class="wl-btn-form-active wl-season-level-task-button"
            type="button"
            [disabled]="!canAddNewLevel"
            (click)="addNewLevel()"
        >
            Добавить уровень
        </button>
    </div>
    <div class="wl-season-error">
      <div *ngFor="let error of (levelsErrors | async)">
        {{ error.message }}
      </div>
    </div>
    <div *ngFor="let level of levels; let i = index">
        <app-wl-battle-pass-level-view
            [level]="level"
            [canDelete]="canUpdate"
            (delEmitter)="deleteLevel(i)"
            (editEmitter)="editLevel(i)"
        ></app-wl-battle-pass-level-view>
    </div>
</div>
