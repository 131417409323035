<div class="wl-container">
    <app-wl-page-header header="{{purpose}}"></app-wl-page-header>
    <app-wl-loading *ngIf="loading"></app-wl-loading>
  <div *ngIf="!viewModel.id.strictValue" class="wl-row-table">
    <label class="wl-label wl-switch-label" [ngClass]="{ 'wl-active': !isPickem }">Daily Task</label>
    <div class="wl-toggle-switch">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="viewModel.isPickemMode.strictValue">
        <span class="slider round"></span>
      </label>
    </div>
    <label class="wl-label wl-switch-label" [ngClass]="{ 'wl-active': isPickem }">Pick'em</label>
  </div>

  <app-wl-strict-input-boolean class="wl-row"
                               [control]="viewModel.isOffPlayDay"
                               (userSelect)="userManipulationsSource.next()"
                               label='Признак "Неигрового дня"'>
  </app-wl-strict-input-boolean>
  <app-wl-strict-input-boolean class="wl-row"
                               [control]="viewModel.isUnconditionalDay"
                               (userSelect)="userManipulationsSource.next()"
                               label='Признак "Безусловного дня"'>
  </app-wl-strict-input-boolean>
    <app-wl-strict-input-text class="wl-row"
                              [control]="viewModel.name"
                              title="Название"
                              placeholder=""
                              (valueChange)="userManipulationsSource.next()"
                              [className]="'wl-battle-pass-task-name'">
    </app-wl-strict-input-text>
    <app-wl-datetime-single-picker class="wl-row"
                                   [date]="viewModel.startDate"
                                   title="Дата старта"
                                   (userChosen)="userManipulationsSource.next()"
    ></app-wl-datetime-single-picker>
    <app-wl-datetime-single-picker class="wl-row"
                                   [date]="viewModel.endDate"
                                   title="Дата окончания"
                                   (userChosen)="userManipulationsSource.next()"
    ></app-wl-datetime-single-picker>
    <app-wl-strict-input-text class="wl-row"
                              [control]="viewModel.description"
                              title="Описание"
                              placeholder=""
                              (valueChange)="userManipulationsSource.next()"
                              [className]="'wl-battle-pass-task-name'">
    </app-wl-strict-input-text>
    <app-strict-scroll-autocomplete-new class="wl-row"
                                        label="Бук.задание"
                                        [targetRouterLink]="routerLinkForCrmTask"
                                        (valueChange)="userManipulationsSource.next()"
                                        [strategy]="crmTaskGroupStrategy"
                                        [control]="viewModel.crmTaskGroup"
    ></app-strict-scroll-autocomplete-new>

  <div *ngIf="!isPickem">
  <app-strict-scroll-autocomplete-new  class="wl-row"
                                       label="Лутбокс"
                                       [targetRouterLink]="routerLinkForLootBox"
                                       placeholder=""
                                       [isSearch]="false"
                                       [control]="viewModel.lootBox"
                                       (valueChange)="userManipulationsSource.next()"
                                       [strategy]="lootBoxStrategy">
  </app-strict-scroll-autocomplete-new>

  <div class="wl-players-cards-selector" *ngFor="let playerCardSelector of viewModel.playerCards.strictValue; let i = index">
    <app-wl-strict-input-text class="wl-number-component"
                              title="Имя игрока"
                              placeholder=""
                              (valueChange)="userManipulationsSource.next()"
                              [control]="playerCardSelector.playerName">
    </app-wl-strict-input-text>
    <app-wl-strict-input-text class="wl-number-component"
                              title="Url карточки"
                              placeholder=""
                              (valueChange)="userManipulationsSource.next()"
                              [control]="playerCardSelector.playerCardUrl">
    </app-wl-strict-input-text>
    <button class="wl-row wl-btn-form-inactive" type="button" [disabled]="!canUpdate" (click)="deletePlayerCard(i)">Удалить</button>
  </div>
  <button class="wl-row wl-btn-form-active"
          type="button"
          [disabled]="!canAddMore"
          (click)="addPlayerCard()"
  >
    Добавить карточку игрока
  </button>

  <div class="players-cards-invalid" *ngIf="isPlayerCardsConflict">
    Карточки игроков в количестве {{ WlDailyTasksLimitsConsts.FreeBetPlayerCardsCount }} не добавлены
  </div>
</div>


  <div class="wl-players-cards-selector" *ngIf="isPickem">

    <app-wl-strict-input-text class="wl-number-component wl-row"
                              title="Текст прогноза"
                              placeholder=""
                              (valueChange)="userManipulationsSource.next()"
                              [control]="viewModel.pickemText">
    </app-wl-strict-input-text>
    <app-wl-strict-input-text class="wl-number-component wl-row"
                              title="Картинка типа прогноза (url)"
                              placeholder=""
                              (valueChange)="userManipulationsSource.next()"
                              [control]="viewModel.forecastTypeImageUrl">
    </app-wl-strict-input-text>
      <app-wl-strict-input-text class="wl-number-component wl-row"
                                title="Описание типа прогноза"
                                placeholder=""
                                (valueChange)="userManipulationsSource.next()"
                                [control]="viewModel.forecastTypeText">
      </app-wl-strict-input-text>
    <div class="wl-form-row">
      <app-wl-datetime-single-picker class="wl-row"
                                     [date]="viewModel.usersForecastDeadline"
                                     title="Время окончания приема прогноза"
                                     (userChosen)="userManipulationsSource.next()"
      ></app-wl-datetime-single-picker>

      <button class="wl-margin-left-4 wl-button-primary wl-task-button"
              [disabled]="!canCopyEndDate"
              (click)="copyDayEndDate()"
      >Копировать дату окончания дня
      </button>
    </div>

    <div class="wl-players-cards-selector" *ngFor="let outcomesSelector of viewModel.outcomes.strictValue; let i = index">
      <app-wl-strict-input-text class="wl-number-component wl-row"
                                title="Название исхода"
                                placeholder=""
                                (valueChange)="userManipulationsSource.next()"
                                [control]="outcomesSelector.name">
      </app-wl-strict-input-text>
      <app-wl-strict-input-text class="wl-number-component wl-row"
                                title="Описание исхода"
                                placeholder=""
                                (valueChange)="userManipulationsSource.next()"
                                [control]="outcomesSelector.description">
      </app-wl-strict-input-text>
      <app-wl-strict-input-text class="wl-number-component wl-row"
                                title="Url картинки исхода"
                                placeholder=""
                                (valueChange)="userManipulationsSource.next()"
                                [control]="outcomesSelector.imageUrl">
      </app-wl-strict-input-text>
    </div>
  </div>

     <button class="wl-margin-bottom-4 wl-button-primary wl-task-button"
             [disabled]="!isValid || !canUpdate"
             (click)="save()"
     >
        {{ saveButtonText }} день
     </button>
</div>
