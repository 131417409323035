<app-wl-page-header header="Список турниров RateRace (Area 51)"></app-wl-page-header>
<div class="wl-template-row">
    <button class="wl-button-primary wl-btn-push-save-wrapper" (click)="add()">Добавить турнир</button>
</div>
<div class="wl-search-container">
    <div class="wl-search-row">
        <div class="wl-search-wrapper">
            <app-wl-strict-input-number
                class="wl-app-input-search-wrapper"
                isSearch="true"
                [control]="paging.minPlacesNumber"
                placeholder="Мин.количество мест"
                [min]="0"
                (searchMethod)="search()"
            ></app-wl-strict-input-number>
        </div>
        <div class="wl-search-wrapper wl-marging">
            <app-wl-strict-input-number
                class="wl-app-input-search-wrapper"
                isSearch="true"
                [control]="paging.maxPlacesNumber"
                placeholder="Макс.количество мест"
                [min]="0"
                (searchMethod)="search()"
            ></app-wl-strict-input-number>
        </div>
    </div>
</div>

<ga-table *ngIf="!!tableData" [data]="tableData" [breakWordOnly]="false"></ga-table>
<div class="wl-paging-wrapper">
    <app-wl-paging *ngIf="result" [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></app-wl-paging>
</div>
