import { Injectable, OnDestroy } from '@angular/core';
import { IAutocompleteItem } from '@koddington/ga-common';
import { WlSeasonListModel, WlSeasonSearchForm } from '../../autogen/BattlePass';
import { WlBattlePassSeasonsListScrollServiceAdapter } from '../services/wrapper-service/wl-battle-pass-seasons-list-scroll-service.adapter';
import { ScrollStrategy } from '../../../common/abstracts/abstract-scroll-strategy';

@Injectable()
export class WlBattlePassSeasonAutocompleteStrategy extends ScrollStrategy<WlSeasonListModel, WlSeasonSearchForm>
    implements OnDestroy {
    constructor(protected readonly _apiClient: WlBattlePassSeasonsListScrollServiceAdapter) {
        super(_apiClient);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    convert(model: WlSeasonListModel): IAutocompleteItem<WlSeasonListModel> {
        return this.convert_(() => {
            return {
                entity: model,
                title: model.name + ' (' + model.id.toString() + ')',
                key: model.id?.toString(),
            };
        });
    }

    public clear(): void {
        super._sourceClearAutocomplete$.next();
    }
}
