import { WlGameLootBoxListModel, WlRateRaceLootBoxPagingForm, BattlePassGameLootBoxesService } from 'src/app/modules/autogen/BattlePass';
import { WlGameLootBoxSearchForm } from 'src/app/modules/autogen/BattlePass';
import { Injectable } from '@angular/core';
import { WlResult } from 'src/app/models/common/results/wl-result';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IScrollService } from '../../../../common/abstracts/types/scroll-service-interface';

@Injectable({
    providedIn: 'root'
})
export class WlBattlePassGameLootBoxesScrollServiceAdapter implements IScrollService<WlGameLootBoxListModel, WlGameLootBoxSearchForm>{
    constructor(private _service: BattlePassGameLootBoxesService) {
    }

    public search(item: WlRateRaceLootBoxPagingForm): Observable<WlResult<WlGameLootBoxListModel[]>> {
        return this._service.lootBoxList(item).pipe(
            map(u => {
                const res = new WlResult<WlGameLootBoxListModel[]>();
                res.result = u.results;
                return res;
                }
            )
        );
    }
}
