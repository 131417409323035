import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { filter, first, map } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { BattlePassLevelTasksBindModels } from './models/battle-pass-level-tasks-bind-models';
import { BattlePassLevelBindModel } from './models/battle-pass-level-bind-model';
import {
  BattlePassService,
  ForceCompleteTaskRequestForm,
  WlBattlePassRewardProcessingErrorsExtensions,
  WlGameLootBoxContentModel,
  WlRateRaceAccrueRewardStateTypesExtensions,
  WlRateRaceStatesExtensions,
  WlSelectedLootboxTypes,
  WlUserBattlePassLevelViewModel,
  WlUserBattlePassRewardViewModel,
  WlUserBattlePassSeasonViewModel,
  WlUserBattlePassSubTaskViewModel,
  WlUserBattlePassTaskViewModel,
  WlUserRateRaceViewModel,
  WlUsersRewardTypesExtensions,
} from '../../../autogen/BattlePass';
import { formatDateTime, GaConfirmationService, GaMessagingService, GaTableCellTemplate, isNullOrUndefined, StrictFormControl } from '@koddington/ga-common';
import { booleanToString } from 'src/app/modules/shared/extensions/convert-extensions';
import { GaTableData } from '@koddington/ga-common';
import { Title } from '@angular/platform-browser';
import { RouterNavigationExtensions } from '../../../shared/extensions/navigation-extensions';

@Component({
    selector: 'app-wl-battle-pass-user-info',
    templateUrl: './wl-battle-pass-user-info.component.html',
    styleUrls: ['./wl-battle-pass-user-info.component.scss'],
})
export class WlBattlePassUserInfoComponent implements OnInit, OnDestroy {
    @ViewChild('showLevelAdditionalInfo') showLevelAdditionalInfo: TemplateRef<any>;
    @ViewChild('showTasks') showTasks: TemplateRef<any>;
    @ViewChild('showLootBoxContents') showLootBoxContents: TemplateRef<any>;
    @ViewChild('showLootBoxInfo') showLootBoxInfo: TemplateRef<any>;

    public isLoading = false;
    public forceCompleteRequests: number[] = [];
    public wildCardTaskTryToForced = false;
    public tasksUnableToForceComplete: number;
    public readonly tasksToCompleteLevelCount = 3;

    public userId = new StrictFormControl<number>(null);
    public seasonId = new StrictFormControl<number>(null);

    public levelBind = new BattlePassLevelBindModel();
    public result: WlUserBattlePassSeasonViewModel;

    public currentLevelTableData: GaTableData<WlUserBattlePassLevelViewModel>;
    public allLevelsTableData: GaTableData<WlUserBattlePassLevelViewModel>;
    public showTradeLinkAsLine = true;

    public rateRaceInfoTableData: GaTableData<WlUserRateRaceViewModel>;
    public userRateRaceLevelTableData: GaTableData<WlUserRateRaceViewModel>;

    constructor(
        private readonly service: BattlePassService,
        private readonly router: Router,
        private readonly activeRoute: ActivatedRoute,
        private readonly messaging: GaMessagingService,
        private readonly confirmation: GaConfirmationService,
        private readonly title: Title
    ) {}

    public ngOnInit(): void {
        this.activeRoute.queryParams
            .pipe(
                map((value) => this.initModel(value)),
                filter(() => this.userId.hasStrictValue && this.seasonId.hasStrictValue),
                untilDestroyed(this)
            )
            .subscribe(() => this.load(this.userId.strictValue, this.seasonId.strictValue));
    }

    public ngOnDestroy(): void {}

    public initModel(params: Params): void {
        this.isLoading = true;
        this.userId.strictValue = !isNullOrUndefined(params['userId']) ? Number(params['userId']) : null;
        this.seasonId.strictValue = !isNullOrUndefined(params['seasonId']) ? Number(params['seasonId']) : null;
    }

    public load(userId: number, seasonId: number): void {
        this.service
            .getUserInfoBySeason(userId, seasonId)
            .pipe(filter((res) => !this.messaging.tryShowError(res)))
            .subscribe((res) => {
                this.result = res.result;
                this.tasksUnableToForceComplete = this.result.currentLevel.userTasks.filter((value) => value.cantBeForced || value.isCompleted).length;
                this.bindTaskForUi(this.result);
                this.mapToTable();
                this.title.setTitle(`Профиль пользователя ${this.userId.strictValue} в сезоне ${this.seasonId.strictValue}`);
                this.isLoading = false;
            });
    }

    public bindTaskForUi(season: WlUserBattlePassSeasonViewModel): void {
        season.userLevels.forEach((level) => {
            const levelModel = new BattlePassLevelTasksBindModels();
            levelModel.gameTasks = level.userTasks.filter((value) => value.gameTaskId !== null);

            const taskGroupIds = [...new Set(level.userTasks.filter((value) => !isNullOrUndefined(value.crmTaskGroupId)).map((value) => value.crmTaskGroupId))];

            for (let taskGroupIndex = 0; taskGroupIndex < taskGroupIds.length; taskGroupIndex++) {
                const tasks = level.userTasks.filter(
                    (value) => value.crmTaskGroupId != null && value.crmSubTasks !== null && value.crmTaskGroupId === taskGroupIds[taskGroupIndex]
                );
                levelModel.crmTasks.set(taskGroupIds[taskGroupIndex], tasks);
            }

            this.levelBind.levels.set(level.id, levelModel);
        });
    }

    public search(): void {
        this.result = null;

        const params: Params = {
            userId: this.userId.strictValue,
            seasonId: this.seasonId.strictValue,
        };
        RouterNavigationExtensions.navigateAndRefresh(this.router, [], {
          queryParams: params
        });
    }

    public forceComplete(userTask: WlUserBattlePassTaskViewModel): void {
        this.confirmation
            .openDialog('Завершить задание для пользователя?')
            .pipe(
                first(),
                untilDestroyed(this),
                filter((res) => !!res)
            )
            .subscribe(() => this.forceCompleteInternal(userTask));
    }

    public canForceComplete(model: WlUserBattlePassTaskViewModel): boolean {
        const uiConditionsAllowance =
            this.forceCompleteRequests.indexOf(model.userTaskId, 0) < 0 && !this.wildCardTaskTryToForced && this.forceCompleteRequests.length < this.tasksToCompleteLevelCount;
        if (!uiConditionsAllowance) return false;

        return !model.cantBeForced;
    }

    public progressForTask(task: WlUserBattlePassTaskViewModel | WlUserBattlePassSubTaskViewModel): string {
        let progress = `${task.currentProgress ?? 0}`;
        if (!isNullOrUndefined(task.targetProgress)) {
            progress += `/${task.targetProgress}`;
        }

        return progress;
    }

    public getLootboxRoute(reward: WlUserBattlePassRewardViewModel): string {
        if (reward.rewardType === WlSelectedLootboxTypes.FreeBet) return '/menu/battlePass/freeBetLootBox/edit/';

        if (reward.rewardType === WlSelectedLootboxTypes.CsGo || reward.rewardType === WlSelectedLootboxTypes.Dota2) return '/menu/battlePass/lootBox/edit/';

        return '';
    }

    public typifyTasks(rawTasks: any): WlUserBattlePassTaskViewModel[] {
        return rawTasks as WlUserBattlePassTaskViewModel[];
    }

    public typifySubTasks(rawSubTasks: any): WlUserBattlePassSubTaskViewModel[] {
        return rawSubTasks as WlUserBattlePassSubTaskViewModel[];
    }

    public typifyLevel(level: any): WlUserBattlePassLevelViewModel {
        return level as WlUserBattlePassLevelViewModel;
    }

    public typifyLootBoxContents(rawRewards: any): WlGameLootBoxContentModel[] {
        return rawRewards as WlGameLootBoxContentModel[];
    }

    public typifyLootBox(rawRewards: any): WlUserBattlePassRewardViewModel[] {
        return rawRewards as WlUserBattlePassRewardViewModel[];
    }

    public isCrmTask(task: WlUserBattlePassTaskViewModel): boolean {
        return !isNullOrUndefined(task?.crmTaskGroupId);
    }

    public isGameTask(task: WlUserBattlePassTaskViewModel): boolean {
        return !isNullOrUndefined(task?.gameTaskId);
    }

    public isWildCard(task: WlUserBattlePassTaskViewModel): boolean {
        return task.isWildCard;
    }

    public hasCompletionDate(task: WlUserBattlePassTaskViewModel) {
        return !isNullOrUndefined(task?.taskCompletionDate);
    }

    public hasProgressOnSubTask(subTask: WlUserBattlePassSubTaskViewModel): boolean {
        return !isNullOrUndefined(subTask) && subTask.showSubTaskProgress;
    }

    public hasProgressOnTask(task: WlUserBattlePassTaskViewModel): boolean {
        return !isNullOrUndefined(task?.currentProgress) || !isNullOrUndefined(task?.targetProgress);
    }

    public hasSubTasks(task: WlUserBattlePassTaskViewModel): boolean {
        return !isNullOrUndefined(task?.crmSubTasks);
    }

    public hasCrmTaskId(subTask: WlUserBattlePassSubTaskViewModel): boolean {
        return !isNullOrUndefined(subTask?.crmTaskId);
    }

    public hasSubTaskId(subTask: WlUserBattlePassSubTaskViewModel): boolean {
        return !isNullOrUndefined(subTask?.subscriptionId);
    }

    public hasDeposits(subTask: WlUserBattlePassSubTaskViewModel): boolean {
        return !isNullOrUndefined(subTask?.depositIds) && subTask.depositIds.length > 0;
    }

    public hasBets(subTask: WlUserBattlePassSubTaskViewModel): boolean {
        return !isNullOrUndefined(subTask?.bets) && subTask.bets.length > 0;
    }

    public isLastItem(index: number, length: number): boolean {
        return index < length - 1;
    }

    private forceCompleteInternal(userTask: WlUserBattlePassTaskViewModel) {
        const form = new ForceCompleteTaskRequestForm();
        form.userTaskId = userTask.userTaskId;

        this.service
            .forceCompleteTask(form)
            .pipe(
                first(),
                untilDestroyed(this),
                filter((res) => !this.messaging.tryShowError(res))
            )
            .subscribe(() => {
                this.forceCompleteRequests.push(userTask.userTaskId);
                if (userTask.isWildCard) this.wildCardTaskTryToForced = true;

                this.messaging.showMessage('Запрос на завершение задания успешно отправлен');
            });
    }

    private mapToTable() {
        const currentLevelTable = this.prepareTableToMap(false);
        currentLevelTable.data = [this.result.currentLevel];
        this.currentLevelTableData = currentLevelTable;

        const rateRaceInfoTable = this.buildRateRaceInfoTableMap();
        rateRaceInfoTable.data = [this.result.rateRace];
        this.rateRaceInfoTableData = rateRaceInfoTable;

        const userRateRaceLevelTable = this.buildUserRateRaceLevelTableMap();
        userRateRaceLevelTable.data = [this.result.rateRace];
        this.userRateRaceLevelTableData = userRateRaceLevelTable;

        const levelsTable = this.prepareTableToMap(true);
        this.allLevelsTableData = levelsTable.setData(this.result.userLevels);
    }

    private prepareTableToMap(showForceCompleteButton: boolean = false): GaTableData<WlUserBattlePassLevelViewModel> {
        const data = new GaTableData<WlUserBattlePassLevelViewModel>();

        data.addSimpleColumn((elem) => elem.id, { title: 'Id уровня', widthSize: 100 })
            .addSimpleColumn((elem) => elem.levelOrder, {
                title: 'Порядковый номер',
                widthSize: 180,
            })
            .addTemplateColumn(
                new GaTableCellTemplate(this.showLevelAdditionalInfo, (elem) => {
                    return {
                        level: elem,
                    };
                }),
                { title: 'Инфо об уровне', widthSize: 260 }
            )
            .addTemplateColumn(
                new GaTableCellTemplate(this.showTasks, (elem) => {
                    return {
                        tasks: elem.userTasks.filter((u) => this.isCrmTask(u)),
                        showForceCompleteButton: showForceCompleteButton,
                    };
                }),
                { title: 'Букмекерские задачи', widthSize: 230 }
            )
            .addTemplateColumn(
                new GaTableCellTemplate(this.showTasks, (elem) => {
                    return {
                        tasks: elem.userTasks.filter((u) => this.isGameTask(u)),
                        showForceCompleteButton: showForceCompleteButton,
                    };
                }),
                { title: 'Игровые задачи', widthSize: 230 }
            )
            .addTemplateColumn(
                new GaTableCellTemplate(this.showLootBoxInfo, (elem) => {
                    return {
                        lootBoxes: elem.reward,
                        rewardSent: elem.rewardSent,
                    };
                }),
                { title: 'Лутбокс', widthSize: 390 }
            );

        return data;
    }

    private buildRateRaceInfoTableMap(): GaTableData<WlUserRateRaceViewModel> {
        const data = new GaTableData<WlUserRateRaceViewModel>();

        data.addSimpleColumn((elem) => elem?.commonRateRace?.rateRaceId, { title: 'RateRace Id', widthSize: 120 })
            .addSimpleColumn((elem) => elem?.commonRateRace?.rateRaceName, { title: 'Название RateRace', widthSize: 750 })
            .addSimpleColumn((elem) => WlRateRaceStatesExtensions.format(elem.commonRateRace?.state), { title: 'Статус RateRace', widthSize: 200 })
            .addSimpleColumn((elem) => formatDateTime(elem?.commonRateRace?.rateRaceStartDate), { title: 'Дата начала RateRace', widthSize: 180 });
        return data;
    }

    private buildUserRateRaceLevelTableMap(): GaTableData<WlUserRateRaceViewModel> {
        const data = new GaTableData<WlUserRateRaceViewModel>();

        data.addSimpleColumn((elem) => formatDateTime(elem?.userRateRaceActivatedDate), { title: 'Дата перехода на уровень RateRace', widthSize: 240 })
            .addSimpleColumn((elem) => elem?.userRateRaceRewardInfo?.id, { title: 'Id награды', widthSize: 120 })
            .addSimpleColumn((elem) => booleanToString(elem?.userRateRaceRewardInfo?.rewardAccrued), { title: 'Награда выдана?', widthSize: 200 })
            .addSimpleColumn((elem) => WlRateRaceAccrueRewardStateTypesExtensions.format(elem?.userRateRaceRewardInfo?.state), { title: 'Статус награды', widthSize: 160 })
            .addSimpleColumn((elem) => formatDateTime(elem?.userRateRaceRewardInfo?.requestAccrualSentAt), { title: 'Дата отправки запроса на выдачу награды', widthSize: 285 })
            .addSimpleColumn((elem) => WlUsersRewardTypesExtensions.format(elem?.userRateRaceRewardInfo?.type), { title: 'Тип награды', widthSize: 120 })
            .addSimpleColumn((elem) => WlBattlePassRewardProcessingErrorsExtensions.format(elem?.userRateRaceRewardInfo?.appErrorCode), {
                title: 'Ошибки выдачи награды',
                widthSize: 180,
            });

        return data;
    }

    get rateRaceId(): number {
        return this.result?.rateRace?.commonRateRace?.rateRaceId;
    }

    get hasUserRateRaceLevel(): boolean {
        return !isNullOrUndefined(this.result?.rateRace);
    }

    get twitchProfileUrl(): string {
      return 'https://www.twitch.tv/' + this.result.platformProfile.twitch.nickName;
    }

    get steamProfileUrl(): string {
      return 'http://steamcommunity.com/profiles/' + this.result.platformProfile.steam.accountId;
    }
}
