import { AbstractModelValidator, StrictError } from '@koddington/ga-common';
import { WlRewardEditViewModel } from '../../../models/wl-reward-edit-view-model';

export class WlRateRaceRewardValidator extends AbstractModelValidator<WlRewardEditViewModel> {
    constructor() {
        super();

        this.ruleForControl((u) => u.reward).customRule((reward, model) => {
            if (!model.reward.hasStrictValue)
                return new StrictError('rewardError', 'Награда должна быть выбрана');

            return null;
        });
    }
}
