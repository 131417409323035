import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GaPagedResult, GaPagingForm, GaTableData } from '@koddington/ga-common';
import { BattlePassRateRaceService, WlRateRaceLootBoxListModel, WlRateRaceLootBoxPagingForm } from 'src/app/modules/autogen/BattlePass';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-wl-rate-lootbox-list',
    templateUrl: './wl-rate-race-lootbox-list.component.html',
    styleUrls: ['./wl-rate-race-lootbox-list.component.scss'],
})
export class WlRateRaceLootBoxListComponent implements OnInit, OnDestroy {
    lootBoxes: GaPagedResult<WlRateRaceLootBoxListModel>;
    loading: boolean;

    tableData: GaTableData<WlRateRaceLootBoxListModel>;

    constructor(private readonly _service: BattlePassRateRaceService, private readonly _router: Router) {}

    public ngOnInit(): void {
        this.load(0);
    }

    public ngOnDestroy(): void {}

    public add(): void {
        this._router.navigate(['/menu/raterace/lootBox/edit']);
    }

    public pageChanged(page: GaPagingForm): void {
        this.load(page.offset);
    }

    private load(offset: number = 0): void {
        this.loading = true;
        const filter = new WlRateRaceLootBoxPagingForm();
        filter.count = 30;
        filter.offset = offset;

        this._service
            .lootBoxList(filter)
            .pipe(take(1))
            .subscribe((res) => {
                this.lootBoxes = res;
                this.mapToTable();
                this.loading = false;
            });
    }

    private mapToTable(): void {
        const data = new GaTableData<WlRateRaceLootBoxListModel>();

        this.tableData = data
            .addRouterLinkColumn(
                (elem) => elem.name,
                (elem) => ['/menu/raterace/lootBox/edit/', elem.id],
                { title: 'Название', widthSize: 550 }
            )
            .addSimpleColumn((elem) => elem.description, { title: 'Описание', widthSize: 550 })
            .addSimpleColumn((elem) => elem.itemsCount, { title: 'Кол-во предметов', widthSize: 250 })
            .setData(this.lootBoxes.results);
    }
}
