import { AbstractModelValidator, isNullOrUndefined, StrictError } from '@koddington/ga-common';
import { WlRateRaceLootBoxViewModel } from '../../../models/wl-rate-race-loot-box-view-model';

export class WlRateRaceLootboxCrudValidator extends AbstractModelValidator<WlRateRaceLootBoxViewModel> {
    constructor() {
        super();

        this.ruleForControl((u) => u.name).notEmptyString();
        this.ruleForControl((u) => u.description).notEmptyString();
        this.ruleForControl((u) => u.game).notEmptyString();
        this.ruleForControl((u) => u.contentsCount).required();
        this.ruleForControl((u) => u.editContent).customRule((editModel, model) => {
            let filtered = editModel?.filter(u => !u.reward.hasStrictValue);
            if (!isNullOrUndefined(filtered) && filtered.length > 0) {
                 return new StrictError('rewardsError', `Открытые для редактирования награды: ${filtered?.map(u => u.order.strictValue).sort((a, b) => a - b).join(', ')}`);
            }
            return null;
        });
    }
}
