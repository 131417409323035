import { isNullOrUndefined } from '@koddington/ga-common';

export function getStringByKey<T extends object, K extends keyof T>(
  item: T,
  key: K
): string | null | T[K] {
  if (!isNullOrUndefined(item[key])) {
    return item[key];
  }
  return null;
}
export function getNumberByKey<T extends object, K extends keyof T>(
  item: T,
  key: K
): number | null {
  if (!isNullOrUndefined(item[key])) {
    return Number(item[key]);
  }
  return null;
}
