<app-wl-page-header header="Список шаблонов"></app-wl-page-header>
<div class="wl-template-row">
    <button class="wl-button-primary wl-btn-push-save-wrapper" (click)="add()">Добавить шаблон</button>
</div>
<div class="mt-4" *ngIf="loading">
    <app-wl-loading></app-wl-loading>
</div>
<ga-table *ngIf="!!tableData" [hideArrows]="true" [data]="tableData"></ga-table>
<div class="wl-paging-wrapper">
    <app-wl-paging *ngIf="templatesList" [pagedResult]="templatesList" (pageChangedEvent)="pageChanged($event)"></app-wl-paging>
</div>
