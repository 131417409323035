import {Injectable} from '@angular/core';
import {IAutocompleteEntityTemplateStrategy} from '../../shared/components/strict-autocomplete/contracts/autocomplete-strategy';
import {MediaPromoGroupFilterForm, PartnersService, WlPromoGroup} from '../../autogen/TaskTrackerPartner';
import {StrictScrollAutocompleteViewModel} from '../../shared/components/strict-scroll-autocomplete/models/strict-scroll-autocomplete-view-model';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {IAutocompleteItem, isNullOrUndefined} from '@koddington/ga-common';


@Injectable()
export class MediaPromoStrategy implements IAutocompleteEntityTemplateStrategy<WlPromoGroup, StrictScrollAutocompleteViewModel> {
  private _entitySource$ = new BehaviorSubject<MediaPromoGroupFilterForm>(undefined);
  private readonly _source$ = new BehaviorSubject<WlPromoGroup[]>([]);
  private _form: MediaPromoGroupFilterForm = new MediaPromoGroupFilterForm();
  private _externalModel: StrictScrollAutocompleteViewModel;
  private _sourceClearAutocomplete$: Subject<any>;

  constructor(private readonly _service: PartnersService) {
    this._form.count = 10;
    this._form.offset = 0;

    this._entitySource$
      .pipe(
        filter((value) => !isNullOrUndefined(value)),
        switchMap((u) => this.load(u))
      )
      .subscribe((value) => this._source$.next(value));
  }

  setCallback(src: Subject<any>): void {
    this._sourceClearAutocomplete$ = src;
  }

  emitUpdateEvent(): void {
    if (isNullOrUndefined(this._externalModel)) {
      throw new Error('External model for scroll autocomplete not bounded');
    }

    const form = new MediaPromoGroupFilterForm();
    form.term = this._externalModel.term.strictValue;
    form.count = this._externalModel.count.strictValue;
    form.offset = this._externalModel.offset.strictValue;
    this._entitySource$.next(form);
  }

  bindControlModel(model: StrictScrollAutocompleteViewModel): void {
    this._externalModel = model;
  }

  public convert(model: WlPromoGroup): IAutocompleteItem<WlPromoGroup> {
    return {
      entity: model,
      title: model.name + ' (' + model.id.toString() + ')',
      key: model.id.toString(),
    };
  }

  public getSource(): Observable<Array<WlPromoGroup>> {
    return this._source$.asObservable();
  }

  public updateSource(term: string): void {
    const form = new MediaPromoGroupFilterForm();
    form.term = term;
    form.count = 10;
    form.offset = 0;

    this._entitySource$.next(form);
  }

  private load(item: MediaPromoGroupFilterForm): Observable<WlPromoGroup[]> {
    if (isNullOrUndefined(item) || item.term?.length === 0) {
      return of([]);
    }
    return this._service.getPromoForAutocomplete(item).pipe(
      filter((v) => !!v.result),
      map((result) => result.result)
    );
  }
}
