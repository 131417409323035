import { AfterContentInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';
import { DropdownItem, DropdownOptions, IDropdownStrategy, isNullOrUndefined, StrictFormControl } from '@koddington/ga-common';

@Component({
    selector: 'app-wl-dropdown',
    templateUrl: './wl-dropdown.component.html',
    styleUrls: ['./wl-dropdown.component.scss'],
})
export class WlDropdownComponent<T> implements OnInit, OnDestroy, AfterContentInit {
    @Input()
    public isInvisible = false;
    @Input()
    public isSearch = false;
    @Input()
    public shouldShowErrorList = false;
    @Input()
    public options?: DropdownOptions<T>;
    @Input()
    public control: StrictFormControl<T>;
    @Input()
    public strategy: IDropdownStrategy<T>;
    @Input()
    public defaultValue: (items: DropdownItem<T>[]) => DropdownItem<T> = null;
    @Output()
    userSelect = new EventEmitter<void>();

    public isActive: boolean = false;
    public isFocused: boolean = false;
    public currentItem: DropdownItem<T>;
    public currentItemsSource: Map<number, DropdownItem<T>> = new Map<number, DropdownItem<T>>();

    private currentFocus: number = 0;
    private readonly KeyDownCode: string = 'ArrowDown';
    private readonly KeyUpCode: string = 'ArrowUp';
    private readonly KeyEnterCode: string = 'Enter';
    private readonly SpaceCode: string = ' ';
    private readonly TabCode: string = 'Tab';

    private readonly clickOutsideSource = fromEvent(document, 'click').pipe(
        map((event) => !this._elementRef.nativeElement.contains(event.target)),
        filter((outside: boolean) => outside)
    );

    constructor(private _changeTracker: ChangeDetectorRef, protected _elementRef: ElementRef) {
    }

    public get title(): string {
        return this.options?.title;
    }

    public get placeholder(): string {
        return isNullOrUndefined(this.options?.placeholder) ? 'Выберите значение' : this.options?.placeholder;
    }

    public get classname(): string {
        return this.options?.classname;
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    public ngAfterContentInit(): void {
        this.validate();
        this.subscription();
        this.subscriptionChangeStrictValue();
        this.subscriptionToChangeGlobalFocus();
    }

    private validate(): void {
        if (isNullOrUndefined(this.control)) {
            throw new Error('Entity container for dropdown cannot be undefined');
        }
        if (isNullOrUndefined(this.strategy)) {
            throw new Error('Dropdown strategy cannot be null or undefined');
        }
    }

    public subscription(): void {
        this.strategy
            .getEntities()
            .pipe(untilDestroyed(this))
            .subscribe((u) => {
                this.currentItemsSource.clear();
                this.init();
                let items = u.map(this.strategy.map);
                items.forEach((item) => {
                    this.currentItemsSource.set(item.id, item);
                })
                if (isNullOrUndefined(this.currentItem) && !isNullOrUndefined(this.defaultValue))
                {
                    this.selectEntity(this.defaultValue(this.getSourceValues));
                }
            });
    }

    private init(): void {
        if (isNullOrUndefined(this?.options) || isNullOrUndefined(this.options?.placeholder)) {
            return;
        }

        let initItem: DropdownItem<T> = new DropdownItem<T>();
        initItem.entity = null;
        initItem.title = this.options?.placeholder;
        initItem.id = -1;
        this.currentItemsSource.set(initItem.id, initItem);
    }

    private subscriptionChangeStrictValue(): void {
        this.control.strictValueSource
            .pipe(
                untilDestroyed(this),
                distinctUntilChanged(),
                map((entity: T) => {
                    if (isNullOrUndefined(entity)) {
                        return null;
                    }

                    return this.strategy.map(entity);
                })
            )
            .subscribe((item) => {
                this.currentItem = item;
                this._changeTracker.detectChanges();
            });
    }

    private subscriptionToChangeGlobalFocus(): void {
        this.clickOutsideSource.pipe(untilDestroyed(this)).subscribe(() => (this.isActive = false));
    }

    public selectEntity(dropItem: DropdownItem<T>): void {
        if (isNullOrUndefined(dropItem) || isNullOrUndefined(dropItem.id)) {
            this.isActive = false;
            return;
        }

        if (!this.currentItemsSource.has(dropItem.id)) {
            this.clear();
            return;
        } else {
            this.currentItem = this.currentItemsSource.get(dropItem.id);
        }

        if (isNullOrUndefined(this.currentItem.id) || isNullOrUndefined(this.currentItem.entity) || isNullOrUndefined(this.currentItem.title)) {
            this.clear();
            return;
        }

        const containerId = this.control.hasStrictValue ? this.strategy.map(this.control.strictValue).id : null;
        if (this.currentItem.id !== containerId) {
            this.control.strictValue = this.currentItem.entity;
        }

        this.userSelect.emit();

        if (this.options?.clearAfterSelect === true) {
            this.currentItem = null;
            this._changeTracker.detectChanges();
        }
        this.isActive = false;
    }

    private clear(): void {
        this.control.strictValue = null;
        this.userSelect.emit();
        this.isActive = false;
    }

    public openDropDown($event: any): void {
        this.isActive = this.isActive ? false : true;
    }

    public onKeyDown(event: KeyboardEvent): void {
        switch (event.key) {
            case this.KeyDownCode:
                this.moveSelectionDown();
                event.preventDefault();
                break;
            case this.KeyUpCode:
                this.moveSelectionUp();
                event.preventDefault();
                break;
            case this.KeyEnterCode:
                if (this.isActive) {
                    const selectedItem = this.currentItemsSource[this.currentFocus];
                    this.selectEntity(selectedItem);
                    return;
                }
                this.userSelect.emit();
                break;
            case this.SpaceCode:
                this.isActive = this.isActive ? false : true;
                event.preventDefault();
                break;
            case this.TabCode:
                this.isActive = false;
            default:
                break;
        }
    }

    private moveSelectionUp() {
        if (this.currentFocus <= 0) {
            this.currentFocus = this.currentItemsSource.size - 1;
            return;
        }
        this.currentFocus--;
    }

    private moveSelectionDown() {
        if (this.currentFocus >= this.currentItemsSource.size - 1) {
            this.currentFocus = 0;
            return;
        }
        this.currentFocus++;
    }

    public trackFocus(event: FocusEvent | null = null): void {
        if (isNullOrUndefined(event)) {
            this.isFocused = false;
            return;
        }
        this.isFocused = true;
    }

    public get label(): string {
        if (isNullOrUndefined(this?.options) && isNullOrUndefined(this.options?.title)) {
            return '';
        }
        return this.options.title;
    }

    public get showError(): boolean {
        return this.control.hasStrictErrors;
    }

    public get getSourceValues(): DropdownItem<T>[] {
        return Array.from(this.currentItemsSource.values());
    }
}
