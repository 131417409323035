import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  BattlePassStatisticsService,
  WlBattlePassFilteredOutsBetsModel,
  BattlePassFilteredOutBetsFilterForm,
  WlRateRaceStatusModeration,
  FiltredOutBetsTargets, FiltredOutBetsTargetsExtensions
} from 'src/app/modules/autogen/BattlePass';
import { formatDateTime, GaPagedResult, GaPagingForm, isNullOrUndefined, StrictFormControl } from '@koddington/ga-common';
import { ActivatedRoute, Params } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { map } from 'rxjs/operators';
import { SearchNavigationService } from 'src/app/modules/shared/services/search-navigation.service';
import { GaTableData } from '@koddington/ga-common';
import {WlRateRaceStatusModerationStrategy} from '../../../../rate-race-area51/components/wl-nickname-moderation/strategy/wl-rate-race-nickname-moderation.strategy';
import {FiltredOutBetsTargetsStrategy} from './Strategy/filtred-out-bets-targets-strategy';

@Component({
    selector: 'app-wl-battle-pass-filtered-out-bets-statistics',
    templateUrl: './wl-battle-pass-filtered-out-bets-statistics.component.html',
    styleUrls: ['./wl-battle-pass-filtered-out-bets-statistics.component.scss'],
})
export class WlBattlePassFilteredOutBetsStatisticsComponent implements OnInit, OnDestroy {
    public logs: GaPagedResult<WlBattlePassFilteredOutsBetsModel>;
    public loading: boolean;
    public searchUserId: StrictFormControl<number> = new StrictFormControl<number>(null);
    public filtredOutBetsTargets: StrictFormControl<FiltredOutBetsTargets> = new StrictFormControl<FiltredOutBetsTargets>(null);
    private count: StrictFormControl<number> = new StrictFormControl<number>(null);
    private offset: StrictFormControl<number> = new StrictFormControl<number>(null);

    tableData: GaTableData<WlBattlePassFilteredOutsBetsModel>;

    constructor(
        private readonly _battlePassStatisticsService: BattlePassStatisticsService,
        private readonly _activeRoute: ActivatedRoute,
        private readonly _navigation: SearchNavigationService,
        public readonly _filtredOutBetsTargetsStrategy: FiltredOutBetsTargetsStrategy,
    ) {}

    public ngOnInit(): void {
        this.init();
    }

    public ngOnDestroy(): void {}

    public init(): void {
        this._activeRoute.queryParams
            .pipe(
                map((param) => this.initModel(param)),
                untilDestroyed(this)
            )
            .subscribe(() => this.load());
    }

    public search(): void {
        this.offset.strictValue = 0;
        this.navigateWithParameters();
    }

    public pageChanged(page: GaPagingForm): void {
        this.offset.strictValue = page.offset;
        this.navigateWithParameters();
    }

    private load(): void {
        this.loading = true;

        const form = this.createForm();

        this._battlePassStatisticsService
            .getFilteredOutBetsList(form)
            .pipe(untilDestroyed(this))
            .subscribe((res) => {
                this.logs = res;
                this.mapToTable(res.results);
                this.loading = false;
            });
    }

    private initModel(params: Params): void {
        this.offset.strictValue = !isNullOrUndefined(params['offset']) ? Number(params['offset']) : 0;
        this.count.strictValue = !isNullOrUndefined(params['count']) ? Number(params['count']) : 30;
        this.searchUserId.strictValue = !isNullOrUndefined(params['userId']) ? Number(params['userId']) : null;
        this.filtredOutBetsTargets.strictValue = !isNullOrUndefined(params['filtredOutBetsTargets']) ? Number(params['filtredOutBetsTargets']) :
          FiltredOutBetsTargets.All;
    }

    private createForm(offset: number = 0): BattlePassFilteredOutBetsFilterForm {
        const form = new BattlePassFilteredOutBetsFilterForm();
        form.offset = this.offset.getStrictValueOrDefault(0);
        form.count = this.count.getStrictValueOrDefault(30);
        form.userId = this.searchUserId.strictValue;
        form.filtredOutBetsTargets = this.filtredOutBetsTargets.strictValue;

        return form;
    }

    private navigateWithParameters(): void {
        const params: Params = {
            offset: this.offset.strictValue,
            count: this.count.strictValue,
            userId: this.searchUserId.strictValue,
            filtredOutBetsTargets: this.filtredOutBetsTargets.strictValue,
        };

        this._navigation.search(this._activeRoute, params);
    }

    private mapToTable(logs: WlBattlePassFilteredOutsBetsModel[]): void {
        const data = new GaTableData<WlBattlePassFilteredOutsBetsModel>();

        this.tableData = data
            .addSimpleColumn((elem) => elem.userId, { title: 'Пользователь', widthSize: 150 })
            .addSimpleColumn((elem) => elem.taskId, { title: 'Id задания', widthSize: 170 })
            .addSimpleColumn((elem) => elem.betId, { title: 'Id ставки', widthSize: 150 })
            .addSimpleColumn((elem) => elem.filterName, { title: 'Имя фильтра', widthSize: 350 })
            .addSimpleColumn((elem) => formatDateTime(elem.logged), { title: 'Дата сохранения', widthSize: 350 })
            .setData(logs);
    }
}
