import { StrictFormControl } from '@koddington/ga-common';
import { WlRateRaceStatusModeration } from '../../autogen/BattlePass';

export class RateRaceNicknameSearchViewModel {
    public seasonId: StrictFormControl<number> = new StrictFormControl<number>(null);
    public fromLevelOrder: StrictFormControl<number> = new StrictFormControl<number>(null);
    public toLevelOrder: StrictFormControl<number> = new StrictFormControl<number>(null);
    public statusModeration?: StrictFormControl<WlRateRaceStatusModeration> = new StrictFormControl<WlRateRaceStatusModeration>(null);
    public userId: StrictFormControl<number> = new StrictFormControl<number>(null);
    public nickname: StrictFormControl<string> = new StrictFormControl<string>(null);
    public offset: StrictFormControl<number> = new StrictFormControl<number>(0);
    public count: StrictFormControl<number> = new StrictFormControl<number>(30);
}
