<app-wl-page-header header="Незачтенные линии ставок для боевого пропуска"></app-wl-page-header>
<div class="wl-search-row">
    <app-wl-strict-input-number
        class="wl-app-input-search-wrapper"
        placeholder="Id ставки"
        [isSearch]="true"
        [control]="searchBetId"
        (valueChange)="search()"
    ></app-wl-strict-input-number>
</div>
<app-wl-loading *ngIf="loading"></app-wl-loading>
<ga-table *ngIf="!!tableData" [data]="tableData" [hideArrows]="false"></ga-table>
<div class="wl-paging-wrapper">
    <app-wl-paging *ngIf="logs" [pagedResult]="logs" (pageChangedEvent)="pageChanged($event)"></app-wl-paging>
</div>
