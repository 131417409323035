import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from './common/enum-dropdown/enum-dropdown.component';
import { MoscowDateTimePipe } from './pipes/moscow-timezone.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingComponent } from './common/loading/loading.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DateTimePipe } from './pipes/date-time.pipe';
import { DateOnlyPipe } from './pipes/date-only.pipe';
import { GaCommonModule } from '@koddington/ga-common';
import { StrictInputBooleanCheckboxComponent } from './common/strict-input-boolean-checkbox/strict-input-boolean-checkbox.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { WlBlockUiService } from './services/wl-block-ui-service';
import { StrictAutocompleteComponent } from './components/strict-autocomplete/strict-autocomplete.component';
import { StrictScrollAutocompleteComponent } from './components/strict-scroll-autocomplete/strict-scroll-autocomplete.component';
import { WlRadioButtonsComponent } from './components/wl-radio-buttons/wl-radio-buttons.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { WlPageHeaderComponent } from './components/wl-page-header/wl-page-header.component';
import { StrictInputTextComponent } from './components/strict-input-text/strict-input-text.component';
import { StrictInputBooleanComponent } from './components/strict-input-boolean/strict-input-boolean.component';
import { WlDatePickerSingleComponent } from './components/wl-datepicker-time-new/wl-datetimepicker-single.component';
import {
  StrictScrollMultiSelectAutocompleteComponent
} from './components/strict-scroll-autocomplete-new/strict-scroll-multi-select-autocomplete/strict-scroll-multi-select-autocomplete';
import { StrictScrollAutocompleteNewComponent } from './components/strict-scroll-autocomplete-new/strict-scroll-autocomplete-new';
import { WlDropdownComponent } from './components/wl-dropdown/wl-dropdown.component';
import { StrictInputNumberComponent } from './components/strict-input-number/strict-input-number.component';
import { WlPagingComponent } from './components/wl-paging/wl-paging.component';
import { GaPagingComponent } from './components/ga-paging/ga-paging.component';
import { StrictPushTemplateAutocompleteComponent } from './components/strict-push-template-autocomplete/strict-push-template-autocomplete.component';
import { StrictThrottlingAutocompleteComponent } from './components/strict-throttling-autocomplete/strict-throttling-autocomplete.component';
import { WlStringJoinPipe } from './pipes/wl-string-join.pipe';
import { WlStringPipe } from './pipes/wl-string.pipe';
import { WlDateTimePipe } from './pipes/wl-date-time.pipe';
import { WlBooleanPipe } from './pipes/wl-boolean.pipe';
import { WlDatePipe } from './pipes/wl-date.pipe';
import {WlLoadingComponent} from './components/wl-loading/wl-loading.component';
import {RouterLink} from '@angular/router';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { StrictMultiSelectDropdownComponent } from './components/strict-multi-select-dropdown/strict-multi-select-dropdown.component';
import { WlConfirmationModalComponent } from './components/wl-confirmation-modal/wl-confirmation-modal.component';

@NgModule({
  declarations: [
    MoscowDateTimePipe,
    LoadingComponent,
    StrictInputBooleanCheckboxComponent,
    DateTimePipe,
    DateOnlyPipe,
    StrictAutocompleteComponent,
    StrictScrollAutocompleteComponent,
    WlRadioButtonsComponent,
    WlPageHeaderComponent,
    StrictInputTextComponent,
    DropdownComponent,
    WlPagingComponent,
    WlLoadingComponent,
    GaPagingComponent,
    StrictInputBooleanComponent,
    WlDatePickerSingleComponent,
    StrictScrollMultiSelectAutocompleteComponent,
    StrictScrollAutocompleteNewComponent,
    WlDropdownComponent,
    StrictInputNumberComponent,
    StrictPushTemplateAutocompleteComponent,
    StrictThrottlingAutocompleteComponent,
    WlStringJoinPipe,
    WlStringPipe,
    WlDateTimePipe,
    WlBooleanPipe,
    WlDatePipe,
    StrictMultiSelectDropdownComponent,
    WlConfirmationModalComponent,
  ],
  exports: [
    MoscowDateTimePipe,
    LoadingComponent,
    DateTimePipe,
    DateOnlyPipe,
    StrictInputBooleanCheckboxComponent,
    StrictAutocompleteComponent,
    StrictScrollAutocompleteComponent,
    WlRadioButtonsComponent,
    WlPageHeaderComponent,
    StrictInputTextComponent,
    DropdownComponent,
    WlPagingComponent,
    WlLoadingComponent,
    GaPagingComponent,
    StrictInputBooleanComponent,
    WlDatePickerSingleComponent,
    StrictScrollMultiSelectAutocompleteComponent,
    StrictScrollAutocompleteNewComponent,
    WlDropdownComponent,
    StrictInputNumberComponent,
    StrictPushTemplateAutocompleteComponent,
    StrictThrottlingAutocompleteComponent,
    WlStringJoinPipe,
    WlStringPipe,
    WlDateTimePipe,
    WlBooleanPipe,
    WlDatePipe,
    StrictMultiSelectDropdownComponent,
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatInputModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    NgxDaterangepickerMd,
    MatIconModule,
    GaCommonModule,
    ScrollingModule,
    FormsModule,
    RouterLink,
    MatLegacyChipsModule
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [WlBlockUiService],
    };
  }
}
