import { Directive, OnDestroy } from '@angular/core';
import { IAutocompleteItem } from '@koddington/ga-common';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WlBattlePassMarketsModel, WlMarketSearchForm } from '../../autogen/BattlePass';
import { WlBattlePassMarketsScrollServiceAdapter } from '../services/wrapper-service/wl-battle-pass-markets.scroll-service-adapter';
import { ScrollStrategy } from '../../../common/abstracts/abstract-scroll-strategy';
@Directive()
export class WlMarketsTypeStrategy extends ScrollStrategy<WlBattlePassMarketsModel, WlMarketSearchForm> implements OnDestroy {
    constructor(private readonly _battlePassService: WlBattlePassMarketsScrollServiceAdapter) {
        super(_battlePassService);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public convert(model: WlBattlePassMarketsModel): IAutocompleteItem<WlBattlePassMarketsModel> {
        return this.convert_(() => {
            return {
                entity: model,
                title: `[${model.id.toString()}] ${model.name}`,
                key: model.id?.toString(),
            };
        });
    }

    protected load(item: WlMarketSearchForm, fnRules: (form: WlMarketSearchForm) => boolean = null): Observable<any> {
        return this._battlePassService.search(item)
            .pipe(
                untilDestroyed(this),
                map((result) => result.result)
            );
    }
}
