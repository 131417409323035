import { Injectable } from '@angular/core';
import { WlResult } from 'src/app/models/common/results/wl-result';
import { Observable } from 'rxjs';
import { BattlePassRateRaceService, WlRateRaceLootBoxPagingForm } from 'src/app/modules/autogen/BattlePass';
import { WlRateRaceLootBoxListModel } from 'src/app/modules/autogen/BattlePass';
import { map } from 'rxjs/operators';
import { IScrollService } from 'src/app/common/abstracts/types/scroll-service-interface';


@Injectable({
    providedIn: 'root'
})
export class WlBattlePassRateRacesLootBoxesScrollServiceAdapter implements IScrollService<WlRateRaceLootBoxListModel, WlRateRaceLootBoxPagingForm> {
    constructor(private _service: BattlePassRateRaceService) {
    }

    public search(item: WlRateRaceLootBoxPagingForm): Observable<WlResult<WlRateRaceLootBoxListModel[]>> {
        return this._service.lootBoxList(item).pipe(
            map(u => {
                const res = new WlResult<WlRateRaceLootBoxListModel[]>();
                res.result = u.results;
                return res;
            })
        );
    }
}
