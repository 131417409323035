import { AbstractModelValidator } from '@koddington/ga-common';
import { PickemOutcomeViewModel } from '../models/pickem-outcome-view-model';

export class OutcomesValidator extends AbstractModelValidator<PickemOutcomeViewModel> {
  constructor(validationRule: () => boolean) {
    super();

    this.ruleForControlIf((u) => u.name, validationRule)
        .notEmptyString('При выборе бук.задания - имя исхода обязательно');

    this.ruleForControlIf((u) => u.description, validationRule)
        .notEmptyString('При выборе бук.задания - описание исхода обязательно');

    this.ruleForControlIf((u) => u.imageUrl, validationRule)
        .notEmptyString('При выборе бук.задания - url картинки исхода обязательно');
  }
}
