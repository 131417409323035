import {Component, OnDestroy, OnInit} from '@angular/core';
import {GaMessagingService, isNullOrUndefined, StrictError} from '@koddington/ga-common';
import {DailyTasksLootBoxViewModel} from './view-models/daily-tasks-loot-box.view-model';
import {BehaviorSubject, Observable} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {
    DailyTasksLootBoxesService,
    WlDtFreeBetLootBoxCreateForm,
    WlDtFreeBetLootBoxGradeForm,
    WlDtFreeBetLootBoxUpdateForm,
    WlFreeBetLootBoxCrudForm
} from '../../../../autogen/DailyTasks';
import {filter, finalize, map, take} from 'rxjs/operators';
import {builderModelToStrict, builderStrictToModel, gaMap, MapExpression} from '../../../../shared/common/operation/builder-operation';
import {DailyTasksLootBoxGradeViewModel} from './view-models/daily-tasks-loot-box-grade.view-model';
import {WlBattlePassFreeBetLootBoxMappers} from '../../../../battle-pass/components/wl-battle-pass-free-bet-loot-box-crud/tools/wl-battle-pass-free-bet-loot-box.mappers';
import {WlBattlePassFreeBetLootBoxesScrollServiceAdapter} from '../../../../battle-pass/services/wrapper-service/wl-battle-pass-free-bet-loot-boxes.scroll-service-adapter';
import {WlFreeBetLootBoxListModel} from '../../../../autogen/BattlePass';
import {
    WlBattlePassFreeBetLootBoxCrudViewModel
} from '../../../../battle-pass/components/wl-battle-pass-free-bet-loot-box-crud/view-models/wl-battle-pass-free-bet-loot-box-crud-view-model';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {DailyTasksLootBoxesCrudValidator} from './validators/daily-tasks-loot-boxes-crud.validator';
import {
    WlBattlePassFreeBetLootBoxContentsViewModel
} from '../../../../battle-pass/components/wl-battle-pass-free-bet-loot-box-crud/view-models/wl-battle-pass-free-bet-loot-box-contents-view-model';

@Component({
    selector: 'app-daily-tasks-loot-boxes-crud',
    templateUrl: './daily-tasks-loot-boxes-crud.component.html',
    styleUrls: ['./daily-tasks-loot-boxes-crud.component.css']
})
export class DailyTasksLootBoxesCrudComponent implements OnInit, OnDestroy {
    protected viewModel = new DailyTasksLootBoxViewModel();
    protected initialized = false;
    protected loading = true;
    protected userManipulationsSource = new BehaviorSubject<void>(null);


    private validator = new DailyTasksLootBoxesCrudValidator();
    private errors: StrictError[] = [];

    constructor(private readonly _route: ActivatedRoute,
                private readonly _router: Router,
                private readonly _titleService: Title,
                private readonly _service: DailyTasksLootBoxesService,
                private readonly _messaging: GaMessagingService,
                private readonly _freeBetLootBoxesApiClient: WlBattlePassFreeBetLootBoxesScrollServiceAdapter) {
    }

    ngOnInit(): void {
        this.init();
    }

    ngOnDestroy(): void {
    }

    protected save(): void {
        this.revalidate();
        if (!this.canSave)
            return;

        if (this.isEdit)
            return this.update();

        return this.create();
    }

    protected addGrade(): void {
        this.viewModel.grades.strictValue.push(new DailyTasksLootBoxGradeViewModel(this._freeBetLootBoxesApiClient));
        this.userManipulationsSource.next();
    }

    protected deleteGrade(gradeIndex: number): void {
        this.viewModel.grades.strictValue.splice(gradeIndex, 1);
        this.userManipulationsSource.next();
    }

    protected onFreeBetLootBoxSelect(grade: DailyTasksLootBoxGradeViewModel): void {
        if (isNullOrUndefined(grade.selectedLootBoxModel.strictValue)) {
            grade.lootBox.strictValue = new WlBattlePassFreeBetLootBoxCrudViewModel();
            grade.lootBoxUpdateEmitter.emit();
            return;
        }

        this._freeBetLootBoxesApiClient.getForUpdate(grade.selectedLootBoxModel.strictValue.id)
            .pipe(
                take(1),
                filter(res => !this._messaging.tryShowError(res)),
                map(res => res.result)
            )
            .subscribe(res => {
                WlBattlePassFreeBetLootBoxMappers.mapLootBoxFormToStrict(grade.lootBox.strictValue, res);
                grade.lootBoxUpdateEmitter.emit();
            });
    }

    protected enrichEmptyLootBoxContent(val: WlBattlePassFreeBetLootBoxContentsViewModel): void {
        // this fields isn't required for DT lootBoxes, so we can fill default vals here
        val.imageUrl.strictValue = '-';
        val.scarcity.strictValue = '-';
        val.scarValue.strictValue = 1;
    }


    protected getFreeBetLootBoxLink(lootBox: WlBattlePassFreeBetLootBoxCrudViewModel): any[] {
        return isNullOrUndefined(lootBox) || !lootBox.id.hasStrictValue
            ? null
            : ['/menu/battlePass/freeBetLootBox/edit', lootBox.id.strictValue];
    }

    private init(): void {
        this.loading = true;
        this._titleService.setTitle('Лутбоксы Daily Tasks');
        this._route.paramMap
            .pipe(
                map((params) => params.get('id')),
                take(1)
            )
            .subscribe(res => {
                this.viewModel.id.strictValue = isNullOrUndefined(res) || Number.isNaN(Number(res))
                    ? null
                    : Number(res);

                this.load();
            });
    }

    private load(): void {
        if (!this.isEdit) {
            this.markContentLoaded();
            this.subscribeToManipulation();
            return;
        }

        this._service.getForUpdate(this.viewModel.id.strictValue)
            .pipe(
                take(1),
                filter(res => !this._messaging.tryShowError(res)),
                map(res => res.result)
            )
            .subscribe(res => {
                builderModelToStrict(this.viewModel, res, [
                    {
                        sourceMapProperty: 'grades',
                        targetMapProperty: 'grades',
                        mapObject: gaMap(res.grades, u => {
                            const model = new DailyTasksLootBoxGradeViewModel(this._freeBetLootBoxesApiClient);
                            builderModelToStrict(model, u, null, null, 'lootBox');
                            WlBattlePassFreeBetLootBoxMappers.mapLootBoxFormToStrict(model.lootBox.strictValue, u.lootBox);
                            model.selectedLootBoxModel.strictValue = new WlFreeBetLootBoxListModel({name: u.lootBox.name, id: u.lootBox.id});

                            return model;
                        })
                    }
                ]);
                this.markContentLoaded();
                this.subscribeToManipulation();
            });
    }

    private create(): void {
        const form = builderStrictToModel(WlDtFreeBetLootBoxCreateForm, this.viewModel, this.formMappers());
        this.loading = true;
        this._service.create(form)
            .pipe(
                take(1),
                filter(res => !this._messaging.tryShowError(res)),
                finalize(() => this.loading = false)
            )
            .subscribe(_ => this.navigateToList());
    }

    private subscribeToManipulation(): void {
        this.userManipulationsSource
            .pipe(untilDestroyed(this))
            .subscribe(res => this.revalidate());
    }

    private revalidate() {
        this.errors = this.validator.validate(this.viewModel);
    }

    private update(): void {
        const form = builderStrictToModel(WlDtFreeBetLootBoxUpdateForm, this.viewModel, this.formMappers());
        this.loading = true;
        this._service.update(form)
            .pipe(
                take(1),
                filter(res => !this._messaging.tryShowError(res)),
                finalize(() => this.loading = false)
            )
            .subscribe(_ => this.navigateToList());
    }

    private formMappers(): MapExpression[] {
        return [
            {
                sourceMapProperty: 'grades',
                targetMapProperty: 'grades',
                mapObject: gaMap(this.viewModel.grades.strictValue, u => {
                    const grade = builderStrictToModel(WlDtFreeBetLootBoxGradeForm, u, null, null, 'lootBoxStrategy');
                    grade.lootBox = WlBattlePassFreeBetLootBoxMappers.mapLootBoxStrictToForm(WlFreeBetLootBoxCrudForm, u.lootBox.strictValue);

                    return grade;
                })
            }
        ];
    }

    private navigateToList() {
        this._router.navigate(['/menu/dailyTasks/loot-boxes/list']);
    }

    private markContentLoaded(): void {
        this.loading = false;
        this.initialized = true;
    }

    get pageHeader(): string {
        return !isNullOrUndefined(this.viewModel?.id.strictValue)
            ? `Редактирование лутбокса Daily Tasks № ${this.viewModel.id.strictValue}`
            : 'Создание лутбокса Daily Tasks';
    }

    get canSave(): boolean {
        return this.errors.length === 0 && this.loading === false;
    }

    get isEdit(): boolean {
        return !isNullOrUndefined(this.viewModel.id.strictValue);
    }

    get canDeleteGrade(): boolean {
        return this.viewModel?.grades?.strictValue.length > 1;
    }

    get gradeErrors(): Observable<StrictError[]> {
        return this.viewModel?.grades?.strictErrors;
    }
}
