import { Injectable } from '@angular/core';
import { WlResult } from 'src/app/models/common/results/wl-result';
import { Observable } from 'rxjs';
import {BattlePassFreeBetLootBoxesService, WlFreeBetLootBoxListModel, WlFreeBetLootBoxSearchForm, WlFreeBetLootBoxUpdateForm} from 'src/app/modules/autogen/BattlePass';
import { map } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { IScrollService } from '../../../../common/abstracts/types/scroll-service-interface';

@Injectable({
    providedIn: 'root'
})
export class WlBattlePassFreeBetLootBoxesScrollServiceAdapter implements IScrollService<WlFreeBetLootBoxListModel, WlFreeBetLootBoxSearchForm> {

    constructor(private readonly _service: BattlePassFreeBetLootBoxesService) {
    }

    public search(item: WlFreeBetLootBoxSearchForm): Observable<WlResult<WlFreeBetLootBoxListModel[]>> {
        return this._service.list(item)
            .pipe(
                take(1),
                map(u => {
                    const res = new WlResult<WlFreeBetLootBoxListModel[]>();
                    res.result = u.results;
                    return res;
                })
            );
    }

    public getForUpdate(id: number): Observable<WlResult<WlFreeBetLootBoxUpdateForm>> {
        return this._service.getForUpdate(id);
    }
}
