import { StrictFormControl } from '@koddington/ga-common';
import { IPagingViewModel } from 'src/app/modules/shared/extensions/search-state-managment';
import { WlBattlePassStates, WlPromoTypes } from '../../../../autogen/DailyTasks';

export class WlDtPromotionsSearchViewModel implements IPagingViewModel {
    public term: StrictFormControl<string> = new StrictFormControl<string>(null);
    public crmTaskGroupId: StrictFormControl<number> = new StrictFormControl<number>(null);
    public promoType: StrictFormControl<WlPromoTypes> = new StrictFormControl<WlPromoTypes>(null);
    public state: StrictFormControl<WlBattlePassStates> = new StrictFormControl<WlBattlePassStates>(null);

    public count: StrictFormControl<number> = new StrictFormControl<number>(30);
    public offset: StrictFormControl<number> = new StrictFormControl<number>(0);
}
