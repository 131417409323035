<app-wl-page-header header="Список акций"></app-wl-page-header>
<div class="wl-template-row">
    <button class="wl-button-primary"
            (click)="add()"
    >
        Добавить акцию
    </button>
</div>
<div class="wl-search-row">
    <app-wl-strict-input-text
            class="wl-default-size-search-autocomplete wl-margin-right-8"
            [control]="pagingModel.term"
            [isSearch]="true"
            placeholder="Наименование акции"
            (searchMethod)="pageFiltered()"
            (keydown.enter)="$event.target.blur(); pageFiltered($event); (false)"
    >
    </app-wl-strict-input-text>
    <app-wl-dropdown
            class="wl-app-input-search-wrapper wl-input-marging wl-margin-right-8"
            isSearch="true"
            [strategy]="promoTypesStrategy"
            (userSelect)="pageFiltered()"
            [control]="pagingModel.promoType"
            [options]="{ placeholder: 'Выберите тип' }"
    >
    </app-wl-dropdown>
    <app-wl-dropdown
            class="wl-app-input-search-wrapper wl-input-marging wl-margin-right-8"
            isSearch="true"
            [strategy]="statesStrategy"
            (userSelect)="pageFiltered()"
            [control]="pagingModel.state"
            [options]="{ placeholder: 'Выберите статус' }"
    >
    </app-wl-dropdown>
    <app-wl-strict-input-number
            class="wl-app-input-search-wrapper"
            [control]="pagingModel.crmTaskGroupId"
            [isSearch]="true"
            placeholder="Id бук.задания"
            (searchMethod)="pageFiltered()"
            (keydown.enter)="$event.target.blur(); pageFiltered(); (false)"
    >
    </app-wl-strict-input-number>
</div>
<app-wl-loading *ngIf="this.loading"></app-wl-loading>
<ga-table *ngIf="!!tableData"
          [data]="tableData"
          [breakWordOnly]="false"
></ga-table>
<div class="wl-paging-wrapper">
    <app-wl-paging
                   *ngIf="apiResult"
                   [pagedResult]="apiResult"
                   (pageChangedEvent)="pageChanged($event)">
    </app-wl-paging>
</div>
