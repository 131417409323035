<div class="wl-markets-parameter-body-block">
    <app-strict-scroll-autocomplete-new [label]="'Маркет'"
                                        [placeholder]="'Выберите маркет'"
                                        [strategy]="marketsTypeStrategy"
                                        (valueChange)="userManipulationsSource.next()"
                                        [control]="market.market"
                                        [instantLoad]="true"
    ></app-strict-scroll-autocomplete-new>

    <span>ФТС</span>
    <app-wl-strict-input-text [title]="'[a]'"
                           [control]="market.ftsParameterA"
                           (valueChange)="userManipulationsSource.next()"
    ></app-wl-strict-input-text>
    <app-wl-strict-input-text [title]="'[b]'"
                           [control]="market.ftsParameterB"
                           (valueChange)="userManipulationsSource.next()"
    ></app-wl-strict-input-text>
    <app-wl-strict-input-text [title]="'[c]'"
                           [control]="market.ftsParameterC"
                           (valueChange)="userManipulationsSource.next()"
    ></app-wl-strict-input-text>

    <div class="wl-button-row">
        <button class="wl-btn-form-active button-margin"
                type="button"
                [disabled]="!isValid"
                (click)="save()">Сохранить
        </button>
        <button class="wl-btn-form-inactive wl-button-position button-margin"
                type="button"
                (click)="close()">Отменить
        </button>
    </div>
</div>

