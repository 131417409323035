import { Injectable, OnDestroy } from '@angular/core';
import { IAutocompleteItem } from '@koddington/ga-common';
import { WlGameTaskSearchForm } from '../../autogen/BattlePass';
import { WlGameTaskSearchModel } from '../../autogen/BattlePass';
import { WlBattlePassGameTaskWrapperService } from '../services/wrapper-service/wl-battle-pass-wrapper-game-service';
import { ScrollStrategy } from '../../../common/abstracts/abstract-scroll-strategy';

@Injectable()
export class WlBattlePassGameTaskScrollStrategy extends ScrollStrategy<WlGameTaskSearchModel, WlGameTaskSearchForm> implements OnDestroy {
    constructor(protected readonly _apiClient: WlBattlePassGameTaskWrapperService) {
        super(_apiClient);
    }
    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
    convert(model: WlGameTaskSearchModel): IAutocompleteItem<WlGameTaskSearchModel> {
        return this.convert_(() => {
            return {
                entity: model,
                title: model.name + ' (' + model.id.toString() + ')',
                key: model.id?.toString(),
            };
        });
    }
    public clear(): void {
        super._sourceClearAutocomplete$.next();
    }
}
