<app-wl-page-header header="Букмекерские задания для боевого пропуска"></app-wl-page-header>
<div class="wl-template-row">
    <button class="wl-button-primary wl-btn-push-save-wrapper" (click)="add()">Добавить задание</button>
</div>
<div class="wl-search-row">
    <div class="wl-search-code-wrapper">
        <svg class="wl-search-svg" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.347656 5.70312C0.347656 8.33398 2.48633 10.4727 5.11719 10.4727C6.0957 10.4727 6.99219 10.1738 7.74219 9.66406L10.4375 12.3594C10.6016 12.5293 10.8301 12.6055 11.0586 12.6055C11.5566 12.6055 11.9141 12.2305 11.9141 11.7441C11.9141 11.5098 11.832 11.293 11.6738 11.1289L8.99609 8.44531C9.55273 7.67773 9.88672 6.72852 9.88672 5.70312C9.88672 3.07227 7.74805 0.933594 5.11719 0.933594C2.48633 0.933594 0.347656 3.07227 0.347656 5.70312ZM1.58984 5.70312C1.58984 3.75781 3.16602 2.17578 5.11719 2.17578C7.0625 2.17578 8.64453 3.75781 8.64453 5.70312C8.64453 7.64844 7.0625 9.23047 5.11719 9.23047C3.16602 9.23047 1.58984 7.64844 1.58984 5.70312Z"
                fill="#C0C0C0"
            />
        </svg>
        <input class="wl-search-input-wrapper" [formControl]="term" placeholder="Поиск по названию" (keydown.enter)="$event.target.blur(); load(); (false)" (focusout)="load()" />
    </div>
</div>
<ga-table *ngIf="!!tableData" [data]="tableData"></ga-table>
<div class="wl-paging-wrapper">
    <app-wl-paging *ngIf="crmTask" [pagedResult]="crmTask" (pageChangedEvent)="pageChanged($event)"></app-wl-paging>
</div>
