import { Injectable } from '@angular/core';
import { WlResult } from 'src/app/models/common/results/wl-result';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { IScrollService } from '../../../../common/abstracts/types/scroll-service-interface';
import { DailyTasksDaysService, WlDtPromotionDayListModel, WlPromotionsDaysSearchForm } from '../../../autogen/DailyTasks';

@Injectable({
    providedIn: 'root'
})
export class DailyTaskDaysScrollServiceAdapter implements IScrollService<WlDtPromotionDayListModel, WlPromotionsDaysSearchForm> {

    constructor(private readonly _service: DailyTasksDaysService) {
    }

    public search(item: WlPromotionsDaysSearchForm): Observable<WlResult<WlDtPromotionDayListModel[]>> {
        return this._service.list(item)
            .pipe(
                take(1),
                map(u => {
                    const res = new WlResult<WlDtPromotionDayListModel[]>();
                    res.result = u.results;
                    return res;
                })
            );
    }
}
