import { WlPartnerRegistriesViewModel } from '../../../models/wl-partner-registries-view-model';
import { AbstractModelValidator } from '@koddington/ga-common';

export class PartnerRegistriesCrudValidator extends AbstractModelValidator<WlPartnerRegistriesViewModel> {
  private readonly _maxVirtualHostNameLength: number = 255;
  private readonly _maxExternalIdLength: number = 255;

  constructor() {
    super();

    this.ruleForControl((u) => u.name)
      .required('Введите название')
      .minLength(1, 'Название не должено быть пустым');
    this.ruleForControl(u => u.virtualHostTitle)
      .required('Введите имя виртуального хоста')
      .minLength(1, 'Имя виртуального хоста не должно быть пустым')
      .maxLength(this._maxVirtualHostNameLength, `Длина имени виртуального хоста превышает ${this._maxVirtualHostNameLength} символов`);
    this.ruleForControl(u => u.externalId)
      .required('Введите внешний Id')
      .minLength(1, 'Внешний Id не должно быть пустым')
      .maxLength(this._maxExternalIdLength, `Длина внешнего Id превышает ${this._maxExternalIdLength} символов`);
    // TODO: separate crud forms with SwitcherForRewardsAndState form
    // this.ruleForControl((u) => u.state).required('Укажите состояние');
    // this.ruleForControl((u) => u.isCanGiveReward).required(
    //   'Укажите разрешение на выдачу наград'
    // );
  }
}
