<app-wl-page-header header="Список фрибетных лутбоксов"></app-wl-page-header>
<div class="wl-template-row">
    <button class="wl-button-primary wl-btn-push-save-wrapper"
            (click)="add()"
    >
        Добавить лутбокс
    </button>
</div>

<app-wl-loading *ngIf="loading"></app-wl-loading>
<ga-table *ngIf="!!tableData"
              [data]="tableData"
              [breakWordOnly]="false"
></ga-table>
<div class="wl-paging-wrapper">
    <app-wl-paging class="wl-test-paging"
                   *ngIf="apiResult"
                   [pagedResult]="apiResult"
                   (pageChangedEvent)="pageChanged($event)"
    ></app-wl-paging>
</div>
