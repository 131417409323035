import { WlBattlePassLootBoxSelectorViewModel } from '../../../models/wl-battle-pass-loot-box-selector-view-model';
import { AbstractModelValidator, StrictError } from '@koddington/ga-common';
import { PatternConsts } from '../../../../../common/consts/pattern-consts';


export class WlBattlePassGameLootBoxSectorValidator extends AbstractModelValidator<WlBattlePassLootBoxSelectorViewModel> {

    constructor() {
        super();

        this.ruleForControl((u) => u.reward).required();
        this.ruleForControl((u) => u.probability).required();
        this.ruleForControl((u) => u.probability)
            .required()
            .customRule((field) => {
                if (PatternConsts.ProbabilityDigital.test(field?.toString())) {
                    return null;
                }

                return new StrictError('decimalDigitError', 'Количество знаков после запятой не должно превышать 10');
            });
    }
}
