<div class="wl-search-row">
    <app-wl-strict-input-number
            class="wl-app-input-search-wrapper"
            [control]="searchViewModel.id"
            [isSearch]="true"
            placeholder="Id награды"
            (searchMethod)="pageChanged($event)"
            (keydown.enter)="$event.target.blur(); pageChanged($event); (false)">
    </app-wl-strict-input-number>
    <app-wl-strict-input-text
            class="wl-app-input-search-wrapper"
            [control]="searchViewModel.name"
            [isSearch]="true"
            placeholder="Наименование награды"
            (searchMethod)="pageChanged($event)"
            (keydown.enter)="$event.target.blur(); pageChanged($event); (false)">
    </app-wl-strict-input-text>
</div>
<ga-table *ngIf="!!tableData" [data]="tableData"></ga-table>

<ng-template #isEditing let-order="order">
    <div *ngIf="!!order">
        <app-wl-strict-input-boolean
                (userSelect)="userManipulationsSource.next()"
                [control]="isEditingByOrder(order)"
        ></app-wl-strict-input-boolean>
    </div>
</ng-template>

<ng-template #exchangeEditing let-order="order">
    <div *ngIf="isCheckByOrder(order)">
        <app-wl-strict-input-number
                [options]="{ placeholder: 'Сумма для обмена награды' }"
                (valueChange)="userManipulationsSource.next()"
                [control]="getExchangeByOrder(order)">
        </app-wl-strict-input-number>
    </div>
    <label class="wl-row-label" *ngIf="!isCheckByOrder(order)">{{ getExchange(order) }} </label>
</ng-template>

<ng-template #rewardEditing let-order="order">
    <div *ngIf="isCheckByOrder(order)">
        <app-strict-scroll-autocomplete-new
                [options]="{ placeholder: 'Наименование' }"
                [strategy]="getStrategyByOrder(order)"
                [control]="getByOrder(order)"
                (valueChange)="userManipulationsSource.next()"
        ></app-strict-scroll-autocomplete-new>
    </div>
    <label class="wl-row-label" *ngIf="!isCheckByOrder(order)">{{ getName(order) }} </label>
</ng-template>

<div class="wl-paging-wrapper">
    <app-wl-paging
            *ngIf="viewModel"
            [pagedResult]="viewModel"
            (pageChangedEvent)="pageChanged($event)"
    ></app-wl-paging>
</div>
