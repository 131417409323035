import { StrictFormControl } from '@koddington/ga-common';
import { WlGameTaskIcon } from 'src/app/modules/autogen/BattlePass';

export class WlBattlePassGameTaskTemplateViewModel {
    public id: StrictFormControl<number> = new StrictFormControl<number>(null);
    public name: StrictFormControl<string> = new StrictFormControl<string>('');
    public description: StrictFormControl<string> = new StrictFormControl<string>('');
    public externalPartnerId: StrictFormControl<string> = new StrictFormControl<string>('');
    public icon: StrictFormControl<WlGameTaskIcon> = new StrictFormControl<WlGameTaskIcon>();
}
