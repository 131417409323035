<form [formGroup]="formGroupDates" class="wl-data-picker-single-container">
    <label *ngIf="title" class="wl-app-strict-autocomplete-label-text">{{ title }}</label>
    <div class="wl-strict-input-wrapper">
        <input
            matInput
            type="text"
            gaDateRangePickerMd
            formControlName="start"
            [locale]="locale"
            [singleDatePicker]="true"
            [timePicker]="true"
            [timePickerSeconds]="true"
            [timePickerIncrement]="1"
            [timePicker24Hour]="true"
            (datesUpdated)="onUpdate($event)"
            [showClearButton]="true"
            class="form-control"
            readonly="true"
            [(ngModel)]="selected"
            [class.is-invalid]="shouldDisplayErrors | async"
            [gaFixDatesInEvents]="true"
        />
        <div class="wl-error-bubble wl-error-bubble-position" *ngIf="hasStrictErrors">
            <span *ngFor="let error of this.date.strictErrors | async">
                {{ error.message }}
            </span>
        </div>
    </div>
</form>
