import { DropdownItem, IDropdownStrategy } from '@koddington/ga-common';
import { Observable, of } from 'rxjs';
import { WlFiltersTypes } from '../../autogen/BattlePass';

export class WlFilterTypeStrategy implements IDropdownStrategy<WlFiltersTypes> {
    public getEntities(): Observable<Array<WlFiltersTypes>> {
        const entities = [WlFiltersTypes.Including, WlFiltersTypes.Excluding];

        return of(entities);
    }

    public map: (model: WlFiltersTypes) => DropdownItem<WlFiltersTypes> = model => {
        const item = new DropdownItem<WlFiltersTypes>();
        item.entity = model;
        item.title = model === WlFiltersTypes.Including ? 'Включая' : 'Исключая';
        item.id = Number(model);

        return item;
    }
}
