import { Component, OnDestroy, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GaPagedResult, GaPagingForm } from '@koddington/ga-common';
import { BattlePassService, WlGameTaskTemplate } from 'src/app/modules/autogen/BattlePass';
import { GaTableData } from '@koddington/ga-common';

@Component({
    selector: 'app-wl-battle-pass-game-task-templates',
    templateUrl: './wl-battle-pass-game-task-templates.component.html',
})
export class WlBattlePassGameTaskTemplatesComponent implements OnInit, OnDestroy {
    templatesList: GaPagedResult<WlGameTaskTemplate>;
    tableData: GaTableData<WlGameTaskTemplate>;
    loading: boolean;

    constructor(private readonly _battlePassService: BattlePassService, private readonly _router: Router) {}

    ngOnInit(): void {
        this.load();
    }

    public ngOnDestroy(): void {}

    load(offset: number = 0) {
        const form = this.buildForm(offset);
        this.loading = true;

        this._battlePassService
            .listGameTaskTemplates(form)
            .pipe(
                take(1),
                finalize(() => (this.loading = false))
            )
            .subscribe((u) => {
                this.templatesList = u.result;
                this.mapToTable();
                this.loading = false;
            });
    }

    pageChanged(page: GaPagingForm) {
        this.load(page.offset);
    }

    private buildForm(offset: number): GaPagingForm {
        const form = new GaPagingForm();
        form.count = 30;
        form.offset = offset;
        return form;
    }

    public add(): void {
        this._router.navigate(['/menu/battlePass/gameTaskTemplates/edit']);
    }

    private mapToTable() {
        const data = new GaTableData<WlGameTaskTemplate>();

        this.tableData = data
            .addRouterLinkColumn(
                (elem) => elem.externalPartnerId,
                (elem) => ['edit', elem.id],
                { title: 'Партнерский Id', widthSize: 400 }
            )
            .addSimpleColumn((elem) => elem.name, { title: 'Название', widthSize: 350 })
            .addSimpleColumn((elem) => elem.description, { title: 'Описание задания', widthSize: 390 })
            .addSimpleColumn((elem) => elem.icon.code, { title: 'Иконка', widthSize: 250 })
            .setData(this.templatesList.results);
    }
}
