import { Injectable } from '@angular/core';
import { BattlePassService, WlGamesModel } from '../../autogen/BattlePass';
import { DropdownItem, IDropdownStrategy, isNullOrUndefined } from '@koddington/ga-common';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn : 'root'
})
export class WlGamesTypeStrategy implements IDropdownStrategy<WlGamesModel> {
    private _source = new BehaviorSubject<WlGamesModel[]>([]);

    constructor(private readonly _battlePassService: BattlePassService) {
        this.load();
    }

    public getEntities(): Observable<Array<WlGamesModel>> {
        return this._source;
    }

    public countEntities(): number {
        return this._source.value.length;
    }

    public map: (model: WlGamesModel) => DropdownItem<WlGamesModel> = model => {
        if (isNullOrUndefined(model)) {
            return {
                entity: model,
                title: '',
                id: null,
            };
        }
        return {
            entity: model,
            title: model.name,
            id: model.id,
        };
    }

    private load(): void {
        this._battlePassService
            .listGames()
            .pipe(
                take(1),
                filter((res) => !isNullOrUndefined(res) && res.isCorrect),
                map((result) => result.result)
            )
            .subscribe((u) => this._source.next(u));
    }
}
