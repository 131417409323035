import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { IAutocompleteItem } from '../strict-autocomplete/contracts/app-autocomplete-item';
import { IAutocompleteEntityTemplateStrategy } from '../strict-autocomplete/contracts/autocomplete-strategy';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject, fromEvent, BehaviorSubject } from 'rxjs';
import { StrictScrollAutocompleteViewModel } from '../strict-scroll-autocomplete/models/strict-scroll-autocomplete-view-model';
import { isNullOrUndefined, StrictFormControl } from '@koddington/ga-common';

@Component({
    selector: 'app-strict-scroll-autocomplete-new',
    templateUrl: './strict-scroll-autocomplete-new.html',
    styleUrls: ['./strict-scroll-autocomplete-new.scss'],
})
export class StrictScrollAutocompleteNewComponent<T> implements OnInit, OnDestroy, AfterContentInit {
    @ViewChild('scrollable') scrollable: ElementRef;

    @Input()
    public isSearch = false;
    @Input()
    public label: string | undefined;
    @Input()
    public targetRouterLink: any[] | undefined;
    @Input()
    public placeholder = '';
    @Input()
    public control: StrictFormControl<T>;
    @Input()
    public strategy: IAutocompleteEntityTemplateStrategy<T, StrictScrollAutocompleteViewModel>;
    @Input()
    public instantLoad = false;
    @Input()
    public changeItemAtEnter = true;
    @Input()
    public showItemTitleAfterSelect = true;

    @Output()
    public userFinalSelected = new EventEmitter<T>();
    @Output()
    public userSelect = new EventEmitter<T>();
    @Output()
    public readonly valueChange = new EventEmitter<void>();
    @Output()
    public readonly inputConfirmed = new EventEmitter<string>();

    protected readonly termSource$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    protected readonly clearSource$: Subject<void> = new Subject<void>();

    public viewModel: StrictScrollAutocompleteViewModel;
    public currentItem: IAutocompleteItem<T>;
    public currentItemsSource: IAutocompleteItem<T>[] = [];
    public scrollingCount = 0;

    public isActive = false;
    public isFocused = false;

    protected currentFocus = 0;

    private readonly scrollBeginPosition: number = 0;
    private readonly KeyDownCode: string = 'ArrowDown';
    private readonly KeyUpCode: string = 'ArrowUp';
    private readonly KeyEnterCode: string = 'Enter';

    private readonly clickOutsideSource = fromEvent(document, 'click').pipe(
        map((event) => !this._elementRef.nativeElement.contains(event.target)),
        filter((outside: boolean) => outside)
    );
    constructor(protected _elementRef: ElementRef) {}

    public ngAfterContentInit(): void {
        this.viewModel = new StrictScrollAutocompleteViewModel();
        this.strategy.bindControlModel(this.viewModel);
        this.checkInitData();
        this.subscriptionToLoad();
        this.subscriptionToChangeTerm();
        this.subscriptionToChangeControl();
        this.subscriptionToChangeGlobalFocus();
        if (this.strategy?.setCallback) {
            this.strategy.setCallback(this.clearSource$);
        }
        this.subscriptionExternalClearAndLoad();

        if (this.instantLoad) {
            this.strategy.emitUpdateEvent();
        }
    }

    public ngOnDestroy(): void {
        this.currentItem = null;
        this.currentItemsSource.length = 0;
    }

    public ngOnInit(): void {}

    private checkInitData(): void {
        if (isNullOrUndefined(this.control)) {
            throw new Error('Entity container for dropdown cannot be undefined');
        }

        if (isNullOrUndefined(this.strategy)) {
            throw new Error('Strategy for dropdown cannot be undefined');
        }
    }

    private subscriptionToLoad(): void {
        this.strategy
            .getSource()
            .pipe(
                map((items) => items.map((u) => this.strategy.convert(u))),
                untilDestroyed(this)
            )
            .subscribe((result) => {
                this.currentItemsSource = [...this.currentItemsSource, ...result];
                this.scrollingCount = result.length;
                this.viewModel.isEndOfList.strictValue = false;
            }, console.log);
    }

    public onInputChange(event: any): void {
        const term: string = event.target.value;
        this.control.setValue(null, { emitEvent: false });

        if (isNullOrUndefined(term) || term.length === 0) {
            this.clearResult();
            this.resetValue();
        }
        this.isActive = true;
        this.termSource$.next(term);
    }

    public setCurrentItem(item: IAutocompleteItem<T>): void {
        this.currentItem = null;
        this.valueChange.emit();
        if (!isNullOrUndefined(item) && !isNullOrUndefined(item.title)) {
            this.currentItem = item;
            this.updateViewModelTerm(item.title, false);
            return;
        }
        // this.currentItem = { entity: null, key: '', title: '' };
    }

    public onKeyDown(event: KeyboardEvent): void {
        switch (event.key) {
            case this.KeyDownCode:
                this.moveSelectionDown();
                break;
            case this.KeyUpCode:
                this.moveSelectionUp();
                break;
            case this.KeyEnterCode:
                if (this.isActive) {
                    const selectedItem = this.currentItemsSource[this.currentFocus];
                    this.onClick(selectedItem, this.changeItemAtEnter);
                    return;
                }

                this.userFinalSelected.emit();
                break;
            default:
                break;
        }
    }

    public resetValue(): void {
        this.dropOffset();
        this.setCurrentItem(null);
        this.currentFocus = 0;
        this.control.strictValue = null;
    }

    // INFO: This is event going not only scrolling but also onclick buttons and keyboard
    public onScroll(event: any): void {
        if (this.isEndScroll(event)) {
            this.nextLoad();
            event.target.scrollTop = event.target.scrollHeight - event.target.offsetHeight;
        }
    }

    public onFocus(): void {
        if (this.currentItemsSource.length > 0) {
            this.isActive = true;
        }
    }

    public trackFocus(event: FocusEvent | null = null): void {
        if (isNullOrUndefined(event)) {
            this.isFocused = false;
            return;
        }
        this.isFocused = true;
    }

    protected onClick(item: IAutocompleteItem<T>, changeTermInSource: boolean): void {
        if (this.control.disabled) {
            return;
        }
        this.isActive = false;

        this.inputConfirmed.emit(this.termSource$.value);
        if (isNullOrUndefined(item)) {
            this.userFinalSelected.emit();
            return;
        }
        this.setCurrentItem(item);
        if (changeTermInSource) {
            this.control.setValue(item.entity);
            this.userSelect.emit(this.control.strictValue);

            const nextTitle = this.showItemTitleAfterSelect
                ? item.title
                : '';
            this.updateViewModelTerm(nextTitle);
        }
        this.userFinalSelected.emit();
        this.clearResult();
    }

    protected clearResult(): void {
        this.currentItemsSource.length = 0;
        this.viewModel.offset.strictValue = 0;
    }

    private subscriptionToChangeTerm(): void {
        this.termSource$.pipe(debounceTime(800), distinctUntilChanged(), untilDestroyed(this)).subscribe((u) => {
            this.clearResult();
            if (isNullOrUndefined(u))
                return;

            this.viewModel.term.strictValue = u;
            this.strategy.emitUpdateEvent();
        }, console.log);
    }

    private subscriptionToChangeControl(): void {
        this.control.strictValueSource
            .pipe(
                distinctUntilChanged(),
                filter((v) => !isNullOrUndefined(v)),
                map((v) => this.strategy.convert(v)),
                untilDestroyed(this)
            )
            .subscribe((u) => {
                this.setCurrentItem(u);
            });
    }

    private subscriptionToChangeGlobalFocus(): void {
        this.clickOutsideSource.pipe(untilDestroyed(this)).subscribe(() => (this.isActive = false));
    }

    private subscriptionExternalClearAndLoad(): void {
        this.clearSource$.pipe(untilDestroyed(this)).subscribe(() => {
            if (this.viewModel.term.hasStrictValue && this.viewModel.term.strictValue.length >= 3) {
                this.currentItem = null;
                this.viewModel.term.setValue(null);
                this.resetValue();
                this.clearResult();
                this.strategy.emitUpdateEvent();
            }
        });
    }

    private dropOffset(): void {
        this.viewModel.offset.strictValue = 0;
        this.viewModel.isEndOfList.strictValue = false;
    }

    private nextLoad(): void {
        this.viewModel.isEndOfList.strictValue = true;
        if (this.scrollingCount === 0 && this.currentItemsSource.length > 0) {
            return;
        }
        this.viewModel.offset.strictValue += this.viewModel.count.strictValue;
        this.strategy.emitUpdateEvent();
    }

    private isEndScroll(event: any): boolean {
        return event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 1 && !this.viewModel.isEndOfList.strictValue && !this.isZeroState(event);
    }

    private isZeroState(event: any): boolean {
        return event.target.offsetHeight === 0 && event.target.scrollTop === 0 && event.target.scrollHeight === 0;

    }

    private moveSelectionUp(): void {
        if (this.currentFocus <= 0) {
            this.currentFocus = this.currentItemsSource.length - 1;
            if (this.isNotEmptyDropDown()) {
                const item = this.getFocusedDIVElement();
                this.setScrollYPosition(item.offsetTop);
            }
        } else {
            this.currentFocus--;
            if (this.isNotEmptyDropDown()) {
                const item = this.getFocusedDIVElement();
                if (this.isNextScrollByPosition(item.offsetTop)) {
                    this.setScrollYPosition(item.offsetTop);
                    return;
                }
                this.setScrollYPosition(this.scrollBeginPosition);
            }
        }
    }

    private moveSelectionDown(): void {
        if (this.currentFocus >= this.currentItemsSource.length - 1) {
            this.currentFocus = 0;
            this.setScrollYPosition(this.scrollBeginPosition);
        } else {
            this.currentFocus++;
            if (this.isNotEmptyDropDown()) {
                const item = this.getFocusedDIVElement();
                const bottomYPosition = item.offsetHeight + item.offsetTop;
                if (this.isNextScrollByPosition(bottomYPosition)) {
                    this.setScrollYPosition(item.offsetTop);
                }
            }
        }
    }

    private isNextScrollByPosition(coordinateY: number): boolean {
        return coordinateY > this.scrollable.nativeElement.offsetHeight;
    }

    private isNotEmptyDropDown(): boolean {
        return this.scrollable.nativeElement?.children.length > 0;
    }

    private setScrollYPosition(coordinateY: number): void {
        this.scrollable.nativeElement.scrollTop = coordinateY;
    }

    private getFocusedDIVElement(): HTMLDivElement {
        const childs = Array.from(this.scrollable.nativeElement?.children) as HTMLDivElement[];
        return childs[this.currentFocus];
    }

    private updateViewModelTerm(title: string, sendUpdateEvent: boolean = true): void {
        this.viewModel.term.strictValue = title;
        if (sendUpdateEvent)
            this.termSource$.next(title);
    }
}
