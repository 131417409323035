import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { StrictScrollAutocompleteViewModel } from './models/strict-scroll-autocomplete-view-model';
import { IAutocompleteItem } from '../strict-autocomplete/contracts/app-autocomplete-item';
import { IAutocompleteEntityStrategy } from '../strict-autocomplete/contracts/autocomplete-strategy';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { CdkVirtualScrollViewport, ScrollDispatcher } from '@angular/cdk/scrolling';
import { isNullOrUndefined, StrictFormControl } from '@koddington/ga-common';

@Component({
    selector: 'app-strict-scroll-autocomplete',
    templateUrl: './strict-scroll-autocomplete.component.html',
    styleUrls: ['./strict-scroll-autocomplete.component.scss'],
})
export class StrictScrollAutocompleteComponent<TSource> implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(CdkVirtualScrollViewport) scrollViewport: CdkVirtualScrollViewport;

    @Input()
    public control: StrictFormControl<TSource>;
    @Input()
    public parentControl?: StrictFormControl<any>;
    @Input()
    public strategy: IAutocompleteEntityStrategy<TSource, StrictScrollAutocompleteViewModel>;
    @Input()
    public placeholder = '';
    @Input()
    public readonly clearField: Subject<void>;
    @Output()
    public emitUserSelection = new EventEmitter<TSource>();
    @Input()
    public customStyle = '';

    public viewModel: StrictScrollAutocompleteViewModel;
    public results: IAutocompleteItem<TSource>[] = [];
    public selectedItem: IAutocompleteItem<TSource>;
    public isActive = false;

    public currentFocus = 0;
    private readonly clickOutsideSource = fromEvent(document, 'click').pipe(
        map((event) => !this._elementRef.nativeElement.contains(event.target)),
        filter((outside: boolean) => outside)
    );
    private resultsSource$: BehaviorSubject<IAutocompleteItem<TSource>[]> = new BehaviorSubject<IAutocompleteItem<TSource>[]>([]);

    constructor(private _elementRef: ElementRef, private _changeTracker: ChangeDetectorRef, private _scrollDispatcher: ScrollDispatcher) {}

    public ngOnInit(): void {
        this.viewModel = new StrictScrollAutocompleteViewModel();
        this.strategy.bindControlModel(this.viewModel);
        if (isNullOrUndefined(this.control)) {
            throw new Error('Entity container for dropdown cannot be undefined');
        }
        if (isNullOrUndefined(this.strategy)) {
            throw new Error('Strategy for dropdown cannot be undefined');
        }

        if (!isNullOrUndefined(this.clearField)) {
            this.clearField.pipe(untilDestroyed(this), debounceTime(300)).subscribe(() => {
                this.selectedItem = null;
            });
        }

        this.clickOutsideSource.pipe(untilDestroyed(this)).subscribe(() => (this.isActive = false));

        this.strategy
            .getSource()
            .pipe(
                filter((value) => {
                    if (value.length === 0) {
                        this.viewModel.isEndOfList.strictValue = true;
                        return false;
                    }
                    return true;
                }),
                map((items) => {
                    return items.map((u) => this.strategy.convert(u));
                }),
                map((items) => {
                    this.results = [...this.results, ...items];
                    this.resultsSource$.next(this.results);
                    this._changeTracker.detectChanges();
                }),
                untilDestroyed(this)
            )
            .subscribe();

        this.viewModel.term.strictValueSource
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                filter((value) => {
                    const isNullOrEmpty = isNullOrUndefined(value) || value === '';
                    if (isNullOrEmpty) {
                        this.resetValue();
                        return false;
                    }
                    const isValidLength = value.length >= 3;

                    if (isValidLength) {
                        return true;
                    }
                    return false;
                }),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.dropOffset();
                this.strategy.emitUpdateEvent();
            });

        this.selectedItem = !this.control.hasStrictValue ? null : this.strategy.convert(this.control.strictValue);

        this.control.strictValueSource
            .pipe(
                distinctUntilChanged(),
                map((value) => {
                    if (isNullOrUndefined(value)) {
                        return null;
                    }
                    return this.strategy.convert(value);
                }),
                untilDestroyed(this)
            )
            .subscribe();

        if (!isNullOrUndefined(this.parentControl)) {
            this.parentControl.registerOnDisabledChange(() => {
                if (this.parentControl.disabled) {
                    this.viewModel.term.disable({ emitEvent: false });
                } else {
                    this.viewModel.term.enable({ emitEvent: false });
                }
            });
        }
    }

    public ngAfterViewInit(): void {
        this._scrollDispatcher
            .scrolled()
            .pipe(
                debounceTime(200),
                filter(
                    (event) =>
                        this.scrollViewport.getRenderedRange().end !== 0 &&
                        !this.viewModel.isEndOfList.strictValue &&
                        this.scrollViewport.getRenderedRange().end === this.scrollViewport.getDataLength()
                )
            )
            .subscribe((event) => {
                this.viewModel.offset.strictValue += this.viewModel.count.strictValue;
                this.strategy.emitUpdateEvent();
            });
    }

    public ngOnDestroy(): void {}

    public onClickOrSelect(item: IAutocompleteItem<TSource>): void {
        if (this.control.disabled) {
        }
        this.isActive = false;
        this.selectedItem = item;
        this.control.strictValue = item.entity;
        this.emitUserSelection.emit();
    }

    public title(): (result: string) => string | null {
        return (itemValue: string) => {
            return !isNullOrUndefined(itemValue) ? itemValue : null;
        };
    }

    public resultsSource(): Observable<IAutocompleteItem<TSource>[]> {
        return this.resultsSource$.asObservable();
    }

    private dropOffset(): void {
        this.results = [];
        this.viewModel.offset.strictValue = 0;
        this.viewModel.isEndOfList.strictValue = false;
    }

    private resetValue(): void {
        this.dropOffset();
        this.selectedItem = null;
        this.currentFocus = 0;
        this.control.strictValue = null;

        this.resultsSource$.next(this.results);
    }
}
