<app-wl-page-header header="Статистика ставок для заданий боевого пропуска"></app-wl-page-header>
<div class="wl-search-row">
    <app-wl-strict-input-number
        class="wl-app-input-search-wrapper"
        placeholder="Id пользователя"
        [isSearch]="true"
        [control]="searchUserId"
        (valueChange)="search()"
    ></app-wl-strict-input-number>
    <app-wl-strict-input-number
        class="wl-app-input-search-wrapper"
        placeholder="Id задания пользователя"
        [isSearch]="true"
        [control]="searchUserTaskId"
        (valueChange)="search()"
    ></app-wl-strict-input-number>
    <app-wl-strict-input-number
        class="wl-app-input-search-wrapper"
        placeholder="Id подписки пользователя"
        [isSearch]="true"
        [control]="searchUserSubscriptionId"
        (valueChange)="search()"
    ></app-wl-strict-input-number>
</div>
<app-wl-loading *ngIf="loading"></app-wl-loading>
<ga-table *ngIf="!!tableData" [data]="tableData" [hideArrows]="false" [breakWordOnly]="true"></ga-table>
<div class="wl-paging-wrapper">
    <app-wl-paging *ngIf="betsForTasks" [pagedResult]="betsForTasks" (pageChangedEvent)="pageChanged($event)"></app-wl-paging>
</div>
