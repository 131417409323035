import { GaPagedResult, GaPagingForm, StrictFormControl } from '@koddington/ga-common';
import { WlBattlePassFreeBetLootBoxContentsViewModel } from './wl-battle-pass-free-bet-loot-box-contents-view-model';

export class WlBattlePassFreeBetLootBoxCrudViewModel {
    public id: StrictFormControl<number> = new StrictFormControl<number>();
    public name: StrictFormControl<string> = new StrictFormControl<string>();
    public lootBoxContents: StrictFormControl<WlBattlePassFreeBetLootBoxContentsViewModel[]> = new StrictFormControl<WlBattlePassFreeBetLootBoxContentsViewModel[]>([]);

    public pagingResult = new GaPagedResult<void>();
    public probabilitiesSum: StrictFormControl<number> = new StrictFormControl<number>(0);

    constructor() {
        this.pagingResult.paging = new GaPagingForm(15, 0);
        this.probabilitiesSum.disable();
    }
}
