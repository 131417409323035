import {Routes} from '@angular/router';
import {WlPartnerRegistriesCrudComponent} from './components/partner-registries-crud/app-partner-registries-crud.component';
import {PartnerRegistriesListComponent} from './components/partner-registries-list/partner-registries-list.component';
import {PartnerRewardsCrudComponent} from './components/partner-rewards-crud/partner-rewards-crud.component';
import {PartnerRewardsListComponent} from './components/partner-rewards-list/partner-rewards-list.component';

export const DictionariesRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'reward',
        component: PartnerRewardsListComponent,
      },
      {
        path: 'reward/edit',
        component: PartnerRewardsCrudComponent,
      },
      {
        path: 'reward/edit/:id',
        component: PartnerRewardsCrudComponent,
      },
      {
        path: 'partner',
        component: PartnerRegistriesListComponent,
      },
      {
        path: 'partner/edit',
        component: WlPartnerRegistriesCrudComponent,
      },
      {
        path: 'partner/edit/:id',
        component: WlPartnerRegistriesCrudComponent,
      },
    ],
  },
];
