import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { GaMessagingService, StrictFormControl } from '@koddington/ga-common';
import { BattlePassRateRaceService } from 'src/app/modules/autogen/BattlePass';
import { RouterNavigationExtensions } from 'src/app/modules/shared/extensions/navigation-extensions';

@Component({
    selector: 'app-wl-import-rewards',
    templateUrl: './wl-import-rewards.component.html',
    styleUrls: ['./wl-import-rewards.component.scss'],
})
export class WlImportRewardsComponent {
    @Input()
    title: string;
    @Input()
    fileNameControl: StrictFormControl<string> = new StrictFormControl<string>(null);
    @Input()
    lootBoxId: StrictFormControl<number> = new StrictFormControl<number>(null);
    @Input()
    fileLoaded: StrictFormControl<boolean> = new StrictFormControl<boolean>(null);
    @Input()
    onChangeSubj: Subject<any>;
    @ViewChild('filepicker')
    filePicker: ElementRef;

    private readonly _dataTransfer = new DataTransfer();
    private fileName = '';

    constructor(private readonly _service: BattlePassRateRaceService,
                private readonly _messaging: GaMessagingService,
                private readonly _router: Router) {
    }

    public onFileChange(event: any): void {
        this.fileLoaded.strictValue = true;
        if (!this.validateFile(event)) {
            return;
        }

        const file = event.target.files[0];
        this.fileName = file.name;
        this._service
            .uploadFile(this.lootBoxId.strictValue, file)
            .pipe(take(1))
            .subscribe((res) => {
                if (!this._messaging.tryShowError(res)) {
                    this.fileNameControl.strictValue = file.name;
                    this._messaging.showMessage('Файл успешно загружен');
                    this.refresh();
                } else {
                    this.fileNameControl.strictValue = null;
                }

                this.onChangeSubj.next();
            });

        this.filePicker.nativeElement.value = null;
    }

    private refresh(): void {
        RouterNavigationExtensions.navigateAndRefresh(this._router, []);
    }

    private validateFile(event: any): boolean {
        const files = event.target.files;

        if (files.length === 0) {
            return false;
        }

        if (files[0].size === 0) {
            this._messaging.showMessage('Выбран пустой файл');
            event.target.files = this._dataTransfer.files;

            return false;
        }

        return true;
    }

    public getFileName(): string {
        return !!this.fileName ? this.fileName : 'Выберите файл для импорта';
    }

    public clear() {
        this.fileNameControl.strictValue = null;
        this.fileLoaded.strictValue = false;
        this.fileName = null;

        this.onChangeSubj.next();
    }
}
