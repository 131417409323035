import { Component, OnDestroy, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { WlAuthStateService } from '../authorize/services/auth-state-service';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'app-root-component',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
})
export class RootComponent implements OnInit, OnDestroy {
  private currentLogin: string = '';
  constructor(private readonly _userAuthStateService: WlAuthStateService) {}
  ngOnInit(): void {
    this._userAuthStateService.userState
      .pipe(untilDestroyed(this))
      .subscribe((u) => {
        if (!u?.login) {
          this.currentLogin = 'Гость';
          return;
        }
        this.currentLogin = u.login;
      });
  }
  ngOnDestroy(): void {}

  get login(): string {
    return this.currentLogin;
  }

  get isLogin(): boolean {
    if (this.currentLogin === '' || this.currentLogin === 'Гость') {
      return false;
    }
    return true;
  }
}
