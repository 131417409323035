import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  GaMessagingService,
  isNullOrUndefined,
  StrictError,
} from '@koddington/ga-common';
import { WlTaskTrackerPartnerModel } from '../../../autogen/TaskTrackerPartner';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter, finalize, map, take } from 'rxjs/operators';
import {
  builderModelToStrict,
  builderStrictToModel,
} from '../../../shared/common/operation/builder-operation';
import { WlPartnerRewardsViewModel } from '../../models/wl-partner-rewards-view-model';
import { WlTaskTrackerRewardTypesStrategy } from '../../strategy/wl-task-tracker-reward-types-strategy';
import {
  RewardsService,
  TaskTrackerRewardCreateForm,
  TaskTrackerRewardUpdateForm,
} from '../../../autogen/TaskTrackerRewards';
import { PartnerRewardsCrudValidator } from './validator/partner-rewards-crud-validator';
import { WlResult } from 'src/app/models/common/results/wl-result';

@Component({
  selector: 'app-partner-rewards-crud',
  templateUrl: './partner-rewards-crud.component.html'
})
export class PartnerRewardsCrudComponent implements OnInit, OnDestroy {
  public loading: boolean;
  public viewModel: WlPartnerRewardsViewModel = new WlPartnerRewardsViewModel();
  public readonly userManipulationsSource = new Subject<void>();
  public errors: StrictError[] = [];
  private readonly validator = new PartnerRewardsCrudValidator();
  public rewardTypeStrategy: WlTaskTrackerRewardTypesStrategy;
  private updateDataLoad$: Observable<WlResult<WlTaskTrackerPartnerModel>>;

  constructor(
    private readonly _service: RewardsService,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _messaging: GaMessagingService
  ) {}

  ngOnInit(): void {
    this.rewardTypeStrategy = new WlTaskTrackerRewardTypesStrategy();

    this.userManipulationsSource.pipe(untilDestroyed(this)).subscribe(() => {
      this.errors = this.validator.validate(this.viewModel);
    });
    this.subscribeLoad();
  }
  ngOnDestroy(): void {}

  private subscribeLoad(): void {
    this._route.paramMap
      .pipe(
        map((params) => params.get('id')),
        take(1)
      )
      .subscribe((id) => {
        if (isNullOrUndefined(id)) return;
        const nId = Number(id);
        this.loading = true;
        if (isNaN(nId)) return;

        this.viewModel.id.strictValue = nId;
        this.updateDataLoad$ = this._service.getForUpdate(nId).pipe(
          filter((value) => !this._messaging.tryShowError(value)),
          untilDestroyed(this),
          finalize(() => (this.loading = false))
        );
      });

    if (this.updateDataLoad$) {
      this.updateDataLoad$.subscribe((response) => {
        this.loadModel(response.result);
        this.viewModel.type.disable();
      });
      return;
    }
    this.userManipulationsSource.next();
  }

  public save(): void {
    const error = this.validator.validate(this.viewModel);

    if (error.length) return;

    if (this.viewModel.id.hasStrictValue) {
      this.update();
      return;
    }

    this.create();
  }

  private create(): void {
    this.loading = true;
    const form = builderStrictToModel(
      TaskTrackerRewardCreateForm,
      this.viewModel
    );

    this._service
      .add(form)
      .pipe(
        filter((value) => !this._messaging.tryShowError(value)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.showListPartner();
        this.loading = false;
      });
  }

  private update(): void {
    this.loading = true;
    const form = builderStrictToModel(
      TaskTrackerRewardUpdateForm,
      this.viewModel
    );

    this._service
      .update(form)
      .pipe(
        filter((value) => !this._messaging.tryShowError(value)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.showListPartner();
        this.loading = false;
      });
  }

  private loadModel(model: WlTaskTrackerPartnerModel): void {
    builderModelToStrict(this.viewModel, model, null, null);
  }

  private showListPartner(): void {
    this._router.navigate(['menu/dictionary/reward']);
  }

  get isValid(): boolean {
    return this.errors.length === 0;
  }
}
