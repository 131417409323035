import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { WlRateRacePagingViewModel } from '../../models/wl-rate-race-paging-view-model';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { formatDateTime, GaPagedResult, GaPagingForm, GaTableData, isNullOrUndefined } from '@koddington/ga-common';
import { BattlePassRateRaceService, WlRateRaceListPagingForm, WlRateRaceStatesExtensions, WlRateRaceTournamentListModel } from 'src/app/modules/autogen/BattlePass';
import { SearchNavigationService } from 'src/app/modules/shared/services/search-navigation.service';
import { builderStrictToModel } from 'src/app/modules/shared/common/operation/builder-operation';

@Component({
    selector: 'app-wl-rate-race-list',
    templateUrl: './wl-rate-race-list.component.html',
    styleUrls: ['./wl-rate-race-list.component.scss'],
})
export class WlRateRaceListComponent implements OnInit, OnDestroy {
    public result: GaPagedResult<WlRateRaceTournamentListModel>;
    public tableData: GaTableData<WlRateRaceTournamentListModel>;
    public paging = new WlRateRacePagingViewModel();

    constructor(
        private readonly _activeRoute: ActivatedRoute,
        private readonly _service: BattlePassRateRaceService,
        private readonly _router: Router,
        private readonly _navigation: SearchNavigationService
    ) {}

    public ngOnInit(): void {
        this.init();
    }

    public ngOnDestroy(): void {}

    public add(): void {
        this._router.navigate(['/menu/raterace/create']);
    }

    public pageChanged(pagingEvent: GaPagingForm): void {
        this.paging.offset.strictValue = pagingEvent.offset;
        this.paging.count.strictValue = pagingEvent.count;

        this.navigateWithParameters();
    }

    public search(): void {
        this.paging.offset.strictValue = 0;

        this.navigateWithParameters();
    }

    private init(): void {
        this._activeRoute.queryParams
            .pipe(
                map((param) => this.initPagingModel(param)),
                untilDestroyed(this)
            )
            .subscribe(() => this.load());
    }

    private initPagingModel(params: Params): void {
        this.paging.offset.strictValue = !isNullOrUndefined(params['offset']) ? Number(params['offset']) : 0;
        this.paging.count.strictValue = !isNullOrUndefined(params['count']) ? Number(params['count']) : 30;
        this.paging.minPlacesNumber.strictValue = !isNullOrUndefined(params['minPlacesNumber']) ? Number(params['minPlacesNumber']) : null;
        this.paging.maxPlacesNumber.strictValue = !isNullOrUndefined(params['maxPlacesNumber']) ? Number(params['maxPlacesNumber']) : null;
    }

    private navigateWithParameters(): void {
        const params: Params = {
            offset: this.paging.offset.strictValue,
            count: this.paging.count.strictValue,
            minPlacesNumber: this.paging.minPlacesNumber.strictValue,
            maxPlacesNumber: this.paging.maxPlacesNumber.strictValue,
        };

        this._navigation.search(this._activeRoute, params);
    }

    private load() {
        const form = builderStrictToModel(WlRateRaceListPagingForm, this.paging);
        this._service
            .list(form)
            .pipe(take(1))
            .subscribe((res) => {
                this.result = res;
                this.mapToTable(res.results);
            });
    }

    private mapToTable(models: WlRateRaceTournamentListModel[]): void {
        const data = new GaTableData<WlRateRaceTournamentListModel>();

        this.tableData = data
            .addRouterLinkColumn(
                (elem) => elem.id,
                (elem) => ['/menu/raterace/update', elem.id],
                { title: 'Id', widthSize: 80 }
            )
            .addSimpleColumn((elem) => elem.name, { title: 'Название', widthSize: 250 })
            .addSimpleColumn((elem) => elem.battlePassSeasonId ?? '-', { title: 'Сезон БП', widthSize: 100 })
            .addSimpleColumn((elem) => WlRateRaceStatesExtensions.format(elem.state), { title: 'Текущий статус', widthSize: 200 })
            .addSimpleColumn((elem) => elem.prizePlacesCount, { title: 'Призовых мест', widthSize: 150 })
            .addSimpleColumn((elem) => elem.entityActors.creatorAccountLogin, { title: 'Создано', widthSize: 150 })
            .addSimpleColumn((elem) => elem.entityActors.editorAccountLogin, { title: 'Изменено', widthSize: 150 })
            .addSimpleColumn((elem) => formatDateTime(elem.entityActors.createdDate), { title: 'Дата создания', widthSize: 130 })
            .addSimpleColumn((elem) => formatDateTime(elem.entityActors.modifiedDate), { title: 'Дата изменения', widthSize: 130 })
            .setData(models);
    }
}
