import { WlCompleteTaskTypes, WlFreeBetLootBoxListModel } from '../../autogen/BattlePass';
import { WlBattlePassFreeBetLootBoxAutocompleteStrategy } from '../strategies/wl-battle-pass-free-bet-loot-box-autocomplete.strategy';
import { WlBattlePassFreeBetLootBoxesScrollServiceAdapter } from '../services/wrapper-service/wl-battle-pass-free-bet-loot-boxes.scroll-service-adapter';
import { StrictFormControl } from '@koddington/ga-common';

export class WlLevelFreeBetLootBoxViewModel {
    constructor(public readonly _api: WlBattlePassFreeBetLootBoxesScrollServiceAdapter) {}

    public lootBox: StrictFormControl<WlFreeBetLootBoxListModel> = new StrictFormControl<WlFreeBetLootBoxListModel>();
    public completedTaskType: StrictFormControl<WlCompleteTaskTypes> = new StrictFormControl<WlCompleteTaskTypes>();
    public canChange = true;

    public strategy: WlBattlePassFreeBetLootBoxAutocompleteStrategy = new WlBattlePassFreeBetLootBoxAutocompleteStrategy(this._api);
}
