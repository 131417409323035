import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WlResult } from 'src/app/models/common/results/wl-result';
import { BattlePassRewardsService, WlBattlePassRewardLiteModel, WlBattlePassRewardSearchForm } from 'src/app/modules/autogen/BattlePass';

@Injectable({
    providedIn: 'root',
})
export class WlRateRaceWrapperService {
    constructor(private readonly _apiClient: BattlePassRewardsService) {}

    public search(body: WlBattlePassRewardSearchForm): Observable<WlResult<WlBattlePassRewardLiteModel[]>> {
        return this._apiClient.fullList(body);
    }
}
