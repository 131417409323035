import {isNullOrUndefined} from '@koddington/ga-common';

export function booleanToString(value: boolean): string {
  if (value) {
    return 'Да';
  }
  return 'Нет';
}

export function nullableBooleanToString(value?: boolean): string {
  if (isNullOrUndefined(value)) {
    return '';
  }

  return booleanToString(value);
}
