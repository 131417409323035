import { StrictFormControl } from '@koddington/ga-common';
import { WlBattlePassGameLootBoxesScrollServiceAdapter } from '../services/wrapper-service/wl-battle-pass-game-loot-boxes.scroll-service-adapter';
import { WlCompleteTaskTypes, WlGameLootBoxLiteModel } from '../../autogen/BattlePass';
import { WlBattlePassGameLootBoxesAutocompleteStrategy } from '../strategies/wl-battle-pass-game-loot-box-autocomplete.strategy';

export class WlLevelGameLootBoxViewModel {
    constructor(private readonly _api: WlBattlePassGameLootBoxesScrollServiceAdapter) {}

    public lootBox: StrictFormControl<WlGameLootBoxLiteModel> = new StrictFormControl<WlGameLootBoxLiteModel>();
    public completedTaskType: StrictFormControl<WlCompleteTaskTypes> = new StrictFormControl<WlCompleteTaskTypes>();
    public canChange = true;

    public strategy: WlBattlePassGameLootBoxesAutocompleteStrategy = new WlBattlePassGameLootBoxesAutocompleteStrategy(this._api);
}
