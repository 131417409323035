import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BattlePassService, WlCrmTaskSearchForm, WlTaskListModel } from '../../../autogen/BattlePass';
import { GaPagedResult, GaPagingForm } from '@koddington/ga-common';
import { GaTableData } from '@koddington/ga-common';

@Component({
    selector: 'app-wl-battle-pass-task-list',
    templateUrl: './wl-battle-pass-task-list.component.html',
    styleUrls: ['./wl-battle-pass-task-list.component.scss'],
})
export class WlBattlePassTaskListComponent implements OnInit, OnDestroy {
    crmTask: GaPagedResult<WlTaskListModel>;
    formGroup: FormGroup;
    loading: boolean;

    tableData: GaTableData<WlTaskListModel>;
    constructor(private readonly _battlePassService: BattlePassService, private readonly _fb: FormBuilder, private readonly _router: Router) {}

    get term(): FormControl {
        return this.formGroup.get('term') as FormControl;
    }

    public ngOnInit(): void {
        this.buildFormGroup();
        this.load();
    }

    public ngOnDestroy(): void {}

    public load(offset: number = 0): void {
        this.loading = true;
        const filter = new WlCrmTaskSearchForm();
        filter.count = 30;
        filter.offset = offset;
        filter.term = this.term.valid ? this.term.value : '';

        this._battlePassService
            .listCrmTask(filter)
            .pipe(take(1))
            .subscribe((res) => {
                this.crmTask = res;
                this.mapToTable();
                this.loading = false;
            });
    }

    public pageChanged(page: GaPagingForm): void {
        this.load(page.offset);
    }

    public add(): void {
        this._router.navigate(['/menu/battlePass/task/edit']);
    }

    private buildFormGroup() {
        this.formGroup = this._fb.group({
            term: this._fb.control(''),
        });
    }

    private mapToTable(): void {
        const data = new GaTableData<WlTaskListModel>();

        this.tableData = data
            .addRouterLinkColumn(
                (elem) => elem.id,
                (elem) => ['edit', elem.id],
                { title: 'Код', widthSize: 100 }
            )
            .addSimpleColumn((elem) => elem.name, { title: 'Название', widthSize: 500 })
            .addSimpleColumn((elem) => elem.description, { title: 'Описание', widthSize: 590 })
            .addSimpleColumn((elem) => elem.seasonId, { title: 'Сезон', widthSize: 100 })
            .addSimpleColumn((elem) => elem.levelId, { title: 'Уровень', widthSize: 100 })
            .setData(this.crmTask.results);
    }
}
