import { Injectable, OnDestroy } from '@angular/core';
import { DropdownItem, EnumExtensions, IDropdownStrategy, isNullOrUndefined } from '@koddington/ga-common';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { WlCrmTaskTargetTypes, WlCrmTaskTargetTypesExtensions } from '../../autogen/BattlePass';

@Injectable()
export class WlBattlePassTargetTypeStrategy implements IDropdownStrategy<WlCrmTaskTargetTypes>, OnDestroy {

    private fnRules: () => WlCrmTaskTargetTypes[] = null;
    private source$ = new BehaviorSubject<Array<WlCrmTaskTargetTypes>>([]);

    constructor(private readonly ignoreEntitys: (() => Array<WlCrmTaskTargetTypes> | null)) {
        this.fnRules = ignoreEntitys;
        this.updateEntities();
    }

    ngOnDestroy(): void {
        this.source$.unsubscribe();
    }

    public updateEntities(): void {
        let entities = EnumExtensions.toArray(WlCrmTaskTargetTypes);
        entities = entities.filter((u) => u !== WlCrmTaskTargetTypes.None);
        if (!isNullOrUndefined(this.fnRules)) {
            entities = entities.filter((u) => !this.fnRules()?.includes(u));
        }
        this.source$.next(entities);
    }

    getEntities(): Observable<Array<WlCrmTaskTargetTypes>> {
        return this.source$.asObservable().pipe(untilDestroyed(this));
    }

    map: (model: WlCrmTaskTargetTypes) => DropdownItem<WlCrmTaskTargetTypes> = model => {
        const item = new DropdownItem<WlCrmTaskTargetTypes>();
        item.entity = model;
        item.title = WlCrmTaskTargetTypesExtensions.format(model);
        item.id = Number(model);

        return item;
    }
}
