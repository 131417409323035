<div class="wl-container">
    <app-wl-page-header header="{{purpose}}"></app-wl-page-header>
    <app-wl-loading *ngIf="loading"></app-wl-loading>
    <div class="wl-button-row">
        <button class="wl-button-primary wl-margin-right-8" [disabled]="!isValid || isFinished" (click)="save()">{{ saveButtonText }}</button>
        <div class="wl-row-table" *ngIf="!isPromotionExist">
            <label class="wl-label wl-switch-label" [ngClass]="{ 'wl-active': !isPickemEnabled }">Daily Task</label>
            <div class="wl-toggle-switch">
                <label class="switch">
                    <input type="checkbox" [(ngModel)]="isPickem.strictValue">
                    <span class="slider round"></span>
                </label>
            </div>
            <label class="wl-label wl-switch-label" [ngClass]="{ 'wl-active': isPickemEnabled }">Pick'em</label>
        </div>
        <button *ngIf="isSavedPickemPromotion" class="wl-button-primary" (click)="openPromoPlacesTable()">Таблица результатов</button>
    </div>
    <app-wl-strict-input-text
            [control]="viewModel.name"
            class="wl-row"
            title="Название"
            placeholder="Введите наименование акции"
            (valueChange)="userManipulationsSource.next()">
    </app-wl-strict-input-text>
    <div *ngIf="!isPickemEnabled">
        <app-wl-strict-input-number
                [control]="viewModel.freeBetId"
                class="wl-row"
                [title]="'Id фрибета'"
                placeholder="Введите id фрибета"
                (valueChange)="userManipulationsSource.next()">
        </app-wl-strict-input-number>
    </div>
    <app-wl-strict-input-number
            [control]="viewModel.daysCount"
            class="wl-row"
            [title]="'Количество дней'"
            placeholder="Введите количество дней"
            (valueChange)="userManipulationsSource.next()">
    </app-wl-strict-input-number>
    <app-wl-datetime-single-picker
            [date]="viewModel.startDate"
            class="wl-row"
            title="Дата начала акции"
            (userChosen)="userManipulationsSource.next()">
    </app-wl-datetime-single-picker>
    <app-wl-datetime-single-picker
            [date]="viewModel.technicalEndDate"
            class="wl-row"
            title="Дата технического завершения"
            (userChosen)="userManipulationsSource.next()">
    </app-wl-datetime-single-picker>
    <app-wl-datetime-single-picker
            [date]="viewModel.endDate"
            class="wl-row"
            title="Дата завершения акции"
            (userChosen)="userManipulationsSource.next()">
    </app-wl-datetime-single-picker>
    <app-wl-strict-input-text
            [control]="viewModel.headerColor"
            class="wl-row"
            title="Цвет заголовка"
            placeholder="Введите цвет заголовка"
            (valueChange)="userManipulationsSource.next()">
    </app-wl-strict-input-text>
    <app-wl-strict-input-text
            [control]="viewModel.headerImageUrl"
            class="wl-row"
            title="Url картинки"
            placeholder="Введите url картинки заголовка"
            (valueChange)="userManipulationsSource.next()">
    </app-wl-strict-input-text>
    <app-wl-strict-input-text
            [control]="viewModel.textColor"
            class="wl-row"
            title="Цвет текста"
            placeholder="Введите цвет текста"
            (valueChange)="userManipulationsSource.next()">
    </app-wl-strict-input-text>
    <div *ngIf="isPickemEnabled">
        <app-strict-scroll-autocomplete-new class="wl-row"
                                            label="Дополнительное задание"
                                            [targetRouterLink]="routerLinkForCrmTask"
                                            (valueChange)="userManipulationsSource.next()"
                                            [strategy]="crmTaskGroupStrategy"
                                            [control]="viewModel.task"
        ></app-strict-scroll-autocomplete-new>
    </div>
    <div *ngIf="isPickemEnabled">
        <app-wl-daily-task-file-picker
                title="Список пользователей"
                class="wl-row"
                [pathControl]="viewModel.responsiblePhonesFile"
                [fileNameControl]="viewModel.usersListFileName"
                [fileLoaded]="viewModel.fileLoaded"
                [onChangeSubj]="userManipulationsSource">
        </app-wl-daily-task-file-picker>
    </div>
    <div *ngIf="isSavedPickemPromotion">
        <div class="wl-button-container">
            <a [href]="downloadUserFileUrl" download class="wl-a-button-export">Скачать список</a>
        </div>
    </div>
    <app-wl-strict-input-boolean
            [control]="viewModel.isLightTheme"
            class="wl-row"
            label="Светлая тема"
            (userSelect)="userManipulationsSource.next()">
    </app-wl-strict-input-boolean>
    <div class="wl-daily-tasks-days-selector" *ngFor="let dayVm of days; let i = index">
        <div class="wl-row-main">
            <div class="wl-column">
                <a>День № {{ i + 1 }}</a>
                <app-strict-scroll-autocomplete-new
                        [targetRouterLink]="routeToDay(dayVm.day.strictValue)"
                        [label]="'Выберите день'"
                        [control]="dayVm.day"
                        [strategy]="dayVm.strategy"
                        (userFinalSelected)="recalculateTechnicalEndDate()">
                </app-strict-scroll-autocomplete-new>
            </div>
            <div class="wl-column">
                <div class="wl-row-main">
                    Начало дня: {{ getDayStartDate(dayVm.day.strictValue) }}
                </div>
                <div class="wl-row-main">
                    Завершение дня: {{ getDayEndDate(dayVm.day.strictValue) }}
                </div>
                <div class="wl-row-main">
                    Статус: {{ getDayState(dayVm.day.strictValue) }}
                </div>
            </div>
        </div>
        <div class="wl-row">
            <button class="wl-btn-form-inactive" type="button" [disabled]="!canDeleteDay(i)" (click)="deleteDay(i)">Удалить</button>
        </div>

        <div *ngIf="hasPickem(dayVm)">
            <div *ngIf="dayWinOutcome(dayVm); let outcomeInfo" class="wl-daily-tasks-days-selector">
                <div class="wl-text-colour-green">
                    Исход рассчитан
                </div>
                <ng-container [ngTemplateOutlet]="pickemOutcomeCard" [ngTemplateOutletContext]="{outcomeInfo: outcomeInfo}"></ng-container>
                <ng-container [ngTemplateOutlet]="pickemOutcomeInfoPublisher" [ngTemplateOutletContext]="{dayVm: dayVm}"></ng-container>
            </div>

            <div *ngIf="!dayWinOutcome(dayVm) && !!dayVm.pickemSelectorInfo">
                <div class="wl-text-colour-red">
                    Исход не рассчитан
                </div>
                <div *ngIf="dayVm?.day?.strictValue?.pickemInfo">
                    <button class="wl-btn-form-active wl-margin-bottom-4" type="button" (click)="changeShowPickemFlag(dayVm)">Раскрыть/скрыть выбор прогноза</button>
                    <div *ngIf="dayVm.pickemSelectorInfo.showPickemForecastSelector">
                        <div class="wl-daily-tasks-days-selector" *ngFor="let outcomeInfo of dayVm?.day?.strictValue?.pickemInfo?.outcomes">
                            <ng-container [ngTemplateOutlet]="pickemOutcomeCard" [ngTemplateOutletContext]="{outcomeInfo: outcomeInfo}"></ng-container>
                            <div class="wl-row">
                                <button class="wl-btn-form-active" type="button"
                                        [disabled]="!canSelectWinOutcome(dayVm) || isOutcomeSelected(dayVm, outcomeInfo.id)"
                                        (click)="selectWinOutcome(dayVm, outcomeInfo.id)">
                                    {{ getSelectWinOutcomeBtnText(dayVm, outcomeInfo.id) }}
                                </button>
                            </div>
                        </div>

                        <ng-container [ngTemplateOutlet]="pickemOutcomeInfoPublisher" [ngTemplateOutletContext]="{dayVm: dayVm}"></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="wl-row">
        <button class="wl-btn-form-active" [disabled]="isFinished" type="button" (click)="addItem()">Добавить день</button>
    </div>
</div>

<ng-template #pickemOutcomeCard let-outcomeInfo="outcomeInfo">
    <div class="wl-daily-tasks-days-selector">
        <div class="wl-row">
            Описание исхода: {{outcomeInfo?.description}}
        </div>
        <div class="wl-row">
            <img class="fixed-size-image" [src]="outcomeInfo?.imageUrl" alt="(Картинка с таким Url не найдена)">
        </div>
    </div>
</ng-template>

<ng-template #pickemOutcomeInfoPublisher let-dayVm="dayVm">
    <app-wl-strict-input-text
            [control]="dayVm.pickemSelectorInfo.description"
            class="wl-row"
            title="Описание выбора"
            placeholder="Введите описание выбора"
            (valueChange)="userManipulationsSource.next()">
    </app-wl-strict-input-text>

    <button class="wl-btn-form-active" type="button"
            [disabled]="!canSetWinOutcome(dayVm)"
            (click)="setWinOutcome(dayVm)">
        Подтвердить выбор
    </button>
</ng-template>
