import { StrictFormControl } from '@koddington/ga-common';
import { WlRateRaceRewardViewModel } from './wl-rate-race-reward-view-model';
import { WlRewardEditViewModel } from './wl-reward-edit-view-model';

export class WlRateRaceLootBoxViewModel {
    public id: StrictFormControl<number> = new StrictFormControl<number>(null);
    public name: StrictFormControl<string> = new StrictFormControl<string>(null);
    public description: StrictFormControl<string> = new StrictFormControl<string>(null);
    public imageUrl: StrictFormControl<string> = new StrictFormControl<string>(null);
    public game: StrictFormControl<string> = new StrictFormControl<string>(null);
    public contentsCount: StrictFormControl<number> = new StrictFormControl<number>(null);
    public content: StrictFormControl<WlRateRaceRewardViewModel[]> = new StrictFormControl<WlRateRaceRewardViewModel[]>(null);
    public editContent: StrictFormControl<WlRewardEditViewModel[]> = new StrictFormControl<WlRewardEditViewModel[]>(null);
}
