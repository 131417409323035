import { DropdownItem, EnumExtensions, IDropdownStrategy } from '@koddington/ga-common';
import { Observable, of } from 'rxjs';
import { WlCrmBetsGroupingTypes, WlCrmBetsGroupingTypesExtensions } from '../../autogen/BattlePass';

export class WlBattlePassCrmBetsGroupingTypesStrategy implements IDropdownStrategy<WlCrmBetsGroupingTypes> {
    getEntities(): Observable<Array<WlCrmBetsGroupingTypes>> {
        const entities = EnumExtensions.toArray(WlCrmBetsGroupingTypes);
        return of(entities);
    }

    map: (model: WlCrmBetsGroupingTypes) => DropdownItem<WlCrmBetsGroupingTypes> = model => {
        const item = new DropdownItem<WlCrmBetsGroupingTypes>();
        item.entity = model;
        item.title = WlCrmBetsGroupingTypesExtensions.format(model);
        item.id = Number(model);

        return item;
    }
}
