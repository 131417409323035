import { Injectable } from '@angular/core';
import { DropdownItem, IDropdownStrategy } from '@koddington/ga-common';
import { Observable, of } from 'rxjs';
import { WlRateRaceStatusModeration, WlRateRaceStatusModerationExtensions } from 'src/app/modules/autogen/BattlePass';

@Injectable({
    providedIn: 'root',
})
export class WlRateRaceStatusModerationStrategy implements IDropdownStrategy<WlRateRaceStatusModeration> {
    getEntities(): Observable<Array<WlRateRaceStatusModeration>> {
        const entities = [WlRateRaceStatusModeration.Accept, WlRateRaceStatusModeration.Reject, WlRateRaceStatusModeration.Undefined];
        return of(entities);
    }

    map: (model: WlRateRaceStatusModeration) => DropdownItem<WlRateRaceStatusModeration> = model => {
        const item = new DropdownItem<WlRateRaceStatusModeration>();
        item.entity = model;
        item.title = WlRateRaceStatusModerationExtensions.format(model);
        item.id = Number(model);

        return item;
    }
}
