import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { WlRateRaceRewardScrollStrategy } from '../../strategies/wl-rate-race-reward-scroll-strategy';
import { WlRewardEditViewModel } from '../../models/wl-reward-edit-view-model';
import { WlRateRaceWrapperService } from '../../services/wrapper-service/wl-rate-race-wrapper-service';
import { Subject } from 'rxjs';
import { WlRateRaceRewardValidator } from './validator/wl-rate-race-reward-validator';
import { WlRateRaceRewardsSearchViewModel } from '../../models/wl-rate-race-rewards-search-view-model';
import { GaConfirmationService, GaPagedResult, GaPagingForm, GaTableCellTemplate, GaTableData, isNullOrUndefined, StrictError, StrictFormControl } from '@koddington/ga-common';
import { BattlePassRateRaceService, WlBattlePassRewardLiteModel, WlRateRaceLootBoxContentModel, WlRateRaceRewardsPagingForm } from 'src/app/modules/autogen/BattlePass';

@Component({
    selector: 'app-wl-rate-race-rewards-list',
    templateUrl: './wl-rate-race-rewards-list.component.html',
    styleUrls: ['./wl-rate-race-rewards-list.component.scss'],
})
export class WlRateRaceRewardsListComponent implements OnInit, OnDestroy {
    @ViewChild('exchangeEditing') exchangeEditing: TemplateRef<any>;
    @ViewChild('isEditing') isEditing: TemplateRef<any>;
    @ViewChild('rewardEditing') rewardEditing: TemplateRef<any>;
    @Input()
    public lootBoxId: StrictFormControl<number> = new StrictFormControl<number>(null);
    @Input()
    public editContent: StrictFormControl<WlRewardEditViewModel[]> = new StrictFormControl<WlRewardEditViewModel[]>(null);
    @Output()
    public valueChange = new EventEmitter<boolean>();
    public viewModel: GaPagedResult<WlRateRaceLootBoxContentModel>;
    public loading: boolean;
    public tableData: GaTableData<WlRewardEditViewModel>;
    public readonly userManipulationsSource = new Subject<void>();
    public errors: StrictError[] = [];
    public validator = new WlRateRaceRewardValidator();
    public searchViewModel: WlRateRaceRewardsSearchViewModel = new WlRateRaceRewardsSearchViewModel();
    private isShowDialog: boolean = false;
    private rewards = new Map<number, WlRewardEditViewModel>();

    constructor(
        private readonly _service: BattlePassRateRaceService,
        private readonly _confirmation: GaConfirmationService,
        private readonly _rateRaceServiceWrapper: WlRateRaceWrapperService
    ) {}

    public ngOnInit(): void {
        this.load(0);
    }

    public ngOnDestroy(): void {}

    public pageChanged(page: GaPagingForm): void {
        if (this.isShowDialog) {
            this._confirmation.openDialog('На странице вносились изменения. Сохранить изменения?', 'Да', 'Нет').subscribe((isAccepted) => {
                this.isShowDialog = false;
                if (isAccepted) {
                    this.valueChange.emit(true);
                }
                this.load(page.offset);
            });
        } else {
            this.load(page.offset);
        }
    }

    public isEditingByOrder(order: number): StrictFormControl<boolean> {
        if (!this.rewards.has(order)) return;

        const reward = this.rewards.get(order);
        return reward.isEditing;
    }

    public getByOrder(order: number): StrictFormControl<WlBattlePassRewardLiteModel> {
        if (!this.rewards.has(order)) return;

        const reward = this.rewards.get(order);
        return reward.reward;
    }

    public getStrategyByOrder(order: number): WlRateRaceRewardScrollStrategy {
        if (!this.rewards.has(order)) return;

        const reward = this.rewards.get(order);
        return reward.strategy;
    }

    public getExchangeByOrder(order: number): StrictFormControl<number> {
        if (!this.rewards.has(order)) return;

        const reward = this.rewards.get(order);
        return reward.exchangeAmount;
    }

    public isCheckByOrder(order: any): boolean {
        if (order % 1 !== 0) return false;

        if (!this.rewards.has(order)) return false;

        const reward = this.rewards.get(order);
        reward.isEditing.valueChanges.pipe(take(1)).subscribe((u) => {
            if (u) {
                this.isShowDialog = true;
                this.userManipulationsSource.pipe(take(1)).subscribe(() => {
                    this.errors = this.validator.validate(reward);
                });
            }
            return false;
        });

        return reward.isEditing.strictValue;
    }

    public getExchange(order: number): string {
        if (!this.rewards.has(order)) return;

        const reward = this.rewards.get(order);
        return reward.exchangeAmount.hasStrictValue ? reward.exchangeAmount.strictValue.toString() : '';
    }

    public getName(order: number): string {
        if (!this.rewards.has(order)) return;

        const reward = this.rewards.get(order);
        return reward.reward.hasStrictValue ? reward.reward.strictValue.name : '';
    }

    private load(offset: number = 0): void {
        this.loading = true;
        const filter = this.createFilterForm(offset);
        this.rewards = new Map<number, WlRewardEditViewModel>();

        this._service
            .rewardsList(filter)
            .pipe(
                take(1),
                finalize(() => (this.loading = false))
            )
            .subscribe((res) => {
                this.viewModel = res;
                this.editContent.strictValue = this.mapContentToEditViewModel(this.viewModel.results);
                this.editContent.strictValue.forEach((reward) => {
                    this.rewards.set(reward.order.strictValue, reward);
                });
                this.mapToTable();
            });
    }

    private mapContentToEditViewModel(result: WlRateRaceLootBoxContentModel[]): WlRewardEditViewModel[] {
        if (isNullOrUndefined(result)) return [];
        const editViewModel: WlRewardEditViewModel[] = result.map((u) => {
            let rrEditViewModel = new WlRewardEditViewModel(this._rateRaceServiceWrapper);
            rrEditViewModel.order.strictValue = u.order;
            rrEditViewModel.exchangeAmount.strictValue = u.exchangeAmount;
            const rewardMapp = new WlBattlePassRewardLiteModel();
            rewardMapp.id = u.reward.id;
            rewardMapp.name = u.reward.name;
            rrEditViewModel.reward.strictValue = rewardMapp;
            return rrEditViewModel;
        });
        return editViewModel;
    }

    private mapToTable(): void {
        const data = new GaTableData<WlRewardEditViewModel>();

        this.tableData = data
            .addTemplateColumn(
                new GaTableCellTemplate(this.isEditing, (elem) => {
                    return {
                        order: elem.order.strictValue,
                    };
                }),
                { title: 'Изменение', widthSize: 110 }
            )
            .addSimpleColumn((elem) => (elem.reward.hasStrictValue ? elem.reward.strictValue.id : ''), { title: 'Id награды', widthSize: 110 })
            .addTemplateColumn(
                new GaTableCellTemplate(this.rewardEditing, (elem) => {
                    return {
                        order: elem.order.strictValue,
                    };
                }),
                { title: 'Наименование', widthSize: 900 }
            )
            .addTemplateColumn(
                new GaTableCellTemplate(this.exchangeEditing, (elem) => {
                    return {
                        order: elem.order.strictValue,
                    };
                }),
                { title: 'Стоимость обмена', widthSize: 150 }
            )
            .addSimpleColumn((elem) => elem.order.strictValue, { title: 'Номер', widthSize: 110 })
            .setData(this.editContent.strictValue);
    }

    private createFilterForm(offset: number): WlRateRaceRewardsPagingForm {
        let filter = new WlRateRaceRewardsPagingForm();
        filter.offset = offset;
        filter.count = this.searchViewModel.count.strictValue;
        filter.lootBoxId = this.lootBoxId.strictValue;

        if (this.searchViewModel.id.hasStrictValue) filter.rewardId = this.searchViewModel.id.strictValue;

        if (this.searchViewModel.name.hasStrictValue) filter.name = this.searchViewModel.name.strictValue;

        return filter;
    }

    get isValid(): boolean {
        return this.errors.length === 0;
    }
}
