import { StrictFormControl } from '@koddington/ga-common';
import { WlGameLootBoxTypes } from '../../autogen/BattlePass';
import { WlBattlePassLootBoxSelectorViewModel } from './wl-battle-pass-loot-box-selector-view-model';


export class WlBattlePassLootBoxViewModel {
    public id: StrictFormControl<number> = new StrictFormControl<number>(null);
    public name: StrictFormControl<string> = new StrictFormControl<string>(null);
    public description: StrictFormControl<string> = new StrictFormControl<string>(null);
    public imageUrl: StrictFormControl<string> = new StrictFormControl<string>(null);
    public gameType: StrictFormControl<WlGameLootBoxTypes> = new StrictFormControl<WlGameLootBoxTypes>(null);
    public grade: StrictFormControl<string> = new StrictFormControl<string>(null);
    public averageFreebet: StrictFormControl<number> = new StrictFormControl<number>(null);
    public rewards: StrictFormControl<WlBattlePassLootBoxSelectorViewModel[]> =
        new StrictFormControl<WlBattlePassLootBoxSelectorViewModel[]>([]);
    public sumProbability: StrictFormControl<number> = new StrictFormControl<number>(null);
}
