import { Injectable } from '@angular/core';
import { WlResult } from 'src/app/models/common/results/wl-result';
import { Observable } from 'rxjs';
import { WlCrmTaskGroupSearchModel, WlCrmTaskSearchForm } from 'src/app/modules/autogen/BattlePass';
import { BattlePassService } from 'src/app/modules/autogen/BattlePass';

@Injectable({
    providedIn: 'root',
})
export class WlBattlePassWrapperService {
    constructor(private readonly _apiClient: BattlePassService) {}

    public search(body: WlCrmTaskSearchForm): Observable<WlResult<WlCrmTaskGroupSearchModel[]>> {
        return this._apiClient.searchCrmTask(body);
    }
}
