import { IWlResultBase, WlError, WlErrorCodes } from '../../../modules/autogen/Shared';
import { GaResult } from '@koddington/ga-common';

export class WlResult<T> extends GaResult<T, WlErrorCodes> {
    get isCorrect(): boolean {
        return !this.error || this.error.code === WlErrorCodes.Ok;
    }

    constructor(data?: IWlResultBase) {
        super();
        if (data) {
            this.init(data);
        }
    }

    init(_data?: any) {
        if (_data) {
            this.error = _data["error"] ? WlError.fromJS(_data["error"]) : <any>undefined;
            (<any>this).isCorrect = _data["isCorrect"];
        }
    }
}
