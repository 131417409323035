import { AbstractModelValidator, AddErrorsToControlMode, StrictError, ValidationLevel } from '@koddington/ga-common';
import { WlBattlePassFreeBetLootBoxCrudViewModel } from '../view-models/wl-battle-pass-free-bet-loot-box-crud-view-model';
import { WlBattlePassFreeBetLootBoxContentValidator } from './wl-battle-pass-free-bet-loot-box-content-validator';

export class WlBattlePassFreeBetLootBoxCrudValidator extends AbstractModelValidator<WlBattlePassFreeBetLootBoxCrudViewModel> {
    private readonly _contentValidator = new WlBattlePassFreeBetLootBoxContentValidator();

    constructor() {
        super();

        this.ruleForControl(u => u.name)
            .required('Название лутбокса обязательно');

        this.ruleForControl(u => u.probabilitiesSum)
            .customRule(u => {
                return u === 1
                    ? null
                    : new StrictError('probabilitiesSumError', 'Сумма вероятностей не равна 1');
            });
    }

    public validate(model: WlBattlePassFreeBetLootBoxCrudViewModel, validationLevel?: ValidationLevel, addErrorsToControls?: AddErrorsToControlMode): StrictError[] {
        return [
            ...super.validate(model, validationLevel, addErrorsToControls),
            ...this._contentValidator.validateArray(model.lootBoxContents.strictValue, validationLevel, addErrorsToControls)
        ];
    }
}
