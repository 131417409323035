import {Observable, of} from 'rxjs';
import { WlBetPlatforms, WlBetPlatformsExtensions } from '../../autogen/BattlePass';
import { DropdownItem } from '../../../models/common/enum-dropdown/dropdown-item';
import { IDropdownStrategy } from '@koddington/ga-common';

export class WlBetPlatformsStrategy implements IDropdownStrategy<WlBetPlatforms> {
    private collection = [
        WlBetPlatforms.WebRu,
        WlBetPlatforms.CheckinPPS,
        WlBetPlatforms.IOS,
        WlBetPlatforms.Android,
        WlBetPlatforms.MobileRU,
        WlBetPlatforms.NewWebRu,
    ];

    constructor() {
    }

    public getEntities(): Observable<Array<WlBetPlatforms>> {
        return of(this.collection);
    }

    public map(model: WlBetPlatforms) : DropdownItem<WlBetPlatforms>{
        return {
            entity: model,
            title: WlBetPlatformsExtensions.format(model),
            id: model.toString()
        };
    }
}
