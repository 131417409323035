<div class="wl-container">
    <app-wl-page-header header="{{purpose}}"></app-wl-page-header>
    <app-wl-loading *ngIf="loading"></app-wl-loading>
    <div class="wl-template-row wl-rate-race-error has-errors-text" *ngFor="let error of viewModel.editContent.strictErrors | async">
        {{ error.message }}
    </div>
    <app-wl-strict-input-text title="Название"
                           class="wl-template-row"
                           (valueChange)="userManipulationsSource.next()"
                           [control]="viewModel.name">
    </app-wl-strict-input-text>
    <app-wl-strict-input-text title="Описание"
                           class="wl-template-row"
                           (valueChange)="userManipulationsSource.next()"
                           [control]="viewModel.description">
    </app-wl-strict-input-text>
    <app-wl-strict-input-text title="Ссылка на изображение"
                           class="wl-template-row"
                           (valueChange)="userManipulationsSource.next()"
                           [control]="viewModel.imageUrl">
    </app-wl-strict-input-text>
    <app-wl-strict-input-text title="Игра"
                           class="wl-template-row"
                           (valueChange)="userManipulationsSource.next()"
                           [control]="viewModel.game">
    </app-wl-strict-input-text>
    <app-wl-strict-input-number title="Количество наград"
                             class="wl-template-row"
                             (valueChange)="userManipulationsSource.next()"
                             [readonly]="isEdit"
                             [control]="viewModel.contentsCount">
    </app-wl-strict-input-number>
    <app-wl-import-rewards title="Импорт наград"
                           *ngIf="isEdit"
                           class="wl-template-row"
                           [lootBoxId]="viewModel.id"
                           [onChangeSubj]="userManipulationsSource">
    </app-wl-import-rewards>
    <app-wl-rate-race-rewards-list *ngIf="isEdit"
                                   [lootBoxId]="viewModel.id"
                                   [editContent]="viewModel.editContent"
                                   (valueChange)="onSave($event)"
    ></app-wl-rate-race-rewards-list>
    <button class="wl-button-primary" (click)="onSave()" [disabled]="!isValid">{{saveButtonText}}</button>
</div>
