<input
    type="text"
    [placeholder]="placeholder"
    [textContent]="currentItem?.title"
    [value]="currentItem?.title"
    [class.has-errors]="control.hasStrictErrors"
    (input)="onInputChange($event)"
    (focus)="onFocus()"
    (keydown)="onKeyDown($event)"
    [disabled]="isDisabled"
    [ngClass]="{ 'wl-search-settings-style': isSearch }"
/>
<div *ngIf="isActive">
    <div *ngIf="allowPickAll" (click)="pickAll()">Все</div>
    <div
        *ngFor="let item of currentItemsSource; index as i"
        (click)="onClick(item)"
        [class.focused]="this.currentFocus === i"
        [class.selected]="this.item.key === currentItem?.key"
    >
        {{ item.title }}
    </div>
</div>
