import { AbstractModelValidator } from '@koddington/ga-common';
import { WlPickemOutcomeSelectorViewModel } from '../models/wl-pickem-outcome-selector.view-model';

export class WlPickemOutcomeSelectorValidator extends AbstractModelValidator<WlPickemOutcomeSelectorViewModel> {

    constructor() {
        super();

        this.ruleForControl(u => u.pickemId)
            .requiredAndNotZero();

        this.ruleForControl(u => u.winOutcomeId)
            .requiredAndNotZero();

        this.ruleForControl(u => u.description)
            .notEmptyString();
    }
}
