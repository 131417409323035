import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {filter} from 'rxjs/operators';
import {WlBattlePassLootBoxViewModel} from '../../models/wl-battle-pass-loot-box-view-model';
import {WlBattlePassLootBoxSelectorViewModel} from '../../models/wl-battle-pass-loot-box-selector-view-model';
import {WlBattlePassGameLootBoxCrudValidator} from './validator/wl-battle-pass-game-loot-box-crud-validator';
import {WlBattlePassRewardStrategy} from '../../strategies/wl-battle-pass-reward-strategy';
import { WlBattlePassGameLootBoxTypesStrategy } from '../../strategies/wl-battle-pass-game-loot-box-types.strategy';
import { GaConfirmationService, GaMessagingService, isNullOrUndefined, StrictError } from '@koddington/ga-common';
import { BattlePassRewardsService, WlGameLootBoxContentForm, WlGameLootBoxCreateForm, WlGameLootBoxModel, WlGameLootBoxUpdateForm } from 'src/app/modules/autogen/BattlePass';
import { BattlePassGameLootBoxesService } from 'src/app/modules/autogen/BattlePass';

@Component({
    selector: 'app-wl-battle-pass-game-loot-box-crud',
    templateUrl: './wl-battle-pass-game-loot-box-crud.component.html',
    styleUrls: ['./wl-battle-pass-game-loot-box-crud.component.scss']
})
export class WlBattlePassGameLootBoxCrudComponent implements OnInit, OnDestroy {

    public loading: boolean;
    public viewModel: WlBattlePassLootBoxViewModel = new WlBattlePassLootBoxViewModel();
    public readonly userManipulationsSource = new Subject<void>();
    public errors: StrictError[] = [];
    public rewardStrategy: WlBattlePassRewardStrategy;
    private readonly validator = new WlBattlePassGameLootBoxCrudValidator();

    constructor(private readonly _messaging: GaMessagingService,
                private readonly _confirmation: GaConfirmationService,
                private readonly _router: Router,
                private readonly _route: ActivatedRoute,
                private readonly _titleService: Title,
                private readonly _battlePassLootBoxService: BattlePassGameLootBoxesService,
                private readonly _battlePassRewardsService: BattlePassRewardsService,
                public readonly gameTypesStrategy: WlBattlePassGameLootBoxTypesStrategy) { }

    ngOnInit(): void {
        this.loading = true;
        this.rewardStrategy = new WlBattlePassRewardStrategy(this._battlePassRewardsService);

        this.viewModel.id.strictValue = isNullOrUndefined(this._route.snapshot.params['id']) ? null : this._route.snapshot.params['id'];

        this.userManipulationsSource.pipe(untilDestroyed(this)).subscribe(() => {
            this.viewModel.sumProbability.strictValue =
                this.viewModel.rewards.strictValue.reduce((acc, curr) => {
                    return Math.round((acc + curr.probability.strictValue) * 10_000_000_000) / 10_000_000_000;
                }, 0);
            this.errors = this.validator.validate(this.viewModel);
        });

        this.load();
        this._titleService.setTitle('Создание / Редактирование лутбокса');
    }

    ngOnDestroy() {
    }

    public save(): void {
        this.loading = true;

        if (this.viewModel.id.hasStrictValue) {
            this.update();
        } else {
            this.create();
        }
    }

    public addItem(): void {
        const toAdd = new WlBattlePassLootBoxSelectorViewModel();
        this.viewModel.rewards.strictValue.push(toAdd);
        this.userManipulationsSource.next();
    }

    public deleteItem(index: number): void {
        if (this.viewModel.rewards.strictValue.length > 1) {
            this.viewModel.rewards.strictValue =
                this.viewModel.rewards.strictValue.filter((u, k) => k !== index);
            this.userManipulationsSource.next();
        }
    }

    private load(): void {
        if (this.viewModel.id.hasStrictValue) {
            this._battlePassLootBoxService.getLootBoxById(this.viewModel.id.value)
                .pipe(
                    filter((value) => !this._messaging.tryShowError(value)),
                    untilDestroyed(this)
                ).subscribe((result) => {
                this.loadModel(result.result);
                this.loading = false;
            });
        } else {
            this.addItem();
            this.userManipulationsSource.next();
            this.loading = false;
        }
    }

    private loadModel(model: WlGameLootBoxModel): void {
        this.viewModel.id.strictValue = model.id;
        this.viewModel.name.strictValue = model.name;
        this.viewModel.description.strictValue = model.description;
        this.viewModel.imageUrl.strictValue = model.imageUrl;
        this.viewModel.gameType.strictValue = model.gameType;
        this.viewModel.grade.strictValue = model.grade;
        this.viewModel.averageFreebet.strictValue = model.averageFreebet;
        this.viewModel.rewards.strictValue = model.lootBoxContent.map((val) => {
            const lootBoxItem = new WlBattlePassLootBoxSelectorViewModel();

            lootBoxItem.reward.strictValue = val.reward;
            lootBoxItem.probability.strictValue = val.probability;

            return lootBoxItem;
        });

        this.viewModel.sumProbability.strictValue =
            this.viewModel.rewards.strictValue.reduce((acc, curr) => acc + curr.probability.strictValue, 0);

        this.userManipulationsSource.next();
    }

    private create(): void {
        const form = this.createAddForm();
        this._battlePassLootBoxService
            .addLootBox(form)
            .pipe(
                filter((value) => !this._messaging.tryShowError(value)),
                untilDestroyed(this),
            ).subscribe(() => {
            this.showListLootBox();
            this.loading = false;
        });
    }

    private createAddForm(): WlGameLootBoxCreateForm {
        const form = new WlGameLootBoxCreateForm();

        form.name = this.viewModel.name.strictValue;
        form.description = this.viewModel.description.strictValue;
        form.imageUrl = this.viewModel.imageUrl.strictValue;
        form.game = this.viewModel.gameType.strictValue;
        form.grade = this.viewModel.grade.strictValue;
        form.averageFreebet = this.viewModel.averageFreebet.strictValue;
        form.contentForm = this.mapLootBoxItems();

        return form;
    }

    private update(): void {
        const form = this.createUpdateForm();
        this._battlePassLootBoxService
            .updateLootBox(form)
            .pipe(
                filter((value) => !this._messaging.tryShowError(value)),
                untilDestroyed(this)
            ).subscribe(() => {
            this.showListLootBox();
            this.loading = false;
        });
    }

    private createUpdateForm(): WlGameLootBoxUpdateForm {
        const form = new WlGameLootBoxUpdateForm(this.createAddForm());
        form.id = this.viewModel.id.strictValue;

        return form;
    }

    private mapLootBoxItems(): WlGameLootBoxContentForm[] {

        return this.viewModel.rewards.strictValue.map((val) => {
            const lootBoxContentForm = new WlGameLootBoxContentForm();

            lootBoxContentForm.rewardId = val.reward.strictValue.id;
            lootBoxContentForm.probability = val.probability.strictValue;

            return lootBoxContentForm;
        });
    }

    private showListLootBox(): void {
        this._router.navigate(['/menu/battlePass/lootBox']);
    }

    get purpose(): string {
        if (this.viewModel.id.hasStrictValue) {
            return `Редактирование игрового лутбокса № ${this.viewModel.id.strictValue}`;
        }
        return 'Создание игрового лутбокса';
    }

    get isValid(): boolean {
        return this.errors.length === 0;
    }

    get saveButtonText(): string {
        if (this.viewModel.id.hasStrictValue) {
            return 'Обновить';
        }
        return 'Сохранить';
    }
}
