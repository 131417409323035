import { Injectable, OnDestroy } from '@angular/core';
import { IAutocompleteItem } from '@koddington/ga-common';
import { WlFreeBetLootBoxListModel, WlFreeBetLootBoxSearchForm } from '../../autogen/BattlePass';
import { WlBattlePassFreeBetLootBoxesScrollServiceAdapter } from '../services/wrapper-service/wl-battle-pass-free-bet-loot-boxes.scroll-service-adapter';
import { ScrollStrategy } from '../../../common/abstracts/abstract-scroll-strategy';

@Injectable()
export class WlBattlePassFreeBetLootBoxAutocompleteStrategy extends ScrollStrategy<WlFreeBetLootBoxListModel, WlFreeBetLootBoxSearchForm> implements OnDestroy {
    constructor(protected readonly _apiClient: WlBattlePassFreeBetLootBoxesScrollServiceAdapter) {
        super(_apiClient);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public convert(model: WlFreeBetLootBoxListModel): IAutocompleteItem<WlFreeBetLootBoxListModel> {
        return this.convert_(() => {
            return {
                entity: model,
                title: `[${model.id.toString()}] ${model.name}`,
                key: model.id?.toString(),
            };
        });
    }

    public clear(): void {
        super._sourceClearAutocomplete$.next();
    }
}
