import { Injectable, OnDestroy } from '@angular/core';
import { IAutocompleteItem, isNullOrUndefined } from '@koddington/ga-common';
import { WlRateRaceListPagingForm } from '../../autogen/BattlePass';
import { WlRateRaceTournamentListModel } from '../../autogen/BattlePass';
import { WlBattlePassRateRacesListScrollServiceAdapter } from '../services/wrapper-service/wl-battle-pass-rate-races-list-scroll-service-adapter';
import { ScrollStrategy } from '../../../common/abstracts/abstract-scroll-strategy';

@Injectable()
export class WlBattlePassRateRacesAutocompleteStrategy extends ScrollStrategy<WlRateRaceTournamentListModel, WlRateRaceListPagingForm> implements OnDestroy {
    constructor(protected readonly _apiClient: WlBattlePassRateRacesListScrollServiceAdapter) {
        super(_apiClient);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public convert(model: WlRateRaceTournamentListModel): IAutocompleteItem<WlRateRaceTournamentListModel> {
        return this.convert_(() => {
            return {
                entity: model,
                title: (!isNullOrUndefined(model.battlePassSeasonId) ? `[Сезон ${model.battlePassSeasonId}]` : '') + ` [${model.id.toString()}] ${model.name}`,
                key: model.id?.toString(),
            };
        });
    }

    public clear(): void {
        super._sourceClearAutocomplete$.next();
    }
}
