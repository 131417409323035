import { AbstractModelValidator, StrictError } from '@koddington/ga-common';
import { PatternConsts } from 'src/app/common/consts/pattern-consts';
import { WlBattlePassFreeBetLootBoxContentsViewModel } from '../view-models/wl-battle-pass-free-bet-loot-box-contents-view-model';

export class WlBattlePassFreeBetLootBoxContentValidator extends AbstractModelValidator<WlBattlePassFreeBetLootBoxContentsViewModel> {
    private readonly minScarValue: number = 1;
    private readonly maxScarValue: number = 10;

    constructor() {
        super();

        this.ruleForControl(u => u.probability)
            .required('Вероятность обязательна!')
            .biggerThan(0)
            .max(1);

        this.ruleForControl(u => u.amount)
            .required('Сумма обязательна!')
            .biggerThan(0)
            .customRule((field) => {
                if (PatternConsts.DecimalDigit.test(field?.toString())) {
                    return null;
                }

                return new StrictError('decimalDigitError', 'Количество знаков после запятой не должно превышать 2');
            });

        this.ruleForControl(u => u.scarcity)
            .required('Поле редкость (Scarcity) обязательно!');

        this.ruleForControl(u => u.scarValue)
            .required('Поле редкость (Scar Value) обязательно!')
            .min(this.minScarValue)
            .max(this.maxScarValue);
    }
}
