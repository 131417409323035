import { Routes } from '@angular/router';
import {
  WlAuthGuardService,
  WlLogoutGuardService,
} from './components/authorize/guards/auth-guard-service';
import { LayoutsModule } from '../layouts/layouts.module';
import { WlDefaultPage } from './components/default/default-page.component';
import { WlPageNotFoundComponent } from './components/page-not-found/app-page-not-found.component';
import { WlLoginComponent } from './components/authorize/login/app-login-component';
import { WlLogoutComponent } from './components/authorize/logout/app-logout-component';
import { RootComponent } from './components/root/root.component';
import { MainLayoutComponent } from '../layouts/main-layout/main-layout.component';

export const RootRoutes: Routes = [
  {
    path: '',
    component: RootComponent,
    children: [
      {
        path: 'menu',
        component: MainLayoutComponent,
        loadChildren: () => LayoutsModule,
        canActivate: [WlAuthGuardService],
      },
      {
        path: 'login',
        component: WlLoginComponent,
      },
      {
        path: 'logout',
        component: WlLogoutComponent,
        canActivate: [WlLogoutGuardService],
      },
      {
        path: 'default-page',
        component: WlDefaultPage,
      },
      {
        path: '**',
        component: WlPageNotFoundComponent,
        pathMatch: 'full',
      },
    ],
  },
];
