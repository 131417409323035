import {StrictFormControl} from '@koddington/ga-common';


export class PartnersBtagSelectorViewModel {

  public btagId: StrictFormControl<number> = new StrictFormControl<number>(null);
  public btagName: StrictFormControl<string> = new StrictFormControl<string>(null);
  public sourceId: StrictFormControl<number> = new StrictFormControl<number>(null);
  public channelId: StrictFormControl<number> = new StrictFormControl<number>(null);
  public sourceName: StrictFormControl<string> = new StrictFormControl<string>(null);
  public channelName: StrictFormControl<string> = new StrictFormControl<string>(null);
  public isBtagEnabled: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
  public isSourceEnabled: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
  public isChannelEnabled: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
}
