<div class="wl-login-workspace-container">
    <div class="wl-login-content">
        <label>Добро пожаловать {{ login }}</label>
        <ng-container *ngIf="!isLogin">
            <div>Вы не авторизованы на портале</div>
            <div>Вы можете войти в систему</div>
            <button
                    class="wl-btn-form-active wl-margin-bottom-4"
                    [routerLink]="['/login']"
            >
                Войти
            </button>
            <div class="error no-401"></div>
        </ng-container>
        <ng-container *ngIf="isLogin">
            <button
                    class="wl-btn-form-active wl-margin-bottom-4"
                    [routerLink]="['/menu']"
            >
                Перейти в меню
            </button>
        </ng-container>
    </div>
</div>
