import { Injectable } from '@angular/core';
import { WlResult } from 'src/app/models/common/results/wl-result';
import { Observable } from 'rxjs';
import { BattlePassService, WlSeasonListModel, WlSeasonSearchForm } from 'src/app/modules/autogen/BattlePass';
import { map } from 'rxjs/operators';
import { IScrollService } from 'src/app/common/abstracts/types/scroll-service-interface';

@Injectable({
    providedIn: 'root'
})
export class WlBattlePassSeasonsListScrollServiceAdapter implements IScrollService<WlSeasonListModel, WlSeasonSearchForm> {

    constructor(private _service: BattlePassService) {
    }

    public search(item: WlSeasonSearchForm): Observable<WlResult<WlSeasonListModel[]>> {
        return this._service.listSeason(item).pipe(
            map(u => {
                const res = new WlResult<WlSeasonListModel[]>();
                res.result = u.results;
                return res;
            })
        );
    }
}
