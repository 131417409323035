import {Directive, Injectable, OnDestroy} from '@angular/core';
import {IAutocompleteItem, isNullOrUndefined} from '@koddington/ga-common';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {Observable, of} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {WlCrmTaskGroupSearchModel, WlCrmTaskSearchForm} from '../../autogen/BattlePass';
import {WlBattlePassWrapperService} from '../services/wrapper-service/wl-battle-pass-wrapper-service';
import {ScrollStrategy} from '../../../common/abstracts/abstract-scroll-strategy';

@Directive()
export class WlBattlePassCRMTaskScrollStrategy extends ScrollStrategy<WlCrmTaskGroupSearchModel, WlCrmTaskSearchForm> implements OnDestroy {
    constructor(protected readonly _apiClient: WlBattlePassWrapperService,
                private readonly formSetter: (u: WlCrmTaskSearchForm) => void) {
        super(_apiClient);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public convert(model: WlCrmTaskGroupSearchModel): IAutocompleteItem<WlCrmTaskGroupSearchModel> {
        let prefix: string = model.id.toString();
        if (!isNullOrUndefined(model.levelId)) {
            prefix += `, ур. БП: ${model.levelId}`;
        }

        return this.convert_(() => {
            return {
                entity: model,
                title: `[${prefix}] ${model.name}`,
                key: model.id?.toString(),
            };
        });
    }

    public clear(): void {
        super._sourceClearAutocomplete$.next();
    }

    protected load(item: WlCrmTaskSearchForm, fnRules: (form: WlCrmTaskSearchForm) => boolean = null): Observable<any> {
        this.formSetter(item);

        if (!isNullOrUndefined(fnRules)) {
            if (fnRules(item)) {
                return of([]);
            }
        }

        return this._apiClient.search(item).pipe(
            filter((v) => v.isCorrect),
            map((result) => result.result),
            untilDestroyed(this)
        );
    }
}
