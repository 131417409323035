import { StrictFormControl } from '@koddington/ga-common';
import {Dayjs} from 'dayjs';
import { WlRateRaceCurrentLevelModel, WlRateRaceStatusModeration } from '../../autogen/BattlePass';

export class WlRateRaceNickNameEditViewModel {
    userId?: number;
    isModerate: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
    nickname?: string | undefined;
    statusModeration?: StrictFormControl<WlRateRaceStatusModeration> = new StrictFormControl<WlRateRaceStatusModeration>(null);
    atUpdateModeration?: Dayjs;
    moderator: string;
    seasonId?: number;
    currentLevel?: WlRateRaceCurrentLevelModel[] = [];
}
