import { StrictFormControl } from '@koddington/ga-common';
import { ThrottlingSearchModel, WlBattlePassTaskTypes, WlCrmTaskTargetTypes, WlNotificationTemplate } from '../../autogen/BattlePass';
import { WlCrmTaskBetsGroupingViewModel } from './wl-crm-task-bets-grouping-view-model';
import { WlCrmTaskCollectExpressViewModel } from './wl-crm-task-collect-express-view-model';
import { WlCrmTaskViewModel } from './wl-crm-task-view-model';
import { WlCrmTaskGroupEventsViewModel } from './wl-crm-task-group-events-view-model';

export class WlCrmTaskGroupViewModel {
    public id: StrictFormControl<number> = new StrictFormControl<number>(null);
    public name: StrictFormControl<string> = new StrictFormControl<string>(null);
    public description: StrictFormControl<string> = new StrictFormControl<string>(null);
    public bullets: StrictFormControl<string> = new StrictFormControl<string>(null);
    public forceComplete: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
    public isRecurrent: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
    public collectExpressViewModel: WlCrmTaskCollectExpressViewModel = new WlCrmTaskCollectExpressViewModel();
    public groupViewModel: WlCrmTaskBetsGroupingViewModel = new WlCrmTaskBetsGroupingViewModel();
    public deeplink: StrictFormControl<string> = new StrictFormControl<string>(null);
    public imageUrl: StrictFormControl<string> = new StrictFormControl<string>(null);
    public imageWebUrl: StrictFormControl<string> = new StrictFormControl<string>(null);
    public taskType: StrictFormControl<WlBattlePassTaskTypes> = new StrictFormControl<WlBattlePassTaskTypes>(null);
    public targetType: StrictFormControl<WlCrmTaskTargetTypes> = new StrictFormControl<WlCrmTaskTargetTypes>(null);
    public targetIndicator: StrictFormControl<number> = new StrictFormControl<number>(null);
    public targetIndicatorType: StrictFormControl<string> = new StrictFormControl<string>(null);
    public limitProgressByDays: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
    public progressIntervalSize: StrictFormControl<number> = new StrictFormControl<number>(null);
    public tasks: StrictFormControl<WlCrmTaskViewModel[]> = new StrictFormControl<WlCrmTaskViewModel[]>([]);
    public switcherEvents: WlCrmTaskGroupEventsViewModel = new WlCrmTaskGroupEventsViewModel();
    public template: StrictFormControl<WlNotificationTemplate> = new StrictFormControl<WlNotificationTemplate>(null);
    public throttling: StrictFormControl<ThrottlingSearchModel> = new StrictFormControl<ThrottlingSearchModel>(null);
}
