import { Component, OnDestroy, OnInit } from '@angular/core';
import { WlAuthStateService } from '../services/auth-state-service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { isNullOrUndefined } from '@koddington/ga-common';
import { Router } from '@angular/router';
import { AuthValidator } from '../validators/auth-validator';
import { LoginViewModel } from '../view-models/wl-login-view-model';

@Component({
  selector: 'app-login',
  templateUrl: './app-login-component.html',
  styleUrls: ['./app-login-component.scss'],
})
export class WlLoginComponent implements OnInit, OnDestroy {
  public viewModel: LoginViewModel = new LoginViewModel();
  private readonly validator: AuthValidator;
  constructor(
    private readonly _authStateService: WlAuthStateService,
    private readonly _router: Router
  ) {
    this.validator = new AuthValidator();
  }
  ngOnInit(): void {
    this._authStateService.userState
      .pipe(untilDestroyed(this))
      .subscribe((u) => {
        if (!isNullOrUndefined(u?.login)) {
          this._router.navigate(['menu']);
        }
      });
  }
  ngOnDestroy(): void {}

  public login(): void {
    const error = this.validator.validate(this.viewModel);
    if (error.length) return;

    this._authStateService.login(
      this.viewModel.login.strictValue,
      this.viewModel.password.strictValue
    );
  }
}
