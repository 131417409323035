import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'wlStringJoin'
})
export class WlStringJoinPipe implements PipeTransform {
    transform(values: (string | number)[]): string {
        return values?.join(', ');
    }
}
