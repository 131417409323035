<app-wl-page-header header="Профиль игрока / привязка к партнёрам"></app-wl-page-header>
<div class="wl-search-row">
    <app-wl-strict-input-number
            class="wl-app-input-search-wrapper wl-margin-left-4"
            [control]="searchModel.userId"
            [isSearch]="true"
            placeholder="Id пользователя"
            (searchMethod)="search()"
            (keydown.enter)="$event.target.blur(); search(); (false)"
    >
    </app-wl-strict-input-number>
    <app-wl-strict-input-number
            class="wl-app-input-search-wrapper wl-margin-left-4"
            [control]="searchModel.partnerId"
            [isSearch]="true"
            placeholder="Id партнёра"
            (searchMethod)="search()"
            (keydown.enter)="$event.target.blur(); search(); (false)"
    >
    </app-wl-strict-input-number>
</div>
<div *ngIf="isShowPrompt">* Для отображения привязки партнёров к пользователю - вводить только логин </div>
<div *ngIf="isShowPrompt">* Для отображения профиля пользователя - вводить логин и id партнёра </div>

<ga-table *ngIf="!!tableData && result?.userPartners?.length > 0" [data]="tableData" [breakWordOnly]="true"></ga-table>

<div *ngIf="result?.partnerUserInfo">
<h4 class="wl-span-header-text">Общая информация игрока: </h4>
<div class="wl-userinfo-container">
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Дата регистрации: </span>
        <span class="wl-userinfo-field-value">
            {{result.partnerUserInfo.registrationDate | wlDateTime}}
        </span>
    </div>

    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Дата идентификации: </span>
        <span class="wl-userinfo-field-value">
            {{result.partnerUserInfo.identificationDate | wlDateTime}}
        </span>
    </div>

    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Дата первого депозита: </span>
        <span class="wl-userinfo-field-value">
            {{result.partnerUserInfo.firstDepositDate | wlDateTime}}
        </span>
    </div>

    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Дата первого депозита после привязки партнёра: </span>
        <span class="wl-userinfo-field-value">
            {{result.partnerUserInfo.fitstDepositAfterbindDate | wlDateTime}}
        </span>
    </div>

    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Дата реактивационного депозита: </span>
        <span class="wl-userinfo-field-value">
            {{result.partnerUserInfo.reactivatedDepositDate | wlDateTime}}
        </span>
    </div>

    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Дата последнего депозита: </span>
        <span class="wl-userinfo-field-value">
             {{result.partnerUserInfo.lastDepositDate | wlDateTime}}
        </span>
    </div>

    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Дата первой ставки: </span>
        <span class="wl-userinfo-field-value">
             {{result.partnerUserInfo.firstBetDate | wlDateTime}}
        </span>
    </div>

    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Дата изменения статуса CRM = 0: </span>
        <span class="wl-userinfo-field-value">
             {{result.partnerUserInfo.crm0Date | wlDateTime}}
        </span>
    </div>

    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Дата изменение статуса РФ и 18+ legal: </span>
        <span class="wl-userinfo-field-value">
             {{result.partnerUserInfo.legalDate | wlDateTime}}
        </span>
    </div>

    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Id Btag: </span>
        <span class="wl-userinfo-field-value">
            {{result.partnerUserInfo.bTagId}}
        </span>
    </div>

    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Id Promo: </span>
        <span class="wl-userinfo-field-value">
            {{result.partnerUserInfo.promoGroupId}}
        </span>
    </div>

    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Дата последнего обновления данных: </span>
        <span class="wl-userinfo-field-value">
            {{result.partnerUserInfo.lastUpdate | wlDateTime}}
        </span>
    </div>
</div>
</div>

<br>
<h4 *ngIf="result?.tasksInfo" class="wl-span-header-text">Подписки на бук. задания: </h4>
<div *ngIf="result?.tasksInfo && result?.tasksInfo?.length < 1" class="wl-userinfo-container"> Отсутствуют подписки на бук. задания у выбранного партнёра</div>

<div *ngIf="!result?.userPartners && result?.tasksInfo?.length > 0">
    <ga-table *ngIf="!!taskInfoTableData" [data]="taskInfoTableData" [breakWordOnly]="true"></ga-table>
</div>
