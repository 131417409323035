import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { isNullOrUndefined } from '@koddington/ga-common';

@Injectable({
    providedIn: 'root',
})
export class WlDialogService {
    constructor(private _dialog: MatDialog) {}

    openDialog<T, D = any, R = any>(componentRef: ComponentType<T>, data: any, widthPixels: number = 1500, heightPixels: number = null): Observable<any> {
        const config: MatDialogConfig<D> = {
            width: widthPixels + 'px',
            data: data,
            maxHeight: '90vh'
        };

        if (!isNullOrUndefined(heightPixels)) {
            config['height'] = heightPixels + 'px';
        }

        return this._dialog
            .open(componentRef, config)
            .afterClosed()
            .pipe(take(1));
    }
}
