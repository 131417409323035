import {AbstractModelValidator, AddErrorsToControlMode, StrictError, ValidationLevel} from '@koddington/ga-common';
import {DailyTasksLootBoxViewModel} from '../view-models/daily-tasks-loot-box.view-model';
import {DailyTasksLootBoxesGradesValidator} from './daily-tasks-loot-boxes-grades-validator';

export class DailyTasksLootBoxesCrudValidator extends AbstractModelValidator<DailyTasksLootBoxViewModel> {
    private readonly maximumNameLength: number = 2000;
    private readonly gradesValidator = new DailyTasksLootBoxesGradesValidator();

    constructor() {
        super();

        this.ruleForControl(u => u.name)
            .requiredAndNotZero('Название обязательно!')
            .maxLength(2000, `"Длина имени лутбокса не должна превышать ${this.maximumNameLength} символов`);

        this.ruleForControl(u => u.grades)
            .required('Грейды должны быть заполнены')
            .minLength(1, 'Грейды должны быть заполнены')
            .customRule(u => {
                return u.some(v => v.gradeStart.strictValue === 0)
                    ? null
                    : new StrictError('gradeStartError', 'Один из грейдов должен стартовать с суммы в 0 рублей');
            });
    }

    public validate(model: DailyTasksLootBoxViewModel, validationLevel?: ValidationLevel, addErrorsToControls?: AddErrorsToControlMode): StrictError[] {
        return [
            ...super.validate(model, validationLevel, addErrorsToControls),
            ...this.gradesValidator.validateArray(model.grades.strictValue, validationLevel, addErrorsToControls)
        ];
    }
}
