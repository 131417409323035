import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  template: '',
})
export class WlLogoutComponent implements OnInit, OnDestroy {
  constructor() {}
  ngOnInit(): void {}
  ngOnDestroy(): void {}
}
