import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { isNullOrUndefined } from '@koddington/ga-common';
import { WlAuthStateService } from '../services/auth-state-service';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Injectable({
  providedIn: 'root',
})
export class WlAuthGuardWrapperService {
  constructor(
    private readonly _authRequestService: WlAuthStateService,
    private readonly _router: Router
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this._authRequestService.userState.pipe(
      take(1),
      map((u) => {
        if (isNullOrUndefined(u) || isNullOrUndefined(u.login)) {
          if (localStorage.getItem('login')) return true;
          this._router.navigate(['default-page']);
        }
        return true;
      })
    );
  }
}
