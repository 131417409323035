import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { isNullOrUndefined, StrictFormControl } from '@koddington/ga-common';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
    selector: 'app-wl-strict-input-number',
    templateUrl: './strict-input-number.component.html',
    styleUrls: ['./strict-input-number.component.scss'],
})
export class StrictInputNumberComponent implements OnInit, OnDestroy {
    @Input()
    public isSearch = false;
    @Input()
    public control: StrictFormControl<number>;
    @Input()
    public placeholder = '';
    @Input()
    public step: number;
    @Input()
    public min: number;
    @Input()
    public max: number;
    @Input()
    public title = '';
    @Input()
    public className = '';
    @Input()
    public readonly = false;
    @Output()
    valueChange = new EventEmitter<void>();
    @Output()
    searchMethod = new EventEmitter<void>();

    constructor() {}

    ngOnInit(): void {
        const minNum = Number(this.min);
        const maxNum = Number(this.max);
        if (!isNullOrUndefined(minNum) && !isNullOrUndefined(maxNum)) {
            if (minNum > maxNum) {
                throw new Error('Incorrect range');
            }
        }

        if (!isNullOrUndefined(minNum)) {
            this.control.strictValueSource.pipe(untilDestroyed(this)).subscribe(() => {
                if (this.control.hasStrictValue && this.control.strictValue < minNum) {
                    this.control.strictValue = minNum;
                    this.valueChange.emit();
                }
            });
        }

        if (!isNullOrUndefined(maxNum)) {
            this.control.strictValueSource.pipe(untilDestroyed(this)).subscribe(() => {
                if (this.control.hasStrictValue && this.control.strictValue > maxNum) {
                    this.control.strictValue = maxNum;
                    this.valueChange.emit();
                }
            });
        }
    }

    public ngOnDestroy(): void {}
}
