import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { GaCommonModule } from '@koddington/ga-common';
import { DictionariesRoutes } from './app-dictionaries-module.routes';
import { WlPartnerRegistriesCrudComponent } from './components/partner-registries-crud/app-partner-registries-crud.component';
import { PartnerRegistriesListComponent } from './components/partner-registries-list/partner-registries-list.component';
import { PartnerRewardsCrudComponent } from './components/partner-rewards-crud/partner-rewards-crud.component';
import { PartnerRewardsListComponent } from './components/partner-rewards-list/partner-rewards-list.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    RouterModule.forChild(DictionariesRoutes),
    GaCommonModule,
  ],
  declarations: [
    WlPartnerRegistriesCrudComponent,
    PartnerRegistriesListComponent,
    PartnerRewardsCrudComponent,
    PartnerRewardsListComponent
  ],
  exports: [WlPartnerRegistriesCrudComponent],
  providers: [],
})
export class WlDictionariesModule {}
