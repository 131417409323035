import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found.component',
  templateUrl: './app-page-not-found.component.html',
  styleUrls: ['./app-page-not-found.component.scss'],
})
export class WlPageNotFoundComponent implements OnInit, OnDestroy {
  ngOnInit(): void {}
  ngOnDestroy(): void {}
}
