import { BattlePassRewardsService, WlBattlePassRewardModel, WlBattlePassRewardSearchForm } from '../../autogen/BattlePass';
import { BehaviorSubject, Observable } from 'rxjs';
import { DropdownItem } from '../../../models/common/enum-dropdown/dropdown-item';
import { IDropdownStrategy, isNullOrUndefined } from '@koddington/ga-common';
import { filter, map, take } from 'rxjs/operators';

export class WlBattlePassRewardStrategy implements IDropdownStrategy<WlBattlePassRewardModel> {
    private _source = new BehaviorSubject<WlBattlePassRewardModel[]>([]);
    private _searchForm = new WlBattlePassRewardSearchForm();

    constructor(private readonly _battlePassRewardsService: BattlePassRewardsService) {
        this.load();
    }

    public getEntities(): Observable<Array<WlBattlePassRewardModel>> {
        return this._source;
    }

    public map(model: WlBattlePassRewardModel): DropdownItem<WlBattlePassRewardModel> {
        if (isNullOrUndefined(model)) {
            return {
                entity: model,
                title: '',
                id: null,
            };
        }
        return {
            entity: model,
            title: model.name,
            id: model.id.toString(),
        };
    }

    private load(): void {
        if (isNullOrUndefined(this._searchForm)) {
            this._source.next([]);
            return;
        }

        this._battlePassRewardsService
            .fullList(this._searchForm)
            .pipe(
                take(1),
                filter((res) => !isNullOrUndefined(res) && res.isCorrect),
                map((result) => result.result)
            )
            .subscribe((u) => this._source.next(u));
    }
}
