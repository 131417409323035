import { AbstractModelValidator } from '@koddington/ga-common';
import { LoginViewModel } from '../view-models/wl-login-view-model';

export class AuthValidator extends AbstractModelValidator<LoginViewModel> {
  constructor() {
    super();
    this.ruleForControl((u) => u.login)
      .required('Укажите Логин')
      .minLength(1, 'Логин не ддолжен быть пустым');
    this.ruleForControl((u) => u.password)
      .required('Укажите Пароль')
      .minLength(1, 'Пароль не должен быть пустым');
  }
}
