<div class="wl-container">
    <app-wl-page-header header="{{purpose}}"></app-wl-page-header>
    <app-wl-loading *ngIf="loading"></app-wl-loading>
    <div class="wl-rate-race-button-header">
        <button class="wl-button-primary" [disabled]="!isValid" (click)="save()">{{saveButtonText}}</button>
    </div>
    <app-wl-strict-input-text class="wl-rate-race-row"
                           [control]="viewModel.name"
                           title="Название"
                           placeholder="Введите название турнира"
                           (valueChange)="userManipulationsSource.next()"
                           [className]="'wl-battle-pass-task-name'">
    </app-wl-strict-input-text>
    <app-wl-strict-input-text class="wl-rate-race-row"
                           [control]="viewModel.description"
                           title="Описание"
                           placeholder="Введите описание турнира"
                           (valueChange)="userManipulationsSource.next()"
                           [className]="'wl-battle-pass-task-name'">
    </app-wl-strict-input-text>
    <app-wl-strict-input-text class="wl-rate-race-row"
                           title="ImageUrl"
                           (valueChange)="userManipulationsSource.next()"
                           [control]="viewModel.imageUrl">
    </app-wl-strict-input-text>
    <app-wl-strict-input-number class="wl-rate-race-row"
                             title="Количество призовых мест"
                             (valueChange)="userManipulationsSource.next()"
                             [control]="viewModel.prizePlacesCount">
    </app-wl-strict-input-number>
    <app-strict-scroll-autocomplete-new class="wl-rate-race-row"
                                        placeholder="Выберите сезон"
                                        [label]="'Сезон'"
                                        [isSearch]="false"
                                        [control]="viewModel.battlePassSeason"
                                        (valueChange)="userManipulationsSource.next()"
                                        [strategy]="seasonAutoCompleteStrategy"
    ></app-strict-scroll-autocomplete-new>
    <div class="wl-rate-race-row">
        <app-wl-datetime-single-picker [date]="viewModel.startDate"
                                       title="Дата начала"
                                       (userChosen)="userManipulationsSource.next()"
        ></app-wl-datetime-single-picker>
    </div>

    <div class="wl-rate-race-row wl-rate-race-error">
        <div *ngFor="let error of gradeErrors | async">
            {{ error.message }}
        </div>
    </div>
    <div *ngFor="let grade of viewModel.gradeDrops.strictValue; index as i">
        <div class="wl-rate-race-row wl-rate-race-grade-selector-body">
            <app-wl-strict-input-number title="Минимальная сумма грейда"
                                     (valueChange)="userManipulationsSource.next()"
                                     [control]="grade.minAmount">
            </app-wl-strict-input-number>
            <app-strict-scroll-autocomplete-new placeholder="Выберите лутбокс"
                                                [label]="'Лутбокс'"
                                                [isSearch]="false"
                                                [control]="grade.rateRaceLootBox"
                                                [strategy]="grade.rateRaceLootBoxStrategy"
                                                (valueChange)="userManipulationsSource.next()"
            ></app-strict-scroll-autocomplete-new>
            <app-wl-strict-input-boolean
                    label="Для начисления"
                    (userSelect)="userManipulationsSource.next()"
                    [control]="grade.isAccrualLootBox"
            >
            </app-wl-strict-input-boolean>
        </div>
        <div *ngIf="canDeleteGrade">
            <button class="wl-btn-form-inactive wl-button-position" type="button" (click)="deleteGrade(i)">Удалить грейд</button>
        </div>
    </div>
    <button *ngIf="canAddGrade" class="wl-btn-form-active" type="button" (click)="addGrade()">Добавить грейд</button>
    <div class="wl-rate-race-button-footer">
        <button class="wl-button-primary" [disabled]="!isValid" (click)="save()">{{saveButtonText}}</button>
    </div>
</div>
