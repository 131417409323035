<div class="wl-season-level-selector">
    <div class="wl-season-level-selector-header">
        <h5 class="wl-season-level-column one-col">Уровень {{ level.order }} (Id уровня - {{ level.id }})</h5>
        <h5 *ngIf="level.isGamblerLevel" class="wl-season-level-column two-col wl-season-gambler-level-caption">Режим лудки</h5>
    </div>
    <div class="wl-season-level-selector-header">
        <div class="wl-season-level-column one-col">
            <div class="wl-level-view-row">
                <span class="wl-field-title">Название: </span>
                <span class="wl-field-text">{{ levelName }}</span>
            </div>
            <div class="wl-level-view-row">
                <span class="wl-field-title">Дата старта уровня: </span>
                <span class="wl-field-text">{{ startDate | dateTime }}</span>
            </div>
            <div class="wl-level-view-row">
                <span class="wl-field-title">Иконка: </span>
                <span class="wl-field-text">{{ level.iconUrl }}</span>
            </div>
        </div>
        <div class="wl-season-level-column two-col">
            <div class="wl-level-view-row">
                <span class="wl-field-title">Описание: </span>
                <span class="wl-field-text">{{ level.description }}</span>
            </div>
            <div class="wl-level-view-row">
                <span class="wl-field-title">Изображение: </span>
                <span class="wl-field-text">{{ level.imageUrl }}</span>
            </div>
            <div class="wl-level-view-row">
                <span class="wl-field-title">Неактивная иконка: </span>
                <span class="wl-field-text">{{ level.inactiveIconUrl }}</span>
            </div>
        </div>
    </div>
    <div class="wl-season-level-selector-block">
        <div *ngIf="hasCrmTaskGroups" class="wl-season-level-column one-col">
            <div class="wl-season-level-task-order">Букмекерские задания</div>
            <div *ngFor="let crm of crmTaskGroups">
                <div class="wl-season-level-task-column-item" *ngIf="!crmTaskEmpty(crm)">
                    <span class="wl-season-level-task-order">
                        {{ crm.order }}
                    </span>
                    <span class="wl-season-level-task-order">
                        <a class="wl-level-task-wrap-text" [routerLink]="['/menu/battlePass/task/edit', crm.taskGroup.id]">{{ crm.taskGroup.name }}</a>
                    </span>
                    <span *ngIf="crm.taskGroup.isWildCard" class="wl-season-level-task-wildcard-label"> (WildCard) </span>
                </div>
            </div>
        </div>
        <div *ngIf="hasGameTasks" class="wl-season-level-column two-col">
            <div class="wl-season-level-task-order">Игровые задания</div>
            <div *ngFor="let game of gameTasks">
                <div class="wl-season-level-column-item" *ngIf="!gameTaskEmpty(game)">
                    <span class="wl-season-level-task-order">
                        {{ game.order }}
                    </span>
                    <span>
                        <a class="wl-level-task-wrap-text" [routerLink]="['/menu/battlePass/gameTask/edit', game.task.id]">{{ game.task.name }}</a>
                    </span>
                    <span *ngIf="game.task.isWildCard" class="wl-season-level-task-wildcard-label"> (WildCard) </span>
                </div>
            </div>
        </div>
        <div class="wl-season-level-column three-col">
            <div class="empty-class"></div>
            <div class="wl-season-level-column-item">
                <button class="wl-btn-form-inactive wl-season-level-task-button" *ngIf="canDelete" type="button" (click)="delete()">Удалить</button>
            </div>
            <div class="wl-season-level-column-item">
                <button class="wl-btn-form-active wl-season-level-task-button" type="button" (click)="edit()">Редактировать</button>
            </div>
        </div>
    </div>
    <div class="wl-season-level-selector-block">
        <div *ngIf="hasTemplate" class="wl-season-level-column one-col">
            <div class="wl-season-level-task-order">Push шаблон рассылки о награде</div>
            <span class="wl-season-level-footer-span">
                <a class="wl-level-task-wrap-text" [routerLink]="['/push/templates/update', templateId]">{{ templateName }}</a>
            </span>
        </div>
    </div>
    <div class="wl-season-level-selector-block">
        <div *ngIf="hasGameLootBoxes" class="wl-season-level-column one-col">
            <div class="wl-season-level-task-order">Игровые лутбоксы</div>
            <div class="wl-season-loot-boxes-column-item" *ngFor="let gameLootBox of gameLootBoxes">
                <div class="wl-lootbox-item">
                    {{ gameLootBox.completeTaskType | wlCompleteTaskTypesTransform }}
                </div>
                <div class="wl-lootbox-item">
                    <a class="wl-lootbox-text" [routerLink]="['/menu/battlePass/lootBox/edit', gameLootBox.id]">{{ gameLootBox.name }}</a>
                </div>
                <div class="wl-lootbox-item">
                    {{ gameLootBox.gameType | wlGameLootBoxTypesTransform }}
                </div>
            </div>
        </div>
        <div *ngIf="hasFreeBetLootBoxes" class="wl-season-level-column two-col">
            <div class="wl-season-level-task-order">Фрибетные лутбоксы</div>
            <div class="wl-season-loot-boxes-freebet-column-item" *ngFor="let freeBetLootBox of freeBetLootBoxes">
                <div class="wl-lootbox-item">
                    {{ freeBetLootBox.completeTaskType | wlCompleteTaskTypesTransform }}
                </div>
                <div class="wl-lootbox-item">
                    <a class="wl-lootbox-text" [routerLink]="['/menu/battlePass/freeBetLootBox/edit', freeBetLootBox.id]">{{ freeBetLootBox.name }}</a>
                </div>
            </div>
        </div>
    </div>
</div>
