import { Routes } from '@angular/router';
import { TrackerModule } from '../trackers/app-tracker.module';
import { WlDictionariesModule } from '../dictionaries/app-dictionaries.module';
import { WlBattlePassModule } from '../battle-pass/wl-battle-pass.module';
import { RateRaceModule } from '../rate-race-area51/rate-race-area51.module';
import { DailyTasksModule } from '../daily-tasks/daily-tasks.module';

export const MainLayoutRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'dictionary',
        loadChildren: () => WlDictionariesModule,
      },
      {
        path: 'tracker',
        loadChildren: () => TrackerModule,
      },
      {
        path: 'battlePass',
        loadChildren: () => WlBattlePassModule,
      },
      {
        path: 'raterace',
        loadChildren: () => RateRaceModule,
      },
      {
        path: 'dailyTasks',
        loadChildren: () => DailyTasksModule,
      },
    ],
  },
];
