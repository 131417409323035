import { isNullOrUndefined, StrictFormControl } from '@koddington/ga-common';
import { WlBattlePassWrapperService } from '../services/wrapper-service/wl-battle-pass-wrapper-service';
import { WlCrmTaskGroupSearchModel } from '../../autogen/BattlePass';
import { WlBattlePassCRMTaskScrollStrategy } from '../strategies/wl-battle-pass-crm-task-scroll-strategy';

export class WlCrmTaskLiteViewModel {
    constructor(private readonly _api: WlBattlePassWrapperService, private isRecurrent: boolean) {}

    public order: StrictFormControl<number> = new StrictFormControl<number>(null);
    public task: StrictFormControl<WlCrmTaskGroupSearchModel> = new StrictFormControl<WlCrmTaskGroupSearchModel>(null);
    public strategy: WlBattlePassCRMTaskScrollStrategy = new WlBattlePassCRMTaskScrollStrategy(this._api, u => u.isRecurrent = this.isRecurrent);
    public isWildCard: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
    public canChange = true;

    get isEmpty() {
        return isNullOrUndefined(this.order.strictValue) || isNullOrUndefined(this.task.strictValue);
    }
}
