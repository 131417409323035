import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-wl-confirmation-modal',
    templateUrl: './wl-confirmation-modal.component.html',
    styleUrls: ['./wl-confirmation-modal.component.scss'],
})
export class WlConfirmationModalComponent {
    message = '';
    acceptMessage = '';
    cancelMessage = '';

    constructor(private dialogRef: MatDialogRef<WlConfirmationModalComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmationModalData) {
        this.message = data.message;
        this.acceptMessage = data.acceptMessage || 'Принять';
        this.cancelMessage = data.cancelMessage || 'Отмена';
    }

    onCancelClick() {
        this.dialogRef.close(false);
    }

    onAcceptClick() {
        this.dialogRef.close(true);
    }
}

export class ConfirmationModalData {
    message: string;
    acceptMessage: string;
    cancelMessage: string;
}
