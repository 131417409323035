import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-wl-loading',
    templateUrl: './wl-loading.component.html',
    styleUrls: ['./wl-loading.component.scss'],
})
export class WlLoadingComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
