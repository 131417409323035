import {Component, OnDestroy, OnInit} from '@angular/core';
import {GaSearchManagementStateService} from '../../../../shared/extensions/search-state-managment';
import {GaPagedResult} from '@koddington/ga-common/lib/results/results';
import {DailyTasksLootBoxesService, WlDtFreeBetLootBoxListModel, WlDtFreeBetLootBoxSearchForm} from '../../../../autogen/DailyTasks';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {formatDateTime, GaPagingForm, GaTableData} from '@koddington/ga-common';
import {DailyTasksLootBoxesSearchViewModel} from './view-models/daily-tasks-loot-boxes-search.view-model';

@Component({
    selector: 'app-daily-tasks-loot-boxes-list',
    templateUrl: './daily-tasks-loot-boxes-list.component.html',
    providers: [GaSearchManagementStateService]
})
export class DailyTasksLootBoxesListComponent implements OnInit, OnDestroy {
    public searchViewModel = new DailyTasksLootBoxesSearchViewModel();

    protected pagedResult: GaPagedResult<WlDtFreeBetLootBoxListModel>;
    protected tableData: GaTableData<WlDtFreeBetLootBoxListModel>;

    constructor(private readonly _service: DailyTasksLootBoxesService,
                private readonly _search: GaSearchManagementStateService<GaPagedResult<WlDtFreeBetLootBoxListModel>>) {
    }

    ngOnInit(): void {
        this._search.init(u => this._service.list(u as WlDtFreeBetLootBoxSearchForm), this.searchViewModel);
        this._search.searchState.pipe(untilDestroyed(this)).subscribe(res => {
            this.pagedResult = res;
            this.mapTable(res.results);
        });
    }

    ngOnDestroy(): void {}

    protected pageChanged(paging: GaPagingForm): void {
        this._search.nextSearch(paging);
    }

    protected pageFiltered(): void {
        this._search.nextSearch(null, true);
    }

    private mapTable(results: WlDtFreeBetLootBoxListModel[]): void {
        this.tableData = new GaTableData<WlDtFreeBetLootBoxListModel>()
            .addRouterLinkColumn(u => u.id, u => ['/menu/dailyTasks/loot-boxes/update', u.id], {title: 'Id', widthSize: 100})
            .addSimpleColumn(u => u.name, {title: 'Название', widthSize: 560})
            .addSimpleColumn(u => u.gradesCount, {title: 'Грейды', widthSize: 100})
            .addSimpleColumn(u => u.entityActors.creatorAccountLogin, { title: 'Создано', widthSize: 150 })
            .addSimpleColumn(u => u.entityActors.editorAccountLogin, { title: 'Изменено', widthSize: 150 })
            .addSimpleColumn(u => formatDateTime(u.entityActors.createdDate), { title: 'Дата создания', widthSize: 150 })
            .addSimpleColumn(u => formatDateTime(u.entityActors.modifiedDate), { title: 'Дата изменения', widthSize: 150 })
            .setData(results);
    }

    get loading(): boolean {
        return this._search.loadingStatus;
    }
}
