<app-wl-page-header header="Сезоны боевого пропуска"></app-wl-page-header>
<div class="wl-template-row">
    <button class="wl-button-primary wl-btn-push-save-wrapper" (click)="add()">Добавить сезон</button>
</div>
<div class="wl-search-row">
    <app-wl-strict-input-number
        class="wl-app-input-search-wrapper wl-margin-right-8"
        [control]="viewSearchModel.id"
        [isSearch]="true"
        placeholder="Id сезона"
        (searchMethod)="pageFiltered($event)"
        (keydown.enter)="$event.target.blur(); pageFiltered($event); (false)"
    >
    </app-wl-strict-input-number>
    <app-wl-strict-input-text
        class="wl-default-size-search-autocomplete"
        [control]="viewSearchModel.term"
        [isSearch]="true"
        placeholder="Наименование сезона"
        (searchMethod)="pageFiltered($event)"
        (keydown.enter)="$event.target.blur(); pageFiltered($event); (false)"
    >
    </app-wl-strict-input-text>
</div>
<app-wl-loading *ngIf="this.loading"></app-wl-loading>
<ga-table *ngIf="!!tableData" [data]="tableData"></ga-table>
<div class="wl-paging-wrapper">
    <app-wl-paging *ngIf="result" [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></app-wl-paging>
</div>
