<div class="wl-container">
    <app-wl-page-header header="{{purpose}}"></app-wl-page-header>
    <app-wl-loading *ngIf="loading"></app-wl-loading>
    <div class="wl-task-button-footer">
        <button class="wl-button-primary" [disabled]="!isValid"
                (click)="save()">{{saveButtonText}}</button>
    </div>
    <div class="wl-loot-box-row">
        <app-wl-strict-input-text title="Название"
                               (valueChange)="userManipulationsSource.next()"
                               [control]="viewModel.name">
        </app-wl-strict-input-text>
    </div>
    <div class="wl-loot-box-row">
        <app-wl-strict-input-text title="Описание"
                               (valueChange)="userManipulationsSource.next()"
                               [control]="viewModel.description">
        </app-wl-strict-input-text>
    </div>
    <div class="wl-loot-box-row">
        <app-wl-strict-input-text title="Ссылка на изображение"
                               (valueChange)="userManipulationsSource.next()"
                               [control]="viewModel.imageUrl">
        </app-wl-strict-input-text>
    </div>
    <div class="wl-loot-box-row">
        <app-wl-dropdown [options]="{title: 'Игра', placeholder: 'Выберите тип'}"
                         [strategy]="gameTypesStrategy"
                         (userSelect)="userManipulationsSource.next()"
                         [control]="viewModel.gameType">
        </app-wl-dropdown>
    </div>
    <div class="wl-loot-box-row">
        <app-wl-strict-input-text title="Грейд"
                               (valueChange)="userManipulationsSource.next()"
                               [control]="viewModel.grade">
        </app-wl-strict-input-text>
    </div>

    <div class="wl-loot-box-row">
        <app-wl-strict-input-number class="wl-number-component"
                                 title="Сумма фрибета"
                                 placeholder=""
                                 min="1"
                                 (valueChange)="userManipulationsSource.next()"
                                 [control]="viewModel.averageFreebet">
        </app-wl-strict-input-number>
    </div>

    <div class="wl-loot-box-row">
        <button class="wl-btn-form-active" type="button" (click)="addItem()">Добавить награду</button>
    </div>
    <div class="wl-loot-box-row">
        <app-wl-strict-input-number title="Сумма вероятностей"
                                 [readonly]="true"
                                 (valueChange)="userManipulationsSource.next()"
                                 [control]="viewModel.sumProbability">
        </app-wl-strict-input-number>
    </div>
    <div class="wl-loot-box-row">
        <div class="wl-loot-box-selector" *ngFor="let lootBoxItem of viewModel.rewards.strictValue; let i = index">
            <div class="wl-loot-box-selector-item">
                <app-wl-dropdown [strategy]="rewardStrategy"
                                 [options]="{placeholder: 'Выберите награду',title:'Награда '}"
                                 (userSelect)="userManipulationsSource.next()"
                                 [shouldShowErrorList]="true"
                                 [control]="lootBoxItem.reward">
                </app-wl-dropdown>
            </div>
            <div class="wl-loot-box-selector-item">
                <div class="wl-loot-box-selector-item">
                    <app-wl-strict-input-number class="wl-number-component"
                                             title="Вероятность"
                                             placeholder=""
                                             (valueChange)="userManipulationsSource.next()"
                                             [control]="lootBoxItem.probability">
                    </app-wl-strict-input-number>
                </div>
            </div>
            <div class="wl-season-level-task-button">
                <button class="wl-btn-form-inactive" type="button" (click)="deleteItem(i)">Удалить</button>
            </div>
        </div>
    </div>
</div>
