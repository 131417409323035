import { StrictFormControl } from '@koddington/ga-common';
import { IPagingViewModel } from '../../../../shared/extensions/search-state-managment';
import { WlDailyTaskStates, WlPromoTypes } from '../../../../autogen/DailyTasks';


export class DailyTasksDaysListSearchViewModel implements IPagingViewModel {
    id: StrictFormControl<number> = new StrictFormControl<number>(null);
    term: StrictFormControl<string> = new StrictFormControl<string>(null);
    dayType: StrictFormControl<WlPromoTypes> = new StrictFormControl<WlPromoTypes>(null);
    state: StrictFormControl<WlDailyTaskStates> = new StrictFormControl<WlDailyTaskStates>(null);
    crmTaskGroupId: StrictFormControl<number> = new StrictFormControl<number>(null);
    lootBoxId: StrictFormControl<number> = new StrictFormControl<number>(null);
    offset: StrictFormControl<number> = new StrictFormControl<number>(0);
    count: StrictFormControl<number> = new StrictFormControl<number>(30);
}
