import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GaPagedResult, GaPagingForm, PageState, PaginateOptions } from '@koddington/ga-common';

@Component({
    selector: 'app-wl-paging',
    templateUrl: './wl-paging.component.html',
    styleUrls: ['./wl-paging.component.scss'],
})
export class WlPagingComponent {
    @Input()
    public readonly pagedResult: GaPagedResult<any>;

    @Output()
    public pageChangedEvent = new EventEmitter<GaPagingForm>();

    @Input()
    public options: PaginateOptions = {
        spanPages: 2,
        previousPage: true,
        nextPage: true,
        firstPage: true,
        lastPage: true,
        titles: {
            firstPage: ' ',
            previousPage: ' ',
            lastPage: ' ',
            nextPage: ' ',
            pageSize: 'Items per page',
        },
        pageSizes: [
            {
                value: 10,
                display: '10',
            },
        ],
    };

    public onPageSelected(pageState: PageState) {
        if (this.pagedResult.paging.offset === (pageState.currentPage - 1) * pageState.pageSize) {
            return;
        }

        this.pagedResult.paging.offset = (pageState.currentPage - 1) * pageState.pageSize;
        this.pageChangedEvent.emit(this.pagedResult.paging);
    }

    public get page(): PageState {
        return {
            currentPage: Math.ceil(this.pagedResult.paging.offset / this.pagedResult.paging.count) + 1,
            totalItems: this.pagedResult.total,
            pageSize: this.pagedResult.paging.count,
            numberOfPages: null,
        };
    }
}
