import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
    name: 'wlDateTime',
})
export class WlDateTimePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (!value) {
            return 'Дата не определена';
        }
        const data = dayjs(value).format('DD.MM.YYYY');
        if (data === '01.01.0001') {
            return 'Дата не определена';
        }

        return dayjs(value).format('DD.MM.YYYY HH:mm');
    }
}
