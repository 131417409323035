import { Component, OnDestroy, OnInit } from '@angular/core';
import { WlAuthStateService } from '../authorize/services/auth-state-service';
import { untilDestroyed } from 'ngx-take-until-destroy';
@Component({
  selector: 'app-default-page',
  templateUrl: './default-page.component.html',
  styleUrls: ['./default-page.component.scss'],
})
export class WlDefaultPage implements OnInit, OnDestroy {
  private currentLogin: string = '';
  constructor(private readonly _userAuthStateService: WlAuthStateService) {}
  ngOnInit(): void {
    this._userAuthStateService.userState
      .pipe(untilDestroyed(this))
      .subscribe((u) => {
        if (!u?.login) {
          this.currentLogin = 'Гость';
          return;
        }
        this.currentLogin = u.login;
      });
  }
  get login(): string {
    return this.currentLogin;
  }

  get isLogin(): boolean {
    if (this.currentLogin === '' || this.currentLogin === 'Гость') {
      return false;
    }
    return true;
  }

  ngOnDestroy(): void {}
}
