import { Directive, OnDestroy } from '@angular/core';
import { EnumExtensions, isNullOrUndefined } from '@koddington/ga-common';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { WlBetTypes } from '../../autogen/BattlePass';
import { BetTypeStrategy } from './wl-bet-type-strategy';
@Directive()
export class BattlePassBetTypeStrategy extends BetTypeStrategy implements OnDestroy {
    private fnRules: () => any[] = null;
    private source$ = new BehaviorSubject<Array<WlBetTypes>>([]);
    constructor(private readonly ignoreEntitys: (() => any[])) {
        super();
        this.fnRules = ignoreEntitys;
        this.updateEntities();
    }

    ngOnDestroy(): void {
        this.source$.unsubscribe();
    }

    public updateEntities(): void {
        let entities = EnumExtensions.toArray(WlBetTypes);
        if (!isNullOrUndefined(this.fnRules)) {
            entities = entities.filter((u) => !this.fnRules().includes(u));
        }
        this.source$.next(entities);
    }

    getEntities(): Observable<Array<WlBetTypes>> {
        return this.source$.asObservable().pipe(untilDestroyed(this));
    }
}
