import { Component, DoCheck, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PaginateOptions } from './types/ga-paginate-options.type';
import { PageState } from './types/ga-page-state.type';
import { isNullOrUndefined } from '@koddington/ga-common';

@Component({
    selector: 'app-ga-paging',
    templateUrl: './ga-paging.component.html',
    styleUrls: ['./ga-paging.component.scss'],
})
export class GaPagingComponent implements OnInit, OnDestroy, DoCheck {
    @Input() options: PaginateOptions;
    @Input() page: PageState;
    @Output() pageChange = new EventEmitter<PageState>();
    @Output() pageSizeChange = new EventEmitter<PageState>();

    public internalOptions: PaginateOptions;

    public range: number[];
    private defaultOptions: PaginateOptions;
    constructor() {
        this.range = [];
    }
    ngOnInit(): void {
        this.setOptions();
        this.calculateRange();
    }
    ngOnDestroy(): void {}

    ngDoCheck(): void {
        if (this.page) {
            this.calculateRange();
        }
    }

    private setOptions(): void {
        if (!this.options) {
            this.setDefaultOptions();
        }
        this.setInternalOptions();
    }

    private setDefaultOptions(): void {
        this.defaultOptions = {
            spanPages: 2,
            previousPage: true,
            nextPage: true,
            firstPage: true,
            lastPage: true,
            titles: {
                firstPage: 'First',
                previousPage: 'Previous',
                lastPage: 'Last',
                nextPage: 'Next',
                pageSize: 'Items per page',
            },
            pageSizes: [
                {
                    value: 5,
                    display: '5',
                },
                {
                    value: 10,
                    display: '10',
                },
                {
                    value: 15,
                    display: '15',
                },
            ],
        };
    }

    private setInternalOptions(): void {
        this.internalOptions = {
            spanPages: this.options.spanPages || this.defaultOptions.spanPages,
            previousPage: !isNullOrUndefined(this.options.previousPage) ? this.options.previousPage : this.defaultOptions.previousPage,
            nextPage: !isNullOrUndefined(this.options.nextPage) ? this.options.nextPage : this.defaultOptions.nextPage,
            firstPage: !isNullOrUndefined(this.options.firstPage) ? this.options.firstPage : this.defaultOptions.firstPage,
            lastPage: !isNullOrUndefined(this.options.lastPage) ? this.options.lastPage : this.defaultOptions.lastPage,
            pageSizes: this.options.pageSizes || this.defaultOptions.pageSizes,
            titles: {
                firstPage: this.options.titles.firstPage || this.defaultOptions.titles.firstPage,
                lastPage: this.options.titles.lastPage || this.defaultOptions.titles.lastPage,
                nextPage: this.options.titles.nextPage || this.defaultOptions.titles.nextPage,
                pageSize: this.options.titles.pageSize || this.defaultOptions.titles.pageSize,
                previousPage: this.options.titles.previousPage || this.defaultOptions.titles.previousPage,
            },
        };
    }

    private calculateRange(): void {
        this.range.length = 0;
        this.page.numberOfPages = Math.ceil(this.page.totalItems / this.page.pageSize);

        if (this.page.numberOfPages === 0) {
            this.page.numberOfPages = 1;
        }
        let upperSpan;

        if (this.page.currentPage - this.options.spanPages > 0) {
            for (let i = this.page.currentPage - this.options.spanPages; i < this.page.currentPage; i++) {
                this.range.push(i);
            }
            upperSpan = this.options.spanPages;
        } else {
            upperSpan = this.options.spanPages * 2;
            for (let i = 1; i < this.page.currentPage; i++) {
                this.range.push(i);
                upperSpan--;
            }
        }

        this.range.push(this.page.currentPage);

        if (this.page.currentPage + upperSpan <= this.page.numberOfPages) {
            for (let i = this.page.currentPage + 1; i <= this.page.currentPage + upperSpan; i++) {
                this.range.push(i);
            }
        } else {
            for (let i = this.page.currentPage + 1; i <= this.page.numberOfPages; i++) {
                this.range.push(i);
                upperSpan--;
            }

            if (upperSpan > 0) {
                while (upperSpan > 0 && this.range[0] - 1 > 0) {
                    this.range.unshift(this.range[0] - 1);
                    upperSpan--;
                }
            }
        }
    }

    public setPageSize(pageSize): void {
        this.page.pageSize = parseInt(pageSize, 10);
        this.pageSizeChange.emit(this.page);
    }

    public setPage(page: number): void {
        if (this.page.currentPage !== page) {
            this.page.currentPage = page;
            this.calculateRange();
            this.pageChange.emit(this.page);
        }
    }

    public previousPage(): void {
        if (this.page.currentPage > 1) {
            this.setPage(this.page.currentPage - 1);
        }
    }

    public nextPage(): void {
        if (this.page.currentPage < this.page.numberOfPages) {
            this.setPage(this.page.currentPage + 1);
        }
    }
}
