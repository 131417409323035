import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RateRaceRoutes } from './rate-race-area51.routes';
import { WlRateRaceLootBoxListComponent } from './components/wl-lootbox-list/wl-rate-race-lootbox-list.component';
import { WlRateRaceLootboxCrudComponent } from './components/wl-lootbox-crud/wl-rate-race-lootbox-crud.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { WlImportRewardsComponent } from './components/wl-lootbox-crud/wl-file-picker/wl-import-rewards.component';
import { WlRateRaceRewardsListComponent } from './components/wl-rewards-list/wl-rate-race-rewards-list.component';
import { WlRateRaceNicknameModerationComponent } from './components/wl-nickname-moderation/wl-rate-race-nickname-moderation.component';
import { WlRateRaceCrudComponent } from './components/wl-rate-race-crud/wl-rate-race-crud.component';
import { WlRateRaceListComponent } from './components/wl-rate-race-list/wl-rate-race-list.component';
import { BattlePassAreaCodegenAppModule } from '../autogen/BattlePass';
import { GaCommonModule } from '@koddington/ga-common';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        SharedModule.forRoot(),
        RouterModule.forChild(RateRaceRoutes),
        // LayoutsModule,
        // ExternalLibsModule,
        FormsModule,
        BattlePassAreaCodegenAppModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatCardModule,
        MatSliderModule,
        MatSnackBarModule,
        GaCommonModule,
        MatAutocompleteModule,
        MatDialogModule,
    ],
    declarations: [
        WlRateRaceCrudComponent,
        WlImportRewardsComponent,
        WlRateRaceLootboxCrudComponent,
        WlRateRaceNicknameModerationComponent,
        WlRateRaceLootBoxListComponent,
        WlRateRaceListComponent,
        WlRateRaceRewardsListComponent,
    ],
    exports: [
        WlRateRaceCrudComponent,
        WlImportRewardsComponent,
        WlRateRaceLootboxCrudComponent,
        WlRateRaceNicknameModerationComponent,
        WlRateRaceLootBoxListComponent,
        WlRateRaceListComponent,
        WlRateRaceRewardsListComponent,
    ],
    providers: [],
})
export class RateRaceModule {}
