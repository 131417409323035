<app-loading *ngIf="loading"></app-loading>
<div *ngIf="initialized" class="wl-loot-box-container">
    <app-wl-page-header header="{{pageHeader}}"></app-wl-page-header>
    <app-wl-loading *ngIf="loading"></app-wl-loading>
    <div class="wl-task-button-footer">
        <button class="wl-button-primary wl-loot-box-btn"
                [disabled]="!canSave"
                (click)="save()"
        >
            Сохранить
        </button>
    </div>
    <app-wl-strict-input-text class="wl-loot-box-row"
                              title="Название"
                              (valueChange)="userManipulationsSource.next()"
                              [control]="viewModel.name"
    ></app-wl-strict-input-text>
    <div class="wl-loot-box-row wl-loot-box-error" *ngFor="let error of gradeErrors | async">
        {{ error.message }}
    </div>
    <div class="wl-loot-box-row wl-loot-box-grade-selector-body" *ngFor="let grade of viewModel.grades.strictValue; index as i">
        <app-wl-strict-input-number class="wl-loot-box-row"
                                    title="Минимальная сумма грейда"
                                    (valueChange)="userManipulationsSource.next()"
                                    [control]="grade.gradeStart">
        </app-wl-strict-input-number>
        <app-strict-scroll-autocomplete-new class="wl-loot-box-row"
                                            [targetRouterLink]="getFreeBetLootBoxLink(grade.lootBox.strictValue)"
                                            [control]="grade.selectedLootBoxModel"
                                            [strategy]="grade.lootBoxStrategy"
                                            [label]="'Выбранный лутбокс'"
                                            (valueChange)="onFreeBetLootBoxSelect(grade)"
        ></app-strict-scroll-autocomplete-new>
        <app-wl-battle-pass-free-bet-loot-box-crud [asSubComponent]="true"
                                                   [viewModel]="grade.lootBox.strictValue"
                                                   (emptyContentEnrichFunc)="enrichEmptyLootBoxContent($event)"
                                                   [outsideUpdateEmitter]="grade.lootBoxUpdateEmitter"
                                                   (updateValuesEmitter)="userManipulationsSource.next()"
        ></app-wl-battle-pass-free-bet-loot-box-crud>
        <div *ngIf="canDeleteGrade">
            <button class="wl-btn-form-inactive wl-button-position" type="button" (click)="deleteGrade(i)">Удалить грейд</button>
        </div>
    </div>
    <button class="wl-btn-form-active" type="button" (click)="addGrade()">Добавить грейд</button>
</div>
