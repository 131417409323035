<app-loading *ngIf="loading"></app-loading>
<div class="wl-row">
  <app-wl-strict-input-text
    [control]="viewModel.name"
    title="Название"
    placeholder="Введите название награды"
    (valueChange)="userManipulationsSource.next()"
  >
  </app-wl-strict-input-text>
</div>
<div class="wl-row">
  <app-strict-dropdown
    [control]="viewModel.type"
    title="Состоние"
    [options]="{ placeholder: 'Выберите тип награды', title: '' }"
    [strategy]="rewardTypeStrategy"
    (userSelect)="userManipulationsSource.next()"
  >
  </app-strict-dropdown>
</div>
<div class="wl-row">
  <app-wl-strict-input-number
    [control]="viewModel.amount"
    title="Размер вознаграждения"
    placeholder="Введите размер вознаграждения"
    min="0"
    (valueChange)="userManipulationsSource.next()"
  >
  </app-wl-strict-input-number>
</div>

<div class="wl-reward-button-footer">
  <button class="wl-button-primary" [disabled]="!isValid" (click)="save()">
    Сохранить
  </button>
</div>
