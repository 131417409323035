import { Component, Inject, OnInit } from '@angular/core';
import { DailyTasksPromotionsService, WlDtPromoPlaceCount } from '../../../autogen/DailyTasks';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { formatDateTime, GaConfirmationService, GaMessagingService, GaTableData } from '@koddington/ga-common';
import { filter, take } from 'rxjs/operators';
import { WlDtPromoPlacesContainer } from '../../models/wl-dt-promo-places.container';

@Component({
    selector: 'app-wl-promotions-places',
    templateUrl: './wl-promotions-places.component.html',
    styleUrls: ['./wl-promotions-places.component.scss']
})
export class WlPromotionsPlacesComponent implements OnInit {
    protected tableData: GaTableData<WlDtPromoPlaceCount>;

    constructor(private readonly service: DailyTasksPromotionsService,
                private readonly messaging: GaMessagingService,
                private readonly confirmation: GaConfirmationService,
                private readonly _dialogRef: MatDialogRef<WlPromotionsPlacesComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { container: WlDtPromoPlacesContainer, promoId: number }) {
    }

    ngOnInit() {
        this.mapTable(this.data.container.places);
    }

    protected recalculate(): void {
        this.confirmation.openDialog('Вы уверены, что хотите запустить перерасчёт?')
            .pipe(
                take(1),
                filter(res => !!res)
            )
            .subscribe(_ => {
                this.service.updatePromoPlaces(this.data.promoId)
                    .pipe(
                        take(1),
                        filter(res => !this.messaging.tryShowError(res))
                    )
                    .subscribe(res => {
                        this.data.container.places = res.result;
                        this.mapTable(res.result);
                        this.messaging.showMessage('Результат пересчитан');
                    });
            });
    }

    protected close(): void {
        this._dialogRef.close();
    }

    private mapTable(places: WlDtPromoPlaceCount[]): void {
        this.tableData = new GaTableData<WlDtPromoPlaceCount>()
            .addSimpleColumn(u => u.order, {title: 'Порядок', widthSize: 100})
            .addSimpleColumn(u => u.placeName, {title: 'Название', widthSize: 200})
            .addSimpleColumn(u => u.minPoints, {title: 'Мин.кол-во очков', widthSize: 150})
            .addSimpleColumn(u => u.rewardSize, {title: 'Награда', widthSize: 100})
            .addSimpleColumn(u => u.usersCount, {title: 'Кол-во юзеров', widthSize: 130})
            .addSimpleColumn(u => formatDateTime(u.calculatedAt), {title: 'Последний расчёт', widthSize: 200})
            .setData(places);
    }

    get title(): string {
        return `Таблица результатов для акции №${this.data.promoId}`;
    }
}
