<app-wl-page-header header="Незачтенные ставки"></app-wl-page-header>
<div class="wl-search-row">
    <app-wl-strict-input-number
        class="wl-app-input-search-wrapper"
        placeholder="Id пользователя"
        [isSearch]="true"
        [control]="searchUserId"
        (valueChange)="search()"
        (keydown.enter)="$event.target.blur(); search(); (false)"
    ></app-wl-strict-input-number>

<app-wl-dropdown
  class="wl-app-input-search-wrapper wl-input-marging"
  isSearch="true"
  [strategy]="_filtredOutBetsTargetsStrategy"
  (userSelect)="search()"
  [control]="filtredOutBetsTargets"
>
</app-wl-dropdown>
</div>
<app-wl-loading *ngIf="loading"></app-wl-loading>
<ga-table *ngIf="!!tableData" [data]="tableData" [hideArrows]="false"></ga-table>
<div class="wl-paging-wrapper">
    <app-wl-paging *ngIf="logs" [pagedResult]="logs" (pageChangedEvent)="pageChanged($event)"></app-wl-paging>
</div>
