import { Component, OnDestroy, OnInit } from '@angular/core';
import { StrictFormControl } from '@koddington/ga-common';

@Component({
  selector: 'app-tracker',
  templateUrl: './app-tracker.component.html',
  styleUrls: ['./app-tracker.component.scss'],
})
export class WlTrackerComponent implements OnInit, OnDestroy {
  public readonly viewModel: TestViewModel = new TestViewModel();
  ngOnInit(): void {}
  ngOnDestroy(): void {}
}

class TestViewModel {
  public readonly password: StrictFormControl<string> =
    new StrictFormControl<string>();
}
