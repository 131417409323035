<div class="wl-login-workspace-container">
  <div class="wl-login-content">
    <label class="wl-margin-top-4">Логин</label>
    <app-wl-strict-input-text
      class="wl-strict-login wl-margin-top-4"
      [control]="viewModel.login"
      placeholder="Логин"
      (keydown.enter)="$event.target.blur(); login(); (false)"
    ></app-wl-strict-input-text>
    <label class="wl-margin-top-4">Пароль</label>
    <app-wl-strict-input-text
      type="password"
      class="wl-strict-login wl-margin-top-4"
      [control]="viewModel.password"
      placeholder="Пароль"
      (keydown.enter)="$event.target.blur(); login(); (false)"
    ></app-wl-strict-input-text>
    <button
      type="submit"
      class="wl-button-primary wl-margin-top-4"
      (click)="login()"
    >
      Войти
    </button>
  </div>
</div>
