import { Injectable } from '@angular/core';
import { WlGameLootBoxTypes, WlGameLootBoxTypesExtensions } from '../../autogen/BattlePass';
import { Observable, of } from 'rxjs';
import { DropdownItem, EnumExtensions, IDropdownStrategy } from '@koddington/ga-common';

@Injectable({
    providedIn: 'root'
})
export class WlBattlePassGameLootBoxTypesStrategy implements IDropdownStrategy<WlGameLootBoxTypes> {
    getEntities(): Observable<Array<WlGameLootBoxTypes>> {
        const entities = EnumExtensions.toArray(WlGameLootBoxTypes);
        return of(entities);
    }

    map: (model: WlGameLootBoxTypes) => DropdownItem<WlGameLootBoxTypes> = model => {
        return {
            entity: model,
            title: WlGameLootBoxTypesExtensions.format(model),
            id: Number(model)
        };
    }
}
