import { WlCrmTaskGroupViewModel } from '../../models/wl-crm-task-group-view-model';
import { AbstractModelValidator, isNullOrUndefined, StrictError } from '@koddington/ga-common';
import { WlBattlePassTaskTypes, WlCrmTaskTargetTypes } from 'src/app/modules/autogen/BattlePass';
import { StrictValidationRules } from 'src/app/modules/shared/validation-rules/strict-validation-rules';
import { BattlePassTaskItemValidator } from './battle-pass-task-item-validator';
import controlIsCorrectAndEqual = StrictValidationRules.controlIsCorrectAndEqual;
import { isMoreThanValue } from 'src/app/modules/shared/extensions/range-comparison-utils';

export class BattlePassTaskGroupValidator extends AbstractModelValidator<WlCrmTaskGroupViewModel> {
    private readonly targetTypeExcludedForWinAndRedeem: WlCrmTaskTargetTypes[] = [
        WlCrmTaskTargetTypes.None
    ];

    constructor() {
        super();
        this.ruleForControl((u) => u.name).notEmptyString('Укажите имя');
        this.ruleForControl((u) => u.description).notEmptyString('Укажите описание');

        this.ruleForControl((u) => u.targetIndicatorType)
            .notEmptyString()
            .maxLength(50, 'Максимальная длина 50 символов');

        this.ruleForControl((u) => u.targetIndicator)
            .requiredAndNotZero('Укажите индикатор целевого показателя')
            .max(Number.MAX_VALUE, 'Значение целевого показателя превышает верхний предел')
            .isInteger('Целевой показатель должэен быть целым числом');

        this.ruleForControlIf((u) => u.progressIntervalSize, u => u.limitProgressByDays?.strictValue)
            .required()
            .isInteger()
            .min(1, 'Минимальный период расчёта событий - 1 сутки')
            .max(999, 'Минимальный период расчёта событий - 999 суток');

        this.ruleForControl(u => u.taskType).required('Укажите тип события');
        this.ruleForControlIf(u => u.targetType, u => u.taskType.strictValue !== WlBattlePassTaskTypes.Other)
            .required('Цель задания обязательна');

        this.ruleForControl(u => u.tasks).required()
            .minLength(1, 'Требуется хотя бы одно событие задания')
            .customRule((fieldValue, model) => {
                const tasksValidator = new BattlePassTaskItemValidator(model.taskType.strictValue, model.targetType.strictValue,
                    model.switcherEvents.isCollectInExpress.strictValue, model.switcherEvents.isGrouped.strictValue);

                if (isNullOrUndefined(fieldValue)) {
                    return new StrictError('taskValidationError', 'Необходимо добавить подзадания');
                }

                const errors = tasksValidator.validateArray(fieldValue);
                if (errors.length > 0) {
                    return new StrictError('taskValidationError', errors.pop().message);
                }

                return null;
            });

        this.ruleForControlIf(u => u.targetType, u => u.switcherEvents.isGrouped.strictValue)
            .customRule((_, model) => this.targetTypeExcludedForWinAndRedeem.includes(model.targetType.strictValue)
                ? new StrictError('taskValidationError', 'Создание заданий "Группировка + Выкуп" недоступно.')
                : null);

        this.ruleForControlIf(u => u.targetType, u => u.taskType.strictValue === WlBattlePassTaskTypes.Win)
            .customRule((_, model) => this.targetTypeExcludedForWinAndRedeem.includes(model.targetType.strictValue)
                ? new StrictError('taskValidationError', 'Создание заданий "Выиграть + Выкуп" недоступно.')
                : null);

        this.ruleForControlIf(
            u => u.taskType,
            v => controlIsCorrectAndEqual(v.taskType, WlBattlePassTaskTypes.Deposits))
            .customRule((_, model) => model.tasks.strictValue.length > 1
                ? new StrictError('taskValidationError', 'Для депозитов не может быть подзаданий')
                : null);

        this.ruleForControlIf(
            u => u.taskType,
            v => controlIsCorrectAndEqual(v.taskType, WlBattlePassTaskTypes.Other))
            .customRule((_, model) => model.tasks.strictValue.length > 1
            ? new StrictError('taskValidationError', 'Для заданий типа "Другое" не может быть подзаданий')
            : null);

        this.ruleForControlIf(
            u => u.taskType,
            v => controlIsCorrectAndEqual(v.targetType, WlCrmTaskTargetTypes.BySum) && v.switcherEvents.isGrouped.strictValue)
            .customRule((_, model) => model.tasks.strictValue.length > 1
                ? new StrictError('taskValidationError', 'Для заданий с группировкой по сумме разрешено только одно подзадание.')
                : null);

        this.ruleForControlIf(u => u.groupViewModel.groupType,
            v => v.switcherEvents.isGrouped.strictValue)
            .required('Параметр группировки обязателен');

        this.ruleForControlIf(u => u.groupViewModel.groupsCount,
            v => v.switcherEvents.isGrouped.strictValue)
            .required('Кол-во элементов группировки обязательно')
            .isInteger();

        this.ruleForControlIf(u => u.collectExpressViewModel.betsCount,
            (v) =>
                ((controlIsCorrectAndEqual(v.targetType, WlCrmTaskTargetTypes.ByCount) && !controlIsCorrectAndEqual(v.taskType, WlBattlePassTaskTypes.Deposits))
                    || (controlIsCorrectAndEqual(v.targetType, WlCrmTaskTargetTypes.ByCount) && controlIsCorrectAndEqual(v.taskType, WlBattlePassTaskTypes.Redeem)))
                && v.switcherEvents.isCollectInExpress.strictValue)
            .required('Укажите число ставок')
            .isInteger();

        this.ruleForControlIf(u => u.collectExpressViewModel.totalWinAmountRange.strictValue.condition,
            v => controlIsCorrectAndEqual(v.taskType, WlBattlePassTaskTypes.Win) &&
                controlIsCorrectAndEqual(v.targetType, WlCrmTaskTargetTypes.BySum) && v.switcherEvents.isCollectInExpress.strictValue)
            .required('Укажите тип сравнения для выигрышей');

        this.ruleForControlIf(u => u.collectExpressViewModel.totalWinAmountRange.strictValue.value,
            v => controlIsCorrectAndEqual(v.taskType, WlBattlePassTaskTypes.Win) &&
                controlIsCorrectAndEqual(v.targetType, WlCrmTaskTargetTypes.BySum) && v.switcherEvents.isCollectInExpress.strictValue)
            .min(1)
            .required('Укажите сумму выигрышей')
            .isInteger('Сумма выигрышей должна быть целым числом');

        this.ruleForControlIf(u => u.collectExpressViewModel.eventCountExpressRange.strictValue.value, v =>
            v.switcherEvents.isCollectInExpress.strictValue)
            .required('Укажите диапазон экспресс ставок')
            .customRule((_, model) => {
                if (!isMoreThanValue(model.collectExpressViewModel.eventCountExpressRange.strictValue, 1)) {
                    return new StrictError('betSumRangeError', 'Событий в экспрессе не может быть меньше или равно 1');
                }

                return null;
            })
            .max(20, 'Событий в экспрессе не может быть больше 20');

        this.ruleForControlIf(u => u.collectExpressViewModel.eventCountExpressRange.strictValue.condition, v =>
            v.switcherEvents.isCollectInExpress.strictValue)
            .required('Укажите тип сравнения диапазона экспресс ставок');

        this.ruleForControlIf(u => u.collectExpressViewModel.betRateRange.strictValue.value,
            v =>
                (controlIsCorrectAndEqual(v.taskType, WlBattlePassTaskTypes.Bets) || controlIsCorrectAndEqual(v.taskType, WlBattlePassTaskTypes.Win))
                && v.switcherEvents.isCollectInExpress.strictValue)
            .required('Укажите коэффициент ставки')
            .min(1.01, 'Коэффициент ставки должен быть больше или равен 1.01');

        this.ruleForControlIf(u => u.collectExpressViewModel.betRateRange.strictValue.condition,
            v =>
                (controlIsCorrectAndEqual(v.taskType, WlBattlePassTaskTypes.Bets) || controlIsCorrectAndEqual(v.taskType, WlBattlePassTaskTypes.Win))
                && v.switcherEvents.isCollectInExpress.strictValue)
            .required('Укажите тип сравнения коэффициента ставки');

        this.ruleForControlIf(u => u.collectExpressViewModel.betSumRange.strictValue.value,
            v => controlIsCorrectAndEqual(v.targetType, WlCrmTaskTargetTypes.ByCount) && v.switcherEvents.isCollectInExpress.strictValue)
            .min(1, 'Укажите сумму ставки')
            .isInteger('Сумма ставки должна быть целым числом');

        this.ruleForControlIf(u => u.collectExpressViewModel.betSumRange.strictValue.condition,
            v => controlIsCorrectAndEqual(v.targetType, WlCrmTaskTargetTypes.ByCount) && v.switcherEvents.isCollectInExpress.strictValue)
            .customRule((_, model) => {
                if (!model.collectExpressViewModel.betSumRange?.strictValue.value.hasStrictValue)
                    return null;
                if (!model.collectExpressViewModel.betSumRange?.strictValue.condition.hasStrictValue) {
                    return new StrictError('betSumRangeError', 'Укажите тип сравнения суммы ставок');
                }

                return null;
            });

        this.ruleForControlIf(
            u => u.collectExpressViewModel.betWinAmountRange.strictValue.value,
            v => controlIsCorrectAndEqual(v.targetType, WlCrmTaskTargetTypes.ByCount) &&
                controlIsCorrectAndEqual(v.taskType, WlBattlePassTaskTypes.Win) && v.switcherEvents.isCollectInExpress.strictValue)
            .min(1, 'Сумма выигрыша ставки не может быть отрицательной или равной нулю')
            .isInteger('Сумма выигрыша ставки должна быть целым числом');

        this.ruleForControlIf(
            u => u.collectExpressViewModel.betWinAmountRange.strictValue.condition,
            v => controlIsCorrectAndEqual(v.targetType, WlCrmTaskTargetTypes.ByCount) &&
                controlIsCorrectAndEqual(v.taskType, WlBattlePassTaskTypes.Win) && v.switcherEvents.isCollectInExpress.strictValue)
            .customRule((_, model) => {
                if (!model.collectExpressViewModel.betWinAmountRange?.strictValue.value.hasStrictValue)
                    return null;

                if (!model.collectExpressViewModel.betWinAmountRange?.strictValue.condition.hasStrictValue) {
                    return new StrictError('betSumRangeError', 'Укажите тип сравнения суммы выигрыша ставки');
                }

                return null;
            });

        this.ruleForControlIf(u => u.groupViewModel.eventsCountInGroup,
            v => v.switcherEvents.isGrouped.strictValue && v.targetType.strictValue === WlCrmTaskTargetTypes.ByCount)
            .required('Кол-во событий в группе обязательно')
            .isInteger();

        this.ruleForControlIf(u => u.groupViewModel.eventsSumInGroup,
            v => v.switcherEvents.isGrouped.strictValue && v.targetType.strictValue === WlCrmTaskTargetTypes.BySum)
            .required('Сумма ставок в группе обязательна');

        this.ruleForControlIf(u => u.template, u => u.throttling.hasStrictValue)
            .required('Шаблон рассылки должен быть заполнен');
    }
}
