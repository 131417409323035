import { WlBattlePassRateRaceLootBoxesAutocompleteStrategy } from '../../battle-pass/strategies/wl-battle-pass-rate-race-loot-boxes-autocomplete-strategy';
import { WlBattlePassRateRacesLootBoxesScrollServiceAdapter } from '../../battle-pass/services/wrapper-service/wl-battle-pass-rate-races-loot-boxes-scroll-service-adapter';
import { StrictFormControl } from '@koddington/ga-common';
import { WlRateRaceLootBoxListModel } from '../../autogen/BattlePass';

export class WlRateRaceGradeViewModel {
    minAmount: StrictFormControl<number> = new StrictFormControl<number>();
    rateRaceLootBoxId: StrictFormControl<number> = new StrictFormControl<number>();
    rateRaceLootBox: StrictFormControl<WlRateRaceLootBoxListModel> = new StrictFormControl<WlRateRaceLootBoxListModel>();
    isAccrualLootBox: StrictFormControl<boolean> = new StrictFormControl<boolean>();
    rateRaceLootBoxStrategy: WlBattlePassRateRaceLootBoxesAutocompleteStrategy;

    constructor(private _lootBoxStrategyApiClient: WlBattlePassRateRacesLootBoxesScrollServiceAdapter) {
        this.rateRaceLootBoxStrategy = new WlBattlePassRateRaceLootBoxesAutocompleteStrategy(_lootBoxStrategyApiClient);
    }
}
