<div class="wl-row">
  <button class="wl-button-primary wl-btn-push-save-wrapper" (click)="add()">
    Добавить награду
  </button>
</div>
<app-loading *ngIf="loading"></app-loading>
<ga-table *ngIf="!!tableData" [data]="tableData"></ga-table>
<div class="wl-paging-wrapper">
  <app-wl-paging
    class="wl-test-paging"
    *ngIf="partners"
    [pagedResult]="partners"
    (pageChangedEvent)="pageChanged($event)"
  ></app-wl-paging>
</div>
