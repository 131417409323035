import { WlBattlePassTargetTypeStrategy } from '../../strategies/wl-battle-pass-target-type-strategy';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { WlCrmTaskGroupViewModel } from '../../models/wl-crm-task-group-view-model';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, finalize } from 'rxjs/operators';
import { WlCrmTaskViewModel } from '../../models/wl-crm-task-view-model';
import { WlRangeConditionViewModel } from '../../models/wl-range-condition-view-model';
import { BattlePassTaskGroupValidator } from '../../services/validators/battle-pass-task-group-validator';
import {
    BattlePassService,
    WlBattlePassTaskTypes,
    WlBetPlatforms,
    WlBetTypes,
    WlCrmTaskBetsGroupingForm,
    WlCrmTaskCollectExpressForm,
    WlCrmTaskCollectExpressModel,
    WlCrmTaskCreateForm,
    WlCrmTaskForm,
    WlCrmTaskGroupModel,
    WlCrmTaskMarketParameterForm,
    WlCrmTaskProgressIntervalTypes,
    WlCrmTaskRangeValueForm,
    WlCrmTaskRangeValueModel,
    WlCrmTaskRateRangeValueForm,
    WlCrmTaskRateRangeValueModel,
    WlCrmTaskTargetTypes,
    WlCrmTaskUpdateForm,
} from '../../../autogen/BattlePass';
import { WlBattlePassTaskTypeStrategy } from '../../strategies/wl-battle-pass-task-type-strategy';
import { WlBattlePassCrmBetsGroupingTypesStrategy } from '../../strategies/wl-battle-pass-crm-bets-grouping-types-strategy';
import { WlMarketsParameterViewModel } from '../../models/wl-markets-parameter-view-model';
import { GaMessagingService, isNullOrUndefined, StrictError, StrictFormControl } from '@koddington/ga-common';
import { WlResult } from '../../../../models/common/results/wl-result';
import { builderModelToStrict, builderStrictToModel, gaMap } from 'src/app/modules/shared/common/operation/builder-operation';
import { RouterNavigationExtensions } from 'src/app/modules/shared/extensions/navigation-extensions';
import { WlRadioButtonViewModel } from 'src/app/modules/shared/components/wl-radio-buttons/view-models/wl-radio-button-view-model';

@Component({
    selector: 'app-wl-battle-pass-task-crud',
    templateUrl: './wl-battle-pass-task-crud.component.html',
    styleUrls: ['./wl-battle-pass-task-crud.component.scss'],
})
export class WlBattlePassTaskCrudComponent implements OnInit, OnDestroy {

    public taskTypeStrategy: WlBattlePassTaskTypeStrategy;
    public targetTypeStrategy: WlBattlePassTargetTypeStrategy;
    public groupingTypeStrategy: WlBattlePassCrmBetsGroupingTypesStrategy;

    public loading: boolean;
    public viewModel: WlCrmTaskGroupViewModel = new WlCrmTaskGroupViewModel();
    public validator = new BattlePassTaskGroupValidator();

    public readonly userManipulationsSource = new Subject<void>();
    public errors: StrictError[] = [];
    public isStarted = false;
    public isUsed = false;
    public switcherEvents: WlRadioButtonViewModel[];
    private setTargetTypeRule = this.targetTypeRule.bind(this);
    private setTaskTypeRule = () => this.taskTypeRule();

    constructor(
        private readonly _messaging: GaMessagingService,
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _titleService: Title,
        private readonly _battlePassService: BattlePassService
    ) {
    }

    public ngOnDestroy(): void {
    }

    public ngOnInit(): void {
        this.loading = true;
        this.taskTypeStrategy = new WlBattlePassTaskTypeStrategy(this.setTaskTypeRule);
        this.targetTypeStrategy = new WlBattlePassTargetTypeStrategy(this.setTargetTypeRule);
        this.groupingTypeStrategy = new WlBattlePassCrmBetsGroupingTypesStrategy();
        this.initOrFilledEventData(true);

        this.viewModel.id.strictValue = isNullOrUndefined(this._route.snapshot.params['id']) ? null : this._route.snapshot.params['id'];
        this.subscribeToValidate();
        this.loadTask();
        this._titleService.setTitle('Создание / Редактирование задания');
    }

    public save(): void {
        this.loading = true;

        if (this.isEdit) {
            this.update();
        } else {
            this.create();
        }
    }

    public clone(): void {
        this.loading = true;

        this.createByService()
            .subscribe(res => {
                this._messaging.showMessage('Копия создана и открыта.');
                this.showCrmTaskByNumber(res.result);
            });
    }

    public addTask(): void {
        this.addEmptyTask();
    }

    public deleteTask(index: number) {
        if (this.viewModel.tasks.strictValue.length > 1) {
            this.viewModel.tasks.strictValue = this.viewModel.tasks.strictValue.filter((u, k) => k !== index);
        }
        this.userManipulationsSource.next();
    }

    private subscribeOnChanges(): void {
        this.viewModel.taskType.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.targetTypeStrategy.updateEntities();
            this.clearViewModel();
            this.pushTasks();
        });
        this.viewModel.switcherEvents.isCollectInExpress.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.targetTypeStrategy.updateEntities();
            this.taskTypeStrategy.updateEntities();
            if (!this.taskCanBeGrouped && this.viewModel.switcherEvents.isGrouped.strictValue) {
                this.viewModel.taskType.strictValue = null;
                this.viewModel.tasks.strictValue = null;
                return;
            }
            this.clearTaskTypeTarget();
            this.pushTasks();
        });
        this.viewModel.switcherEvents.isGrouped.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.targetTypeStrategy.updateEntities();
            this.taskTypeStrategy.updateEntities();
            if (!this.taskCanBeGrouped) {
                this.viewModel.taskType.strictValue = null;
                return;
            }
            this.pushTasks();
        });

        this.viewModel.switcherEvents.isRecurrent.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.taskTypeStrategy.updateEntities();
            this.targetTypeStrategy.updateEntities();
            if (!this.isRecurrentTask) {
                this.viewModel.taskType.strictValue = null;
                this.viewModel.targetType.strictValue = null;
                this.viewModel.tasks.strictValue = null;
                return;
            }
            this.clearTaskTypeTarget();
            this.pushTasks();
        });
    }

    private subscribeToValidate(): void {
        this.userManipulationsSource.pipe(untilDestroyed(this)).subscribe(() => {
            this.errors = this.validator.validate(this.viewModel);
        });
    }

    public emmitMethod(): void {
        this.userManipulationsSource.next();
    }

    private pushTasks(): void {
        const taskTypeExist = this.viewModel.taskType.hasStrictValue;
        let tasksCount = this.viewModel.tasks.hasStrictValue ? this.viewModel.tasks.strictValue.length : 0;
        if (taskTypeExist && tasksCount === 0) {
            this.addTask();
            tasksCount++;
        }
        if (this.isCollectInExpress && tasksCount < 2 && taskTypeExist) {
            for (let i = tasksCount; i < 2; i++) {
                this.addTask();
            }
        }
        if (this.isRecurrentTask && tasksCount === 0) {
            this.addTask();
        }
    }

    private initOrFilledEventData(isDefault: boolean = false): void {
        this.switcherEvents = [{
            label: 'Нет события',
            radioButton: isDefault ? new StrictFormControl<boolean>(false) : this.viewModel.switcherEvents.isEmptyEvent
        },
            {
                label: 'Группировка событий',
                radioButton: isDefault ? new StrictFormControl<boolean>(false) : this.viewModel.switcherEvents.isGrouped
            },
            {
                label: 'Циклическое задание',
                radioButton: isDefault ? new StrictFormControl<boolean>(false) : this.viewModel.switcherEvents.isRecurrent
            }];
    }

    private loadTask(): void {
        if (this.isEdit) {
            this._battlePassService
                .getCrmTaskGroupForUpdate(this.viewModel.id.value)
                .pipe(
                    filter((value) => !this._messaging.tryShowError(value)),
                    untilDestroyed(this)
                )
                .subscribe((result) => {
                    this.loadModel(result.result);
                    this.setDisabled();
                    this.afterLoadTask();
                });
            return;
        }

        this.afterLoadTask();
    }

    private afterLoadTask(): void {
        this.userManipulationsSource.next();
        this.loading = false;
        this.subscribeOnChanges();
        this.initOrFilledEventData();
        this.targetTypeStrategy.updateEntities();
        this.taskTypeStrategy.updateEntities();
    }

    private create(): void {
        this.createByService()
            .subscribe(() => {
                this.showListCrmTask();
            });
    }

    private update(): void {
        const form = this.createUpdateForm();
        this.clearExcessTasksProperties(form);
        this._battlePassService
            .updateCrmTask(form)
            .pipe(
                filter((value) => !this._messaging.tryShowError(value)),
                finalize(() => (this.loading = false)),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.showListCrmTask();
            });
    }

    private taskTypeRule(): WlBattlePassTaskTypes[] {
        if (this.viewModel.switcherEvents.isGrouped.strictValue || this.viewModel.switcherEvents.isCollectInExpress.strictValue)
            return [WlBattlePassTaskTypes.Deposits, WlBattlePassTaskTypes.Redeem, WlBattlePassTaskTypes.Other];

        if (this.viewModel.switcherEvents.isRecurrent.strictValue)
            return [WlBattlePassTaskTypes.Win, WlBattlePassTaskTypes.Other];

        return [];
    }

    private createByService(): Observable<WlResult<number>> {
        const form = this.createAddForm();
        this.clearExcessTasksProperties(form);

        return this._battlePassService
            .addCrmTask(form)
            .pipe(
                filter((value) => !this._messaging.tryShowError(value)),
                finalize(() => (this.loading = false)),
                untilDestroyed(this)
            );
    }

    private clearExcessTasksProperties(form: WlCrmTaskUpdateForm): void {
        const taskType = form.taskType;
        const targetType = form.targetType;
        const isCollect = form.isCollectInExpress;

        form.tasks.forEach((task) => {
            if (taskType === WlBattlePassTaskTypes.Bets || taskType === WlBattlePassTaskTypes.Win || taskType === WlBattlePassTaskTypes.Redeem) {
                this.clearTaskByTargetType(task, targetType);
                this.clearTaskByBetType(task);
            }

            if (isCollect) {
                this.clearTaskByCollectExpress(task);
            }

            if (taskType !== WlBattlePassTaskTypes.Bets && taskType !== WlBattlePassTaskTypes.Redeem) {
                task.severalBetsSumRange = null;
            }

            if (taskType !== WlBattlePassTaskTypes.Win) {
                task.totalWinAmountRange = null;
            }

            if (taskType === WlBattlePassTaskTypes.Deposits) {
                this.clearTaskByDeposit(task);
            }
        });
    }

    private clearTaskByDeposit(task: WlCrmTaskForm): void {
        task.betType = null;
        task.eventCountExpressRange = null;
        task.betRateRange = null;
        task.lineRateRange = null;
        task.betSumRange = null;
        task.markets = [];
        task.sportTypeId = null;
        task.betPeriod = null;
        task.betPlatforms = [] as WlBetPlatforms[];
        task.championshipId = null;
        task.games = [];
        task.betWinAmountRange = null;
        task.betCount = null;
    }

    private clearTaskByCollectExpress(task: WlCrmTaskForm): void {
        task.betRateRange = null;
        task.eventCountExpressRange = null;
        task.betSumRange = null;
        task.betCount = null;
        task.totalWinAmountRange = null;
        task.betWinAmountRange = null;
    }

    private clearTaskByBetType(task: WlCrmTaskForm): void {
        task.depositsCount = null;
        task.depositsSumRange = null;
        switch (task.betType) {
            case WlBetTypes.Ordinar:
                task.lineRateRange = null;
                task.eventCountExpressRange = null;
                break;
            case WlBetTypes.Any:
                task.eventCountExpressRange = null;
                break;
        }
    }

    private clearTaskByTargetType(task: WlCrmTaskForm, targetType: WlCrmTaskTargetTypes): void {
        switch (targetType) {
            case WlCrmTaskTargetTypes.ByCount:
                task.totalWinAmountRange = null;
                task.severalBetsSumRange = null;
                break;
            case WlCrmTaskTargetTypes.BySum:
                task.betWinAmountRange = null;
                task.betCount = null;
                task.betSumRange = null;
                break;
        }
    }

    private loadModel(model: WlCrmTaskGroupModel): void {
        builderModelToStrict(this.viewModel, model,
            [
                {
                    sourceMapProperty: 'tasks',
                    targetMapProperty: 'tasks',
                    mapObject: gaMap(model.tasks, (val) => {
                        const mapped = new WlCrmTaskViewModel();

                        mapped.id.strictValue = val.id;
                        mapped.betType.strictValue = val.betType;
                        mapped.betCount.strictValue = val.betsCount;
                        mapped.sportType.strictValue = val.sportType;
                        mapped.betPeriod.strictValue = val.betPeriods;
                        mapped.betPlatforms.strictValue = val.betPlatforms;
                        mapped.championshipId.strictValue = val.championshipId;
                        mapped.isExcludedSportType.strictValue = val.sportTypesFilterType;
                        mapped.sportTypes.strictValue = val.sportTypes;
                        mapped.isExcludedGames.strictValue = val.gamesFilterType;
                        mapped.games.strictValue = val.games;
                        mapped.depositsCount.strictValue = val.depositsCount;

                        mapped.activeMarkets.strictValue = val.activeMarkets.map(value => {
                            const market = new WlMarketsParameterViewModel();

                            market.market.strictValue = value.market;
                            market.ftsParameterA.strictValue = value.ftsParameterA;
                            market.ftsParameterB.strictValue = value.ftsParameterB;
                            market.ftsParameterC.strictValue = value.ftsParameterC;

                            return market;
                        });

                        if (!isNullOrUndefined(val.eventCountExpressRange)) {
                            mapped.eventCountExpressRange.strictValue = this.mapRangeCondition(val.eventCountExpressRange);
                        }

                        if (!isNullOrUndefined(val.betRateRange)) {
                            mapped.betRateRange.strictValue = this.mapRangeCondition(val.betRateRange);
                        }

                        if (!isNullOrUndefined(val.lineRateRange)) {
                            mapped.lineRateRange.strictValue = this.mapRangeCondition(val.lineRateRange);
                        }

                        if (!isNullOrUndefined(val.betSumRange)) {
                            mapped.betSumRange.strictValue = this.mapRangeCondition(val.betSumRange);
                        }

                        if (!isNullOrUndefined(val.totalWinAmountRange)) {
                            mapped.totalWinAmountRange.strictValue = this.mapRangeCondition(val.totalWinAmountRange);
                        }

                        if (!isNullOrUndefined(val.betWinAmountRange)) {
                            mapped.betWinAmountRange.strictValue = this.mapRangeCondition(val.betWinAmountRange);
                        }

                        if (!isNullOrUndefined(val.depositsSumRange)) {
                            mapped.depositsSumRange.strictValue = this.mapRangeCondition(val.depositsSumRange);
                        }

                        if (!isNullOrUndefined(val.totalDepositsAmountRange)) {
                            mapped.totalDepositsAmountRange.strictValue = this.mapRangeCondition(val.totalDepositsAmountRange);
                        }

                        if (!isNullOrUndefined(val.severalBetsSumRange)) {
                            mapped.severalBetsSumRange.strictValue = this.mapRangeCondition(val.severalBetsSumRange);
                        }

                        return mapped;
                    })
                }
            ]);
        this.isStarted = model.isStarted;
        this.isUsed = model.isUsed;

        if (!isNullOrUndefined(model.progressIntervalSize)) {
            this.viewModel.limitProgressByDays.strictValue = true;
            this.viewModel.progressIntervalSize.strictValue = model.progressIntervalSize;
        }

        if (model.isCollectInExpress) {
            builderModelToStrict(this.viewModel.collectExpressViewModel, model.collectExpress, null,
                [
                    {
                        sourceKey: 'eventCountExpressRange',
                        targetKey: 'eventCountExpressRange',
                        newValue: this.mapCollectExpress(model.collectExpress, 'eventCountExpressRange')
                    },
                    {
                        sourceKey: 'betRateRange',
                        targetKey: 'betRateRange',
                        newValue: this.mapCollectExpress(model.collectExpress, 'betRateRange')
                    },
                    {
                        sourceKey: 'betSumRange',
                        targetKey: 'betSumRange',
                        newValue: this.mapCollectExpress(model.collectExpress, 'betSumRange')
                    },
                    {
                        sourceKey: 'totalWinAmountRange',
                        targetKey: 'totalWinAmountRange',
                        newValue: this.mapCollectExpress(model.collectExpress, 'totalWinAmountRange')
                    },
                    {
                        sourceKey: 'betWinAmountRange',
                        targetKey: 'betWinAmountRange',
                        newValue: this.mapCollectExpress(model.collectExpress, 'betWinAmountRange')
                    }
                ]);
            this.viewModel.switcherEvents.isCollectInExpress.strictValue = model.isCollectInExpress;
        }

        if (model.isGrouped) {
            this.viewModel.switcherEvents.isGrouped.strictValue = model.isGrouped;
            builderModelToStrict(this.viewModel.groupViewModel, model.betsGrouping);
        }

        this.viewModel.switcherEvents.isRecurrent.strictValue = model.isRecurrent;

        if (!model.isGrouped && !model.isRecurrent) {
            this.viewModel.switcherEvents.isEmptyEvent.strictValue = true;
        }

        this.viewModel.template.strictValue = model.template;
        this.viewModel.throttling.strictValue = model.throttling;
    }

    private mapCollectExpress(model: WlCrmTaskCollectExpressModel, key: string): WlRangeConditionViewModel | null {
        if (!isNullOrUndefined(model[key])) {
            return this.mapRangeCondition(model[key]);
        }
        return new WlRangeConditionViewModel;
    }

    private mapRangeCondition(model: WlCrmTaskRangeValueModel | WlCrmTaskRateRangeValueModel): WlRangeConditionViewModel {
        const mapped = new WlRangeConditionViewModel();

        mapped.condition.strictValue = model.conditionsType;
        mapped.value.strictValue = model.value;

        return mapped;
    }

    private addEmptyTask(): void {
        const task = new WlCrmTaskViewModel();
        if (this.isCollectInExpress) {
            task.betType.strictValue = WlBetTypes.Express;
        }

        if (this.viewModel.tasks.hasStrictValue) {
            this.viewModel.tasks.strictValue.push(task);
            return;
        }

        this.viewModel.tasks.strictValue = new Array<WlCrmTaskViewModel>(task);
    }

    private createAddForm(): WlCrmTaskCreateForm {
        const form = new WlCrmTaskCreateForm();

        form.name = this.viewModel.name.strictValue;
        form.description = this.viewModel.description.strictValue;
        form.bullets = this.viewModel.bullets.strictValue;
        form.forceComplete = this.viewModel.forceComplete.strictValue;
        form.isRecurrent = this.viewModel.switcherEvents.isRecurrent.strictValue;
        form.isCollectInExpress = this.viewModel.switcherEvents.isCollectInExpress.strictValue;
        form.deeplink = this.viewModel.deeplink.strictValue;
        form.imageUrl = this.viewModel.imageUrl.strictValue;
        form.imageWebUrl = this.viewModel.imageWebUrl.strictValue;
        form.taskType = this.viewModel.taskType.strictValue;
        form.templateId = this.viewModel.template.strictValue?.id;
        form.throttlingId = this.viewModel.throttling.strictValue?.id;

        form.targetType = form.taskType !== WlBattlePassTaskTypes.Other && this.viewModel.targetType.hasStrictValue
            ? this.viewModel.targetType.strictValue
            : WlCrmTaskTargetTypes.None;

        form.targetIndicator = this.viewModel.targetIndicator.strictValue;
        form.targetIndicatorType = this.viewModel.targetIndicatorType.strictValue;

        if (this.viewModel.limitProgressByDays.strictValue) {
            form.progressIntervalType = WlCrmTaskProgressIntervalTypes.Days;
            form.progressIntervalSize = this.viewModel.progressIntervalSize.strictValue;
        }

        form.tasks = this.mappedTaskModel();

        form.collectExpress = this.viewModel.switcherEvents.isCollectInExpress.strictValue ?
            this.mappedCollectExpressModel() : null;

        form.betsGrouping = this.viewModel.switcherEvents.isGrouped.strictValue ?
            builderStrictToModel(WlCrmTaskBetsGroupingForm, this.viewModel.groupViewModel) : null;

        return form;
    }

    private createUpdateForm(): WlCrmTaskUpdateForm {
        const form = new WlCrmTaskUpdateForm(this.createAddForm());

        form.id = this.viewModel.id.strictValue;

        return form;
    }

    private mappedTaskModel(): WlCrmTaskForm[] {
        return this.viewModel.tasks.strictValue.map((val) => {
            const mapped = new WlCrmTaskForm();

            mapped.id = val.id.strictValue;
            mapped.betType = val.betType.strictValue;
            mapped.betCount = val.betCount.strictValue;
            mapped.betPeriod = val.betPeriod.strictValue;
            mapped.betPlatforms = val.betPlatforms.strictValue;
            mapped.championshipId = val.championshipId.strictValue;
            mapped.sportTypesFilterType = val.isExcludedSportType.strictValue;
            mapped.gamesFilterType = val.isExcludedGames.strictValue;
            mapped.games = val.games.strictValue.map((value) => value.id);
            mapped.depositsCount = val.depositsCount.strictValue;
            mapped.sportTypeId = val.sportType.strictValue?.id;

            mapped.markets = val.activeMarkets.strictValue.map((value) => {
                const market = new WlCrmTaskMarketParameterForm();

                market.id = value.market.strictValue.id;
                market.ftsParameterA = value.ftsParameterA.strictValue;
                market.ftsParameterB = value.ftsParameterB.strictValue;
                market.ftsParameterC = value.ftsParameterC.strictValue;

                return market;
            });

            if (val.sportTypes.hasStrictValue) {
                mapped.sportTypes = val.sportTypes.strictValue.map((value) => value.id);
            }

            if (val.eventCountExpressRange.strictValue.value.hasStrictValue) {
                mapped.eventCountExpressRange = this.mapRangeValueModel(val.eventCountExpressRange.strictValue);
            }

            if (val.betRateRange.strictValue.value.hasStrictValue) {
                mapped.betRateRange = this.mapRateRangeValueModel(val.betRateRange.strictValue);
            }

            if (val.lineRateRange.strictValue.value.hasStrictValue) {
                mapped.lineRateRange = this.mapRateRangeValueModel(val.lineRateRange.strictValue);
            }

            if (val.betSumRange.strictValue.value.hasStrictValue) {
                mapped.betSumRange = this.mapRangeValueModel(val.betSumRange.strictValue);
            }

            if (val.totalWinAmountRange.strictValue.value.hasStrictValue) {
                mapped.totalWinAmountRange = this.mapRangeValueModel(val.totalWinAmountRange.strictValue);
            }

            if (val.betWinAmountRange.strictValue.value.hasStrictValue) {
                mapped.betWinAmountRange = this.mapRangeValueModel(val.betWinAmountRange.strictValue);
            }

            if (val.depositsSumRange.strictValue.value.hasStrictValue) {
                mapped.depositsSumRange = this.mapRangeValueModel(val.depositsSumRange.strictValue);
            }

            if (val.totalDepositsAmountRange.strictValue.value.hasStrictValue) {
                mapped.totalDepositsAmountRange = this.mapRangeValueModel(val.totalDepositsAmountRange.strictValue);
            }

            if (val.severalBetsSumRange.strictValue.value.hasStrictValue) {
                mapped.severalBetsSumRange = this.mapRangeValueModel(val.severalBetsSumRange.strictValue);
            }

            return mapped;
        });
    }

    private mappedCollectExpressModel(): WlCrmTaskCollectExpressForm {
        const mapped = new WlCrmTaskCollectExpressForm();
        mapped.betsCount = this.viewModel.collectExpressViewModel.betsCount.strictValue;

        if (this.viewModel.collectExpressViewModel.eventCountExpressRange?.strictValue?.value?.hasStrictValue) {
            mapped.eventCountExpressRange =
                this.mapRangeValueModel(this.viewModel.collectExpressViewModel.eventCountExpressRange.strictValue);
        }

        if (this.viewModel.collectExpressViewModel.betRateRange?.strictValue?.value?.hasStrictValue) {
            mapped.betRateRange = this.mapRateRangeValueModel(this.viewModel.collectExpressViewModel.betRateRange.strictValue);
        }

        if (this.viewModel.collectExpressViewModel.betSumRange?.strictValue?.value?.hasStrictValue) {
            mapped.betSumRange = this.mapRangeValueModel(this.viewModel.collectExpressViewModel.betSumRange.strictValue);
        }

        if (this.viewModel.collectExpressViewModel.totalWinAmountRange?.strictValue?.value?.hasStrictValue) {
            mapped.totalWinAmountRange = this.mapRangeValueModel(this.viewModel.collectExpressViewModel.totalWinAmountRange.strictValue);
        }

        if (this.viewModel.collectExpressViewModel.betWinAmountRange?.strictValue?.value?.hasStrictValue) {
            mapped.betWinAmountRange = this.mapRangeValueModel(this.viewModel.collectExpressViewModel.betWinAmountRange.strictValue);
        }

        return mapped;
    }

    private mapRangeValueModel(model: WlRangeConditionViewModel): WlCrmTaskRangeValueForm {
        const mapped = new WlCrmTaskRangeValueForm();

        mapped.conditionsType = model.condition.strictValue;
        mapped.value = model.value.strictValue;

        return mapped;
    }

    private mapRateRangeValueModel(model: WlRangeConditionViewModel): WlCrmTaskRateRangeValueForm {
        const mapped = new WlCrmTaskRateRangeValueForm();

        mapped.conditionsType = model.condition.strictValue;
        mapped.value = model.value.strictValue;

        return mapped;
    }

    private showListCrmTask(): void {
        this._router.navigate(['/menu/battlePass/task']);
    }

    private showCrmTaskByNumber(taskId: number): void {
        RouterNavigationExtensions.navigateAndRefresh(this._router, ['/menu/battlePass/task/edit', taskId]);
    }

    private setDisabled(): void {
        if (!this.isUsed) {
            return;
        }

        this.viewModel.switcherEvents.isRecurrent.disable();

        if (!this.isStarted) {
            return;
        }

        this.viewModel.name.disable();
        this.viewModel.taskType.disable();
        this.viewModel.targetType.disable();
        this.viewModel.targetIndicator.disable();
        this.viewModel.targetIndicatorType.disable();
        this.viewModel.switcherEvents.isCollectInExpress.disable();
        this.viewModel.switcherEvents.isGrouped.disable();
        this.viewModel.switcherEvents.isEmptyEvent.disable();
        this.viewModel.limitProgressByDays.disable();
        this.viewModel.progressIntervalSize.disable();
        if (this.viewModel.forceComplete.strictValue || this.viewModel.switcherEvents.isRecurrent.strictValue) {
            this.viewModel.forceComplete.disable();
        }
        this.viewModel.groupViewModel.groupType.disable();
        this.viewModel.groupViewModel.groupsCount.disable();
        this.viewModel.groupViewModel.eventsCountInGroup.disable();
        this.viewModel.groupViewModel.eventsSumInGroup.disable();
    }

    private targetTypeRule(): Array<WlCrmTaskTargetTypes> | null {
        if (this.isRecurrentTask) {
            return [WlCrmTaskTargetTypes.None];
        }

        if (!this.viewModel.taskType.hasStrictValue || !this.isCollectInExpress) {
            return null;
        }

        switch (this.viewModel.taskType.strictValue) {
            case WlBattlePassTaskTypes.Bets:
                return [WlCrmTaskTargetTypes.BySum];
            case WlBattlePassTaskTypes.Win:
            case WlBattlePassTaskTypes.Deposits:
            case WlBattlePassTaskTypes.Redeem:
                return null;
        }
    }

    private clearTaskTypeTarget(): void {
        const taskType = this.viewModel.taskType.strictValue;
        const target = this.viewModel.targetType.strictValue;
        if (isNullOrUndefined(target))
            return;
        if (isNullOrUndefined(taskType))
            return;

        if (taskType === WlBattlePassTaskTypes.Win && target === WlCrmTaskTargetTypes.BySum)
            return;

        if (taskType === WlBattlePassTaskTypes.Win && target === WlCrmTaskTargetTypes.ByCount)
            return;

        if (taskType === WlBattlePassTaskTypes.Bets && target === WlCrmTaskTargetTypes.ByCount)
            return;

        if (taskType === WlBattlePassTaskTypes.Bets && target === WlCrmTaskTargetTypes.BySum)
            return;

        this.viewModel.taskType.strictValue = null;
        this.viewModel.targetType.strictValue = null;
    }

    private clearViewModel(): void {
        const target = this.viewModel.targetType.strictValue;

        if (isNullOrUndefined(target))
            return;

        if (this.isOtherType) {
            this.viewModel.targetType.strictValue = null;
            this.viewModel.tasks.strictValue.length = 0;
        }

        if (this.isDepositsType && !this.isByCount && !this.isBySum) {
            this.viewModel.targetType.strictValue = null;
            this.viewModel.tasks.strictValue.length = 0;
        }
    }

    get purpose(): string {
        if (this.isEdit) {
            return `Редактирование букмекерского задания для боевого пропуска № ${this.viewModel.id.strictValue}`;
        }
        return 'Создание букмекерского задания для боевого пропуска';
    }

    get isValid(): boolean {
        return this.errors.length === 0;
    }

    get saveButtonText(): string {
        if (this.isEdit) {
            return 'Обновить';
        }
        return 'Сохранить';
    }

    get canDeleteTask(): boolean {
        return this.viewModel.tasks.strictValue.length > 1;
    }

    get isDepositsType(): boolean {
        return this.viewModel.taskType.strictValue === WlBattlePassTaskTypes.Deposits;
    }

    get isOtherType(): boolean {
        return this.viewModel.taskType.strictValue === WlBattlePassTaskTypes.Other;
    }

    get isGrouped(): boolean {
        return this.viewModel.switcherEvents.isGrouped?.strictValue;
    }

    get taskCanBeGrouped(): boolean {
        return !this.isDepositsType && !this.isOtherType;
    }

    get isCollectInExpress(): boolean {
        return this.viewModel.switcherEvents.isCollectInExpress?.strictValue;
    }

    get isRecurrentTask(): boolean {
        return this.viewModel.switcherEvents.isRecurrent?.strictValue;
    }

    get isTasksExist(): boolean {
        return this.viewModel.tasks.hasStrictValue && this.viewModel.tasks.strictValue.length > 0;
    }

    get isByCount(): boolean {
        return this.viewModel.targetType.strictValue === WlCrmTaskTargetTypes.ByCount;
    }

    get isBySum(): boolean {
        return this.viewModel.targetType.strictValue === WlCrmTaskTargetTypes.BySum;
    }

    get isProgressLimitedByDays(): boolean {
        return this.viewModel.limitProgressByDays.strictValue;
    }

    get isEdit(): boolean {
        return this.viewModel.id.hasStrictValue;
    }

    get canBeCloned(): boolean {
        return this.isEdit && this.isValid;
    }
}
