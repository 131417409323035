import { Injectable } from '@angular/core';
import { DropdownItem, IDropdownStrategy } from '@koddington/ga-common';
import { Observable, of } from 'rxjs';
import { WlPromoTypes, WlPromoTypesExtensions } from '../../autogen/DailyTasks';

@Injectable({
    providedIn: 'root',
})
export class DailyTaskTypesStrategy implements IDropdownStrategy<WlPromoTypes> {
    getEntities(): Observable<Array<WlPromoTypes>> {
        const entities = [WlPromoTypes.Pickem, WlPromoTypes.LootBox];
        return of(entities);
    }

    map: (model: WlPromoTypes) => DropdownItem<WlPromoTypes> = model => {
        const item = new DropdownItem<WlPromoTypes>();
        item.entity = model;
        item.title = WlPromoTypesExtensions.format(model);
        item.id = Number(model);

        return item;
    }
}
