import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { WlAuthStateService } from './services/auth-state-service';

@Injectable()
export class UnauthorizedErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly _router: Router,
    private readonly _authState: WlAuthStateService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    req = req.clone({
      withCredentials: true,
    });
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this._authState.setAuthState(null, null);
          this._router.navigate(['/default-page']);
        }

        return of(null);
      })
    );
  }
}
