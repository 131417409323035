import { StrictFormControl } from '@koddington/ga-common';

export class WlBattlePassFreeBetLootBoxContentsViewModel {
    public id: StrictFormControl<number> = new StrictFormControl<number>();
    public amount: StrictFormControl<number> = new StrictFormControl<number>();
    public probability: StrictFormControl<number> = new StrictFormControl<number>();
    public imageUrl: StrictFormControl<string> = new StrictFormControl<string>();
    public scarValue: StrictFormControl<number> = new StrictFormControl<number>();
    public scarcity: StrictFormControl<string> = new StrictFormControl<string>();
}
