import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { BattlePassService, WlBattlePassMarketsModel, WlMarketSearchForm } from 'src/app/modules/autogen/BattlePass';
import { IScrollService } from 'src/app/common/abstracts/types/scroll-service-interface';
import { WlResult } from 'src/app/models/common/results/wl-result';

@Injectable({
    providedIn: 'root'
})
export class WlBattlePassMarketsScrollServiceAdapter implements IScrollService<WlBattlePassMarketsModel, WlMarketSearchForm> {

    constructor(private readonly _service: BattlePassService) {
    }

    public search(item: WlMarketSearchForm): Observable<WlResult<WlBattlePassMarketsModel[]>> {
        return this._service.listMarkets(item)
            .pipe(
                take(1),
                map(u => {
                    const res = new WlResult<WlBattlePassMarketsModel[]>();
                    res.result = u.results;
                    return res;
                })
            );
    }
}
