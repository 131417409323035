import { Injectable } from '@angular/core';
import { DropdownItem, EnumExtensions, IDropdownStrategy } from '@koddington/ga-common';
import { WlDailyTaskStates, WlDailyTaskStatesExtensions } from '../../autogen/DailyTasks';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DailyTaskStateStrategy implements IDropdownStrategy<WlDailyTaskStates> {
    getEntities(): Observable<Array<WlDailyTaskStates>> {
        const entities = EnumExtensions.toArray(WlDailyTaskStates);
        return of(entities);
    }

    map: (model: WlDailyTaskStates) => DropdownItem<WlDailyTaskStates> = model => {
        const item = new DropdownItem<WlDailyTaskStates>();
        item.entity = model;
        item.title = WlDailyTaskStatesExtensions.format(model);
        item.id = Number(model);

        return item;
    }
}
