import { WlBattlePassLootBoxViewModel } from '../../../models/wl-battle-pass-loot-box-view-model';
import { WlBattlePassGameLootBoxSectorValidator } from './wl-battle-pass-game-loot-box-sector-validator';
import { AbstractModelValidator, StrictError } from '@koddington/ga-common';
import { PatternConsts } from '../../../../../common/consts/pattern-consts';

export class WlBattlePassGameLootBoxCrudValidator extends AbstractModelValidator<WlBattlePassLootBoxViewModel> {
    private selectorValidator = new WlBattlePassGameLootBoxSectorValidator();

    constructor() {
        super();

        this.ruleForControl((u) => u.name).required('Название обязательно');
        this.ruleForControl((u) => u.description).required('Описание обязательно');
        this.ruleForControl((u) => u.gameType).required('Тип игры обязателен');
        this.ruleForControl((u) => u.grade).required();
        this.ruleForControl((u) => u.averageFreebet)
            .required()
            .customRule((field) => {
                if (PatternConsts.DecimalDigit.test(field?.toString())) {
                    return null;
                }

                return new StrictError('decimalDigitError', 'Количество знаков после запятой не должно превышать 2');
            });
        this.ruleForControl((u) => u.sumProbability)
            .customRule((fieldValue, model) => {
                if (model.sumProbability.strictValue !== 1) {
                    return new StrictError('Error', 'Сумма вероятностей должна равняться 1');
                }
                return null;
            });

        this.ruleForControlIf(
            (u) => u.rewards,
            (u) => u.rewards.strictValue.length > 0
        )
            .customRule((field, model) => {
                const errors = this.selectorValidator.validateArray(field);

                return errors.length > 0 ? new StrictError('selectorHasErrors', '') : null;
            });
    }
}
