import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { RouterModule } from '@angular/router';
import { MainLayoutRoutes } from './layouts-module.routes';
import { TrackerModule } from '../trackers/app-tracker.module';
import { WlBattlePassModule } from '../battle-pass/wl-battle-pass.module';
import { RateRaceModule } from '../rate-race-area51/rate-race-area51.module';
import {DailyTasksModule} from '../daily-tasks/daily-tasks.module';

@NgModule({
    declarations: [MainLayoutComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(MainLayoutRoutes),
        TrackerModule,
        WlBattlePassModule,
        RateRaceModule,
        DailyTasksModule
    ],
    exports: [MainLayoutComponent],
})
export class LayoutsModule {
}
