import { Observable } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { ScrollStrategy } from 'src/app/common/abstracts/abstract-scroll-strategy';
import { IAutocompleteItem, isNullOrUndefined, StrictFormControl } from '@koddington/ga-common';
import { PushTemplateFilterForm, PushTemplatesService, WlNotificationTemplate } from '../../../../autogen/BattlePass';

@Injectable()
export class PushTemplateScrollStrategy extends ScrollStrategy<WlNotificationTemplate, PushTemplateFilterForm> implements OnDestroy {
    private _isShow: StrictFormControl<boolean>;
    constructor(protected readonly _apiClient: PushTemplatesService) {
        super(_apiClient);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public setIsShow(isShowAllTemplate: StrictFormControl<boolean>): void {
        this._isShow = isShowAllTemplate;
        this.subscriptionSwitchCheckBox();
    }

    private subscriptionSwitchCheckBox(): void {
        this._isShow.strictValueSource.subscribe((u) => {
            if (!isNullOrUndefined(this._externalModel) && !isNullOrUndefined(this._sourceClearAutocomplete$)) {
                this._sourceClearAutocomplete$.next();
            }
        });
    }

    protected load(item: PushTemplateFilterForm): Observable<any> {
        item.isScrollStrategy = true;
        if (!isNullOrUndefined(this._isShow) && this._isShow.hasStrictValue) {
            item.isShowAllTemplates = this._isShow.strictValue;
        }
        return super.load(item);
    }

    convert(model: WlNotificationTemplate): IAutocompleteItem<WlNotificationTemplate> {
        return this.convert_(() => {
            return {
                entity: model,
                title: model.name,
                key: model.id?.toString(),
            };
        });
    }

    public clear(): void {
        this._sourceClearAutocomplete$.next();
    }
}
