import {Pipe, PipeTransform } from '@angular/core';
import { stringIsNullOrEmpty } from '@koddington/ga-common';

@Pipe({
    name: 'wlString'
})
export class WlStringPipe implements PipeTransform {
    public transform(value?: string): string {
        if (stringIsNullOrEmpty(value)) {
            return '-';
        }

        return value;
    }
}
