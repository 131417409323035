<div class="wl-input-container-search {{ className }}-container-custom" *ngIf="isSearch">
    <div class="wl-strict-input-wrapper">
        <input
            type="{{ type }}"
            class="form-control wl-input wl-input-search {{ className }}-input-custom"
            [formControl]="control"
            [disabled]="control.disabled"
            #inputText
            [class.is-invalid]="control.shouldDisplayErrors | async"
            [placeholder]="placeholder"
            (change)="valueChange.emit()"
            (keyup.enter)="searchMethod.emit()"
        />
        <div class="wl-error-bubble wl-error-bubble-position" *ngIf="control.hasStrictErrors">
                <span *ngFor="let error of this.control.strictErrors | async">
                    {{ error.message }}
                </span>
        </div>
    </div>
</div>

<div class="wl-input-form-container" *ngIf="!isSearch">
    <div class="wl-app-strict-input-label-block">
        <label *ngIf="title" class="wl-input-label {{ className }}-label-custom">{{ title }}</label>
        <div class="wl-strict-input-wrapper">
            <input
                type="{{ type }}"
                class="form-control  {{ className }}-input-custom wl-input"
                [formControl]="control"
                [disabled]="control.disabled"
                #inputText
                [class.is-invalid]="control.shouldDisplayErrors | async"
                [placeholder]="placeholder"
                (change)="valueChange.emit()"
                (keyup.enter)="searchMethod.emit()"
            />
            <div class="wl-error-bubble wl-error-bubble-position" *ngIf="control.hasStrictErrors">
                <span *ngFor="let error of this.control.strictErrors | async">
                    {{ error.message }}
                </span>
            </div>
        </div>
    </div>
</div>
