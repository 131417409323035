import {AbstractModelValidator, AddErrorsToControlMode, StrictError, ValidationLevel} from '@koddington/ga-common';
import {DailyTasksLootBoxGradeViewModel} from '../view-models/daily-tasks-loot-box-grade.view-model';
import {
    WlBattlePassFreeBetLootBoxCrudValidator
} from '../../../../../battle-pass/components/wl-battle-pass-free-bet-loot-box-crud/validators/wl-battle-pass-free-bet-loot-box-crud-validator';

export class DailyTasksLootBoxesGradesValidator extends AbstractModelValidator<DailyTasksLootBoxGradeViewModel> {
    private freeBetLootBoxValidator = new WlBattlePassFreeBetLootBoxCrudValidator();
    private lootBoxesWithRepeatsIds: number[] = [];

    constructor() {
        super();

        this.ruleForControl(u => u.gradeStart)
            .required('Минимальная граница грейда обязательна!')
            .min(0, 'Граница грейда не должна быть ниже нуля');

        this.ruleForControlIf(u => u.selectedLootBoxModel, u => u.lootBox.strictValue?.id.hasStrictValue && this.lootBoxesWithRepeatsIds.length > 0)
            .customRule(u => this.lootBoxesWithRepeatsIds.includes(u.id)
                ? new StrictError('lootBoxRepeat', 'Фрибетный лутбокс используется более одного раза')
                : null);
    }

    public validate(model: DailyTasksLootBoxGradeViewModel, validationLevel?: ValidationLevel, addErrorsToControls?: AddErrorsToControlMode): StrictError[] {
        this.lootBoxesWithRepeatsIds = [];

        return [
            ...super.validate(model, validationLevel, addErrorsToControls),
            ...this.freeBetLootBoxValidator.validate(model.lootBox.strictValue, validationLevel, addErrorsToControls)
        ];
    }

    public validateArray(models: DailyTasksLootBoxGradeViewModel[], validationLevel?: ValidationLevel, addErrorsToControls?: AddErrorsToControlMode): StrictError[] {
        const counts = {};
        const ids = models.filter(u => u.lootBox.strictValue?.id.hasStrictValue)
            .map(u => u.lootBox.strictValue.id.strictValue);
        ids.forEach(function (x) {
            counts[x] = (counts[x] || 0) + 1;
        });
        this.lootBoxesWithRepeatsIds = ids.filter(u => counts[u] > 1);

        const lbErrors = models
            .map(u => u.lootBox.strictValue)
            .map(u => this.freeBetLootBoxValidator.validate(u, validationLevel, addErrorsToControls))
            .reduce((prev, curr) => curr.concat(prev), []);
        return [
            ...super.validateArray(models, validationLevel, addErrorsToControls),
            ...lbErrors
        ];
    }
}
