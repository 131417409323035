import { Injectable } from '@angular/core';
import { DropdownItem, GaMessagingService, IDropdownStrategy } from '@koddington/ga-common';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { BattlePassService, WlGameTaskIcon } from '../../autogen/BattlePass';

@Injectable({
    providedIn: 'root'
})
export class GameTasksIconsStrategyService implements IDropdownStrategy<WlGameTaskIcon> {

    constructor(private _bpApi: BattlePassService,
                private _messages: GaMessagingService) {
    }

    public getEntities(): Observable<Array<WlGameTaskIcon>> {
        return this._bpApi.getGameIcons().pipe(
            first(),
            filter(u => !this._messages.tryShowError(u)),
            map(u => u.result)
        );
    }

    public map: (model: WlGameTaskIcon) => DropdownItem<WlGameTaskIcon> = model => {
        const item = new DropdownItem<WlGameTaskIcon>();
        item.entity = model;
        item.title = model.code;
        item.id = model.id;

        return item;
    }
}
