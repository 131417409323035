<div class="wl-checkbox-input-strict-container">
    <input
        type="checkbox"
        class="wl-checkbox-input"
        [disabled]="control?.disabled"
        [(ngModel)]="control.strictValue"
        (change)="onSelect()"
    />
    <label class="wl-checkbox-label" (click)="onClickLabel()" *ngIf="label" for="wl-checkbox-input">{{ label }}</label>
</div>
