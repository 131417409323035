<app-wl-page-header header="Профиль пользователя"></app-wl-page-header>
<div class="wl-search-row">
    <app-wl-strict-input-number
        class="wl-app-input-search-wrapper wl-app-search-input-margin-left"
        isSearch="true"
        [control]="userId"
        [placeholder]="'Id пользователя'"
        [min]="0"
        (searchMethod)="search()"
    ></app-wl-strict-input-number>
    <app-wl-strict-input-number
        class="wl-app-input-search-wrapper wl-app-search-input-margin-left"
        isSearch="true"
        [control]="seasonId"
        [placeholder]="'Id сезона'"
        [min]="0"
        (searchMethod)="search()"
    ></app-wl-strict-input-number>
    <button class="wl-search-btn" (click)="search()" [disabled]="!userId.hasStrictValue || !seasonId.hasStrictValue">Показать</button>
</div>

<div class="wl-bp-userinfo-season-container" *ngIf="result && !isLoading">
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Id пользователя: </span>
        <span class="wl-userinfo-field-value">
            {{ result.userId }}
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Id сезона: </span>
        <span class="wl-userinfo-field-value">
            <a [routerLink]="['/menu/battlePass/season/edit', result.season.seasonId]">{{ result.season.seasonId }}</a>
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Статус сезона: </span>
        <span class="wl-userinfo-field-value">
            {{ result.season.state | wlBattlePassStatesTransform }}
        </span>
    </div>

    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Название сезона: </span>
        <span class="wl-userinfo-field-value">
            {{ result.season.name }}
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Время начала сезона: </span>
        <span class="wl-userinfo-field-value">
            {{ result.season.startDate | wlDate }}
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Время окончания сезона: </span>
        <span class="wl-userinfo-field-value">
            {{ result.season.endDate | wlDate }}
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Steam подключен: </span>
        <span class="wl-userinfo-field-value">
            {{ result.platformProfile.steam.isSteamBounded | wlBoolean }}
        </span>
    </div>
  <div class="wl-user-info-row" *ngIf="result.platformProfile.steam.isSteamBounded">
    <a href="{{steamProfileUrl}}" target="_blank">Steam аккаунт</a>
    <button class="wl-search-btn wl-platforms" cdkCopyToClipboard="{{steamProfileUrl}}">Копировать Url</button>
  </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Дата подключения Steam: </span>
        <span class="wl-userinfo-field-value">
            {{ result.platformProfile.steam.bindingTime | wlDateTime }}
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Twitch подключен: </span>
        <span class="wl-userinfo-field-value">
            {{ result.platformProfile.twitch.isTwitchBounded | wlBoolean }}
        </span>
    </div>

  <div class="wl-user-info-row" *ngIf="result.platformProfile.twitch.isTwitchBounded">
    <a href="{{twitchProfileUrl}}" target="_blank">Twitch Аккаунт </a>
    <button class="wl-search-btn wl-platforms" cdkCopyToClipboard="{{twitchProfileUrl}}">Копировать Url</button>
  </div>


    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Дата подключения Twitch: </span>
        <span class="wl-userinfo-field-value">
            {{ result.platformProfile.twitch.bindingTime | wlDateTime }}
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Tradelink: </span>
        <span class="wl-userinfo-field-value" [ngClass]="{ 'one-line': showTradeLinkAsLine }" (click)="showTradeLinkAsLine = !showTradeLinkAsLine">
            {{ result.tradeLink | wlString }}
        </span>
    </div>
</div>
<app-wl-page-header [ignoreTitleSet]="true" class="wl-userinfo-level-information" header="Базовая информация о RateRace" *ngIf="result && !isLoading"></app-wl-page-header>
<div class="wl-bp-userinfo-season-container" *ngIf="result && !isLoading && !rateRaceId">
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Отсутствует информация о RateRace </span>
    </div>
</div>
<ga-table class="wl-user-info-table" *ngIf="!!rateRaceInfoTableData && result && rateRaceId" [data]="rateRaceInfoTableData" [breakWordOnly]="true"></ga-table>

<app-wl-page-header [ignoreTitleSet]="true" class="wl-userinfo-level-information" header="Текущий уровень" *ngIf="result && !isLoading && !hasUserRateRaceLevel"></app-wl-page-header>
<ga-table class="wl-user-info-table" *ngIf="!!currentLevelTableData && !hasUserRateRaceLevel && result" [data]="currentLevelTableData" [breakWordOnly]="true"></ga-table>

<app-wl-page-header [ignoreTitleSet]="true" class="wl-userinfo-level-information" header="Текущий уровень (RateRace)" *ngIf="!!userRateRaceLevelTableData && hasUserRateRaceLevel"></app-wl-page-header>
<ga-table class="wl-user-info-table" *ngIf="!!userRateRaceLevelTableData && hasUserRateRaceLevel" [data]="userRateRaceLevelTableData" [breakWordOnly]="true"></ga-table>

<app-wl-page-header [ignoreTitleSet]="true" class="wl-userinfo-level-information" header="Уровни" *ngIf="result && !isLoading"></app-wl-page-header>
<ga-table class="wl-user-info-table" *ngIf="!!allLevelsTableData && result" [data]="allLevelsTableData" [breakWordOnly]="true"></ga-table>

<app-wl-page-header [ignoreTitleSet]="true" class="wl-userinfo-level-information" header="Уровень RateRace" *ngIf="result && !isLoading"></app-wl-page-header>
<ga-table class="wl-user-info-table" *ngIf="!!userRateRaceLevelTableData && hasUserRateRaceLevel" [data]="userRateRaceLevelTableData" [breakWordOnly]="true"></ga-table>
<div class="wl-bp-userinfo-season-container" *ngIf="result && !isLoading && !hasUserRateRaceLevel">
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Пользователь не достиг уровня RateRace </span>
    </div>
</div>
<ng-template #showLootBoxInfo let-rawLootBoxes="lootBoxes" let-rewardSent="rewardSent">
    <ng-template [ngIf]="typifyLootBox(rawLootBoxes)" let-lootBoxes="ngIf">
        <div class="wl-task-info-text" *ngFor="let lootBox of lootBoxes; let i = index">
            <div class="wl-task-info-text">
                <div>Уведомление о лутбоксе отправлено: {{ rewardSent | wlBoolean }}</div>
                <div>Дата выбора награды: {{ lootBox?.rewardPickAt | wlDateTime }}</div>
                <div>Дата отправления запроса на выдачу награды: {{ lootBox?.requestAccrualSentAt | wlDateTime }}</div>
                <div>Тип выбранной награды: {{ lootBox?.rewardType | wlSelectedLootboxTypesTransform }}</div>
                <div *ngIf="lootBox?.lootBoxId">
                    Id лутбокса:
                    <a [routerLink]="[getLootboxRoute(lootBox), lootBox.lootBoxId]">{{ lootBox.lootBoxId }}</a>
                </div>
                <div>Открыт: {{ lootBox?.lootBoxOpened | wlBoolean }}</div>
                <div>Состояние награды: {{ lootBox?.state | wlBattlePassAccrueRewardStateTypesTransform }}</div>
                <div *ngIf="lootBox?.freebetAmount">Номинал фрибета: {{ lootBox.freebetAmount }}</div>
                <div *ngIf="lootBox?.gameRewardId">
                    Игровая награда:
                    <a [routerLink]="['/menu/battlePass/reward/view', lootBox.gameRewardId]">{{ lootBox.gameRewardId }}</a>
                </div>
                <div>Тип выполненного задания: {{ lootBox?.completeTaskType | wlCompleteTaskTypesTransform }}</div>
                <hr *ngIf="isLastItem(i, lootBoxes.length)" />
            </div>
        </div>
    </ng-template>
</ng-template>
<ng-template #showTasks let-rawTasks="tasks" let-showForceCompleteButton="showForceCompleteButton">
    <ng-template [ngIf]="typifyTasks(rawTasks)" let-tasks="ngIf">
        <div class="wl-task-info-text" *ngFor="let task of tasks; let i = index">
            <span *ngIf="isCrmTask(task)">
                <a [routerLink]="['/menu/battlePass/task/edit', task.crmTaskGroupId]">{{ task.crmTaskGroupId }}</a>
            </span>
            <span *ngIf="isGameTask(task)">
                <a [routerLink]="['/menu/battlePass/gameTask/edit', task.gameTaskId]">{{ task.gameTaskId }}</a>
            </span>
            <div *ngIf="isWildCard(task)" class="wl-task-wildcard">WildCard-задание</div>
            <div>Id для профиля: {{ task.userTaskId }}</div>
            <div *ngIf="isCrmTask(task)">Id подписки задания: {{ task.crmTaskGroupSubscriptionId }}</div>
            <div>Завершено: {{ task.isCompleted | wlBoolean }}</div>
            <div *ngIf="hasCompletionDate(task)">Дата выполнения: {{ task.taskCompletionDate | wlDateTime }}</div>
            <ng-container *ngIf="hasSubTasks(task)" [ngTemplateOutlet]="showCrmSubTasks" [ngTemplateOutletContext]="{ subTasks: task.crmSubTasks }"></ng-container>
            <div *ngIf="hasProgressOnTask(task)">Прогресс: {{ progressForTask(task) }}</div>
            <button class="wl-a-button-cell" *ngIf="!showForceCompleteButton && canForceComplete(task)" (click)="forceComplete(task)">Зачесть</button>
            <hr *ngIf="isLastItem(i, tasks.length)" />
        </div>
    </ng-template>
</ng-template>

<ng-template #showLevelAdditionalInfo let-rawLevel="level">
    <ng-template [ngIf]="typifyLevel(rawLevel)" let-level="ngIf">
        <div class="wl-task-info-text">Дата старта уровня: {{ level.levelStartDate | wlDateTime}}</div>
        <div class="wl-task-info-text">Игрок попал в уровень: {{ level.levelEnterDate | wlDateTime}}</div>
    </ng-template>
</ng-template>

<ng-template #showCrmSubTasks let-rawSubTasks="subTasks">
    <ng-template [ngIf]="typifySubTasks(rawSubTasks)" let-subTasks="ngIf">
        <div *ngFor="let subTask of subTasks; let i = index">
            <br />
            <div *ngIf="hasCrmTaskId(subTask)">Подзадание: {{ subTask.crmTaskId }}</div>
            <div *ngIf="hasSubTaskId(subTask)">Id подписки подзадания: {{ subTask.subscriptionId }}</div>
            <div *ngIf="hasProgressOnSubTask(subTask)">Прогресс подзадания: {{ progressForTask(subTask) }}</div>
            <div *ngIf="hasDeposits(subTask)">Id депозитов: {{ subTask.depositIds | wlStringJoin }}</div>
            <div *ngIf="hasBets(subTask)">
                Id ставок:
                <span *ngFor="let bet of subTask.bets" [class.wl-bet-used-for-progress]="bet.usedForProgress"> {{ bet.betId }} <br /> </span>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #showLootBoxContents let-rawContents="contents">
    <ng-template [ngIf]="typifyLootBoxContents(rawContents)" let-contents="ngIf">
        <div class="wl-task-info-text" *ngFor="let content of contents; let i = index">
            <a [routerLink]="['/menu/battlePass/reward/view', content.reward.id]">{{ content.reward.id }}</a>
            <span *ngIf="isLastItem(i, contents.length)">, </span>
        </div>
    </ng-template>
</ng-template>
