import { AbstractModelValidator } from '@koddington/ga-common';
import {WlMarketsParameterViewModel} from '../../../models/wl-markets-parameter-view-model';

export class WlBattlePassMarketParameterValidator extends AbstractModelValidator<WlMarketsParameterViewModel> {
    constructor() {
        super();

        this.ruleForControl(u => u.market).required();
    }
}
