import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { formatDateTime, GaPagedResult, GaPagingForm, GaTableData } from '@koddington/ga-common';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { GaSearchManagementStateService } from 'src/app/modules/shared/extensions/search-state-managment';
import { DailyTasksPromotionsService, WlDtPromotion, WlPromotionsSearchForm, WlPromoTypesExtensions } from '../../../autogen/DailyTasks';
import { WlDtPromotionsSearchViewModel } from './models/wl-daily-tasks-promotions-search-view-model';
import { WlBattlePassStatesExtensions } from '../../../autogen/BattlePass';
import { DailyTaskTypesStrategy } from '../../strategies/daily-task-types-strategy.service';
import { WlBattlePassStatesStrategy } from '../../../shared/strategies/wl-battle-pass-states.strategy';

@Component({
    selector: 'app-wl-promotions-list',
    templateUrl: './wl-promotions-list.component.html',
    providers: [GaSearchManagementStateService],
})
export class WlPromotionsListComponent implements OnInit, OnDestroy {
    public tableData: GaTableData<WlDtPromotion>;
    public apiResult: GaPagedResult<WlDtPromotion>;
    public pagingModel = new WlDtPromotionsSearchViewModel();

    constructor(
        private readonly _router: Router,
        private readonly _searchManagementState: GaSearchManagementStateService<GaPagedResult<WlDtPromotion>>,
        private readonly _service: DailyTasksPromotionsService,
        protected readonly promoTypesStrategy: DailyTaskTypesStrategy,
        protected readonly statesStrategy: WlBattlePassStatesStrategy
    ) {}

    ngOnInit() {
        this.init();
    }

    ngOnDestroy() {}

    public add(): void {
        this._router.navigate(['/menu/dailyTasks/promotions/add']);
    }

    public pageChanged(pagingEvent: GaPagingForm): void {
        this._searchManagementState.nextSearch(pagingEvent);
    }

    public pageFiltered(page: GaPagingForm = null): void {
        this._searchManagementState.nextSearch(page, true);
    }

    private init(): void {
        this._searchManagementState.init((form) => this._service.list(form as WlPromotionsSearchForm), this.pagingModel);
        this._searchManagementState.searchState.pipe(untilDestroyed(this)).subscribe((result: GaPagedResult<WlDtPromotion>) => {
            this.apiResult = result;
            this._searchManagementState.loadingStatus = false;
            this.mapToTable(result.results);
        });
    }

    private mapToTable(models: WlDtPromotion[]): void {
        const data = new GaTableData<WlDtPromotion>();

        this.tableData = data
            .addRouterLinkColumn(
                (elem) => elem.id,
                (elem) => ['/menu/dailyTasks/promotions/update', elem.id],
                { title: 'Id', widthSize: 80 }
            )
            .addSimpleColumn((elem) => elem.name, { title: 'Название', widthSize: 450 })
            .addSimpleColumn((elem) => WlBattlePassStatesExtensions.format(elem.state), { title: 'Состояние', widthSize: 120 })
            .addSimpleColumn((elem) => WlPromoTypesExtensions.format(elem.type), { title: 'Тип акции', widthSize: 120 })
            .addSimpleColumn((elem) => elem.entityActors.creatorAccountLogin, { title: 'Создано', widthSize: 150 })
            .addSimpleColumn((elem) => elem.entityActors.editorAccountLogin, { title: 'Изменено', widthSize: 150 })
            .addSimpleColumn((elem) => formatDateTime(elem.entityActors.createdDate), { title: 'Дата создания', widthSize: 150 })
            .addSimpleColumn((elem) => formatDateTime(elem.entityActors.modifiedDate), { title: 'Дата изменения', widthSize: 150 })
            .setData(models);
    }

    get loading(): boolean {
        return this._searchManagementState.loadingStatus;
    }
}
