import { BetPeriods, BetPeriodsExtensions } from '../../autogen/BattlePass';
import { DropdownItem, EnumExtensions, IDropdownStrategy } from '@koddington/ga-common';
import { Observable, of } from 'rxjs';

export class WlBetPeriodTypeStrategy implements IDropdownStrategy<BetPeriods> {
    getEntities(): Observable<Array<BetPeriods>> {
        const entities = EnumExtensions.toArray(BetPeriods);
        return of(entities);
    }

    map: (model: BetPeriods) => DropdownItem<BetPeriods> = model => {
        const item = new DropdownItem<BetPeriods>();
        item.entity = model;
        item.title = BetPeriodsExtensions.format(model);
        item.id = Number(model);

        return item;
    }
}
