import {AbstractModelValidator, StrictError} from '@koddington/ga-common';
import {DailyTasksPlayersCardsSelectorViewModel} from '../models/daily-tasks-players-cards-selector-view-model';


export class DailyTasksPlayersCardsSelectorValidator  extends AbstractModelValidator<DailyTasksPlayersCardsSelectorViewModel> {
  constructor() {
    super();

    this.ruleForControl((u) => u.playerName)
      .customRule((fieldValue, model) => {
        if (!fieldValue) {
          return new StrictError('DailyTasksDayError7', 'Имя игрока обязательно');
        }
        return null;
      });

    this.ruleForControl((u) => u.playerCardUrl)
      .customRule((fieldValue, model) => {
        if (!fieldValue) {
          return new StrictError('DailyTasksDayError7', 'Url обязателен');
        }
        return null;
      });

  }
}
