<div class="wl-row">
  <button class="wl-button-primary wl-btn-push-save-wrapper" (click)="add()">
    Добавить партнера
  </button>
</div>
<app-loading *ngIf="loading"></app-loading>
<ga-table *ngIf="!!tableData" [data]="tableData"></ga-table>
<div class="wl-paging-wrapper">
  <app-wl-paging
    class="wl-test-paging"
    *ngIf="partners"
    [pagedResult]="partners"
    (pageChangedEvent)="pageChanged($event)"
  ></app-wl-paging>
</div>

<ng-template #reward let-partner="partner">
  <button
    *ngIf="getHasReward(partner)"
    class="wl-btn-form-inactive wl-btn-push-save-wrapper"
    (click)="declineReward(partner)"
  >
    Запретить подарки
  </button>
  <button
    *ngIf="!getHasReward(partner)"
    class="wl-btn-form-active wl-btn-push-save-wrapper"
    (click)="acceptReward(partner)"
  >
    Разрешить подарки
  </button>
</ng-template>

<ng-template #hasActivatePartner let-partner="partner">
  <button
    *ngIf="!getHasState(partner)"
    class="wl-btn-form-active wl-btn-push-save-wrapper"
    (click)="activatePartner(partner)"
  >
    Активировать партнера
  </button>

  <button
    *ngIf="getHasState(partner)"
    class="wl-btn-form-inactive wl-btn-push-save-wrapper"
    (click)="deactivatePartner(partner)"
  >
    Деактивировать партнера
  </button>
</ng-template>
