import { Routes } from '@angular/router';
import { WlTrackerComponent } from './components/app-tracker/app-tracker.component';
import { TrackerGamblerBindingProfileComponent } from './components/tracker-gambler-profile/tracker-gambler-binding-profile.component';

export const TrackerRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'regestry',
        component: WlTrackerComponent,
      },
      {
            path: 'gamblerBindingProfile',
            component: TrackerGamblerBindingProfileComponent,
      },
    ],
  },
];
