import { StrictFormControl } from '@koddington/ga-common';
import {Dayjs} from 'dayjs';
import { WlBattlePassStates, WlRateRaceTournamentListModel, WlSeasonLevelModel } from '../../autogen/BattlePass';

export class WlSeasonViewModel {
    public id: StrictFormControl<number> = new StrictFormControl<number>(null);
    public name: StrictFormControl<string> = new StrictFormControl<string>(null);
    public description: StrictFormControl<string> = new StrictFormControl<string>(null);
    public imageUrl: StrictFormControl<string> = new StrictFormControl<string>(null);
    public payoutDate?: StrictFormControl<Dayjs> = new StrictFormControl<Dayjs>(null);
    public startDate: StrictFormControl<Dayjs> = new StrictFormControl<Dayjs>(null);
    public endDate: StrictFormControl<Dayjs> = new StrictFormControl<Dayjs>(null);
    public levelsCount: StrictFormControl<number> = new StrictFormControl<number>(null);
    public rateRace: StrictFormControl<WlRateRaceTournamentListModel> = new StrictFormControl<WlRateRaceTournamentListModel>(null);
    public state: StrictFormControl<WlBattlePassStates> = new StrictFormControl<WlBattlePassStates>(null);
    public levels: StrictFormControl<WlSeasonLevelModel[]> = new StrictFormControl<WlSeasonLevelModel[]>([]);
    public actorLogin: StrictFormControl<string> = new StrictFormControl<string>(null);
    public editorLogin: StrictFormControl<string> = new StrictFormControl<string>(null);
}
