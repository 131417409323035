<div class="wl-season-level-selector">
    <div class="wl-season-level-selector-block">
        <div class="wl-season-level-column one-col">
            <app-wl-strict-input-number
                    class="wl-season-level-column-item wl-season-level-task-order"
                    [control]="viewModel.order"
                    title="Уровень"
                    placeholder="Порядковый номер"
                    min="1"
                    max="60"
                    (valueChange)="userManipulationsSource.next()"
            ></app-wl-strict-input-number>
        </div>
        <div class="wl-season-level-column two-col">
            <app-wl-strict-input-boolean
                    class="wl-season-level-column-item"
                    [control]="viewModel.isGamblerLevel"
                    label="Режим лудки"
                    (userSelect)="onGamblerModeChange()"
            ></app-wl-strict-input-boolean>
        </div>
    </div>
    <div class="wl-season-level-selector-block">
        <div class="wl-season-level-column one-col">
            <app-wl-strict-input-text
                class="wl-season-level-column-item wl-season-level-task-order"
                [control]="viewModel.name"
                title="Название"
                placeholder="Введите название уровня"
                (valueChange)="userManipulationsSource.next()"
            >
            </app-wl-strict-input-text>
            <div class="wl-season-level-column-item wl-season-level-task-order">
                <app-wl-datetime-single-picker
                    class="wl-season-level-date-selector wl-float-left-wrapper wl-margin-right-8"
                    [date]="viewModel.startDate"
                    title="Дата старта уровня"
                    (userChosen)="userManipulationsSource.next()"
                ></app-wl-datetime-single-picker>
                <button class="wl-btn-form-inactive"
                        type="button"
                        [disabled]="isActiveLevel || !hasSeasonStartDate"
                        (click)="setLevelDateFromSeasonStart()">
                    По старту сезона
                </button>
            </div>
            <app-wl-strict-input-text
                class="wl-season-level-column-item wl-season-level-task-order"
                [control]="viewModel.iconUrl"
                title="Иконка"
                placeholder="Введите ссылку на иконку"
                (valueChange)="userManipulationsSource.next()"
            ></app-wl-strict-input-text>
        </div>
        <div class="wl-season-level-column two-col">
            <app-wl-strict-input-text
                class="wl-season-level-column-item wl-season-level-task-order"
                [control]="viewModel.description"
                title="Описание"
                placeholder="Введите описание уровня"
                (valueChange)="userManipulationsSource.next()"
            >
            </app-wl-strict-input-text>
            <app-wl-strict-input-text
                class="wl-season-level-column-item wl-season-level-task-order"
                [control]="viewModel.imageUrl"
                title="Изображение"
                placeholder="Введите ссылку на изображение"
                (valueChange)="userManipulationsSource.next()"
            >
            </app-wl-strict-input-text>
            <app-wl-strict-input-text
                class="wl-season-level-column-item wl-season-level-task-order"
                [control]="viewModel.inactiveIconUrl"
                title="Неактивная иконка"
                placeholder="Введите ссылку на иконку"
                (valueChange)="userManipulationsSource.next()"
            >
            </app-wl-strict-input-text>
        </div>
    </div>
    <div class="wl-season-level-selector-block">
        <div class="wl-season-level-column one-col">
            <div class="wl-season-level-task-order">Букмекерские задания</div>
            <div class="wl-season-level-column-item" *ngFor="let crm of crmTasks">
                <app-wl-strict-input-number
                        class="wl-input-number-order"
                        placeholder=""
                        min="1"
                        [max]="maxOrder"
                        (valueChange)="userManipulationsSource.next()"
                        [control]="crm.order">
                </app-wl-strict-input-number>
                <div class="wl-season-level-task-selector">
                    <app-strict-scroll-autocomplete-new
                            class="wl-app-strict-control"
                            (userFinalSelected)="userManipulationsSource.next()"
                            [strategy]="crm.strategy"
                            [control]="crm.task"
                    ></app-strict-scroll-autocomplete-new>
                </div>
                <app-wl-strict-input-boolean *ngIf="!isGamblerLevel" [control]="crm.isWildCard"
                                          [label]="'WildCard'"
                ></app-wl-strict-input-boolean>
                <button class="wl-btn-form-inactive wl-season-level-task-remove-button"
                        type="button"
                        [disabled]="!crm.canChange"
                        (click)="deleteCrmTask(crm)">
                    x
                </button>
            </div>
            <button [disabled]="!canAddTask"
                    class="wl-btn-form-active wl-season-level-task-add-button"
                    type="button"
                    (click)="addCrmTask()"
            >Добавить задание
            </button>
        </div>
        <div class="wl-season-level-column two-col">
            <ng-container *ngIf="!isGamblerLevel"
                          [ngTemplateOutlet]="gameTasksBlock"
            ></ng-container>
        </div>
    </div>
    <div *ngIf="!isGamblerLevel" class="wl-season-level-selector-block">
        <div class="wl-season-level-column one-col">
            <div class="wl-season-level-task-order">Push шаблон рассылки о награде</div>
            <div class="wl-season-level-task-selector">
                <app-strict-push-template-autocomplete
                    class="wl-app-search-autocomplete-wrapper"
                    [control]="viewModel.template"
                    (userSelect)="userManipulationsSource.next()"
                    [isSearch]="true"
                ></app-strict-push-template-autocomplete>
            </div>
        </div>
    </div>
    <ng-container [ngTemplateOutlet]="lootBoxBlock"></ng-container>
    <div class="wl-season-level-selector-block">
        <button
            class="wl-btn-form-active wl-season-level-task-button"
            type="button"
            [disabled]="!isValid"
            (click)="save()"
        >
            Добавить
        </button>
        <button class="wl-btn-form-inactive wl-season-level-task-button" type="button" (click)="close()">
            Отменить
        </button>
    </div>
</div>

<ng-template #gameTasksBlock>
    <div class="wl-season-level-task-order">Игровые задания</div>
    <div class="wl-season-level-column-item" *ngFor="let game of viewModel.gameTasks.strictValue">
        <app-wl-strict-input-number
                class="wl-input-number-order"
                min="1"
                [max]="maxOrder"
                (valueChange)="userManipulationsSource.next()"
                [control]="game.order"
        ></app-wl-strict-input-number>
        <div class="wl-season-level-task-selector">
            <app-strict-scroll-autocomplete-new
                    id="{{ game.order }}"
                    class="wl-app-strict-control"
                    (userFinalSelected)="userManipulationsSource.next()"
                    [strategy]="game.strategy"
                    [control]="game.task"
            ></app-strict-scroll-autocomplete-new>
        </div>
        <app-wl-strict-input-boolean [control]="game.isWildCard"
                                  [label]="'WildCard'"
        ></app-wl-strict-input-boolean>
        <button class="wl-btn-form-inactive wl-season-level-task-remove-button"
                type="button"
                [disabled]="!game.canChange"
                (click)="deleteGameTask(game)">
            x
        </button>
    </div>
    <button [disabled]="!canAddTask"
            class="wl-btn-form-active wl-season-level-task-add-button"
            type="button"
            (click)="addGameTask()"
    >Добавить задание
    </button>
</ng-template>

<ng-template #lootBoxBlock>
    <div class="wl-season-level-selector-block">
        <div class="wl-season-level-column one-col">
            <div class="wl-season-level-task-order">Игровые лутбоксы</div>
            <div class="wl-season-level-column-item" *ngFor="let gameLootBox of gameLootBoxes">
                <div class="wl-season-level-task-selector">
                    <app-strict-scroll-autocomplete-new
                            class="wl-app-strict-control"
                            (userFinalSelected)="userManipulationsSource.next()"
                            [strategy]="gameLootBox.strategy"
                            [control]="gameLootBox.lootBox"
                    ></app-strict-scroll-autocomplete-new>
                </div>
                <div class="wl-season-level-task-selector wl-complete-task-types-selector">
                    <app-wl-dropdown
                            [options]="{ placeholder: 'Выберите тип' }"
                            [strategy]="taskTypesStrategy"
                            (userSelect)="userManipulationsSource.next()"
                            [control]="gameLootBox.completedTaskType"
                    ></app-wl-dropdown>
                </div>
                <button class="wl-btn-form-inactive wl-season-level-task-remove-button"
                        type="button"
                        [disabled]="!gameLootBox.canChange"
                        (click)="deleteGameLootBox(gameLootBox)">
                    x
                </button>
            </div>
            <button class="wl-btn-form-active wl-season-level-task-add-button"
                    type="button"
                    [disabled]="isActiveLevel"
                    (click)="addGameLootBox()"
            >
                Добавить лутбокс
            </button>
            <div class="wl-level-edit-error">
                <div *ngFor="let error of (gameLootBoxErrors | async)">
                    {{ error.message }}
                </div>
            </div>
        </div>
        <div class="wl-season-level-column two-col">
            <div class="wl-season-level-task-order">Фрибетные лутбоксы</div>
            <div class="wl-season-level-column-item" *ngFor="let freeBetLootBox of freeBetLootBoxes">
                <div class="wl-season-level-task-selector">
                    <app-strict-scroll-autocomplete-new
                            class="wl-app-strict-control"
                            (userFinalSelected)="userManipulationsSource.next()"
                            [strategy]="freeBetLootBox.strategy"
                            [control]="freeBetLootBox.lootBox"
                    ></app-strict-scroll-autocomplete-new>
                </div>
                <div class="wl-season-level-task-selector wl-complete-task-types-selector">
                    <app-wl-dropdown
                            [options]="{ placeholder: 'Выберите тип' }"
                            [strategy]="taskTypesStrategy"
                            (userSelect)="userManipulationsSource.next()"
                            [control]="freeBetLootBox.completedTaskType"
                    ></app-wl-dropdown>
                </div>
                <button class="wl-btn-form-inactive wl-season-level-task-remove-button"
                        type="button"
                        [disabled]="!freeBetLootBox.canChange"
                        (click)="deleteFreeBetLootBox(freeBetLootBox)">
                    x
                </button>
            </div>
            <button class="wl-btn-form-active wl-season-level-task-add-button"
                    type="button"
                    [disabled]="isActiveLevel"
                    (click)="addFreeBetLootBox()"
            >
                Добавить лутбокс
            </button>
            <div class="wl-level-edit-error">
                <div *ngFor="let error of (freeBetLootBoxErrors | async)">
                    {{ error.message }}
                </div>
            </div>
        </div>
    </div>
</ng-template>
