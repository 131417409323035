import { Routes } from '@angular/router';
import { WlPageNotFoundComponent } from './modules/root/components/page-not-found/app-page-not-found.component';
import { WlRootModule } from './modules/root/root.module';
import { AppComponent } from './modules/app-main/app.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: '',
        loadChildren: () => WlRootModule,
      },
    ],
  },

  {
    path: '**',
    component: WlPageNotFoundComponent,
    pathMatch: 'full',
  },
];
