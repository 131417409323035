<div class="wl-container">
    <app-wl-page-header header="{{purpose}}"></app-wl-page-header>
    <app-wl-loading *ngIf="loading"></app-wl-loading>
    <div class="wl-task-row">
        <button class="wl-button-primary wl-task-button" [disabled]="!isValid"
                (click)="save()">{{saveButtonText}}</button>
        <button class="wl-button-primary wl-task-button" [disabled]="!canBeCloned"
                (click)="clone()">Копировать</button>
    </div>
    <div class="wl-task-row">
        <app-wl-strict-input-text
                [control]="viewModel.name"
                title="Название"
                placeholder="Введите название задания"
                (valueChange)="emmitMethod()"
                (searchMethod)="emmitMethod()"
                class="wl-battle-pass-task-name"
        >
        </app-wl-strict-input-text>
    </div>
    <div class="wl-task-row">
        <app-wl-strict-input-text
                [control]="viewModel.description"
                title="Описание"
                placeholder="Введите описание задания"
                (valueChange)="emmitMethod()"
                (searchMethod)="emmitMethod()"
                [className]="'wl-battle-pass-task-name'"
        >
        </app-wl-strict-input-text>
    </div>
    <div class="wl-task-row">
        <app-wl-strict-input-text
                [control]="viewModel.bullets"
                title="Буллиты"
                placeholder="Дополнительные условия выполнения задания"
                (valueChange)="emmitMethod()"
                (searchMethod)="emmitMethod()"
                [className]="'wl-battle-pass-task-name'"
        >
        </app-wl-strict-input-text>
    </div>
    <div class="wl-task-row">
        <app-wl-strict-input-boolean
                label="Пройдено автоматически"
                (userSelect)="userManipulationsSource.next()"
                [control]="viewModel.forceComplete"
        >
        </app-wl-strict-input-boolean>
    </div>
    <div class="wl-task-row">
        <app-wl-strict-input-text
                title="Deeplink"
                (valueChange)="emmitMethod()"
                (searchMethod)="emmitMethod()"
                [control]="viewModel.deeplink"
        >
        </app-wl-strict-input-text>
    </div>
    <div class="wl-task-row">
        <app-wl-strict-input-text
                title="Картинка"
                (valueChange)="emmitMethod()"
                (searchMethod)="emmitMethod()"
                [control]="viewModel.imageUrl"
        >
        </app-wl-strict-input-text>
    </div>
    <div class="wl-task-row">
        <app-wl-strict-input-text
                title="Картинка для Web"
                (valueChange)="emmitMethod()"
                (searchMethod)="emmitMethod()"
                [control]="viewModel.imageWebUrl"
        >
        </app-wl-strict-input-text>
    </div>
    <div class="wl-task-row">
        <app-strict-push-template-autocomplete
                class="wl-app-search-autocomplete-wrapper"
                textlabel="Push шаблон рассылки о выполнении задания"
                [control]="viewModel.template"
                (userSelect)="emmitMethod()"
                [isSearch]="true"
        ></app-strict-push-template-autocomplete>
    </div>
    <div class="wl-task-row">
        <app-strict-throttling-autocomplete
                class="wl-app-wrapper"
                textLabel="Троттлинг для рассылки"
                (userSelect)="emmitMethod()"
                [control]="viewModel.throttling">
        </app-strict-throttling-autocomplete>
    </div>
    <div class="wl-task-row">
        <div class="wl-item-container">
            <app-wl-strict-input-number
                    title="Целевой показатель"
                    class="wl-number-component"
                    min="0"
                    placeholder=""
                    (valueChange)="userManipulationsSource.next()"
                    [control]="viewModel.targetIndicator"
            >
            </app-wl-strict-input-number>
        </div>
    </div>
    <div class="wl-task-row">
        <app-wl-strict-input-text
                [control]="viewModel.targetIndicatorType"
                placeholder="Укажите тип целевого показателя"
                title="Единица измерения целевого показателя"
                (valueChange)="emmitMethod()"
                (searchMethod)="emmitMethod()"
        ></app-wl-strict-input-text>
    </div>
    <div class="wl-task-row">
        <app-wl-radio-buttons
                (userSelect)="userManipulationsSource.next()"
                [control]="switcherEvents">
        </app-wl-radio-buttons>
    </div>
    <div class="wl-task-row" *ngIf="isGrouped">
        <app-wl-dropdown [strategy]="groupingTypeStrategy"
                         [options]="{placeholder: 'Выберите параметр группировки',title:'Параметр группировки '}"
                         (userSelect)="userManipulationsSource.next()"
                         [control]="viewModel.groupViewModel.groupType">
        </app-wl-dropdown>
        <app-wl-strict-input-number class="wl-number-component"
                                 title="Число элементов группировки"
                                 min="1"
                                 (valueChange)="emmitMethod()"
                                 [control]="viewModel.groupViewModel.groupsCount">
        </app-wl-strict-input-number>
        <app-wl-strict-input-number *ngIf="isByCount"
                                 class="wl-number-component"
                                 title="Событий в группе"
                                 min="1"
                                 (valueChange)="emmitMethod()"
                                 [control]="viewModel.groupViewModel.eventsCountInGroup">
        </app-wl-strict-input-number>
        <app-wl-strict-input-number *ngIf="isBySum"
                                 class="wl-number-component"
                                 title="Сумма ставок в группе"
                                 min="1"
                                 (valueChange)="emmitMethod()"
                                 [control]="viewModel.groupViewModel.eventsSumInGroup">
        </app-wl-strict-input-number>
    </div>
    <div class="wl-task-row" *ngIf="isCollectInExpress">
        <app-wl-battle-pass-task-express
                (change)="userManipulationsSource.next()"
                [viewModel]="viewModel">
        </app-wl-battle-pass-task-express>
    </div>
    <div class="wl-task-row" *ngIf="!isOtherType">
        <app-wl-strict-input-boolean
                label="Ограничить выполнение по времени"
                (userSelect)="userManipulationsSource.next()"
                [control]="viewModel.limitProgressByDays"
        >
        </app-wl-strict-input-boolean>
    </div>
    <div class="wl-task-row" *ngIf="isProgressLimitedByDays">
        <app-wl-strict-input-number class="wl-number-component"
                                 title="Количество суток"
                                 min="1"
                                 max="999"
                                 (valueChange)="emmitMethod()"
                                 [control]="viewModel.progressIntervalSize">
        </app-wl-strict-input-number>
    </div>
    <div class="wl-task-row">
        <app-wl-dropdown [strategy]="taskTypeStrategy"
                         [options]="{placeholder: 'Выберите тип задания',title:'Тип букмекерского задания '}"
                         (userSelect)="userManipulationsSource.next()"
                         [control]="viewModel.taskType">
        </app-wl-dropdown>
    </div>
    <div class="wl-task-row" *ngIf="!isOtherType">
        <app-wl-dropdown [strategy]="targetTypeStrategy"
                         [options]="{placeholder: 'Выберите цель задания',title:'Цель задания '}"
                         (userSelect)="userManipulationsSource.next()"
                         [control]="viewModel.targetType">
        </app-wl-dropdown>
    </div>
    <div class="wl-task-row" *ngIf="isTasksExist && !isOtherType">
        <div *ngFor="let task of viewModel.tasks.strictValue; let i = index">
            <app-wl-battle-pass-task-item (change)="userManipulationsSource.next()"
                                          (delEmitter)="deleteTask(i)"
                                          (addEmitter)="addTask()"
                                          [isDeleteButtonActive]="canDeleteTask"
                                          [taskItem]="task"
                                          [taskType]="viewModel.taskType.strictValue"
                                          [targetType]="viewModel.targetType.strictValue"
                                          [collectInExpress]="viewModel.switcherEvents.isCollectInExpress"
                                          [isGrouped]="viewModel.switcherEvents.isGrouped.strictValue"
                                          [isRecurrent]="viewModel.switcherEvents.isRecurrent.strictValue"
                                          [isDisabled]="isStarted">
            </app-wl-battle-pass-task-item>
        </div>
    </div>
</div>
