import {WlRateRaceGradeViewModel} from './wl-rate-race-grade-view-model';
import {Dayjs} from 'dayjs';
import { WlBattlePassRateRacesLootBoxesScrollServiceAdapter } from '../../battle-pass/services/wrapper-service/wl-battle-pass-rate-races-loot-boxes-scroll-service-adapter';
import { StrictFormControl } from '@koddington/ga-common';
import { WlSeasonListModel } from '../../autogen/BattlePass';

export class WlRateRaceViewModel {
    id: StrictFormControl<number> = new StrictFormControl<number>();
    name: StrictFormControl<string> = new StrictFormControl<string>();
    description: StrictFormControl<string> = new StrictFormControl<string>();
    imageUrl: StrictFormControl<string> = new StrictFormControl<string>();
    startDate: StrictFormControl<Dayjs> = new StrictFormControl<Dayjs>();
    prizePlacesCount: StrictFormControl<number> = new StrictFormControl<number>();
    gradeDrops: StrictFormControl<WlRateRaceGradeViewModel[]>;

    battlePassSeason: StrictFormControl<WlSeasonListModel> = new StrictFormControl<WlSeasonListModel>();

    constructor(private _lootBoxStrategyApiClient: WlBattlePassRateRacesLootBoxesScrollServiceAdapter) {
        this.gradeDrops = new StrictFormControl<WlRateRaceGradeViewModel[]>([new WlRateRaceGradeViewModel(_lootBoxStrategyApiClient)]);
    }
}
