import { NavigationExtras, Router } from '@angular/router';

export namespace RouterNavigationExtensions {
  export function navigateAndRefresh(
    _router: Router,
    commands: any[],
    extras?: NavigationExtras
  ): void {
    const prev = _router.routeReuseStrategy.shouldReuseRoute;
    const prevOSN = _router.onSameUrlNavigation;
    _router.routeReuseStrategy.shouldReuseRoute = () => false;
    _router.onSameUrlNavigation = 'reload';
    _router.navigate(commands, extras).finally(() => {
      _router.routeReuseStrategy.shouldReuseRoute = prev;
      _router.onSameUrlNavigation = prevOSN;
    });
  }
}
