<app-wl-page-header header="Игровые задания для боевого пропуска"></app-wl-page-header>
<div class="wl-template-row">
    <button class="wl-button-primary wl-btn-push-save-wrapper" (click)="add()">Добавить задание</button>
</div>
<div class="wl-search-row">
    <div class="wl-search-input-wrapper">
        <app-strict-scroll-autocomplete-new
            placeholder="Поиск по названию"
            [isSearch]="true"
            (userFinalSelected)="load()"
            [strategy]="strategy"
            [control]="control"
        ></app-strict-scroll-autocomplete-new>
    </div>
</div>
<ga-table *ngIf="!!tableData" [data]="tableData"></ga-table>
<div class="wl-paging-wrapper">
    <app-wl-paging *ngIf="gameTask" [pagedResult]="gameTask" (pageChangedEvent)="pageChanged($event)"></app-wl-paging>
</div>
