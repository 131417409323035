import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, of, Subject} from 'rxjs';
import {filter, finalize, first, map} from 'rxjs/operators';
import {untilDestroyed} from 'ngx-take-until-destroy';
import { WlBattlePassGameTaskTemplateViewModel } from './models/wl-battle-pass-game-task-template-view-model';
import { WlBattlePassGameTaskTemplateValidator } from './validators/wl-battle-pass-game-task-template-validator';
import { GaMessagingService, isNullOrUndefined, StrictError } from '@koddington/ga-common';
import { GameTasksIconsStrategyService } from '../../strategies/game-tasks-icons-strategy.service';
import { BattlePassService, CreateTaskTemplateForm, UpdateTaskTemplateForm } from 'src/app/modules/autogen/BattlePass';


@Component({
    selector: 'app-wl-battle-pass-game-task-template-edit',
    templateUrl: './wl-battle-pass-game-task-template-edit.component.html',
    styleUrls: ['./wl-battle-pass-game-task-template-edit.component.scss']
})
export class WlBattlePassGameTaskTemplateEditComponent implements OnInit, OnDestroy {
    public loading: boolean;
    public readonly userManipulationsSource = new Subject<void>();
    viewModel: WlBattlePassGameTaskTemplateViewModel;

    private readonly validator = new WlBattlePassGameTaskTemplateValidator();
    errors: StrictError[] = [];

    constructor(public readonly gameIconsStrategy: GameTasksIconsStrategyService,
                private readonly _route: ActivatedRoute,
                private readonly _router: Router,
                private readonly _messaging: GaMessagingService,
                private readonly _battlePassService: BattlePassService) {
    }

    ngOnInit(): void {
        this.viewModel = new WlBattlePassGameTaskTemplateViewModel();
        this.viewModel.id.strictValue = isNullOrUndefined(this._route.snapshot.params['id']) ? null : this._route.snapshot.params['id'];
        this.userManipulationsSource.pipe(untilDestroyed(this)).subscribe(() => {
            this.errors = this.validator.validate(this.viewModel);
        });

        if (this.viewModel.id.hasStrictValue) {
            this.loadEditTemplate();
        } else {
            this.userManipulationsSource.next();
        }
    }

    public ngOnDestroy(): void {
    }

    public header(): string {
        if (this.viewModel.id.hasStrictValue) {
            return `Шаблон №'${this.viewModel.id.strictValue}'`;
        } else {
            return 'Создание шаблона игрового задания';
        }
    }

    get saveButtonText(): string {
        if (this.viewModel.id.hasStrictValue) {
            return 'Обновить';
        }
        return 'Сохранить';
    }

    public onSave(): void {
        this.saveChanges().subscribe(() => this.showTemplateList());
    }

    saveChanges(): Observable<number> {
        if (this.viewModel.id.hasStrictValue) {
            return this.updateTemplate();
        } else {
            return this.addTemplate();
        }
    }

    private loadEditTemplate(): void {
        this.loading = true;
        this._battlePassService
            .getById(this.viewModel.id.strictValue)
            .pipe(
                first(),
                filter((res) => !this._messaging.tryShowError(res)),
                finalize(() => (this.loading = false))
            )
            .subscribe((result) => {
                const template = result.result;
                this.viewModel.id.strictValue = template.id;
                this.viewModel.name.strictValue = template.name;
                this.viewModel.description.strictValue = template.description;
                this.viewModel.icon.strictValue = template.icon;

                this.viewModel.externalPartnerId.disable();
                this.viewModel.externalPartnerId.strictValue = template.externalPartnerId;
            });
    }

    private updateTemplate(): Observable<number> {
        if (!this.isValid) {
            return of(0);
        }

        this.loading = true;
        const form = this.createUpdateForm();
        return this._battlePassService.updateGameTaskTemplate(form).pipe(
            first(),
            filter((value) => !this._messaging.tryShowError(value)),
            map(value => value.result),
            finalize(() => this.loading = false)
        );
    }

    private addTemplate(): Observable<number> {
        if (!this.isValid) {
            return of(0);
        }

        this.loading = true;
        const form = this.createAddForm();
        return this._battlePassService.addGameTaskTemplate(form).pipe(
            first(),
            filter((value) => !this._messaging.tryShowError(value)),
            map(value => value.result),
            finalize(() => {
                this.loading = false;
            })
        );
    }

    public showTemplateList(): void {
        this._router.navigateByUrl('menu/battlePass/gameTaskTemplates');
    }

    get isValid(): boolean {
        return this.errors.length === 0;
    }

    private createAddForm(): CreateTaskTemplateForm {
        const form = new CreateTaskTemplateForm();

        form.name = this.viewModel.name.strictValue;
        form.description = this.viewModel.description.strictValue;
        form.externalPartnerId = this.viewModel.externalPartnerId.strictValue;
        form.iconId = this.viewModel.icon.strictValue.id;

        return form;
    }

    private createUpdateForm(): UpdateTaskTemplateForm {
        const form = UpdateTaskTemplateForm.fromJS(this.createAddForm());
        form.id = this.viewModel.id.strictValue;

        return form;
    }
}
