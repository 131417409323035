import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
    name: 'wlDate',
})
export class WlDatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (!value) {
            return '-';
        }

        const res = dayjs(value);

        return res.year() === 1 ? '-' : res.format('DD.MM.YYYY');
    }
}
