import { Injectable, OnDestroy } from '@angular/core';
import { IAutocompleteItem } from '@koddington/ga-common';
import { WlRateRaceLootBoxPagingForm } from '../../autogen/BattlePass';
import { WlRateRaceLootBoxListModel } from '../../autogen/BattlePass';
import { WlBattlePassRateRacesLootBoxesScrollServiceAdapter } from '../services/wrapper-service/wl-battle-pass-rate-races-loot-boxes-scroll-service-adapter';
import { ScrollStrategy } from '../../../common/abstracts/abstract-scroll-strategy';

@Injectable()
export class WlBattlePassRateRaceLootBoxesAutocompleteStrategy extends ScrollStrategy<WlRateRaceLootBoxListModel, WlRateRaceLootBoxPagingForm> implements OnDestroy {
    constructor(protected readonly _apiClient: WlBattlePassRateRacesLootBoxesScrollServiceAdapter) {
        super(_apiClient);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public convert(model: WlRateRaceLootBoxListModel): IAutocompleteItem<WlRateRaceLootBoxListModel> {
        return this.convert_(() => {
            return {
                entity: model,
                title: `[${model.id.toString()}] [${model.itemsCount} предметов] ${model.name}`,
                key: model.id?.toString(),
            };
        });
    }

    public clear(): void {
        super._sourceClearAutocomplete$.next();
    }
}
