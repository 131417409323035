import { StrictFormControl } from '@koddington/ga-common';
import { ThrottlingSearchModel, WlGameTaskTemplate, WlNotificationTemplate } from '../../autogen/BattlePass';

export class WlCrmGameTaskViewModel {
    public id: StrictFormControl<number> = new StrictFormControl<number>(null);
    public name: StrictFormControl<string> = new StrictFormControl<string>(null);
    public description: StrictFormControl<string> = new StrictFormControl<string>(null);
    public bullets: StrictFormControl<string> = new StrictFormControl<string>(null);
    public humanizedTargetDescription: StrictFormControl<string> = new StrictFormControl<string>(null);
    public target: StrictFormControl<number> = new StrictFormControl<number>(null);
    public taskTemplate: StrictFormControl<WlGameTaskTemplate> = new StrictFormControl<WlGameTaskTemplate>(null);
    public deeplink: StrictFormControl<string> = new StrictFormControl<string>(null);
    public imageUrl: StrictFormControl<string> = new StrictFormControl<string>(null);
    public imageWebUrl: StrictFormControl<string> = new StrictFormControl<string>(null);
    public forceComplete: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
    public template: StrictFormControl<WlNotificationTemplate> = new StrictFormControl<WlNotificationTemplate>(null);
    public throttling: StrictFormControl<ThrottlingSearchModel> = new StrictFormControl<ThrottlingSearchModel>(null);
}
