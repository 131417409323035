import { StrictFormControl } from '@koddington/ga-common';
import { WlBattlePassRewardLiteModel } from '../../autogen/BattlePass';
import { WlRateRaceWrapperService } from '../services/wrapper-service/wl-rate-race-wrapper-service';
import { WlRateRaceRewardScrollStrategy } from '../strategies/wl-rate-race-reward-scroll-strategy';

export class WlRewardEditViewModel {
    public reward: StrictFormControl<WlBattlePassRewardLiteModel> = new StrictFormControl<WlBattlePassRewardLiteModel>(null);
    public exchangeAmount: StrictFormControl<number> = new StrictFormControl<number>(null);
    public strategy: WlRateRaceRewardScrollStrategy = new WlRateRaceRewardScrollStrategy(this._api);
    public order: StrictFormControl<number> = new StrictFormControl<number>(null);
    public isEditing: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);

    constructor(private readonly _api: WlRateRaceWrapperService) {
    }
}
