import { StrictFormControl } from '@koddington/ga-common';
import { WlRangeConditionViewModel } from './wl-range-condition-view-model';

export class WlCrmTaskCollectExpressViewModel {
    public betsCount: StrictFormControl<number> = new StrictFormControl<number>(null);
    public totalWinAmountRange: StrictFormControl<WlRangeConditionViewModel> =
        new StrictFormControl<WlRangeConditionViewModel>(new WlRangeConditionViewModel());
    public eventCountExpressRange: StrictFormControl<WlRangeConditionViewModel> =
        new StrictFormControl<WlRangeConditionViewModel>(new WlRangeConditionViewModel());
    public betRateRange: StrictFormControl<WlRangeConditionViewModel> =
        new StrictFormControl<WlRangeConditionViewModel>(new WlRangeConditionViewModel());
    public betSumRange: StrictFormControl<WlRangeConditionViewModel> =
        new StrictFormControl<WlRangeConditionViewModel>(new WlRangeConditionViewModel());
    public betWinAmountRange: StrictFormControl<WlRangeConditionViewModel> =
        new StrictFormControl<WlRangeConditionViewModel>(new WlRangeConditionViewModel());
}
