import { Component, OnDestroy, OnInit } from '@angular/core';
import { BattlePassService, WlGameTaskSearchForm, WlTaskListModel } from 'src/app/modules/autogen/BattlePass';
import { GaPagedResult, GaPagingForm, StrictFormControl } from '@koddington/ga-common';
import { WlBattlePassGameTaskAutocompleteStrategy } from '../../strategies/wl-battle-pass-game-task-autocomplete.strategy';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { GaTableData } from '@koddington/ga-common';

@Component({
    selector: 'app-wl-battle-pass-game-task-list',
    templateUrl: './wl-battle-pass-game-task-list.component.html',
    styleUrls: ['./wl-battle-pass-game-task-list.component.scss'],
    providers: [WlBattlePassGameTaskAutocompleteStrategy],
})
export class WlBattlePassGameTaskListComponent implements OnInit, OnDestroy {
    public control = new StrictFormControl<WlTaskListModel>(null);
    gameTask: GaPagedResult<WlTaskListModel>;
    formGroup: FormGroup;
    loading: boolean;
    tableData: GaTableData<WlTaskListModel>;
    private readonly count = 30;
    constructor(
        private readonly _battlePassService: BattlePassService,
        private readonly _fb: FormBuilder,
        private readonly _router: Router,
        public strategy: WlBattlePassGameTaskAutocompleteStrategy
    ) {}

    public ngOnInit(): void {
        this.buildFormGroup();
        this.load();
    }

    public ngOnDestroy(): void {}
    public load(offset: number = 0): void {
        this.setLoadingState(true);
        const filter = new WlGameTaskSearchForm();
        filter.count = this.count;
        filter.offset = offset;
        filter.term = this.control.hasStrictValue ? this.control.strictValue.name : '';

        this._battlePassService
            .listGameTask(filter)
            .pipe(take(1))
            .subscribe((res) => {
                this.gameTask = res;
                this.mapToTable();
                this.setLoadingState(false);
            });
    }

    public pageChanged(page: GaPagingForm): void {
        this.load(page.offset);
    }

    public add(): void {
        this._router.navigate(['/menu/battlePass/gameTask/edit']);
    }

    private buildFormGroup() {
        this.formGroup = this._fb.group({
            term: this._fb.control(''),
        });
    }

    private mapToTable(): void {
        const data = new GaTableData<WlTaskListModel>();

        this.tableData = data
            .addRouterLinkColumn(
                (elem) => elem.id,
                (elem) => ['edit', elem.id],
                { title: 'Код', widthSize: 100 }
            )
            .addSimpleColumn((elem) => elem.name, { title: 'Название', widthSize: 500 })
            .addSimpleColumn((elem) => elem.description, { title: 'Описание', widthSize: 590 })
            .addSimpleColumn((elem) => elem.seasonId, { title: 'Id сезона', widthSize: 100 })
            .addSimpleColumn((elem) => elem.levelId, { title: 'Id уровня', widthSize: 100 })
            .setData(this.gameTask.results);
    }

    private setLoadingState(state: boolean): void {
        this.loading = state;
    }
}
