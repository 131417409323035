import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {DropdownItem} from '../../../../models/common/enum-dropdown/dropdown-item';
import {DropdownOptions} from '../../../../models/common/enum-dropdown/dropdown-options';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {IDropdownStrategy} from './strategies/i-dropdown-strategy';
import {isNullOrUndefined, StrictFormControl} from '@koddington/ga-common';

@Component({
  selector: 'app-enum-dropdown',
  templateUrl: './enum-dropdown.component.html',
  styleUrls: ['./enum-dropdown.component.css']
})
export class DropdownComponent<T> implements OnInit, OnDestroy {

  @Input()
  public options?: DropdownOptions;
  @Input()
  public entityContainer: StrictFormControl<T>;
  @Input()
  public strategy: IDropdownStrategy<T>;

  @Output()
  public userChanges = new EventEmitter<T>();

  public selectedItem: DropdownItem<T>;
  public currentItemsSource: DropdownItem<T>[] = [];

  constructor() {
  }

  public get placeholder(): string {
    return isNullOrUndefined(this.options) || isNullOrUndefined(this.options.placeholder) ? '' : this.options.placeholder;
  }

  public get classname(): string {
    return isNullOrUndefined(this.options) || isNullOrUndefined(this.options.classname) ? '' : this.options.classname;
  }

  public ngOnDestroy(): void {
  }

  public ngOnInit(): void {
    if (isNullOrUndefined(this.entityContainer)) {
      throw new Error('Entity container for dropdown cannot be undefined');
    }
    if (isNullOrUndefined(this.strategy)) {
      throw new Error('Strategy for dropdown cannot be undefined');
    }

    this.strategy.getEntities().pipe(
      untilDestroyed(this)
    ).subscribe((u) => this.currentItemsSource = u.map(v => this.strategy.convert(v)));

    this.selectedItem = !this.entityContainer.hasStrictValue ?
      null : this.strategy.convert(this.entityContainer.strictValue);

    this.entityContainer.strictValueSource.pipe(
      untilDestroyed(this),
      distinctUntilChanged(),
      map((u: T) => this.strategy.convert(u))
    ).subscribe((item) => this.selectedItem = item);
  }

  public selectEntity(id: string): void {
    this.selectedItem = this.currentItemsSource.find(u => u.id === id);

    if (isNullOrUndefined(this.selectedItem)) {
      this.entityContainer.strictValue = null;
      return;
    }

    const currentContainerId = !this.entityContainer.hasStrictValue ? null :
      this.strategy.convert(this.entityContainer.strictValue).id;
    const selectedItemId = isNullOrUndefined(this.selectedItem) ? null : this.selectedItem.id;

    if (selectedItemId !== currentContainerId) {
      this.entityContainer.strictValue = this.selectedItem.entity;
    }

    this.userChanges.emit(this.selectedItem.entity);
  }
}
