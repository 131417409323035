<div class="wl-users-file-picker-container">
    <div class="wl-users-file-picker-row">
        <label *ngIf="title" class="wl-users-file-picker-label">{{title}}</label>
        <div class="wl-users-file-input-block">
            <div class="wl-input-file-wrapper-button" [class.has-errors]="shouldDisplayErrors | async">
                <input class="wl-users-file-input"
                       [disabled]="pathControl.disabled"
                       id="customFileLang"
                       type="file"
                       [placeholder]="getFileName()"
                       (change)="onFileChange($event)" lang="ru"
                       #filepicker>
                <label for="customFileLang" class="wl-users-file-input-label">{{getFileName()}}</label>
                <label class="wl-users-file-input-label-svg" for="customFileLang">
                    <div class="wl-users-file-button-label" for="customFileLang">
                        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M0.162718 0.15976C-0.0542395 0.372773 -0.0542395 0.718136 0.162718 0.931149L4.60716 5.84024C4.82412 6.05325 5.17588 6.05325 5.39284 5.84024L9.83728 0.93115C10.0542 0.718136 10.0542 0.372774 9.83728 0.15976C9.62032 -0.0532529 9.26857 -0.0532529 9.05161 0.15976L5 4.68316L0.948393 0.15976C0.731435 -0.0532533 0.379676 -0.0532533 0.162718 0.15976Z"
                                  fill="#777777"/>
                        </svg>
                    </div>
                </label>
            </div>
        </div>
        <button *ngIf="isFileLoaded()" class="wl-cancel-file wl-cancel-btn" (click)="clear()">
            Удалить
        </button>
    </div>
    <div class="wl-users-file-picker-row" *ngIf="(shouldDisplayErrors | async)">
        <div class="errors-list" *ngIf="(shouldDisplayErrors | async)">
            <span class="error-item" *ngFor="let error of this.pathControl.strictErrors | async">
                {{error.message}}
            </span>
            <span class="error-item" *ngFor="let error of this.fileNameControl.strictErrors | async">
                {{error.message}}
            </span>
            <span class="error-item" *ngFor="let error of this.fileLoaded.strictErrors | async">
                {{error.message}}
            </span>
        </div>
    </div>
</div>

