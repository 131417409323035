import { NgModule } from '@angular/core';
import { WlBattlePassTaskCrudComponent } from './components/wl-battle-pass-task-crud/wl-battle-pass-task-crud.component';
import { WlBattlePassTaskListComponent } from './components/wl-battle-pass-task-list/wl-battle-pass-task-list.component';
import { WlBattlePassGameTaskListComponent } from './components/wl-battle-pass-game-task-list/wl-battle-pass-game-task-list.component';
import { WlBattlePassGameTaskCrudComponent } from './components/wl-battle-pass-game-task-crud/wl-battle-pass-game-task-crud.component';
import { WlBattlePassTaskItemComponent } from './components/wl-battle-pass-task-item/wl-battle-pass-task-item.component';
import { WlBattlePassRangeConditionComponent } from './components/wl-battle-pass-range-condition/wl-battle-pass-range-condition.component';
import { WlBattlePassRewardViewComponent } from './components/wl-battle-pass-reward-view/wl-battle-pass-reward-view.component';
import { WlBattlePassRewardListComponent } from './components/wl-battle-pass-reward-list/wl-battle-pass-reward-list.component';
import { WlBattlePassSeasonCrudComponent } from './components/wl-battle-pass-season-crud/wl-battle-pass-season-crud.component';
import { WlBattlePassSeasonListComponent } from './components/wl-battle-pass-season-list/wl-battle-pass-season-list.component';
import { WlBattlePassLevelEditComponent } from './components/wl-battle-pass-level-edit/wl-battle-pass-level-edit.component';
import { WlBattlePassGameLootBoxCrudComponent } from './components/wl-battle-pass-game-loot-box-crud/wl-battle-pass-game-loot-box-crud.component';
import { WlBattlePassGameLootBoxListComponent } from './components/wl-battle-pass-game-loot-box-list/wl-battle-pass-game-loot-box-list.component';
import { WlBattlePassGameTaskTemplatesComponent } from './components/wl-battle-pass-game-task-templates/wl-battle-pass-game-task-templates.component';
import { WlBattlePassGameTaskTemplateEditComponent } from './components/wl-battle-pass-game-task-template-edit/wl-battle-pass-game-task-template-edit.component';
import { WlBattlePassLevelViewComponent } from './components/wl-battle-pass-level-view/wl-battle-pass-level-view.component';
import { WlBattlePassBetsTaskStatisticsComponent } from './components/statistics/wl-battle-pass-bets-task-statistics/wl-battle-pass-bets-task-statistics.component';
import { WlBattlePassDepositsTasksStatisticsComponent } from './components/statistics/wl-battle-pass-deposits-tasks-statistics/wl-battle-pass-deposits-tasks-statistics.component';
import {
    WlBattlePassFilteredOutBetsStatisticsComponent
} from './components/statistics/wl-battle-pass-filtered-out-bets-statistics/wl-battle-pass-filtered-out-bets-statistics.component';
import {
    WlBattlePassFilteredOutBetsLinesStatisticsComponent
} from './components/statistics/wl-battle-pass-filtered-out-bets-lines-statistics/wl-battle-pass-filtered-out-bets-lines-statistics.component';
import { WlBattlePassUserInfoComponent } from './components/wl-battle-pass-user-info/wl-battle-pass-user-info.component';
import { WlBattlePassTaskExpressComponent } from './components/wl-battle-pass-task-crud/wl-battle-pass-task-express/wl-battle-pass-task-express.component';
import { WlBattlePassMarketsParameterComponent } from './components/wl-battle-pass-markets-parameter/wl-battle-pass-markets-parameter.component';
import { WlBattlePassFreeBetLootBoxListComponent } from './components/wl-battle-pass-free-bet-loot-box-list/wl-battle-pass-free-bet-loot-box-list.component';
import { WlBattlePassFreeBetLootBoxCrudComponent } from './components/wl-battle-pass-free-bet-loot-box-crud/wl-battle-pass-free-bet-loot-box-crud.component';
import { SharedModule } from '../shared/shared.module';
import { WlBattlePassRoutes } from './wl-battle-pass.routes';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { SharedAreaCodegenAppModule } from '../autogen/Shared';
import { BattlePassAreaCodegenAppModule } from '../autogen/BattlePass';
import { GaCommonModule } from '@koddington/ga-common';
import {ClipboardModule} from '@angular/cdk/clipboard';

@NgModule({
    declarations: [
        WlBattlePassTaskCrudComponent,
        WlBattlePassTaskListComponent,
        WlBattlePassGameTaskListComponent,
        WlBattlePassGameTaskCrudComponent,
        WlBattlePassTaskItemComponent,
        WlBattlePassRangeConditionComponent,
        WlBattlePassRewardViewComponent,
        WlBattlePassRewardListComponent,
        WlBattlePassSeasonCrudComponent,
        WlBattlePassSeasonListComponent,
        WlBattlePassLevelEditComponent,
        WlBattlePassGameLootBoxCrudComponent,
        WlBattlePassGameLootBoxListComponent,
        WlBattlePassGameTaskTemplatesComponent,
        WlBattlePassGameTaskTemplateEditComponent,
        WlBattlePassLevelViewComponent,
        WlBattlePassBetsTaskStatisticsComponent,
        WlBattlePassDepositsTasksStatisticsComponent,
        WlBattlePassFilteredOutBetsStatisticsComponent,
        WlBattlePassFilteredOutBetsLinesStatisticsComponent,
        WlBattlePassUserInfoComponent,
        WlBattlePassTaskExpressComponent,
        WlBattlePassMarketsParameterComponent,
        WlBattlePassFreeBetLootBoxListComponent,
        WlBattlePassFreeBetLootBoxCrudComponent,
    ],
    imports: [
        SharedModule,
        CommonModule,
        RouterModule.forChild(WlBattlePassRoutes),
        FormsModule,
        ReactiveFormsModule,
        GaCommonModule,
        SharedAreaCodegenAppModule,
        BattlePassAreaCodegenAppModule,
        ClipboardModule
    ],
    entryComponents: [],
    exports: [
        WlBattlePassRangeConditionComponent,
        WlBattlePassFreeBetLootBoxCrudComponent
    ]
})
export class WlBattlePassModule {}
