import { AbstractModelValidator } from '@koddington/ga-common';
import { WlRateRaceGradeViewModel } from '../../../models/wl-rate-race-grade-view-model';

export class WlRateRaceGradeValidator extends AbstractModelValidator<WlRateRaceGradeViewModel>{
    constructor() {
        super();

        this.ruleForControl(u => u.minAmount)
            .required('Сумма грейда обязательна!')
            .min(0, 'Минимальная сумма старта грейда - 0 рублей.');

        this.ruleForControl(u => u.rateRaceLootBox)
            .required('Лутбокс обязателен для грейда!');
    }
}
