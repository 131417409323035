<div class="wl-form-container">
    <div class="wl-app-strict-autocomplete-label-block">
        <a *ngIf="label && !!targetRouterLink" class="wl-app-strict-autocomplete-label-text" [routerLink]="targetRouterLink">{{label}}</a>
        <label *ngIf="label && !targetRouterLink" class="wl-app-strict-autocomplete-label-text">{{label}}</label>
        <div
            class="wl-search-name-wrapper-form"
            [class.has-errors]="control.hasStrictErrors"
            [class.item-focus]="isFocused"
        >
            <svg
                *ngIf="isSearch"
                class="wl-search-svg"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0.347656 5.70312C0.347656 8.33398 2.48633 10.4727 5.11719 10.4727C6.0957 10.4727 6.99219 10.1738 7.74219 9.66406L10.4375 12.3594C10.6016 12.5293 10.8301 12.6055 11.0586 12.6055C11.5566 12.6055 11.9141 12.2305 11.9141 11.7441C11.9141 11.5098 11.832 11.293 11.6738 11.1289L8.99609 8.44531C9.55273 7.67773 9.88672 6.72852 9.88672 5.70312C9.88672 3.07227 7.74805 0.933594 5.11719 0.933594C2.48633 0.933594 0.347656 3.07227 0.347656 5.70312ZM1.58984 5.70312C1.58984 3.75781 3.16602 2.17578 5.11719 2.17578C7.0625 2.17578 8.64453 3.75781 8.64453 5.70312C8.64453 7.64844 7.0625 9.23047 5.11719 9.23047C3.16602 9.23047 1.58984 7.64844 1.58984 5.70312Z"
                    fill="#C0C0C0"
                />
            </svg>
            <div class="wl-scroll-autocomplete">
                <input
                    type="text"
                    [ngClass]="{'wl-input-search': isSearch, 'wl-autocomplete-input-form': !isSearch}"
                    [placeholder]="placeholder"
                    [textContent]="currentItem?.title"
                    [value]="viewModel?.term?.strictValue"
                    (input)="onInputChange($event)"
                    (click)="onFocus()"
                    (focus)="trackFocus($event)"
                    (blur)="trackFocus()"
                    (keydown)="onKeyDown($event)"
                    [disabled]="control.disabled"
                />
                <div
                    #scrollable
                    *ngIf="isActive"
                    class="wl-autocomplete-items"
                    [ngClass]="{'wl-form-left-box': isSearch}"
                    (scroll)="onScroll($event)"
                >
                    <div
                        class="wl-autocomplete-item"
                        *ngFor="let item of currentItemsSource; index as i; let last = last;"
                        (click)="onClick(item, true)"
                        [class.focused]="this.currentFocus === i"
                        [class.selected]="this.item.key === currentItem?.key"
                    >
                        {{item.title}}
                    </div>
                </div>
            </div>
            <div class="wl-arrow-down" for="customFileLang">
                <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    [ngClass]="{'wl-arrow-up':isActive }"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.162718 0.15976C-0.0542395 0.372773 -0.0542395 0.718136 0.162718 0.931149L4.60716 5.84024C4.82412 6.05325 5.17588 6.05325 5.39284 5.84024L9.83728 0.93115C10.0542 0.718136 10.0542 0.372774 9.83728 0.15976C9.62032 -0.0532529 9.26857 -0.0532529 9.05161 0.15976L5 4.68316L0.948393 0.15976C0.731435 -0.0532533 0.379676 -0.0532533 0.162718 0.15976Z"
                        fill="#777777"
                    />
                </svg>
            </div>
            <div class="wl-error-bubble wl-error-bubble-position" *ngIf="control.hasStrictErrors">
                <span *ngFor="let error of this.control.strictErrors | async"> {{error.message}} </span>
            </div>
        </div>
    </div>
</div>
