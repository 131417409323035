import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { formatDateTime, GaPagedResult, GaPagingForm, GaTableData } from '@koddington/ga-common';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BattlePassFreeBetLootBoxesService, WlFreeBetLootBoxListModel, WlFreeBetLootBoxSearchForm } from 'src/app/modules/autogen/BattlePass';
import { GaSearchManagementStateService } from 'src/app/modules/shared/extensions/search-state-managment';
import { WlBattlePassFreeBetLootBoxSearchViewModel } from './view-models/wl-battle-pass-free-bet-loot-box-search-view-model';


@Component({
    selector: 'app-wl-battle-pass-free-bet-loot-box-list',
    templateUrl: './wl-battle-pass-free-bet-loot-box-list.component.html',
    providers: [GaSearchManagementStateService],
})
export class WlBattlePassFreeBetLootBoxListComponent implements OnInit, OnDestroy {
    public loading = true;
    public tableData: GaTableData<WlFreeBetLootBoxListModel>;
    public apiResult: GaPagedResult<WlFreeBetLootBoxListModel>;

    private pagingModel = new WlBattlePassFreeBetLootBoxSearchViewModel();

    constructor(
        private readonly _router: Router,
        private readonly _searchManagementState: GaSearchManagementStateService<GaPagedResult<WlFreeBetLootBoxListModel>>,
        private readonly _service: BattlePassFreeBetLootBoxesService
    ) {}

    ngOnInit() {
        this.init();
    }

    ngOnDestroy() {}

    public add(): void {
        this._router.navigate(['/menu/battlePass/freeBetLootBox/create']);
    }

    public pageChanged(pagingEvent: GaPagingForm): void {
        this._searchManagementState.nextSearch(pagingEvent);
    }

    private init(): void {
        this._searchManagementState.init((form) => this._service.list(form as WlFreeBetLootBoxSearchForm), this.pagingModel);
        this._searchManagementState.searchState.pipe(untilDestroyed(this)).subscribe((result: GaPagedResult<WlFreeBetLootBoxListModel>) => {
            this.apiResult = result;
            this.loading = false;
            this.mapToTable(result.results);
        });
    }

    private mapToTable(models: WlFreeBetLootBoxListModel[]): void {
        const data = new GaTableData<WlFreeBetLootBoxListModel>();

        this.tableData = data
            .addRouterLinkColumn(
                (elem) => elem.id,
                (elem) => ['/menu/battlePass/freeBetLootBox/edit', elem.id],
                { title: 'Id', widthSize: 80 }
            )
            .addSimpleColumn((elem) => elem.name, { title: 'Название', widthSize: 450 })
            .addSimpleColumn((elem) => elem.entityActors.creatorAccountLogin, { title: 'Создано', widthSize: 150 })
            .addSimpleColumn((elem) => elem.entityActors.editorAccountLogin, { title: 'Изменено', widthSize: 150 })
            .addSimpleColumn((elem) => formatDateTime(elem.entityActors.createdDate), { title: 'Дата создания', widthSize: 130 })
            .addSimpleColumn((elem) => formatDateTime(elem.entityActors.modifiedDate), { title: 'Дата изменения', widthSize: 430 })
            .setData(models);
    }
}
