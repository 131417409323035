import {AbstractModelValidator} from '@koddington/ga-common';
import { WlDailyTasksDaysSelectorViewModel } from '../models/wl-daily-tasks-days-selector-view-model';


export class WlDailyTasksDaysSelectorValidator extends AbstractModelValidator<WlDailyTasksDaysSelectorViewModel> {
  constructor() {
    super();

    this.ruleForControl((u) => u.day).required('День обязателен');
  }
}
