import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { RateRaceNicknameSearchViewModel } from '../../models/wl-rate-race-nick-name-view-model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { finalize, map, switchMap, take } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subject } from 'rxjs';
import { WlRateRaceStatusModerationStrategy } from './strategy/wl-rate-race-nickname-moderation.strategy';
import { WlRateRaceNickNameEditViewModel } from '../../models/wl-rate-race-nick-name-edit-view-model';
import { formatDateTime, GaConfirmationService, GaMessagingService, GaPagedResult, GaPagingForm, GaTableCellTemplate, GaTableData, isNullOrUndefined, StrictFormControl, stringIsNullOrEmpty } from '@koddington/ga-common';
import {
    BattlePassRateRaceService, WlEntityActors, WlRateRaceNicknameModel, WlRateRaceNicknamePagingForm,
    WlRateRaceNicknameUpdateForm, WlRateRaceNicknameUpdateForms, WlRateRaceStatusModeration, WlRateRaceStatusModerationExtensions
} from 'src/app/modules/autogen/BattlePass';
import { SearchNavigationService } from 'src/app/modules/shared/services/search-navigation.service';
import { WlResult } from 'src/app/models/common/results/wl-result';
import { getNumberByKey, getStringByKey } from 'src/app/modules/shared/common/operation/common-operation';
import { builderStrictToModel } from 'src/app/modules/shared/common/operation/builder-operation';
import { RouterNavigationExtensions } from 'src/app/modules/shared/extensions/navigation-extensions';

@Component({
    selector: 'app-wl-rate-nickname-moderation',
    templateUrl: './wl-rate-race-nickname-moderation.component.html',
    styleUrls: ['./wl-rate-race-nickname-moderation.component.scss'],
})
export class WlRateRaceNicknameModerationComponent implements OnInit, OnDestroy {
    @ViewChild('statusModerate') statusModerate: TemplateRef<any>;
    @ViewChild('isModerate') isModerate: TemplateRef<any>;

    public searchViewModel: RateRaceNicknameSearchViewModel = new RateRaceNicknameSearchViewModel();
    public loading: boolean = false;
    public tableData: GaTableData<WlRateRaceNickNameEditViewModel>;
    public readonly userManipulationsSource = new Subject<void>();
    public pageResult: GaPagedResult<WlRateRaceNicknameModel>;
    public editViewModel: WlRateRaceNickNameEditViewModel[];
    public allItemButtonClick: boolean = false;

    constructor(
        private readonly _rateRaceService: BattlePassRateRaceService,
        private readonly _navigation: SearchNavigationService,
        private readonly _route: ActivatedRoute,
        private readonly _router: Router,
        public readonly _rateRaceStatusModerationStrategy: WlRateRaceStatusModerationStrategy,
        public readonly _confirmation: GaConfirmationService,
        private readonly _messages: GaMessagingService
    ) {}

    ngOnInit(): void {
        this.subscribeToLoad();
    }
    ngOnDestroy(): void {}

    private subscribeToLoad(): void {
        this._route.queryParams
            .pipe(
                map((params) => this.initViewModel(params)),
                map(() => this.createRateRaceNicknamePagingForm()),
                switchMap((value: WlRateRaceNicknamePagingForm) => this._rateRaceService.nicknameList(value)),
                untilDestroyed(this)
            )
            .subscribe((value) => {
                this.pageResult = value;
                if (!isNullOrUndefined(this.editViewModel)) {
                    this.editViewModel.length = 0;
                }

                this.editViewModel = this.mapWlRateRaceNicknameModelToRateRaceNicknameEditViewModel(value.results);
                this.mapToTable();
                this.loading = false;
                this.allItemButtonClick = false;
            });
    }
    public pageChanged(page: GaPagingForm) {
        this.search(page);
    }

    public pageFiltered(page: GaPagingForm) {
        page.offset = 0;
        this.search(page);
    }

    public selectStatus(): void {
        this.userManipulationsSource.next();
        this.search(null);
    }
    public getStatus(userId: number): string {
        const user = this.editViewModel.find((u) => u.userId === userId);

        return isNullOrUndefined(user.statusModeration.strictValue)
            ? WlRateRaceStatusModerationExtensions.format(WlRateRaceStatusModeration.Undefined)
            : WlRateRaceStatusModerationExtensions.format(user.statusModeration.strictValue);
    }

    public isCheckByUserId(userId: number): boolean {
        const user = this.editViewModel.find((u) => u.userId === userId);
        user.isModerate.valueChanges.pipe(take(1)).subscribe((u) => {
            if (u) {
                user.statusModeration.enable();
                return;
            }
            user.statusModeration.disable();
            return;
        });

        return user.isModerate.strictValue;
    }

    public getByUserId(userId: number): StrictFormControl<boolean> {
        const user = this.editViewModel.find((u) => u.userId === userId);
        return user.isModerate;
    }

    public getStatusByUserId(userId: number): StrictFormControl<WlRateRaceStatusModeration> {
        const user = this.editViewModel.find((u) => u.userId === userId);
        return user.statusModeration;
    }

    public chooseAndFill(): void {
        this.editViewModel.forEach((u) => {
            u.isModerate.strictValue = true;
            u.statusModeration.strictValue = WlRateRaceStatusModeration.Accept;
        });
        this.allItemButtonClick = true;
    }
    public chooseAndReset(): void {
        this.subscribeToLoad();
        this.allItemButtonClick = false;
    }

    public save(): void {
        this.loading = true;
        this._confirmation
            .openDialog('Вы уверены, что хотите сохранить изменения выбранных элементов?', 'Подтвердить')
            .pipe(
                take(1),
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe((isConfirm) => {
                if (isConfirm) {
                    this.updateModerate()
                        .pipe(take(1))
                        .subscribe((result) => {
                            if (result.isCorrect) {
                                this._messages.showMessage('Модерация никнеймов сохранена');
                                this.refresh('/menu/raterace/rate-race-nickname-moderation');
                                return;
                            }
                            this._messages.showMessage('Модерация никнеймов не сохранена');
                            return;
                        });
                    return;
                }
                this._messages.showMessage('Отмена сохранения');
                return;
            });
    }
    public updateModerate(): Observable<WlResult<boolean>> {
        let filterItems = this.editViewModel.filter((x) => x.isModerate.strictValue);
        let formToUpadte = filterItems.map((x) => {
            let itemToUpdate: WlRateRaceNicknameUpdateForm = new WlRateRaceNicknameUpdateForm();
            itemToUpdate.userId = x.userId;
            itemToUpdate.statusModeration = x.statusModeration.strictValue;
            itemToUpdate.nickname = x.nickname;
            return itemToUpdate;
        });

        if (formToUpadte.length === 0) {
            let result: WlResult<boolean> = new WlResult<boolean>({ isCorrect: false, error: null });
            return new Observable((u) => u.next(result));
        }

        let form: WlRateRaceNicknameUpdateForms = new WlRateRaceNicknameUpdateForms();
        form.updateForm = formToUpadte;
        return this._rateRaceService.updateNicknames(form);
    }
    private mapWlRateRaceNicknameModelToRateRaceNicknameEditViewModel(result: WlRateRaceNicknameModel[]): WlRateRaceNickNameEditViewModel[] {
        if (isNullOrUndefined(result)) return [];
        const editViewModel: WlRateRaceNickNameEditViewModel[] = result.map((u) => {
            let rrEditViewModel = new WlRateRaceNickNameEditViewModel();
            rrEditViewModel.userId = u.userId;
            rrEditViewModel.atUpdateModeration = u.atUpdateModeration;
            rrEditViewModel.moderator = this.getModeratorName(u.entityActors);
            rrEditViewModel.nickname = u.nickname;
            rrEditViewModel.statusModeration.strictValue = u.statusModeration;
            rrEditViewModel.currentLevel = u.levels;
            return rrEditViewModel;
        });
        return editViewModel;
    }

    private refresh(url: string): void {
        RouterNavigationExtensions.navigateAndRefresh(this._router, [url]);
    }
    private initViewModel(params: Params): void {
        this.loading = true;
        this.searchViewModel.seasonId.strictValue = getNumberByKey(params, 'seasonId');
        this.searchViewModel.fromLevelOrder.strictValue = getNumberByKey(params, 'fromLevelOrder');
        this.searchViewModel.toLevelOrder.strictValue = getNumberByKey(params, 'toLevelOrder');
        this.searchViewModel.statusModeration.strictValue = getNumberByKey(params, 'statusModeration');
        this.searchViewModel.userId.strictValue = getNumberByKey(params, 'userId');
        this.searchViewModel.nickname.strictValue = getStringByKey(params, 'nickname');
        this.searchViewModel.count.strictValue = getNumberByKey(params, 'count');
        this.searchViewModel.offset.strictValue = getNumberByKey(params, 'offset');
    }
    private createRateRaceNicknamePagingForm(): WlRateRaceNicknamePagingForm {
        return builderStrictToModel(WlRateRaceNicknamePagingForm, this.searchViewModel);
    }

    private search(page: GaPagingForm = null): void {
        const params: Params = {
            offset: page?.offset ?? this.searchViewModel.offset.strictValue,
            count: this.searchViewModel.count.strictValue,
            seasonId: this.searchViewModel.seasonId.strictValue,
            fromLevelOrder: this.searchViewModel.fromLevelOrder.strictValue,
            toLevelOrder: this.searchViewModel.toLevelOrder.strictValue,
            nickname: this.searchViewModel.nickname.strictValue,
            userId: this.searchViewModel.userId.strictValue,
            statusModeration: this.searchViewModel.statusModeration.strictValue,
        };
        this._navigation.search(this._route, params);
    }

    private mapToTable() {
        const data = new GaTableData<WlRateRaceNickNameEditViewModel>();

        this.tableData = data
            .addTemplateColumn(
                new GaTableCellTemplate(this.isModerate, (elem) => {
                    return {
                        userid: elem.userId,
                    };
                }),
                { title: 'Модерация', widthSize: 110 }
            )
            .addSimpleColumn((elem) => elem.userId.toString(), { title: 'Логин', widthSize: 110 })
            .addSimpleColumn((elem) => elem.nickname, { title: 'Никнейм', widthSize: 200 })
            .addTemplateColumn(
                new GaTableCellTemplate(this.statusModerate, (elem) => {
                    return {
                        userid: elem.userId,
                    };
                }),
                { title: 'Статус модерации', widthSize: 200 }
            )
            .addSimpleColumn((elem) => formatDateTime(elem.atUpdateModeration), { title: 'Дата последнего изменения', widthSize: 250 })
            .addSimpleColumn((elem) => elem.currentLevel.map((u) => u.currentLevel.toString() + ` (${u.seasonId.toString()})`).join(','), {
                title: 'Текущие уровни',
                widthSize: 250,
            })
            .addSimpleColumn((elem) => elem.moderator, { title: 'Модератор', widthSize: 150 })
            .setData(this.editViewModel);
    }

    private getModeratorName(actor: WlEntityActors): string {
        if (!stringIsNullOrEmpty(actor.editorAccountLogin)) return actor.editorAccountLogin;

        if (!stringIsNullOrEmpty(actor.creatorAccountLogin)) return actor.creatorAccountLogin;

        return '';
    }
}
