import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import * as dayjs from 'dayjs';
import {Dayjs} from 'dayjs/esm';
import { filter } from 'rxjs/operators';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {GaDateRangePickerDirective, isNullOrUndefined, StrictFormControl} from '@koddington/ga-common';
import updateLocale from 'dayjs/plugin/updateLocale';
import localeData from 'dayjs/plugin/localeData';
import ru from 'dayjs/locale/ru';
import {TimePeriod} from 'ngx-daterangepicker-material/daterangepicker.component';
dayjs.extend(updateLocale);
dayjs.extend(localeData);
dayjs.locale(ru);

@Component({
    selector: 'app-wl-datetime-single-picker',
    templateUrl: './wl-datetimepicker-single.component.html',
    styleUrls: ['./wl-datetimepicker-single.component.scss'],
})
export class WlDatePickerSingleComponent implements OnInit, OnDestroy {
    @ViewChild(GaDateRangePickerDirective, { static: true }) dateRangePicker: GaDateRangePickerDirective;
    @Input()
    date: StrictFormControl<Dayjs>;
    @Input()
    placeholder = 'Укажите дату';
    @Input()
    title = '';

    @Output() public userChosen: EventEmitter<void> = new EventEmitter<void>();

    public formGroupDates: FormGroup;
    public selected: any = null;


    locale: LocaleConfig = {
        format: 'DD.MM.YYYY HH:mm:ss',
        displayFormat: 'DD.MM.YYYY HH:mm:ss',
        direction: 'ltr',
        applyLabel: 'Применить',
        clearLabel: 'Очистить',
        daysOfWeek: dayjs.localeData().weekdaysMin(),
        monthNames: dayjs.localeData().monthsShort(),
        firstDay: dayjs.localeData().firstDayOfWeek(),
    };

    constructor(private fb: FormBuilder) {}

    ngOnDestroy(): void {}
    ngOnInit(): void {
        this.init();
        this.subscriptionToChange();
    }

    private init(): void {
        if (isNullOrUndefined(this.date)) {
            throw new Error('input control not defined.');
        }

        this.formGroupDates = this.fb.group({
            start: [
                {
                    startDate: null,
                    endDate: null,
                },
                Validators.required,
            ],
        });

        if (this.date.disabled && !this.formGroupDates.disabled) {
            this.formGroupDates.disable();
        }

        if (this.date.hasStrictValue) {
            this.selected = { startDate: null, endDate: null };

            if (typeof this.date.strictValue === 'string') {
                this.setDateTimeValue(dayjs(this.date.strictValue));
                return;
            }
            this.setDateTimeValue(this.date.strictValue);

            return;
        }
    }

    private subscriptionToChange(): void {
        this.date.valueChanges
            .pipe(
                filter((v) => !isNullOrUndefined(v)),
                untilDestroyed(this)
            )
            .subscribe((v) => {
                this.setDateTimeValue(v);
                if (this.date.disabled) {
                    this.formGroupDates.disable();
                    return;
                }
                this.formGroupDates.enable();
            });
    }

    private setDateTimeValue(dateTime: Dayjs | null): void {
        const dates = {
            start: {
                startDate: dateTime,
                endDate: dateTime,
            },
        };
        this.formGroupDates.patchValue(dates);
    }

    public onUpdate(date: TimePeriod): void {
        this.date.setValue(date?.startDate, {emitEvent: false});
        this.userChosen.emit();
    }

    get shouldDisplayErrors() {
        return this.date.shouldDisplayErrors;
    }

    get hasStrictErrors() {
        return this.date.hasStrictErrors;
    }
}
