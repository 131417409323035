import { Injectable, OnDestroy } from '@angular/core';
import { IAutocompleteItem } from '../../shared/components/strict-autocomplete/contracts/app-autocomplete-item';
import { ScrollStrategy } from 'src/app/common/abstracts/abstract-scroll-strategy';
import { WlRateRaceWrapperService } from '../services/wrapper-service/wl-rate-race-wrapper-service';
import { WlBattlePassRewardLiteModel, WlBattlePassRewardSearchForm } from '../../autogen/BattlePass';

@Injectable()
export class WlRateRaceRewardScrollStrategy extends ScrollStrategy<WlBattlePassRewardLiteModel, WlBattlePassRewardSearchForm> implements OnDestroy {
    constructor(protected readonly _apiClient: WlRateRaceWrapperService) {
        super(_apiClient);
    }
    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
    convert(model: WlBattlePassRewardLiteModel): IAutocompleteItem<WlBattlePassRewardLiteModel> {
        return this.convert_(() => {
            return {
                entity: model,
                title: model.name + ' (' + model.id.toString() + ')',
                key: model.id?.toString(),
            };
        });
    }
    public clear(): void {
        this._sourceClearAutocomplete$.next();
    }
}
