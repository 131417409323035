<div class="page-size-selection">
    <span>{{internalOptions.titles.pageSize}}</span>
    <select (change)="setPageSize($event.target.value)">
        <option *ngFor="let opt of internalOptions.pageSizes">{{opt.display}}</option>
    </select>
</div>
<div class="pages">
    <span class="first-page-button page-entry" *ngIf="internalOptions.firstPage" (click)="setPage(1)"
        [ngClass]="{'disabled':page.currentPage === 1}">
        {{internalOptions.titles.firstPage}}
    </span>
    <span class="prev-button page-entry" *ngIf="internalOptions.previousPage" (click)="previousPage()"
        [ngClass]="{'disabled':page.currentPage === 1}">
        {{internalOptions.titles.previousPage}}
    </span>
    <span class="page-selection">
        <span *ngFor="let num of range" class="page-entry" [ngClass]="{'active': num === page.currentPage}"
            (click)="setPage(num)">{{num}}</span>
    </span>
    <span class="next-button page-entry" *ngIf="internalOptions.nextPage" (click)="nextPage()"
        [ngClass]="{'disabled':page.currentPage === page.numberOfPages}">
        {{internalOptions.titles.nextPage}}
    </span>
    <span class="last-page-button page-entry" *ngIf="internalOptions.lastPage" (click)="setPage(page.numberOfPages)"
        [ngClass]="{'disabled':page.currentPage === page.numberOfPages}">
        {{internalOptions.titles.lastPage}}
    </span>
</div>