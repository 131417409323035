import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {WlMarketsParameterViewModel} from '../../models/wl-markets-parameter-view-model';
import {WlMarketsTypeStrategy} from '../../strategies/wl-markets-type-strategy';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {WlBattlePassMarketParameterValidator} from './validator/wl-battle-pass-market-parameter-validator';
import {Subject} from 'rxjs';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {WlBattlePassMarketsScrollServiceAdapter} from '../../services/wrapper-service/wl-battle-pass-markets.scroll-service-adapter';
import { StrictError } from '@koddington/ga-common';

@Component({
  selector: 'app-wl-battle-pass-markets-parameter',
  templateUrl: './wl-battle-pass-markets-parameter.component.html',
  styleUrls: ['./wl-battle-pass-markets-parameter.component.scss']
})
export class WlBattlePassMarketsParameterComponent implements OnInit, OnDestroy {

    public market: WlMarketsParameterViewModel;
    public marketsTypeStrategy: WlMarketsTypeStrategy;
    public readonly userManipulationsSource = new Subject<void>();
    public errors: StrictError[] = [];

    private readonly validator = new WlBattlePassMarketParameterValidator();

    constructor(private readonly _battlePassService: WlBattlePassMarketsScrollServiceAdapter,
                private readonly _dialogRef: MatDialogRef<WlBattlePassMarketsParameterComponent, WlMarketsParameterViewModel>,
                @Inject(MAT_DIALOG_DATA) public data: { market: WlMarketsParameterViewModel }) {
        this.market = data.market;
    }
    public ngOnInit(): void {
        this.marketsTypeStrategy = new WlMarketsTypeStrategy(this._battlePassService);
        this.userManipulationsSource.pipe(untilDestroyed(this)).subscribe(() => {
            this.errors = this.validator.validate(this.market);
        });
        this.userManipulationsSource.next();
    }

    public ngOnDestroy(): void { }

    public save(): void {
        this._dialogRef.close(this.market);
    }

    public close(): void {
        this._dialogRef.close();
    }

    get isValid(): boolean {
        return this.errors.length === 0;
    }
}
