import { DropdownItem, EnumExtensions, IDropdownStrategy } from '@koddington/ga-common';
import { Observable, of } from 'rxjs';
import { WlBetTypes, WlBetTypesExtensions } from '../../autogen/BattlePass';

export class BetTypeStrategy implements IDropdownStrategy<WlBetTypes> {
    getEntities(): Observable<Array<WlBetTypes>> {
        const entities = EnumExtensions.toArray(WlBetTypes);
        return of(entities);
    }

    map: (model: WlBetTypes) => DropdownItem<WlBetTypes> = model => {
        const item = new DropdownItem<WlBetTypes>();
        item.entity = model;
        item.title = WlBetTypesExtensions.format(model);
        item.id = Number(model);

        return item;
    }
}
