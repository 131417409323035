import {DropdownItem, EnumExtensions, IDropdownStrategy} from '@koddington/ga-common';
import {WlTaskTrackerPartnerStateTypes, WlTaskTrackerPartnerStateTypesExtensions} from '../../autogen/TaskTrackerPartner';
import {Observable, of} from 'rxjs';

export class WlTaskTrackerPartnerStateTypesStrategy implements IDropdownStrategy<WlTaskTrackerPartnerStateTypes> {
  getEntities(): Observable<Array<WlTaskTrackerPartnerStateTypes>> {
    const entities = EnumExtensions.toArray(WlTaskTrackerPartnerStateTypes);
    return of(entities);
  }

  map(model: WlTaskTrackerPartnerStateTypes): DropdownItem<WlTaskTrackerPartnerStateTypes> {
    const item = new DropdownItem<WlTaskTrackerPartnerStateTypes>();
    item.entity = model;
    item.title = WlTaskTrackerPartnerStateTypesExtensions.format(model);
    item.id = Number(model);

    return item;
  }
}
