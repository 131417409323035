<div class="autocomplete">
    <input type="text" class="{{customStyle}}"
           placeholder="{{placeholder}}"
           [formControl]="viewModel.term"
           matInput
           [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" id="autocomplete-list" class="autocomplete-items" [displayWith]="title()">
        <cdk-virtual-scroll-viewport class="autocomplete-viewport"
                                     itemSize="35" minBufferPx="140" maxBufferPx="140">
            <mat-option *cdkVirtualFor="let item of resultsSource() | async; index as i"
                        (click)="onClickOrSelect(item)"
                        (onSelectionChange)="onClickOrSelect(item)"
                        [class.focused]="this.currentFocus === i"
                        [class.selected]="this.item.key === selectedItem?.key"
                        [value]="item.title"
                        class="autocomplete-item">
                {{item.title}}
            </mat-option>
        </cdk-virtual-scroll-viewport>
    </mat-autocomplete>
    <div class="errors-list" *ngIf="(this.control.shouldDisplayErrors | async)">
        <li *ngFor="let error of this.control.strictErrors | async">
            {{error.message}}
        </li>
    </div>
</div>
