import {WlFreeBetLootBoxContentForm, WlFreeBetLootBoxCreateForm} from '../../../../autogen/BattlePass';
import {builderModelToStrict, builderStrictToModel, gaMap, MapExpression} from '../../../../shared/common/operation/builder-operation';
import {WlBattlePassFreeBetLootBoxContentsViewModel} from '../view-models/wl-battle-pass-free-bet-loot-box-contents-view-model';
import {WlBattlePassFreeBetLootBoxCrudViewModel} from '../view-models/wl-battle-pass-free-bet-loot-box-crud-view-model';

export class WlBattlePassFreeBetLootBoxMappers {
    public static mapLootBoxFormToStrict(viewModel: WlBattlePassFreeBetLootBoxCrudViewModel, form: WlFreeBetLootBoxCreateForm) {
        builderModelToStrict(viewModel, form, [
            {
                sourceMapProperty: 'lootBoxContents',
                targetMapProperty: 'lootBoxContents',
                mapObject: gaMap(form.lootBoxContents, (value) => {
                    const mapped = new WlBattlePassFreeBetLootBoxContentsViewModel();
                    builderModelToStrict(mapped, value);
                    return mapped;
                }),
            },
        ]);
    }

    public static mapLootBoxStrictToForm<T extends WlFreeBetLootBoxCreateForm>( type: { new (...args: any[]): T } , viewModel: WlBattlePassFreeBetLootBoxCrudViewModel): T {
        return builderStrictToModel(type, viewModel, this.strictToModelMappers(viewModel));
    }

    private static strictToModelMappers(viewModel: WlBattlePassFreeBetLootBoxCrudViewModel): MapExpression[] {
        return [
            {
                sourceMapProperty: 'lootBoxContents',
                targetMapProperty: 'lootBoxContents',
                mapObject: gaMap(viewModel?.lootBoxContents.strictValue, (value) => {
                    return builderStrictToModel(WlFreeBetLootBoxContentForm, value);
                }),
            },
        ];
    }
}
