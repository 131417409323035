<div class="wl-container">
    <app-wl-page-header header="{{purpose}}"></app-wl-page-header>
    <app-wl-loading *ngIf="loading"></app-wl-loading>
    <div class="wl-reward-row">
        <div class="wl-reward-label">
            Название
        </div>
        {{viewModel.name}}
    </div>
    <div class="wl-reward-row">
        <div class="wl-reward-label">
            Описание
        </div>
        <span>{{viewModel.description}}</span>
    </div>
    <div class="wl-reward-row">
        <div class="wl-reward-label">
            Ссылка на изображение
        </div>
        <span>{{viewModel.imageUrl}}</span>
    </div>
    <div class="wl-reward-row">
        <div class="wl-reward-label">
            Рыночная стоимость
        </div>
        <span>{{viewModel.marketPrice}}</span>
    </div>
    <div class="wl-reward-row">
        <div class="wl-reward-label">
            Имя ассета steam
        </div>
        <span>{{viewModel.hashName}}</span>
    </div>
    <div class="wl-reward-row">
        <div class="wl-reward-label">
            Количество
        </div>
        <span>{{viewModel.quantity}}</span>
    </div>
    <div class="wl-reward-row">
        <div class="wl-reward-label">
            Лимит на получение
        </div>
        <span>{{viewModel.limit}}</span>
    </div>
    <div class="wl-reward-row">
        <div class="wl-reward-label">
            Игра к которой относится предмет
        </div>
        <span>{{viewModel.game}}</span>
    </div>
    <div class="wl-reward-row">
        <div class="wl-reward-label">
            Тип предмета
        </div>
        <span>{{viewModel.type}}</span>
    </div>
    <div class="wl-reward-row">
        <div class="wl-reward-label">
            Группа предметов
        </div>
        <span>{{viewModel.group}}</span>
    </div>
    <div class="wl-reward-row">
        <div class="wl-reward-label">
            Категория предмета
        </div>
        <span>{{viewModel.category}}</span>
    </div>
    <div class="wl-reward-row">
        <div class="wl-reward-label">
            Редкость предмета
        </div>
        <span>{{viewModel.scarcity}}</span>
    </div>
    <div class="wl-reward-row">
        <div class="wl-reward-label">
            Редкость предмета
        </div>
        <span>{{viewModel.scarValue}}</span>
    </div>
    <div class="wl-reward-row">
        <div class="wl-reward-label">
            Качество предмета
        </div>
        <span>{{viewModel.quality}}</span>
    </div>
</div>
