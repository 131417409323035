import { Component, OnDestroy, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GaPagedResult, GaPagingForm } from '@koddington/ga-common';
import { BattlePassRewardsService, WlBattlePassRewardModel, WlBattlePassRewardSearchForm } from '../../../autogen/BattlePass';
import { GaTableData } from '@koddington/ga-common';

@Component({
    selector: 'app-wl-battle-pass-reward-list',
    templateUrl: './wl-battle-pass-reward-list.component.html',
})
export class WlBattlePassRewardListComponent implements OnInit, OnDestroy {
    rewards: GaPagedResult<WlBattlePassRewardModel>;
    loading: boolean;

    tableData: GaTableData<WlBattlePassRewardModel>;

    constructor(private readonly _battlePassRewardsService: BattlePassRewardsService, private readonly _router: Router) {}

    ngOnInit(): void {
        this.load(0);
    }

    ngOnDestroy(): void {}

    public pageChanged(page: GaPagingForm): void {
        this.load(page.offset);
    }

    private load(offset: number = 0): void {
        this.loading = true;
        const filter = new WlBattlePassRewardSearchForm();
        filter.count = 30;
        filter.offset = offset;

        this._battlePassRewardsService
            .list(filter)
            .pipe(take(1))
            .subscribe((res) => {
                this.rewards = res;
                this.mapToTable();
                this.loading = false;
            });
    }

    private mapToTable(): void {
        const data = new GaTableData<WlBattlePassRewardModel>();

        this.tableData = data
            .addRouterLinkColumn(
                (elem) => elem.id,
                (elem) => ['view', elem.id],
                { title: 'Id предмета', widthSize: 150 }
            )
            .addSimpleColumn((elem) => elem.name, { title: 'Название', widthSize: 500 })
            .addSimpleColumn((elem) => elem.description, { title: 'Описание', widthSize: 700 })
            .setData(this.rewards.results);
    }
}
