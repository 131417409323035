<div class="wl-main-body-container">
    <div class="wl-left-menu">
        <div class="wl-menu-sidenav" *ngFor="let item of menu; index as i">
            <div class="wl-menu-accardion-item" (click)="open(i)">
                <svg
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            [attr.d]="item.image"
                            fill="#000000de"
                    />
                </svg>
                <span class="wl-span-header-text">{{ item.nameMenu }}</span>
            </div>
            <div
                    class="wl-sidenav-item"
                    [ngClass]="{ 'wl-sidenav-item-active': openMenus[i] }"
                    *ngFor="let navItem of item.sideNav"
            >
                <a class="wl-route-item" [routerLink]="[navItem.url]">{{
                        navItem.name
                    }}</a>
            </div>
        </div>
    </div>
    <div class="wl-app-workspace-container">
        <router-outlet></router-outlet>
    </div>
</div>
