import { DropdownItem, EnumExtensions, IDropdownStrategy } from '@koddington/ga-common';
import { WlBattlePassStates, WlBattlePassStatesExtensions } from '../../autogen/BattlePass';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class WlBattlePassStatesStrategy implements IDropdownStrategy<WlBattlePassStates> {
    getEntities(): Observable<Array<WlBattlePassStates>> {
        const entities = EnumExtensions.toArray(WlBattlePassStates);
        return of(entities);
    }

    map: (model: WlBattlePassStates) => DropdownItem<WlBattlePassStates> = model => {
        const item = new DropdownItem<WlBattlePassStates>();
        item.entity = model;
        item.title = WlBattlePassStatesExtensions.format(model);
        item.id = Number(model);

        return item;
    }
}
