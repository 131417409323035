import { WlRateRaceGradeValidator } from './wl-rate-race-grade-validator';
import { WlRateRaceViewModel } from '../../../models/wl-rate-race-view-model';
import { AbstractModelValidator, AddErrorsToControlMode, StrictError, ValidationLevel } from '@koddington/ga-common';
import { StrictValidationRules } from 'src/app/modules/shared/validation-rules/strict-validation-rules';

export class WlRateRaceCrudValidator extends AbstractModelValidator<WlRateRaceViewModel> {
    private readonly gradeValidator = new WlRateRaceGradeValidator();

    constructor() {
        super();

        this.ruleForControl(u => u.name)
            .required('Имя турнира обязательно!');

        this.ruleForControl(u => u.description)
            .required('Описание турнира обязательно!');

        this.ruleForControl(u => u.startDate)
            .required('Дата старта обязательна!');

        this.ruleForControl(u => u.prizePlacesCount)
            .required('Количество призовых мест в турнире обязательно!')
            .customRule(u => StrictValidationRules.isInteger(u))
            .min(1, 'Количество призовых мест в турнире должно быть не меньше 1');

        this.ruleForControl(u => u.gradeDrops)
            .customRule(field => {
                if (field.length < 1) {
                    return new StrictError('minFieldError', 'Для RateRace требуется хотя бы один грейд');
                }

                if (field.length > 5) {
                    return new StrictError('minFieldError', 'Количество грейдов не должно превышать 5');
                }

                let accrualLootBoxCount = field.filter((value) => value.isAccrualLootBox.strictValue).length;
                if (accrualLootBoxCount > 1) {
                    return new StrictError('maxAccrualLootBoxCount', 'Количество лутбоксов для начисления не должно превышать 1');
                }

                if (accrualLootBoxCount != 1) {
                    return new StrictError('minAccrualLootBoxCount', 'Необходимо выбрать лутбокс для начисления');
                }

                return null;
            });
    }

    public validate(model: WlRateRaceViewModel, validationLevel?: ValidationLevel, addErrorsToControls?: AddErrorsToControlMode): StrictError[] {
        return [
            ...super.validate(model, validationLevel, addErrorsToControls),
            ...this.gradeValidator.validateArray(model.gradeDrops.strictValue, validationLevel, addErrorsToControls)
        ];
    }
}
